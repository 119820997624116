import * as React from "react";
import "./Forms.scss";

import AccessTimeRoundedIcon from "@material-ui/icons/AccessTimeRounded";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import BlockRoundedIcon from "@material-ui/icons/BlockRounded";
import { Typography } from "@material-ui/core";

import { ServiceFormStatus } from "../../../constants/serviceFormStatus";
import { StatusProps, useColorStyles } from "./types";

export function Status(props: StatusProps): JSX.Element {
  const { status } = props;
  const colors = useColorStyles();

  function resolveStatusIcon(serviceFormStatus: ServiceFormStatus): JSX.Element {
    switch (serviceFormStatus) {
      case "WaitingForApproval":
        return <AccessTimeRoundedIcon className={`${colors.blue} size-3`} />;
      case "WaitingForFinalApproval":
        return <AccessTimeRoundedIcon className={`${colors.green} size-3`} />;
      case "Approved":
        return <CheckCircleOutlineRoundedIcon className={`${colors.green} size-3`} />;
      case "Denied":
        return <BlockRoundedIcon className={`${colors.red} size-3`} />;
      case "Canceled":
        return <HighlightOffRoundedIcon className="size-3" />;
      case "Completed":
            return <CheckCircleRoundedIcon className={`${colors.green} size-3`} />;
      case "WaitingForBCSMApproval":
            return <AccessTimeRoundedIcon className={`${colors.blue} size-3`} />;
      default:
        return <div />;
    }
  }

  function resolveStatusText(serviceFormStatus: ServiceFormStatus): string {
    switch (serviceFormStatus) {
      case "WaitingForApproval":
        return "Waiting";
      case "WaitingForFinalApproval":
        return "Final Approval Pending";
      case "WaitingForBCSMApproval":
        return "BCSM Approval Pending";
      default:
        return serviceFormStatus;
    }
  }

  return (
    <div>
      {resolveStatusIcon(status)}
      <Typography className="status-text" variant="body2">
        {resolveStatusText(status)}
      </Typography>
    </div>
  );
}
