import "./Settings.scss";

import * as React from "react";
import { useSnackbar } from "notistack";

import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  DialogActions,
  Button
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { RoleResponse } from "../../../models";
import { PEER_REVIEWER, FORM_APPROVER } from "../../../constants/userRoles";
import { showInformationSnackbar } from "../../Snackbars/Snackbars";
import { RolesDialogProps } from "./types";

export function RolesDialog(props: RolesDialogProps): React.ReactElement {
  const { roles, selectedUser, open, handleDialogState, update } = props;

  const [currentRoles] = React.useState<RoleResponse[] | undefined>(selectedUser.userRole);
  const [selectedRoles, setSelectedRoles] = React.useState<RoleResponse[] | undefined>(
    selectedUser.userRole
    );
  const [render, reRender] = React.useState<boolean>(true);
  const incompatibleRoles: Map<string, string> = new Map([
    [FORM_APPROVER, PEER_REVIEWER],
    [PEER_REVIEWER, FORM_APPROVER]
  ]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function handleDialogClose(): void {
    selectedUser.userRole = currentRoles;
    handleDialogState(false, null);
  }

  function handleSavesRolesChange(): void {
    selectedUser.userRole = selectedRoles;
    if (selectedUser.userRole !== undefined) {
        update(selectedUser);
    }
  }

  function checkForIncompatibleRoles(role: RoleResponse, _roles: RoleResponse[]): boolean {
    const incompatibleRole = incompatibleRoles.get(role.name);
    if (incompatibleRole !== undefined) {
      const existingRoles = _roles.filter(r => r.name === incompatibleRole);
      if (existingRoles.length > 0) {
        showInformationSnackbar(
          enqueueSnackbar,
          closeSnackbar,
          "User can not be Form Approver and Peer Reviewer at the same time, please select only one."
        );
        return true;
      }
    }
    return false;
  }

  function handleChangeRoles(role: RoleResponse): void {
      if (selectedRoles !== undefined) {
      const localRoles = selectedRoles.slice();
      const existingRoles = localRoles.filter(localRole => localRole.id === role.id);
      if (existingRoles.length > 0) {
        const index = localRoles.indexOf(existingRoles[0]);
        localRoles.splice(index, 1);
      } else if (!checkForIncompatibleRoles(role, localRoles)) {
          localRoles.push(role);
      }
      setSelectedRoles(localRoles);
    }
    reRender(!render);
  }

  function userHasRole(roleName: string): boolean {
    if (selectedRoles) {
      const exists = selectedRoles.some(r => r.name === roleName);
      return exists;
    }
    return false;
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        User roles - {selectedUser.name}
        <IconButton aria-label="close" onClick={handleDialogClose} className="dialog-close-button">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <List>
          {roles.map(role => (
            <ListItem
              key={role.id}
              button
              onClick={(): void => handleChangeRoles(role)}
              className="dialog-list-item"
            >
              <Checkbox
                color="primary"
                checked={userHasRole(role.name)}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText
                className="dialog-list-item-text"
                primary={role.fullName}
                secondary={role.description}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSavesRolesChange} variant="outlined">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
