import "./Forms.scss";

import * as React from "react";
import * as Redux from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../../context/AuthContext";
import { AppContext } from "../../../context/AppContext";
import { ApplicationState } from "../../../redux/reducers";
import { ServiceFormResponse } from "../../../models/serviceFormResponses";
import {
  getServiceFormsDispatch,
  updateServiceFormStatusDispatch,
  updateServiceFormBCSMStatusDispatch,
  deleteServiceFormDispatch
} from "../../../services/ServiceFormService";
import { getAllUsersDispatch } from "../../../services";
import { FORMS_DETAILS } from "../../../constants/paths";
import { ServiceFormStatusRequest } from "../../../models";
import { ConfirmationType } from "../../Dialogs/types";
import { Confirmation } from "../../Dialogs";
import { ServiceFormsContainer } from "./ServiceFormsContainer";
import { ServiceFormCard } from "./ServiceFormCard";
import { Page } from "../Page";
import { FormsPageProps } from "./types";
import { isAllowed } from "../../Navigation/NavigationBar";
import { ADMIN, FORM_CREATOR, FORM_APPROVER, PEER_REVIEWER, BCSM_REVIEWER } from "../../../constants/userRoles";
import { showErrorSnackbar, showSuccessSnackbar } from "../../Snackbars/Snackbars";

function FormsPage(props: FormsPageProps): JSX.Element {
  const {
    deleteServiceForm,
    getServiceForms,
    serviceForms,
    updateServiceFormStatus,
    
    users,
    getAllUsers
  } = props;
  const { setSelectedTab } = React.useContext(AppContext);
  const { user, roles } = React.useContext(AuthContext);
  const history = useHistory();

  const [serviceFormsLoading, setServiceFormsLoading] = React.useState<boolean>(true);
  const [userServiceForms, setUserServiceForms] = React.useState<ServiceFormResponse[]>([]);
  const [assignedServiceForms, setAssignedServiceForms] = React.useState<ServiceFormResponse[]>([]);
  const [actionRequiredServiceForms, setactionRequiredServiceForms] = React.useState<
    ServiceFormResponse[]
  >([]);

  const [confirmationActive, setConfirmationActive] = React.useState<boolean>(false);
  const [confirmationMessage, setConfirmationMessage] = React.useState<string>("");
  const [confirmationCallback, setConfirmationCallback] = React.useState<(() => void) | undefined>(
    undefined
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (setSelectedTab) setSelectedTab(1);
    if (!serviceForms.pending && !serviceForms.completed) getServiceForms();
    if (!users.pending && !users.completed) getAllUsers();
  }, []);

  React.useEffect(() => {
    if (serviceForms.completed && user) {
      const filteredServiceForms = serviceForms.data
        .filter(sf => sf.userId === user?.id)
        .sort((a, b) => b.id - a.id);

      const approvalAssignedServiceForms = serviceForms.data.filter(
        sf => sf.approverId === user?.id
      );

      const reviewAssignedBCSMServiceForms = serviceForms.data.filter(sf =>
        sf.serviceFormBCSMReviewer?.bcsmReviewerId === user?.id);

        const actionReviewBCSMServiceForms = serviceForms.data.filter(sf =>
            sf.serviceFormBCSMReviewer?.bcsmReviewerId === user?.id &&
            sf.serviceFormBCSMReviewer?.reviewerStatus === "WaitingForBCSMApproval"
        );

      const reviewAssignedServiceForms = serviceForms.data.filter(sf =>
        sf.serviceFormPeerReviewers.some(r => r.peerReviewerId === user?.id)
      );
      
      const actionReviewServiceForms = serviceForms.data.filter(sf =>
        sf.serviceFormPeerReviewers.some(
          r => r.peerReviewerId === user?.id && r.reviewerStatus === "WaitingForApproval"
        )
      );

      const actionApproveServiceForms = serviceForms.data.filter(
        sf => sf.approverId === user?.id && sf.status === "WaitingForApproval"
      );

      const actionProceedServiceForms = serviceForms.data.filter(
        sf => sf.userId === user?.id && sf.status === "Approved"
      );

        const mergedActionServiceForms = [
        ...actionReviewBCSMServiceForms,
        ...actionReviewServiceForms,
        ...actionApproveServiceForms,
        ...actionProceedServiceForms
      ].sort((a, b) => b.id - a.id);

      const mergedAssignedServiceForms = [
        ...approvalAssignedServiceForms,
        ...reviewAssignedBCSMServiceForms,
        ...reviewAssignedServiceForms
      ].sort((a, b) => b.id - a.id);

      setUserServiceForms(filteredServiceForms);
      setAssignedServiceForms(mergedAssignedServiceForms);
      setactionRequiredServiceForms(mergedActionServiceForms);
      setServiceFormsLoading(false);
    }
  }, [serviceForms.data]);

  function closeConfirmationDialog(): void {
    setConfirmationActive(false);
  }

  function handleRedirectToShow(serviceFormId: number): void {
    history.push(FORMS_DETAILS.replace(":serviceFormId", String(serviceFormId)));
  }

  function handleServiceFormActivation(serviceFormId: number): void {
    if (user) {
      const request: ServiceFormStatusRequest = {
        id: serviceFormId,
        status: "WaitingForBCSMApproval",
        userId: user.id
      };
      updateServiceFormStatus(request)
        .then(sf =>
          showSuccessSnackbar(enqueueSnackbar, closeSnackbar, "Activated Cloud Service Request.")
        )
        .catch(error =>
          showErrorSnackbar(
            enqueueSnackbar,
            closeSnackbar,
            "Failed to activate Cloud Service Request."
          )
        );
    }
  }

  function handleServiceFormCancelation(serviceFormId: number): void {
    if (user) {
      const request: ServiceFormStatusRequest = {
        id: serviceFormId,
        status: "Canceled",
        userId: user.id
      };
      updateServiceFormStatus(request)
        .then(sf =>
          showSuccessSnackbar(enqueueSnackbar, closeSnackbar, "Canceled Cloud Service Request.")
        )
        .catch(error =>
          showErrorSnackbar(
            enqueueSnackbar,
            closeSnackbar,
            "Failed to cancel Cloud Service Request."
          )
        );
    }
  }

  function handleServiceFormDeletion(serviceFormId: number): void {
    if (user) {
      const filteredServiceForms = serviceForms.data.filter(sf => sf.id === serviceFormId);
      if (filteredServiceForms.length > 0) {
        deleteServiceForm(filteredServiceForms[0])
          .then(sf =>
            showSuccessSnackbar(enqueueSnackbar, closeSnackbar, "Deleted Cloud Service Request.")
          )
          .catch(error =>
            showErrorSnackbar(
              enqueueSnackbar,
              closeSnackbar,
              "Failed to delete Cloud Service Request."
            )
          );
      }
    }
  }

  function handleServiceFormCompletion(serviceFormId: number): void {
    if (user) {
      const request: ServiceFormStatusRequest = {
        id: serviceFormId,
        status: "Completed",
        userId: user.id
      };
      updateServiceFormStatus(request)
        .then(sf =>
          showSuccessSnackbar(enqueueSnackbar, closeSnackbar, "Completed Cloud Service Request.")
        )
        .catch(error =>
          showErrorSnackbar(
            enqueueSnackbar,
            closeSnackbar,
            "Failed to complete Cloud Service Request."
          )
        );
    }
  }

  function triggerConfirmationDialog(
    message: string,
    serviceFormId: number,
    type: ConfirmationType
  ): void {
    switch (type) {
      case "activate":
        setConfirmationCallback(() => (): void => handleServiceFormActivation(serviceFormId));
        break;
      case "cancel":
        setConfirmationCallback(() => (): void => handleServiceFormCancelation(serviceFormId));
        break;
      case "delete":
        setConfirmationCallback(() => (): void => handleServiceFormDeletion(serviceFormId));
        break;
      case "complete":
        setConfirmationCallback(() => (): void => handleServiceFormCompletion(serviceFormId));
        break;
      default:
        break;
    }

    setConfirmationMessage(message);
    setConfirmationActive(true);
  }

  return (
    <Page title="Cloud Service Requests">
      <div className="flex-row space-horizontal-16">
        {isAllowed([ADMIN, FORM_CREATOR], roles) && (
          <ServiceFormsContainer
            confirmation={triggerConfirmationDialog}
            createButton={<ServiceFormCard />}
            loading={serviceFormsLoading}
            onFormClickCallback={handleRedirectToShow}
            pageSize={5}
            serviceForms={userServiceForms}
            title="Requested by me"
          />
        )}
        {isAllowed([ADMIN, FORM_APPROVER, PEER_REVIEWER,BCSM_REVIEWER], roles) && (
          <ServiceFormsContainer
            loading={serviceFormsLoading}
            onFormClickCallback={handleRedirectToShow}
            pageSize={5}
            serviceForms={assignedServiceForms}
            title="Assigned to me"
            users={users.data}
          />
        )}
      </div>
      <Confirmation
        inProgress={serviceForms.deleting || serviceForms.updating}
        onClose={closeConfirmationDialog}
        onProceed={(): void => {
          if (confirmationCallback) confirmationCallback();
        }}
        open={confirmationActive}
        message={confirmationMessage}
      />
    </Page>
  );
}

function mapStateToProps(state: ApplicationState): unknown {
  return {
    serviceForms: state.serviceForms,
    users: state.users
  };
}

function mapDispatchToProps(dispatch: Redux.Dispatch<Redux.AnyAction>): unknown {
  return Redux.bindActionCreators(
    {
      deleteServiceForm: deleteServiceFormDispatch,
      getServiceForms: getServiceFormsDispatch,
      updateServiceFormStatus: updateServiceFormStatusDispatch,
      updateServiceFormBCSMReviewStatus :updateServiceFormBCSMStatusDispatch,
      getAllUsers: getAllUsersDispatch
    },
    dispatch
  );
}

export const Forms = connect(mapStateToProps, mapDispatchToProps)(FormsPage);
