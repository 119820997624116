/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";

import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { generateRandomGuid } from "../../utilities/StringOperations";

function action(key: string, closeSnackbar: any) {
  return (
    <IconButton
      onClick={() => {
        closeSnackbar(key);
      }}
    >
      <CloseIcon />
    </IconButton>
  );
}

export function showErrorSnackbar(enqueueSnackbar: any, closeSnackbar: any, message: string) {
  const key = generateRandomGuid();

  enqueueSnackbar(message.toString(), {
    variant: "error",
    anchorOrigin: { vertical: "bottom", horizontal: "right" },
    autoHideDuration: 30000,
    preventDuplicate: true,
    key,
    action: action(key, closeSnackbar)
  });
}

export function showSuccessSnackbar(enqueueSnackbar: any, closeSnackbar: any, message: string) {
  const key = generateRandomGuid();

  enqueueSnackbar(message.toString(), {
    variant: "success",
    anchorOrigin: { vertical: "bottom", horizontal: "right" },
    autoHideDuration: 5000,
    key,
    action: action(key, closeSnackbar)
  });
}

export function showInformationSnackbar(enqueueSnackbar: any, closeSnackbar: any, message: string) {
  const key = generateRandomGuid();

  enqueueSnackbar(message.toString(), {
    variant: "info",
    anchorOrigin: { vertical: "bottom", horizontal: "right" },
    autoHideDuration: 5000,
    preventDuplicate: true,
    key,
    action: action(key, closeSnackbar)
  });
}
