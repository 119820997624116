import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

import { requestAuthContext } from "./services/AuthenticationService";
import configureStore from "./redux/configureStore";
import { initialApplicationState } from "./redux/reducers";
import { AuthProvider } from "./context/AuthContext";
import { AppProvider } from "./context/AppContext";
import { App } from "./App";
import { AuthContextResponse } from "./types";

declare global {
  interface Window {
    authContext: AuthContextResponse;
  }
}

requestAuthContext().then(() => {
  const history = createBrowserHistory();
  const store = configureStore(history, initialApplicationState);

  ReactDOM.render(
    <AuthProvider>
      <BrowserRouter basename="/">
        <AppProvider>
          <App store={store} history={history} />
        </AppProvider>
      </BrowserRouter>
    </AuthProvider>,
    document.getElementById("root")
  );
});
