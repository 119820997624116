/* eslint-disable @typescript-eslint/no-use-before-define */
import "./Settings.scss";

import * as React from "react";
import MUIDataTable from "mui-datatables";
import { useSnackbar } from "notistack";

import { IconButton, Chip, Tooltip, Paper, ThemeProvider, Menu, MenuItem, Grid } from "@material-ui/core";
import ManageRolesIcon from "@material-ui/icons/VerifiedUserOutlined";
import DeleteIcon from '@material-ui/icons/Delete';

import { textFieldsTheme, darkTextFieldsTheme } from "../../../themes";
import { AuthContext } from "../../../context/AuthContext";
import { AppContext } from "../../../context/AppContext";
import { UserResponse, UpdateUserRoleRequestBody, DeleteUserRequestBody } from "../../../models";
import { showSuccessSnackbar, showErrorSnackbar } from "../../Snackbars/Snackbars";
import { RolesDialog } from "./RolesDialog";
import { UsersTableProps } from "./types";

export function Users(props: UsersTableProps): JSX.Element {
  const { roles, updateUserRole, users, confirmation} = props;
  const { darkMode } = React.useContext(AppContext);
  const { user } = React.useContext(AuthContext);

  const [updateInitiated, setUpdateInitiated] = React.useState<boolean>(false);
  const [usersList, setUsersList] = React.useState<UserResponse[]>([]);
  const [manageRolesDialogOpen, setManageRolesDialogOpen] = React.useState<boolean>(false);
  const [selectedUser, setSelectedUser] = React.useState<UserResponse | null>(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (users.completed && roles.completed) {
      setUsersList(users.data);
    }
  }, [users.completed, roles.completed]);

  React.useEffect(() => {
    if (updateInitiated && !users.updating) {
      if (users.error) {
        showErrorSnackbar(
          enqueueSnackbar,
          closeSnackbar,
          `Failed to update ${selectedUser?.name} user roles`
        );
      } else {
        showSuccessSnackbar(
          enqueueSnackbar,
          closeSnackbar,
          `Successfully updated ${selectedUser?.name} user roles.`
        );
      }

      handleDialogState(false, null);

      setUpdateInitiated(false);
      if (user && selectedUser) {
        if (user.id === selectedUser.id) {
          window.location.reload();
        }
      }
    }
  }, [users.updating]);

  function handleDialogState(dialogState: boolean, _user: UserResponse | null): void {
    setSelectedUser(_user);
    setManageRolesDialogOpen(dialogState);
  }

  function actionsRowRender(_user: UserResponse): unknown {
    return (
      <Tooltip title="Manage roles" placement="top-start">
        <IconButton onClick={(): void => handleDialogState(true, _user)} className="icon-button">
          <ManageRolesIcon />
        </IconButton>
      </Tooltip>
    );
  }

  function moreActionsRowRender(_user: UserResponse): unknown {
    return (
      <Tooltip title="Delete" placement="top-start">
        <IconButton onClick={()=> handleUserDelete(_user)} className="icon-button">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );
  }

  function handleUserDelete(_user: UserResponse): void {
    if (_user) {
        if (confirmation && _user) confirmation(`Are you sure you want to delete ${_user.name} ?`, _user, "delete");
    }
  }

  function userRolesRender(value: Array<string>): React.ReactFragment {
    return (
      <>
        {value?.map((roleName: string) => (
          <Chip
            key={roleName}
            color="primary"
            variant="outlined"
            style={{ color: darkMode ? "#ffffff" : undefined }}
            size="small"
            label={roleName}
            className="chip"
          />
        ))}
      </>
    );
  }

  function update(_user: UserResponse): void {
    if (_user.userRole) {
      const body: UpdateUserRoleRequestBody = {
        id: _user.id,
        userRole: _user.userRole
      };
      updateUserRole(body);
      setUpdateInitiated(true);
    }
  }

  return (
    <div>
      <ThemeProvider theme={darkMode ? darkTextFieldsTheme : textFieldsTheme}>
        <Paper variant="outlined">
          <MUIDataTable
            title="Users"
            columns={[
              {
                label: "Name",
                name: "name",
                options: {
                  sort: true,
                  sortDirection: "asc",
                  filter: false
                }
              },
              {
                label: "Email",
                name: "email",
                options: {
                  sort: true,
                  filter: false
                }
              },
              {
                label: "Roles",
                name: "roles",
                options: {
                  sort: false,
                  filter: true,
                  searchable: false,
                  customBodyRender: userRolesRender
                }
              },
              {
                label: "Actions",
                name: "actions",
                options: {
                  sort: false,
                  searchable: false,
                  filter: false
                }
              },
              {
                label: "Delete",
                name: "delete",
                options: {
                    sort: false,
                    searchable: false,
                    filter: false
                  }
              }
            ]}
            data={usersList.map(u => ({
              name: u.name,
              email: u.email,
              roles: u.userRole?.map(role => role.fullName),
              actions: actionsRowRender(u),
              delete: moreActionsRowRender(u)
            }))}
            options={{
              page: 0,
              pagination: true,
              selectableRows: "none",
              rowsPerPage: 10,
              rowsPerPageOptions: [10, 25, 50, 100],
              download: false,
              print: false,
              filter: true,
              elevation: 0
            }}
          />
        </Paper>
        {selectedUser !== null && user !== undefined && (
          <RolesDialog
            roles={roles.data}
            selectedUser={selectedUser}
            open={manageRolesDialogOpen}
            handleDialogState={handleDialogState}
            update={update}
          />
        )}
      </ThemeProvider>
    </div>
  );
}
