import { DeployablesColumnsIds, DeployablesColumnsText } from "../../../constants/deployablesColumns";

export function getDeployablesTableColumnsItems() {
  return [
    {
      id: DeployablesColumnsIds.GprId,
      text: DeployablesColumnsText.GprId
    },
    {
      id: DeployablesColumnsIds.ConsumerGprId,
      text: DeployablesColumnsText.ConsumerGprId
    },
    {
      id: DeployablesColumnsIds.Deployable,
      text: DeployablesColumnsText.Deployable
    },
    {
      id: DeployablesColumnsIds.ProductManager,
      text: DeployablesColumnsText.ProductManager
    },
    {
      id: DeployablesColumnsIds.DevelopmentLead,
      text: DeployablesColumnsText.DevelopmentLead
    },
    {
      id: DeployablesColumnsIds.ProjectType,
      text: DeployablesColumnsText.ProjectType
    },
    {
      id: DeployablesColumnsIds.ResourceGroup,
      text: DeployablesColumnsText.ResourceGroup
    },
    {
      id: DeployablesColumnsIds.ResourceGroupNameBase,
      text: DeployablesColumnsText.ResourceGroupNameBase
    },
    {
      id: DeployablesColumnsIds.AdvancementUnit,
      text: DeployablesColumnsText.AdvancementUnit
    },
    {
      id: DeployablesColumnsIds.Environment,
      text: DeployablesColumnsText.Environment
    },
    {
      id: DeployablesColumnsIds.SubscriptionName,
      text: DeployablesColumnsText.SubscriptionName
    },
  ];
}

export function getDeployablesTableRelationsItems() {
  return [
    {
      id: "equals",
      text: "Equals"
    },
    {
      id: "contains",
      text: "Contains"
    },
  ];
}