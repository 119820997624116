/* eslint-disable react/jsx-props-no-spreading */

import React from "react";
import "./Inputs.scss";

import { TextField, ClickAwayListener } from "@material-ui/core";

import { OutlinedTextFieldComponentProps } from "./types";

function isLastCharNumeric(string: string): boolean {
  return /^\d+$/.test(string.charAt(string.length - 1));
}

export const OutlinedTextField = React.memo((props: OutlinedTextFieldComponentProps) => {
  const {
    dispatch,
    fullWidth,
    handle,
    helperText,
    multiline,
    onChange,
    onlyNumbers,
    value,
    ...other
  } = props;

  const [localValue, setLocalValue] = React.useState<string>(String(value));

  React.useEffect(() => {
    setLocalValue(String(value));
  }, [value]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    event.preventDefault();

    if (onlyNumbers && !isLastCharNumeric(event.target.value) && event.target.value.length > 0)
      return;

    setLocalValue(event.target.value);
    if (onChange) onChange(event);
  }

  function triggerHandle(event: React.MouseEvent<Document, MouseEvent>): void {
    event.preventDefault();
    if (dispatch && handle) dispatch(handle(localValue));
    else if (handle) handle(localValue);
  }

  return (
    <ClickAwayListener
      mouseEvent={value === localValue ? false : "onClick"}
      onClickAway={triggerHandle}
    >
      <TextField
        {...other}
        className="full-width data-field"
        fullWidth={fullWidth || true}
        helperText={helperText || ""}
        margin="dense"
        multiline={multiline || false}
        onChange={handleChange}
        minRows={8}
        variant="outlined"
        value={localValue}
        InputLabelProps={{ className: "input-label" }}
      />
    </ClickAwayListener>
  );
});
