import * as React from "react";
import { Grid } from "@material-ui/core";
import { Definition } from "./Definition";
import { ContactsTable } from "./ContactsTable";
import { ContactRow, SharePointContactViewModel } from "./types";
import * as Utils from "../../../utilities/SharePointUtils";

function mapContactsToRows(data: { [name: string]: SharePointContactViewModel }): ContactRow[] {
  return Object.keys(data).map(key => ({
    name: data[key].name,
    phone: data[key].phone,
    role: Utils.getRoleNameByKey(key)
  }));
}

interface Props {
  contacts: { [name: string]: SharePointContactViewModel };
  definition: string;
}

export default (props: Props): JSX.Element => {
  const { contacts, definition } = props;
  return (
    <Grid container direction="row" justifyContent="flex-end" spacing={1}>
      <Grid item xs={12}>
        <ContactsTable contacts={mapContactsToRows(contacts)} />
      </Grid>
      {definition ? (
        <Grid item xs={12}>
          <Definition definition={definition} />
        </Grid>
      ) : null}
    </Grid>
  );
};
