import * as React from "react";
import "./Inputs.scss";

import { Autocomplete, RenderInputParams, UseAutocompleteSingleProps } from "@material-ui/lab";

import { OutlinedTextField } from "./OutlinedTextField";
import { OutlinedAutocompleteProps } from "./types";

export function OutlinedAutocomplete<T>(
  props: OutlinedAutocompleteProps<T> & UseAutocompleteSingleProps<T>
): JSX.Element {
  const {
    autoSelect,
    disabled,
    dispatch,
    error,
    freeSolo,
    handle,
    helperText,
    getOptionLabel,
    label,
    loading,
    onChange,
    onClose,
    onOpen,
    options,
    required,
    value
  } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectableOptions, setSelectableOptions] = React.useState<T[]>([]);

  React.useEffect(() => {
    if (options.length > 0) {
      if (getOptionLabel) {
        setSelectableOptions(
          options.sort((a, b) => getOptionLabel(a).localeCompare(getOptionLabel(b)))
        );
      }
      setSelectableOptions(options.sort((a, b) => String(a).localeCompare(String(b))));
    }
  }, [options]);

  function handleChange(event: React.ChangeEvent<{}>, newValue: T | null): void {
    event.preventDefault();
    if (dispatch && handle) dispatch(handle(newValue));
    else if (handle) handle(newValue);
    else if (onChange) onChange(event, newValue,"blur");
  }

  function handleClose(event: React.ChangeEvent<{}>): void {
    event.preventDefault();
    setOpen(false);
    if (onClose) onClose(event,"escape");
  }

  function handleOpen(event: React.ChangeEvent<{}>): void {
    event.preventDefault();
    setOpen(true);
    if (onOpen) onOpen(event);
  }

  function renderInput(params: RenderInputParams): JSX.Element {
    return (
      <OutlinedTextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        className=""
        disabled={disabled || false}
        error={error || false}
        helperText={helperText || ""}
        label={label}
        required={required || false}
        InputLabelProps={{ className: "" }}
      />
    );
  }

  return (
    <Autocomplete
      autoSelect={autoSelect || false}
      className="full-width data-field"
      disabled={disabled || false}
      freeSolo={freeSolo || false}
      getOptionLabel={getOptionLabel}
      loading={loading || false}
      loadingText="Loading..."
      onChange={handleChange}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      options={selectableOptions}
      renderInput={renderInput}
      value={value}
    />
  );
}
