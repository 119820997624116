import * as React from "react";

import {
  ButtonGroup,
  Button,
  Grid,
  Switch,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  withStyles
} from "@material-ui/core";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FieldValidator } from "../../../utilities/FieldValidator";
import * as actions from "./actions";
import { OutlinedTextField, OutlinedAutocomplete } from "../../Inputs";
import {
  DevOpsProjectResponse,
  ProductResponse,
  ScorecardSpecificationResponse,
  ScorecardProjectResponse,
  ScorecardItemResponse
} from "../../../models";
import { ScorecardProps } from "./types";

export function Scorecards(props: ScorecardProps): JSX.Element {
  const {
    azureDevOpsProjects,
    deployableName,
    devOpsProject,
    isScorecardRequired,
    dispatch,
    scorecardCategories,
    selectedItems,
    scorecardSpecifications,
    scorecardProjects,
    scorecardProject,
    releaseType,
    productType,
    products,
    product,
    devLeadEmail,
    productManagerEmail,
    serviceForm,
    isCloneEnabled,
    errors,
    displayError
  } = props;

  React.useEffect(() => {
    if (
      productType !== null &&
      scorecardCategories.completed &&
      scorecardCategories.data !== null
    ) {
      let mandatoryItems: ScorecardItemResponse[] = [];
      scorecardCategories.data.forEach(category => {
        mandatoryItems = mandatoryItems.concat(
          category.items.filter(
            item =>
              item.isLatest &&
              !item.isDeleted &&
              item.mandatory &&
              item.specGroups.find(
                specGroup =>
                  specGroup.specifications !== null &&
                  specGroup.specifications.find(spec => spec.id === productType.id)
              )
          )
        );
      });
      if (dispatch) dispatch(actions.selectValidator(mandatoryItems));
    }
  }, [productType]);

  React.useEffect(() => {
    let _scorecardProject = scorecardProjects.data.find(
      instance => instance.projectId === devOpsProject?.id
    );

    if (dispatch) {
      dispatch(actions.setScorecardProject(_scorecardProject || null));
    }
  }, [azureDevOpsProjects, scorecardProjects]);

  function not(a: ScorecardItemResponse[], b: ScorecardItemResponse[]) {
    return a.filter(value => b.indexOf(value) === -1);
  }

  function union(a: ScorecardItemResponse[], b: ScorecardItemResponse[]) {
    return [...a, ...not(b, a)];
  }

  const [expanded, setExpanded] = React.useState<string | false>();
  const handleExpansion = (panel: string) => (
    event: React.ChangeEvent<unknown>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleToggle = (item: ScorecardItemResponse) => () => {
    const currentItemIndex = selectedItems.indexOf(item);
    const newSelectedItems = [...selectedItems];

    if (currentItemIndex === -1) {
      newSelectedItems.push(item);
    } else {
      newSelectedItems.splice(currentItemIndex, 1);
    }

    if (dispatch) dispatch(actions.selectValidator(newSelectedItems));
  };

  const numberOfSelected = (items: ScorecardItemResponse[]) =>
    selectedItems.filter(value => items.indexOf(value) !== -1).length;

  const ExpansionPanel = withStyles({
    root: {
      border: "1px solid rgba(0, 0, 0, .125)",
      boxShadow: "none",
      "&:not(:last-child)": {
        borderBottom: 0
      },
      "&:before": {
        display: "none"
      },
      "&$expanded": {
        margin: "auto"
      }
    },
    expanded: {}
  })(MuiExpansionPanel);

  const ExpansionPanelSummary = withStyles({
    root: {
      backgroundColor: "rgba(0, 0, 0, .03)",
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
      marginBottom: -1,
      minHeight: 56,
      "&$expanded": {
        minHeight: 56
      }
    },
    content: {
      "&$expanded": {
        margin: "12px 0"
      }
    },
    expanded: {}
  })(MuiExpansionPanelSummary);

  const ExpansionPanelDetails = withStyles(theme => ({
    root: {
      padding: theme.spacing(1)
    }
  }))(MuiExpansionPanelDetails);

  if (isCloneEnabled)
    return (
      <div>
        <Typography color="textSecondary" variant="body2" style={{ marginBottom: 24 }}>
          Scorecard Association setup for cloned services is not supported.
        </Typography>
      </div>
    );
  return (
      <div>
        <ButtonGroup color="primary">
          <Button
            disableElevation
            onClick={(): void => {
              if (dispatch) dispatch(actions.setIsScorecardRequired(false));
            }}
            variant={!isScorecardRequired ? "contained" : "outlined"}
          >
            Release Validation is not required
          </Button>
          <Button
            disableElevation
            onClick={(): void => {
              if (dispatch) dispatch(actions.setIsScorecardRequired(true));
            }}
            variant={isScorecardRequired ? "contained" : "outlined"}
          >
            Setup Release Validation
          </Button>
        </ButtonGroup>
        <div className="mt-4">
          <Grid container direction="row" spacing={10}>
            <Grid item xs={4}>
              <Typography
                className="text-uppercase font-weight-bold"
                color="textSecondary"
                variant="body2"
              >
                Product
              </Typography>
              <Divider className="mt-2 mb-2" />
              <FieldValidator
                errors={errors}
                displayError={displayError}
                disabled={!isScorecardRequired || serviceForm !== null}
              >
                <OutlinedAutocomplete<DevOpsProjectResponse>
                  dispatch={dispatch}
                  disabled={serviceForm !== null || !isScorecardRequired}
                  getOptionLabel={(p: DevOpsProjectResponse): string => p.name}
                  handle={actions.setDevOpsProject}
                  label="Azure DevOps Project"
                  helperText='Select Azure DevOps project (Only "beconnect" is supported currently)'
                  loading={azureDevOpsProjects.pending}
                  options={azureDevOpsProjects.data}
                  required={isScorecardRequired}
                  value={devOpsProject}
                />
              </FieldValidator>
              {devOpsProject ? (
                <FieldValidator
                  errors={errors}
                  displayError={displayError}
                  disabled={!isScorecardRequired || serviceForm !== null}
                >
                  <OutlinedAutocomplete<ScorecardProjectResponse>
                    dispatch={dispatch}
                    disabled={serviceForm !== null || !isScorecardRequired}
                    getOptionLabel={(p: ScorecardProjectResponse): string => p.projectName}
                    handle={actions.setScorecardProject}
                    label="Scorecard Project"
                    helperText="Azure DevOps project registered in Scorecard Service"
                    loading={scorecardProjects.pending}
                    options={scorecardProjects.data.filter(
                      proj => proj.projectId === devOpsProject.id
                    )}
                    required={isScorecardRequired}
                    value={scorecardProject}
                  />
                </FieldValidator>
              ) : null}
              <FieldValidator
                errors={errors}
                displayError={displayError}
                disabled={!isScorecardRequired || serviceForm !== null}
              >
                <OutlinedAutocomplete<ProductResponse>
                  dispatch={dispatch}
                  disabled={serviceForm !== null || !isScorecardRequired}
                  getOptionLabel={(p: ProductResponse): string =>
                    `${p.productId} - ${p.productName}`
                  }
                  handle={actions.setProduct}
                  label="Product"
                  helperText="Select product from Global Product Registry site (GPRID - Product Name)"
                  loading={products.pending}
                  options={products.data}
                  required={isScorecardRequired}
                  value={product}
                />
              </FieldValidator>
              <FieldValidator
                errors={errors}
                displayError={displayError}
                disabled={!isScorecardRequired || serviceForm !== null}
              >
                <OutlinedTextField
                  dispatch={dispatch}
                  disabled={serviceForm !== null || !isScorecardRequired}
                  handle={actions.setProductManagerEmail}
                  label="Product Manager"
                  helperText="Provide Product Manager email"
                  required={isScorecardRequired}
                  value={productManagerEmail}
                />
              </FieldValidator>
            </Grid>
            <Grid item xs={4}>
              <Typography
                className="text-uppercase font-weight-bold"
                color="textSecondary"
                variant="body2"
              >
                Deployable
              </Typography>
              <Divider className="mt-2 mb-2" />
              <FieldValidator
                errors={errors}
                displayError={displayError}
                disabled={!isScorecardRequired || serviceForm !== null}
              >
                <OutlinedTextField
                  dispatch={dispatch}
                  disabled={serviceForm !== null || !isScorecardRequired}
                  handle={actions.setDeployableName}
                  label="Deployable Name"
                  helperText="Octopus project/deployable name"
                  required={isScorecardRequired}
                  value={deployableName}
                />
              </FieldValidator>
              <FieldValidator
                errors={errors}
                displayError={displayError}
                disabled={!isScorecardRequired || serviceForm !== null}
              >
                <OutlinedTextField
                  dispatch={dispatch}
                  disabled={serviceForm !== null || !isScorecardRequired}
                  handle={actions.setDevLeadEmail}
                  label="Lead Developer"
                  helperText="Provide Lead Developer email"
                  required={isScorecardRequired}
                  value={devLeadEmail}
                />
              </FieldValidator>
              {scorecardProject ? (
                <FieldValidator
                  errors={errors}
                  displayError={displayError}
                  disabled={!isScorecardRequired}
                >
                  <OutlinedAutocomplete<ScorecardSpecificationResponse>
                    dispatch={dispatch}
                    getOptionLabel={(p: ScorecardSpecificationResponse): string => p.value}
                    handle={actions.setReleaseType}
                    label="Release Type"
                    helperText="Select type of releases"
                    loading={scorecardSpecifications.pending}
                    options={scorecardSpecifications.data.filter(
                      spec => spec.type === "Release Type"
                    )}
                    required={isScorecardRequired}
                    value={releaseType}
                  />
                </FieldValidator>
              ) : null}
              {scorecardProject ? (
                <FieldValidator
                  errors={errors}
                  displayError={displayError}
                  disabled={!isScorecardRequired}
                >
                  <OutlinedAutocomplete<ScorecardSpecificationResponse>
                    dispatch={dispatch}
                    getOptionLabel={(p: ScorecardSpecificationResponse): string => p.value}
                    handle={actions.setProductType}
                    label="Product Type"
                    helperText="Select type of product. Product Types are used to define which mandatory items should be applicable to specific Product Type"
                    loading={scorecardSpecifications.pending}
                    options={scorecardSpecifications.data.filter(
                      spec =>
                        spec.type === "Product Type" &&
                        spec.productTypeId === scorecardProject.productTypeId
                    )}
                    required={isScorecardRequired}
                    value={productType}
                  />
                </FieldValidator>
              ) : null}
            </Grid>
            <Grid item xs={4}>
              <Typography
                className="text-uppercase font-weight-bold"
                color="textSecondary"
                variant="body2"
              >
                Validators
              </Typography>
              <Divider className="mt-2 mb-2" />
              {scorecardProject && productType && isScorecardRequired ? (
                <Paper  
                  variant="outlined"
                  style={{
                    border: 0,
                    height: "fit-content",
                    maxHeight: "55vh",
                    overflowY: "auto",
                    background: "inherit"
                  }}
                >
                  {scorecardCategories.data
                    .filter(category => category.productTypeId === scorecardProject.productTypeId)
                    .sort()
                    .map(category => {
                      return (
                        <ExpansionPanel
                          key={`panel${category.id}`}
                          square
                          expanded={expanded === `panel${category.id}`}
                          onChange={handleExpansion(`panel${category.id}`)}
                        >
                          <ExpansionPanelSummary
                            key={`panel-summary${category.id}`}
                            expandIcon={<ExpandMoreIcon />}
                          >
                            <Typography>
                              {category.name} ({numberOfSelected(category.items)}/
                              {category.items.length})
                            </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails key={`panel-details${category.id}`}>
                            <List
                              key={`category${category.id}`}
                              component="div"
                              style={{ width: "100%" }}
                            >
                              {category.items
                                .filter(item => item.isLatest && !item.isDeleted)
                                .sort()
                                .map(item => {
                                  return (
                                    <div key={`item-${category.id}-${item.id}`}>
                                      {category.items.indexOf(item) === 0 ? null : (
                                        <Divider variant="middle" />
                                      )}
                                      <ListItem key={`item-${category.id}-${item.id}`}>
                                        <ListItemText
                                          key={`item-text-${category.id}-${item.id}`}
                                          primary={item.name}
                                          style={{ wordWrap: "break-word", marginRight: 14 }}
                                        />
                                        <ListItemSecondaryAction
                                          key={`item-action-${category.id}-${item.id}`}
                                        >
                                          <Switch
                                            key={`item-switch-${category.id}-${item.id}`}
                                            color="primary"
                                            disabled={
                                              item.mandatory &&
                                              item.specGroups.find(
                                                specGroup =>
                                                  specGroup.specifications !== null &&
                                                  specGroup.specifications !== undefined &&
                                                  specGroup.specifications.find(
                                                    spec => spec.id === productType.id
                                                  )
                                              ) !== null &&
                                              item.specGroups.find(
                                                specGroup =>
                                                  specGroup.specifications !== null &&
                                                  specGroup.specifications !== undefined &&
                                                  specGroup.specifications.find(
                                                    spec => spec.id === productType.id
                                                  )
                                              ) !== undefined
                                            }
                                            edge="end"
                                            checked={selectedItems.some(x => x.id === item.id)}
                                            onClick={handleToggle(item)}
                                          />
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    </div>
                                  );
                                })}
                            </List>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      );
                    })}
                </Paper>
              ) : (
                <Typography color="textSecondary" variant="caption">
                  Requires Azure DevOps Project, Product & Product Type selected.
                </Typography>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
}
