import * as React from "react";
import { Link, useHistory } from "react-router-dom";

import TimeAgo from "react-timeago";
import EventNoteRoundedIcon from "@material-ui/icons/EventNoteRounded";
import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  Paper,
  Typography,
  Grid,
  Button,
  Tooltip,
  IconButton,
  MenuItem,
  Menu
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { NEW_FORM, FORMS_EDIT, PROVISION_FORM } from "../../../constants/paths";
import { ClickSuppressor } from "../../ClickSuppressor";
import { preventOnClick } from "../..";
import { Status } from "./Status";
import { ServiceFormCardProps } from "./types";

export function ServiceFormCard(props: ServiceFormCardProps): JSX.Element {
  const { confirmation, loading, onClick, serviceForm, showMenu, users } = props;

  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const activateMessage =
    "Are you sure you want to activate this cloud service setup request? After activation no more changes can be done.";
  const cancelMessage =
    "Are you sure you want to cancel this cloud service setup request? All approval progress will be lost, but you will be able to edit it.";
  const deleteMessage = "Are you sure you want to delete this cloud service setup request?";
  const completeMessage =
    "Are you sure you want to complete this cloud service setup request? After completion cloud service setup request will be closed and service provisioning using this request will not be available anymore.";

  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    event.preventDefault();
    if (onClick && serviceForm) onClick(serviceForm.id);
  }

  function handleMenuClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();
    setAnchorEl(null);
  }

  function handleServiceFormActivate(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
    event.stopPropagation();
    setAnchorEl(null);
    if (confirmation && serviceForm) confirmation(activateMessage, serviceForm.id, "activate");
  }

  function handleServiceFormCancel(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
    event.stopPropagation();
    setAnchorEl(null);
    if (confirmation && serviceForm) confirmation(cancelMessage, serviceForm.id, "cancel");
  }

  function handleServiceFormDelete(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
    event.stopPropagation();
    setAnchorEl(null);
    if (confirmation && serviceForm) confirmation(deleteMessage, serviceForm.id, "delete");
  }

  function handleServiceFormEdit(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
    event.stopPropagation();
    if (serviceForm) history.push(FORMS_EDIT.replace(":serviceFormId", String(serviceForm.id)));
  }

  function handleServiceFormProceed(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
    event.stopPropagation();
    if (serviceForm) history.push(PROVISION_FORM.replace(":serviceFormId", String(serviceForm.id)));
  }

  function handleServiceFormCompleted(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
    event.stopPropagation();
    setAnchorEl(null);
    if (confirmation && serviceForm) confirmation(completeMessage, serviceForm.id, "complete");
  }

  function renderMenu(): JSX.Element | null {
    if (serviceForm) {
      switch (serviceForm.status) {
        case "Approved":
          return (
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleServiceFormProceed}>Proceed</MenuItem>
              <MenuItem onClick={handleServiceFormCompleted}>Complete</MenuItem>
              <MenuItem onClick={handleServiceFormCancel}>Cancel</MenuItem>
            </Menu>
          );
        case "Canceled":
          return (
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleServiceFormActivate}>Activate</MenuItem>
              <MenuItem onClick={handleServiceFormEdit}>Edit</MenuItem>
              <MenuItem onClick={handleServiceFormDelete}>Delete</MenuItem>
            </Menu>
          );
        case "Denied":
          return (
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleServiceFormDelete}>Delete</MenuItem>
            </Menu>
          );
        case "WaitingForBCSMApproval":
          return (
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleServiceFormCancel}>Cancel</MenuItem>
            </Menu>
          );
        case "WaitingForApproval":
          return (
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleServiceFormCancel}>Cancel</MenuItem>
            </Menu>
          );
        case "WaitingForFinalApproval":
          return (
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleServiceFormCancel}>Cancel</MenuItem>
            </Menu>
          );
        default:
          return null;
      }
    }

    return null;
  }

  if (loading) {
    return (
      <Skeleton animation="wave" className="service-form-card MuiPaper-rounded" variant="rect" />
    );
  }

  if (!serviceForm) {
    return (
      <Button
        className="service-form-card-button"
        color="secondary"
        component={Link}
        variant="outlined"
        to={NEW_FORM}
      >
        Create new cloud service setup request
      </Button>
    );
  }

  return (
    <Paper className="service-form-card" onClick={handleClick} variant="outlined">
      <Grid
        alignItems="center"
        className="flex-row margin-0 full-width padding-right"
        container
        direction="row"
        justifyContent="space-between"
        spacing={5}
        wrap="nowrap"
        zeroMinWidth
      >
        <Grid className="service-form-card-status" item xs={2}>
          <Status status={serviceForm.status} />
        </Grid>
        <Grid className="flex-column" item xs={6}>
          <Typography
            className="service-form-card-title"
            onClick={preventOnClick}
            variant="body1"
            noWrap
          >
            {serviceForm.projectName}
          </Typography>
          <Typography color="textSecondary" onClick={preventOnClick} variant="body2">
            Product: {serviceForm.productName} ({serviceForm.productId})
          </Typography>
          <Typography color="textSecondary" onClick={preventOnClick} variant="body2" noWrap>
            Region: {serviceForm.region}
          </Typography>
          <Typography color="textSecondary" onClick={preventOnClick} variant="body2" noWrap>
             Created By: {serviceForm.formCreator}
          </Typography>
          {users && (
            <Typography color="textSecondary" onClick={preventOnClick} variant="body2" noWrap>
              Updated By: {users?.find(u => u.id === serviceForm.userId)?.name}
            </Typography>
          )}   
        </Grid>
        <Grid className="flex-column space-vertical-4" item xs={3}>
          <div className="flex-row space-horizontal-4">
            <ClickSuppressor>
              <Tooltip placement="left" title="Created">
                <EventNoteRoundedIcon className="size-1-5" />
              </Tooltip>
            </ClickSuppressor>
            <ClickSuppressor>
              <TimeAgo date={`${serviceForm.createdTime}Z`} />
            </ClickSuppressor>
          </div>
          <div className="flex-row space-horizontal-4">
            <ClickSuppressor>
              <Tooltip placement="left" title="Last update">
                <UpdateRoundedIcon className="size-1-5" />
              </Tooltip>
            </ClickSuppressor>
            <ClickSuppressor>
              <TimeAgo date={`${serviceForm.updateTime}Z` || -1} />
            </ClickSuppressor>
          </div>
        </Grid>
        <Grid item xs={1}>
          {showMenu && (
            <IconButton aria-label="more" onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
          )}
          {showMenu && renderMenu()}
        </Grid>
      </Grid>
    </Paper>
  );
}
