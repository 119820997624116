export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_ERROR = "GET_ALL_CATEGORIES_ERROR";

export const GET_ALL_ITEMS = "GET_ALL_ITEMS";
export const GET_ALL_ITEMS_SUCCESS = "GET_ALL_ITEMS_SUCCESS";
export const GET_ALL_ITEMS_ERROR = "GET_ALL_ITEMS_ERROR";

export const GET_ALL_SPECIFICATIONS = "GET_ALL_SPECIFICATIONS";
export const GET_ALL_SPECIFICATIONS_SUCCESS = "GET_ALL_SPECIFICATIONS_SUCCESS";
export const GET_ALL_SPECIFICATIONS_ERROR = "GET_ALL_SPECIFICATIONS_ERROR";

export const GET_ALL_SCORECARD_PRODUCTS = "GET_ALL_SCORECARD_PRODUCTS";
export const GET_ALL_SCORECARD_PRODUCTS_SUCCESS = "GET_ALL_SCORECARD_PRODUCTS_SUCCESS";
export const GET_ALL_SCORECARD_PRODUCTS_ERROR = "GET_ALL_SCORECARD_PRODUCTS_ERROR";

export const GET_ALL_SCORECARD_PROJECTS = "GET_ALL_SCORECARD_PROJECTS";
export const GET_ALL_SCORECARD_PROJECTS_SUCCESS = "GET_ALL_SCORECARD_PROJECTS_SUCCESS";
export const GET_ALL_SCORECARD_PROJECTS_ERROR = "GET_ALL_SCORECARD_PROJECTS_ERROR";

export const CREATE_ASSOCIATION = "CREATE_ASSOCIATION";
export const CREATE_ASSOCIATION_SUCCESS = "CREATE_ASSOCIATION_SUCCESS";
export const CREATE_ASSOCIATION_ERROR = "CREATE_ASSOCIATION_ERROR";
export const CREATE_ASSOCIATION_RESET = "CREATE_ASSOCIATION_RESET";
