/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { action } from "typesafe-actions";
import { RegionConfigurationResponse } from "../../models/configurationResponses";
import * as actions from "../constants/configurationActionTypes";

export function getRegions() {
  return action(actions.GET_REGIONS);
}

export function getRegionsSuccess(users: RegionConfigurationResponse[]) {
  return action(actions.GET_REGIONS_SUCCESS, users);
}

export function getRegionsError(error: Error) {
  return action(actions.GET_REGIONS_ERROR, error);
}
