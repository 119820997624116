export function getRoleNameByKey(key: string): string {
  if (key === "primaryContactUS") return "Primary Contact US";

  if (key === "primaryContactEU") return "Primary Contact EU";

  if (key === "primaryContactAsia") return "Primary Contact Asia";

  if (key === "primaryDev") return "Primary Developer";

  if (key === "secondaryDev") return "Secondary Developer";

  if (key === "devManager") return "Dev Manager";

  if (key === "productManager") return "Product Manager";

  if (key === "supportManager") return "Support Manager";

  if (key === "secondarySupport") return "Secondary support";

  return "Unidentified";
}
