import * as React from "react";
import { InputBase } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import * as Constants from "./constants";

interface Props {
  definition: string;
  setChangesHandler: (value: string) => void;
}

export default function DefinitionEdit(props: Props): JSX.Element {
  const { definition, setChangesHandler } = props;
  const data = {
    definition: (
      <InputBase
        defaultValue={definition}
        placeholder="Enter service definition"
        fullWidth
        multiline
        onChange={e => setChangesHandler(e.target.value)}
      />
    )
  };
  return (
    <MUIDataTable
      title=""
      columns={Constants.DefinitionTableColumn}
      data={[data]}
      options={Constants.DefinitionTableOptions}
    />
  );
}
