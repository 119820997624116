/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { action } from "typesafe-actions";

import {
  DevOpsProjectResponse,
  OctopusProjectResponse,
  OctopusARMStepResponse,
  OctopusLibraryVariableSetResponse,
  OctopusProjectGroupResponse,
  OctopusLifecycleResponse,
  ScorecardCategoryResponse,
  ProductResponse,
  ScorecardSpecificationResponse,
  ScorecardProjectResponse,
  ScorecardItemResponse,
  DevOpsEnvironmentResponse,
  OpTestsServiceResponse,
  RegionConfigurationResponse,
  BUDDIUrl,
  BUDDIRegionalUrlResponse,
  ServiceFormResponse
} from "../../../models";
import * as actionType from "./actionTypes";
import { ProvisioningOperation } from "./ProvisionProgress";

export function setAdditionalResources(value: OctopusARMStepResponse[]) {
  return action(actionType.SET_ADDITIONAL_RESOURCES, value);
}
export function setDeployableName(value: string) {
  return action(actionType.SET_DEPLOYABLE_NAME, value);
}
export function setIsOctopusRequired(value: boolean) {
  return action(actionType.SET_IS_OCTOPUS_REQUIRED, value);
}
export function setDevOpsProject(value: DevOpsProjectResponse | null) {
  return action(actionType.SET_DEVOPS_PROJECT, value);
}
export function setDevOpsTeamName(value: string | null) {
  return action(actionType.SET_DEVOPS_TEAM_NAME, value);
}
export function setGitRepoName(value: string) {
  return action(actionType.SET_GIT_REPO_NAME, value);
}
export function setIsBuildPipelineRequired(value: boolean) {
  return action(actionType.SET_IS_BUILD_PIPELINE_REQUIRED, value);
}
export function setIsCloneEnabled(value: boolean) {
  return action(actionType.SET_IS_CLONE_ENABLED, value);
}
export function setIsGitRepoRequired(value: boolean) {
  return action(actionType.SET_IS_GIT_REPO_REQUIRED, value);
}
export function setIsReleasePipelineRequired(value: boolean) {
  return action(actionType.SET_IS_RELEASE_PIPELINE_REQUIRED, value);
}
export function setIsEscalationContactsRequired(value: boolean) {
  return action(actionType.SET_IS_ESCALATION_CONTACTS_REQUIRED, value);
}
export function setLifecycle(value: OctopusLifecycleResponse | null) {
  return action(actionType.SET_LIFECYCLE, value);
}
export function setOctopusProject(value: OctopusProjectResponse | null) {
  return action(actionType.SET_OCTOPUS_PROJECT, value);
}
export function setSetProjectGroup(value: OctopusProjectGroupResponse | null) {
  return action(actionType.SET_PROJECT_GROUP, value);
}
export function setProjectType(value: OctopusProjectResponse | null) {
  return action(actionType.SET_PROJECT_TYPE, value);
}
export function setRegion(value: RegionConfigurationResponse | null) {
  return action(actionType.SET_REGION, value);
}
export function setSubscription(value: OctopusLibraryVariableSetResponse | null) {
  return action(actionType.SET_SUBSCRIPTION, value);
}
export function setIsScorecardRequired(value: boolean) {
  return action(actionType.SET_IS_SCORECARD_REQUIRED, value);
}
export function setProduct(value: ProductResponse | null) {
  return action(actionType.SET_GPR_PRODUCT, value);
}
export function setScorecardProject(value: ScorecardProjectResponse | null) {
  return action(actionType.SET_SCORECARD_PROJECT, value);
}
export function setReleaseType(value: ScorecardSpecificationResponse | null) {
  return action(actionType.SET_RELEASE_TYPE, value);
}
export function setProductType(value: ScorecardSpecificationResponse | null) {
  return action(actionType.SET_PRODUCT_TYPE, value);
}
export function setProductManagerEmail(value: string) {
  return action(actionType.SET_PRODUCT_MANAGER_EMAIL, value);
}
export function setDevLeadEmail(value: string) {
  return action(actionType.SET_DEV_LEAD_EMAIL, value);
}
export function selectValidator(value: ScorecardItemResponse[]) {
  return action(actionType.SELECT_VALIDATOR, value);
}
export function setIsOperationalTestsRequired(value: boolean) {
  return action(actionType.SET_IS_OPERATIONAL_TESTS_REQUIRED, value);
}
export function setOperationalTestsService(value: OpTestsServiceResponse | null) {
  return action(actionType.SET_OPERATIONAL_TESTS_SERVICE, value);
}
export function setOperationalTestsCrontabExpression(value: string) {
  return action(actionType.SET_OPERATIONAL_TESTS_CRONTAB_EXPRESSION, value);
}
export function setOperationalTestsTimeout(value: string) {
  return action(actionType.SET_OPERATIONAL_TESTS_TIMEOUT, value);
}
export function setOperationalTestsNotificationsList(value: string) {
  return action(actionType.SET_OPERATIONAL_TESTS_NOTIFICATIONS_LIST, value);
}
export function setIsGeoReplicated(value: boolean) {
  return action(actionType.SET_IS_GEO_REPLICATED, value);
}
export function setOTDisabled(value: boolean) {
  return action(actionType.SET_OT_DISABLED, value);
}
export function setOTHighSeverity(value: boolean) {
  return action(actionType.SET_OT_HIGH_SEVERITY, value);
}
export function setIsSeqRequired(value: boolean) {
  return action(actionType.SET_IS_SEQ_REQUIRED, value);
}
export function setSeqKeyEnvironments(value: string[]) {
  return action(actionType.SET_SEQ_KEY_ENVIRONMENTS, value);
}
export function setIsBuddiRequired(value: boolean) {
  return action(actionType.SET_IS_BUDDI_REQUIRED, value);
}
export function setBuddiUrl(value: BUDDIUrl | null) {
  return action(actionType.SET_BUDDI_URL, value);
}
export function setBuddiRegionalUrlsRequired(value: boolean) {
  return action(actionType.SET_BUDDI_REGIONAL_URL_REQUIRED, value);
}
export function setBuddiRegionalUrls(value: BUDDIRegionalUrlResponse[]) {
  return action(actionType.SET_BUDDI_REGIONAL_URL, value);
}
export function setServiceForm(value: ServiceFormResponse | null) {
  return action(actionType.SET_SERVICE_FORM, value);
}
export function setOperations(value: ProvisioningOperation[]) {
  return action(actionType.SET_OPERATIONS, value);
}
export function setBuildFolderPath(value: string) {
  return action(actionType.SET_BUILD_FOLDER_PATH, value);
}
