import * as React from "react";

import { authorize } from "../services/AuthorizationService";
import { AuthContextValue, AuthProviderProps } from "./types";
import { getUserByName } from "../services/UserService";
import { getUserRoles } from "../services/RoleService";
import { RoleResponse } from "../models/roleResponses";
import { UserResponse } from "../models/userResponses";

function handleLogout(): void {
  if (window.authContext && window.authContext.clientApp) {
    window.authContext.clientApp.logout();
  }
}

const defaultValue: AuthContextValue = {
  authenticated: false,
  inProgress: false,
  logout: handleLogout,
  roles: undefined,
  user: undefined
};

export const AuthContext = React.createContext<AuthContextValue>(defaultValue);

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const [authenticated] = React.useState<boolean>(!!window.authContext.clientApp.getAccount());
  const [inProgress, setInProgress] = React.useState<boolean>(defaultValue.inProgress);
  const [roles, setRoles] = React.useState<RoleResponse[] | undefined>(defaultValue.roles);
  const [user, setUser] = React.useState<UserResponse | undefined>(defaultValue.user);

  const { logout } = defaultValue;

  React.useEffect(() => {
    if (authenticated) {
      setInProgress(true);
      const account = window.authContext.clientApp.getAccount();
      authorize(account)
        .then(authorized => {
          if (authorized) {
            getUserByName(account.name).then(currentUser => {
              if (currentUser) setUser(currentUser);
            });
          }
        })
        .catch(() => setInProgress(false));
    }
  }, [authenticated]);

  React.useEffect(() => {
    if (user) {
      getUserRoles(user.id)
        .then(currentUserRoles => {
          if (currentUserRoles) setRoles(currentUserRoles);
        })
        .finally(() => {
          setInProgress(false);
        });
    }
  }, [user]);

  return (
    <AuthContext.Provider value={{ authenticated, inProgress, logout, roles, user }}>
      {children}
    </AuthContext.Provider>
  );
}
