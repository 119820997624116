/* eslint-disable no-duplicate-case */
import { ProvisionState, ProvisionAction } from "./types";
import {
  SET_ADDITIONAL_RESOURCES,
  SET_DEPLOYABLE_NAME,
  SET_DEVOPS_PROJECT,
  SET_DEVOPS_TEAM_NAME,
  SET_GIT_REPO_NAME,
  SET_IS_BUILD_PIPELINE_REQUIRED,
  SET_IS_CLONE_ENABLED,
  SET_IS_GIT_REPO_REQUIRED,
  SET_IS_RELEASE_PIPELINE_REQUIRED,
  SET_IS_ESCALATION_CONTACTS_REQUIRED,
  SET_LIFECYCLE,
  SET_OCTOPUS_PROJECT,
  SET_PROJECT_GROUP,
  SET_PROJECT_TYPE,
  SET_REGION,
  SET_SUBSCRIPTION,
  SET_IS_SCORECARD_REQUIRED,
  SET_GPR_PRODUCT,
  SET_RELEASE_TYPE,
  SET_PRODUCT_TYPE,
  SET_PRODUCT_MANAGER_EMAIL,
  SET_DEV_LEAD_EMAIL,
  SET_SCORECARD_PROJECT,
  SELECT_VALIDATOR,
  SET_IS_OPERATIONAL_TESTS_REQUIRED,
  SET_OPERATIONAL_TESTS_SERVICE,
  SET_OPERATIONAL_TESTS_CRONTAB_EXPRESSION,
  SET_OPERATIONAL_TESTS_NOTIFICATIONS_LIST,
  SET_OPERATIONAL_TESTS_TIMEOUT,
  SET_IS_GEO_REPLICATED,
  SET_OT_DISABLED,
  SET_OT_HIGH_SEVERITY,
  SET_IS_SEQ_REQUIRED,
  SET_SEQ_KEY_ENVIRONMENTS,
  SET_IS_BUDDI_REQUIRED,
  SET_BUDDI_URL,
  SET_BUDDI_REGIONAL_URL_REQUIRED,
  SET_BUDDI_REGIONAL_URL,
  SET_SERVICE_FORM,
  SET_OPERATIONS,
  SET_BUILD_FOLDER_PATH,
  SET_IS_OCTOPUS_REQUIRED
} from "./actionTypes";

export function reducer(state: ProvisionState, action: ProvisionAction): ProvisionState {
  switch (action.type) {
    case SET_ADDITIONAL_RESOURCES:
      return {
        ...state,
        additionalResources: action.payload
      };
    case SET_DEPLOYABLE_NAME:
      return {
        ...state,
        deployableName: action.payload,
        buildFolderPath: `\\${  action.payload}`
      };
    case SET_IS_OCTOPUS_REQUIRED:
      return {
        ...state,
        isOctopusRequired: action.payload
      };
    case SET_DEVOPS_PROJECT:
      return {
        ...state,
        devOpsProject: action.payload,
        devOpsTeamName: null
      };
    case SET_DEVOPS_TEAM_NAME:
      return {
        ...state,
        devOpsTeamName: action.payload
      };
    case SET_GIT_REPO_NAME:
      return {
        ...state,
        gitRepoName: action.payload
      };
    case SET_IS_BUILD_PIPELINE_REQUIRED:
      return {
        ...state,
        isBuildPipelineRequired: action.payload
      };
    case SET_IS_CLONE_ENABLED:
      return {
        ...state,
        isCloneEnabled: action.payload
      };
    case SET_IS_GIT_REPO_REQUIRED:
      return {
        ...state,
        isGitRepoRequired: action.payload
      };
    case SET_IS_RELEASE_PIPELINE_REQUIRED:
      return {
        ...state,
        isReleasePipelineRequired: action.payload
      };
    case SET_IS_ESCALATION_CONTACTS_REQUIRED:
      return {
        ...state,
        isEscalationContactRequired: action.payload
      };
    case SET_LIFECYCLE:
      return {
        ...state,
        lifecycle: action.payload
      };
    case SET_OCTOPUS_PROJECT:
      return {
        ...state,
        octopusProject: action.payload
      };
    case SET_PROJECT_GROUP:
      return {
        ...state,
        projectGroup: action.payload
      };
    case SET_PROJECT_TYPE:
      return {
        ...state,
        projectType: action.payload
      };
    case SET_REGION:
      return {
        ...state,
        region: action.payload,
        buddiRegionalUrlsRequired: false
      };
    case SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload
      };
    case SET_IS_SCORECARD_REQUIRED:
      return {
        ...state,
        isScorecardRequired: action.payload
      };
    case SET_GPR_PRODUCT:
      return {
        ...state,
        product: action.payload
      };
    case SET_PRODUCT_MANAGER_EMAIL:
      return {
        ...state,
        productManagerEmail: action.payload
      };
    case SET_DEV_LEAD_EMAIL:
      return {
        ...state,
        devLeadEmail: action.payload
      };
    case SET_RELEASE_TYPE:
      return {
        ...state,
        releaseType: action.payload
      };
    case SET_PRODUCT_TYPE:
      return {
        ...state,
        productType: action.payload
      };
    case SET_SCORECARD_PROJECT:
      return {
        ...state,
        scorecardProject: action.payload
      };
    case SELECT_VALIDATOR:
      return {
        ...state,
        selectedItems: action.payload
      };
    case SET_IS_OPERATIONAL_TESTS_REQUIRED:
      return {
        ...state,
        isOperationalTestsRequired: action.payload
      };
    case SET_OPERATIONAL_TESTS_SERVICE:
      return {
        ...state,
        operationalTestsService: action.payload
      };
    case SET_OPERATIONAL_TESTS_CRONTAB_EXPRESSION:
      return {
        ...state,
        operationalTestsCrontabExpression: action.payload
      };
    case SET_OPERATIONAL_TESTS_TIMEOUT:
      return {
        ...state,
        operationalTestsTimeout: action.payload
      };
    case SET_OPERATIONAL_TESTS_NOTIFICATIONS_LIST:
      return {
        ...state,
        operationalTestsNotificationList: action.payload
      };
    case SET_IS_GEO_REPLICATED:
      return {
        ...state,
        isGeoReplicated: action.payload
      };
    case SET_OT_DISABLED:
      return {
        ...state,
        operationalTestsAreDisabled: action.payload
      };
    case SET_OT_HIGH_SEVERITY:
      return {
        ...state,
        operationalTestsAreHighSeverity: action.payload
      };
    case SET_IS_SCORECARD_REQUIRED:
      return {
        ...state,
        isScorecardRequired: action.payload
      };
    case SET_GPR_PRODUCT:
      return {
        ...state,
        product: action.payload
      };
    case SET_PRODUCT_MANAGER_EMAIL:
      return {
        ...state,
        productManagerEmail: action.payload
      };
    case SET_DEV_LEAD_EMAIL:
      return {
        ...state,
        devLeadEmail: action.payload
      };
    case SET_RELEASE_TYPE:
      return {
        ...state,
        releaseType: action.payload
      };
    case SET_PRODUCT_TYPE:
      return {
        ...state,
        productType: action.payload
      };
    case SET_SCORECARD_PROJECT:
      return {
        ...state,
        scorecardProject: action.payload
      };
    case SET_IS_SEQ_REQUIRED:
      return {
        ...state,
        isSeqRequired: action.payload
      };
    case SET_SEQ_KEY_ENVIRONMENTS:
      return {
        ...state,
        seqKeyEnvironments: action.payload
      };
    case SET_IS_BUDDI_REQUIRED:
      return {
        ...state,
        isBuddiRequired: action.payload
      };
    case SET_BUDDI_URL:
      return {
        ...state,
        buddiUrl: action.payload
      };
    case SET_BUDDI_REGIONAL_URL_REQUIRED:
      return {
        ...state,
        buddiRegionalUrlsRequired: action.payload
      };
    case SET_BUDDI_REGIONAL_URL:
      return {
        ...state,
        configuredBuddiRegionalUrls: action.payload
      };
    case SET_SERVICE_FORM:
      return {
        ...state,
        serviceForm: action.payload
      };
    case SET_OPERATIONS:
      return {
        ...state,
        operations: action.payload
      };
    case SET_BUILD_FOLDER_PATH:
      return {
        ...state,
        buildFolderPath: action.payload
      };
    default:
      return state;
  }
}
