import "../PreAuthPage.scss";
import * as React from "react";

import { Typography, LinearProgress, ThemeProvider } from "@material-ui/core";

import { preAuthTheme } from "../../../themes";
import { LoadingProps } from "./types";

export function LoadingPage(props: LoadingProps): JSX.Element {
  const { message } = props;

  return (
    <div className="pre-auth-content">
      <ThemeProvider theme={preAuthTheme}>
        <Typography noWrap variant="h6">
          {message}
        </Typography>
        <LinearProgress className="margin-top-8" variant="indeterminate" />
        <Typography className="pre-auth-app-title" color="secondary" noWrap variant="h6">
          Cloud Service Setup App
        </Typography>
      </ThemeProvider>
    </div>
  );
}
