/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { action } from "typesafe-actions";
import {
  DevOpsTeamResponse,
  DevOpsProjectResponse,
  DevOpsEnvironmentResponse,
  DevOpsReleaseDefinitionResponse
} from "../../models/azureDevOpsResponses";
import * as actions from "../constants/azureDevOpsActionTypes";

export function getAllTeams() {
  return action(actions.GET_ALL_TEAMS);
}

export function getAllTeamsSuccess(teams: DevOpsTeamResponse[]) {
  return action(actions.GET_ALL_TEAMS_SUCCESS, teams);
}

export function getAllTeamsError(error: Error) {
  return action(actions.GET_ALL_TEAMS_ERROR, error);
}

export function getAllProjects() {
  return action(actions.GET_ALL_ADO_PROJECTS);
}

export function getAllProjectsSuccess(projects: DevOpsProjectResponse[]) {
  return action(actions.GET_ALL_ADO_PROJECTS_SUCCESS, projects);
}

export function getAllProjectsError(error: Error) {
  return action(actions.GET_ALL_ADO_PROJECTS_ERROR, error);
}

export function getReleaseEnvironments(releaseId: number) {
  return action(actions.GET_RELEASE_ENVIRONMENTS, releaseId);
}

export function getReleaseEnvironmentsSuccess(releaseEnvironments: DevOpsEnvironmentResponse[]) {
  return action(actions.GET_RELEASE_ENVIRONMENTS_SUCCESS, releaseEnvironments);
}

export function getReleaseEnvironmentsError(error: Error) {
  return action(actions.GET_RELEASE_ENVIRONMENTS_ERROR, error);
}

export function createOrGetAzureDevOpsTeam() {
  return action(actions.CREATE_OR_GET_ADO_TEAM);
}

export function createOrGetAzureDevOpsTeamSuccess() {
  return action(actions.CREATE_OR_GET_ADO_TEAM_SUCCESS);
}

export function createOrGetAzureDevOpsTeamError(error: Error) {
  return action(actions.CREATE_OR_GET_ADO_TEAM_ERROR, error);
}

export function createOrGetAzureDevOpsGitRepo() {
  return action(actions.CREATE_OR_GET_ADO_GIT_REPO);
}

export function createOrGetAzureDevOpsGitRepoSuccess() {
  return action(actions.CREATE_OR_GET_ADO_GIT_REPO_SUCCESS);
}

export function createOrGetAzureDevOpsGitRepoError(error: Error) {
  return action(actions.CREATE_OR_GET_ADO_GIT_REPO_ERROR, error);
}

export function createAzureDevOpsBuildDefinition() {
  return action(actions.CREATE_ADO_BUILD_DEFINITION);
}

export function createAzureDevOpsBuildDefinitionSuccess() {
  return action(actions.CREATE_ADO_BUILD_DEFINITION_SUCCESS);
}

export function createAzureDevOpsBuildDefinitionError(error: Error) {
  return action(actions.CREATE_ADO_BUILD_DEFINITION_ERROR, error);
}

export function createAzureDevOpsReleaseDefinition() {
  return action(actions.CREATE_ADO_RELEASE_DEFINITION);
}

export function createAzureDevOpsReleaseDefinitionSuccess(
  instance: DevOpsReleaseDefinitionResponse
) {
  return action(actions.CREATE_ADO_RELEASE_DEFINITION_SUCCESS, instance);
}

export function createAzureDevOpsReleaseDefinitionError(error: Error) {
  return action(actions.CREATE_ADO_RELEASE_DEFINITION_ERROR, error);
}

export function setAzureDevOpsDefaultTeamPermissions() {
  return action(actions.SET_ADO_TEAM_PERMISSIONS);
}

export function setAzureDevOpsDefaultTeamPermissionsSuccess() {
  return action(actions.SET_ADO_TEAM_PERMISSIONS_SUCCESS);
}

export function setAzureDevOpsDefaultTeamPermissionsError(error: Error) {
  return action(actions.SET_ADO_TEAM_PERMISSIONS_ERROR, error);
}

export function setAzureDevOpsBuildPipelinePermissions() {
  return action(actions.SET_ADO_BUILD_PIPELINE_PERMISSIONS);
}

export function setAzureDevOpsBuildPipelinePermissionsSuccess() {
  return action(actions.SET_ADO_BUILD_PIPELINE_PERMISSIONS_SUCCESS);
}

export function setAzureDevOpsBuildPipelinePermissionsError(error: Error) {
  return action(actions.SET_ADO_BUILD_PIPELINE_PERMISSIONS_ERROR, error);
}

export function setAzureDevOpsReleasePipelinePermissions() {
  return action(actions.SET_ADO_RELEASE_PIPELINE_PERMISSIONS);
}

export function setAzureDevOpsReleasePipelinePermissionsSuccess() {
  return action(actions.SET_ADO_RELEASE_PIPELINE_PERMISSIONS_SUCCESS);
}

export function setAzureDevOpsReleasePipelinePermissionsError(error: Error) {
  return action(actions.SET_ADO_RELEASE_PIPELINE_PERMISSIONS_ERROR, error);
}

export function gitRepoToCreateReset() {
  return action(actions.CREATE_OR_GET_ADO_GIT_REPO_RESET);
}

export function teamToCreateReset() {
  return action(actions.CREATE_OR_GET_ADO_TEAM_RESET);
}

export function buildPipelineToCreateReset() {
  return action(actions.CREATE_ADO_BUILD_DEFINITION_RESET);
}

export function releasePipelineToCreateReset() {
  return action(actions.CREATE_ADO_RELEASE_DEFINITION_RESET);
}

export function teamPermissionsToAddReset() {
  return action(actions.SET_ADO_TEAM_PERMISSIONS_RESET);
}

export function buildPipelinePermissionsToAddReset() {
  return action(actions.SET_ADO_BUILD_PIPELINE_PERMISSIONS_RESET);
}

export function releasePipelinePermissionsToAddReset() {
  return action(actions.SET_ADO_RELEASE_PIPELINE_PERMISSIONS_RESET);
}
