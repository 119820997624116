import * as React from "react";
import "./Home.scss";
import { Link } from "react-router-dom";
import { Paper, Typography, Button, Card, CardActions, CardContent } from "@material-ui/core";
import { NEW_FORM, FORMS, PROVISION } from "../../../constants/paths";
import { isAllowed } from "../../Navigation/NavigationBar";
import { ADMIN, FORM_CREATOR, FORM_APPROVER, PEER_REVIEWER, BCSM_REVIEWER } from "../../../constants/userRoles";
import { QuickStartProps } from "./types";

function QuickStartPage(props: QuickStartProps): JSX.Element {
  const { roles } = props;
  function openDocumentation(): void {
    window.open(
      "https://bentleycs.visualstudio.com/beconnect/_wiki/wikis/BCSM%20Wiki/4216/Cloud-Service-Setup-App-Documentation",
      "_blank"
    );
  }

  return (
    <Paper variant="outlined" className="service-forms-overview-paper">
      <Typography className="sub-title">Quick start</Typography>
      <div className="flex-row space-horizontal-16">
        {isAllowed([ADMIN, FORM_CREATOR], roles) && (
          <Card className="documentation-card" variant="outlined">
            <CardContent className="documentation-card-content">
              <Typography className="documentation-card-header" gutterBottom>
                Cloud Service Requests
              </Typography>
              <Typography
                className="documentation-card-content-text"
                color="textSecondary"
                gutterBottom
              >
                Start your new service development by filling in cloud service setup request!
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                className="documentation-card-button"
                color="secondary"
                variant="outlined"
                component={Link}
                to={NEW_FORM}
              >
                New Request
              </Button>
            </CardActions>
          </Card>
        )}
        {isAllowed([ADMIN, FORM_APPROVER, PEER_REVIEWER, BCSM_REVIEWER], roles) && (
          <Card className="documentation-card" variant="outlined">
            <CardContent className="documentation-card-content">
              <Typography className="documentation-card-header" gutterBottom>
                Review and Approve Requests
              </Typography>
              <Typography
                className="documentation-card-content-text"
                color="textSecondary"
                gutterBottom
              >
                You have been chosen to review other requests and approve/deny new services! Start
                by viewing requests you are assigned to review.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                className="documentation-card-button"
                color="secondary"
                variant="outlined"
                component={Link}
                to={FORMS}
              >
                Requests
              </Button>
            </CardActions>
          </Card>
        )}
        {isAllowed([ADMIN], roles) && (
          <Card className="documentation-card" variant="outlined">
            <CardContent className="documentation-card-content">
              <Typography className="documentation-card-header" gutterBottom>
                Provision
              </Typography>
              <Typography
                className="documentation-card-content-text"
                color="textSecondary"
                gutterBottom
              >
                Approved requests can become cloud services in just a few clicks! Click Proceed and
                Cloud Service Setup App will take you to Provision guide where you can choose
                additional parameters your service requires.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                className="documentation-card-button"
                color="secondary"
                variant="outlined"
                component={Link}
                to={PROVISION}
              >
                Provision
              </Button>
            </CardActions>
          </Card>
        )}
        <Card className="documentation-card" variant="outlined">
          <CardContent className="documentation-card-content">
            <Typography className="documentation-card-header" gutterBottom>
              Documentation
            </Typography>
            <Typography
              className="documentation-card-content-text"
              color="textSecondary"
              gutterBottom
            >
              Still not sure how Cloud Service Setup App works? Check our documentation in DevOps
              Wiki! If you still have questions feel free to contact BCSM team.
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              className="documentation-card-button"
              color="secondary"
              variant="outlined"
              onClick={openDocumentation}
            >
              Learn more
            </Button>
          </CardActions>
        </Card>
      </div>
    </Paper>
  );
}

export const QuickStart = QuickStartPage;
