/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import * as React from "react";
import { useHistory } from "react-router-dom";

import {
  Button,
  Grid,
  Typography,
  Divider,
  Grow,
  LinearProgress,
  CircularProgress,
  Box,
  Link,
  Paper
} from "@material-ui/core";
import { LinearProgressProps } from "@material-ui/core/LinearProgress";


import DoneIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import { defaultTheme, defaultDarkTheme } from "../../../themes";
import { AppContext } from "../../../context/AppContext";
import { ProvisionProgressProps } from "./types";
import * as actions from "./actions";
import { ServiceFormStatusRequest } from "../../../models";
import { HOME } from "../../../constants/paths";
import { ProvisioningOperationKeys } from "../../../constants/operationKeys";

export interface ProvisioningOperation {
  name: string;
  state: any;
  key: string;
  parentKeys: string[];
  function: any;
  requestBody: any;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box className="mt-2 mb-2" display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export function ProvisionProgress(props: ProvisionProgressProps): JSX.Element {
  const {
    deployableName,
    operations,
    octopusProjectToCreateState,
    devOpsGitRepoState,
    devOpsTeamToCreateState,
    devOpsTeamPermissionsToAddState,
    devOpsBuildPipelineToCreateState,
    devOpsBuildPipelinePermissionsToAddState,
    devOpsReleasePipelineToCreateState,
    devOpsReleasePipelinePermissionsToAddState,
    devOpsEscalationContactsState,
    scorecardAssociationState,
    operationaTestsServiceToCreateState,
    operationaTestsScheduleToCreateState,
    seqKeysToCreateState,
    updateServiceFormStatus,
    user,
    serviceFormId,
    dispatch
  } = props;

  const { darkMode } = React.useContext(AppContext);
  const [progress, setProgress] = React.useState(0);
  const history = useHistory();

  React.useEffect(() => {
    operations.forEach(request => {
      if (request.function && !request.state.creating && !request.state.completed) {
        if (request.parentKeys.length > 0) {
          let canProceed = false;
          request.parentKeys.forEach(dependencyKey => {
            let parentRequest = operations.find(x => x.key === dependencyKey);
            canProceed = parentRequest?.state.completed;
          });
          if (
            request.key === ProvisioningOperationKeys.ScorecardAssociation ||
            request.key === ProvisioningOperationKeys.OperationalTestService
          ) {
            canProceed = request.requestBody.releaseDefinitionId !== 0;
          }
          if (request.key === ProvisioningOperationKeys.OperationalTestSchedule) {
            canProceed =
              request.requestBody.definitionEnvironmentId !== 0 &&
              request.requestBody.dependentEnvironmentId !== 0 &&
              request.requestBody.serviceId !== "";
          }
          if (canProceed) request.function(request.requestBody);
        } else request.function(request.requestBody);
      }
    });
  }, [progress]);

  React.useEffect(() => {
    let _operations = operations;
    // eslint-disable-next-line array-callback-return
    _operations.map(request => {
      if (request.key === ProvisioningOperationKeys.DevOpsTeam)
        request.state = devOpsTeamToCreateState;

      if (request.key === ProvisioningOperationKeys.DevOpsTeamPermissions)
        request.state = devOpsTeamPermissionsToAddState;

      if (request.key === ProvisioningOperationKeys.DevOpsBuildPipeline)
        request.state = devOpsBuildPipelineToCreateState;

      if (request.key === ProvisioningOperationKeys.DevOpsBuildPipelinePermissions)
        request.state = devOpsBuildPipelinePermissionsToAddState;

      if (request.key === ProvisioningOperationKeys.DevOpsReleasePipeline)
        request.state = devOpsReleasePipelineToCreateState;

      if (request.key === ProvisioningOperationKeys.DevOpsReleasePipelinePermissions)
        request.state = devOpsReleasePipelinePermissionsToAddState;

      if (request.key === ProvisioningOperationKeys.EscalationContacts)
        request.state = devOpsEscalationContactsState;

      if (request.key === ProvisioningOperationKeys.DevOpsGitRepo)
        request.state = devOpsGitRepoState;

      if (request.key === ProvisioningOperationKeys.SeqKey) request.state = seqKeysToCreateState;

      if (request.key === ProvisioningOperationKeys.ScorecardAssociation) {
        request.state = scorecardAssociationState;

        if (
          devOpsReleasePipelineToCreateState.completed &&
          !devOpsReleasePipelineToCreateState.error &&
          devOpsReleasePipelineToCreateState.data &&
          !scorecardAssociationState.completed
        )
          request.requestBody.releaseDefinitionId = devOpsReleasePipelineToCreateState.data.id;
      }

      if (request.key === ProvisioningOperationKeys.OperationalTestService) {
        request.state = operationaTestsServiceToCreateState;

        if (
          devOpsReleasePipelineToCreateState.completed &&
          !devOpsReleasePipelineToCreateState.error &&
          devOpsReleasePipelineToCreateState.data
        )
          request.requestBody.releaseDefinitionId = devOpsReleasePipelineToCreateState.data.id;
      }

      if (request.key === ProvisioningOperationKeys.OperationalTestSchedule) {
        request.state = operationaTestsScheduleToCreateState;

        if (
          devOpsReleasePipelineToCreateState.completed &&
          !devOpsReleasePipelineToCreateState.error &&
          devOpsReleasePipelineToCreateState.data
        ) {
          request.requestBody.definitionEnvironmentId = devOpsReleasePipelineToCreateState.data.environments.find(
            env => env.name === "PRD Scheduled Operational Tests"
          )?.id;
          request.requestBody.dependentEnvironmentId = devOpsReleasePipelineToCreateState.data.environments.find(
            env => env.name === "PRD"
          )?.id;
        }
        if (
          operationaTestsServiceToCreateState.completed &&
          !operationaTestsServiceToCreateState.error &&
          operationaTestsServiceToCreateState.data
        )
          request.requestBody.serviceId = operationaTestsServiceToCreateState.data.serviceId;
      }

      if (request.key === ProvisioningOperationKeys.OctopusProject) {
        request.state = octopusProjectToCreateState;
        if (
          seqKeysToCreateState.completed &&
          !seqKeysToCreateState.error &&
          seqKeysToCreateState.data.length > 0
        ) {
          seqKeysToCreateState.data.forEach(key => {
            request.requestBody.variables.push({
              name: "Bentley.Cloud.Framework.Serilog.Seq.ApiKey",
              scope: key.environment,
              value: key.token,
              sensitive: true
            });
          });
        }
      }
    });

    if (dispatch) dispatch(actions.setOperations(_operations));
    setProgress(
      (operations.filter(x => x.state.completed === true).length * 100) / operations.length
    );
  }, [
    operations,
    octopusProjectToCreateState,
    devOpsGitRepoState,
    devOpsTeamToCreateState,
    devOpsTeamPermissionsToAddState,
    devOpsBuildPipelineToCreateState,
    devOpsBuildPipelinePermissionsToAddState,
    devOpsReleasePipelineToCreateState,
    devOpsReleasePipelinePermissionsToAddState,
    devOpsEscalationContactsState,
    scorecardAssociationState,
    operationaTestsServiceToCreateState,
    operationaTestsScheduleToCreateState
  ]);
  return (
    <div>
      <Grow in>
        <div id="content-child">
          <div id="progress-content-head">
            <Paper className="p-4">
              <Typography
                className="text-uppercase font-weight-bold"
                color="textSecondary"
                variant="body2"
              >
                Provisioning {deployableName}
              </Typography>
              <LinearProgressWithLabel value={progress} />
              <Grid container direction="column" justifyContent="space-evenly" alignItems="center">
                {operations.map(operation => (
                  <Grid
                    container
                    className="mb-1"
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <Grid item xs={4}>
                      <Typography variant="body2" color="textSecondary">
                        {operation.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <div>
                        {operation.state?.completed && !operation.state?.error ? (
                          <DoneIcon
                            htmlColor={
                              !darkMode
                                ? defaultTheme.palette.success.main
                                : defaultDarkTheme.palette.success.dark
                            }
                            style={{
                              fontSize: 30
                            }}
                          />
                        ) : operation.state?.completed && operation.state?.error ? (
                          <ErrorIcon
                            htmlColor={
                              !darkMode
                                ? defaultTheme.palette.error.main
                                : defaultDarkTheme.palette.error.dark
                            }
                            style={{
                              fontSize: 30
                            }}
                          />
                        ) : (
                          <CircularProgress
                            size={30}
                            thickness={5}
                            variant="indeterminate"
                            color="primary"
                          />
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={7}>
                      {operation.state?.error ? (
                        <Typography variant="body2" color="textSecondary">                      
                          {operation.state?.error.message}
                        </Typography>
                      ) : operation.state?.completed && !operation.state?.error ? (
                        <Typography variant="body2" color="textSecondary">
                          OK
                        </Typography>
                      ) : null}
                    </Grid>
                    <Divider />
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </div>
          <div id="progress-content-buttons">
            <Grow in={operations.every(p => p.state.completed)}>
              <div>
                <Typography className="mt-3 mb-3" color="textSecondary" variant="body2">
                  Automated provisioning is compeleted. Follow the{" "}
                  <Link
                    target="_blank"
                    href="https://bentleycs.visualstudio.com/beconnect/_wiki/wikis/BCSM%20Wiki/9084/Manual-steps-after-automation-completed"
                  >
                    instructions to finalize the setup.
                  </Link>
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    if (serviceFormId && Number(serviceFormId) !== 0 && user) {
                      const requestBody: ServiceFormStatusRequest = {
                        id: Number(serviceFormId),
                        status: "Completed",
                        userId: user.id
                      };

                      updateServiceFormStatus(requestBody);
                    }

                    history.push(HOME);
                  }}
                >
                  Done
                </Button>
              </div>
            </Grow>
          </div>
        </div>
      </Grow>
    </div>
  );
}
