export function readContentOnLoadEnd(event: ProgressEvent<FileReader>): string | null {
  if (event.target !== null && event.target.result !== null) {
    if (event.target.readyState === FileReader.DONE && typeof event.target.result === "object") {
      return btoa(
        new Uint8Array(event.target.result).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
    }
  }

  return null;
}
