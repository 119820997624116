import * as React from "react";
import MUIDataTable from "mui-datatables";
import * as Constants from "./constants";
import { ContactRow } from "./types";

interface Props {
  contacts: ContactRow[];
}

export function ContactsTable(props: Props): JSX.Element {
  const { contacts } = props;

  return (
    <MUIDataTable
      title=""
      columns={Constants.ContactsTableColumn}
      data={contacts}
      options={Constants.ContactsTableOptions}
    />
  );
}
