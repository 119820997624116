/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { action } from "typesafe-actions";
import {
  OctopusProjectResponse,
  OctopusARMStepResponse,
  OctopusLifecycleResponse,
  OctopusProjectGroupResponse,
  OctopusLibraryVariableSetResponse
} from "../../models/octopusResponses";
import * as actions from "../constants/octopusActionTypes";

export function getAllLifecycles() {
  return action(actions.GET_ALL_LIFECYCLES);
}

export function getAllLifecyclesSuccess(lifecycles: OctopusLifecycleResponse[]) {
  return action(actions.GET_ALL_LIFECYCLES_SUCCESS, lifecycles);
}

export function getAllLifecyclesError(error: Error) {
  return action(actions.GET_ALL_LIFECYCLES_ERROR, error);
}

export function getAllProjects() {
  return action(actions.GET_ALL_OCTO_PROJECTS);
}

export function getAllProjectsSuccess(projects: OctopusProjectResponse[]) {
  return action(actions.GET_ALL_OCTO_PROJECTS_SUCCESS, projects);
}

export function getAllProjectsError(error: Error) {
  return action(actions.GET_ALL_OCTO_PROJECTS_ERROR, error);
}

export function getAllProjectGroups() {
  return action(actions.GET_ALL_PROJECT_GROUPS);
}

export function getAllProjectGroupsSuccess(groups: OctopusProjectGroupResponse[]) {
  return action(actions.GET_ALL_PROJECT_GROUPS_SUCCESS, groups);
}

export function getAllProjectGroupsError(error: Error) {
  return action(actions.GET_ALL_PROJECT_GROUPS_ERROR, error);
}

export function getAllSubscriptionLibraries() {
  return action(actions.GET_ALL_SUBSCRIPTION_LIBRARIES);
}

export function getAllSubscriptionLibrariesSuccess(libraries: OctopusLibraryVariableSetResponse[]) {
  return action(actions.GET_ALL_SUBSCRIPTION_LIBRARIES_SUCCESS, libraries);
}

export function getAllSubscriptionLibrariesError(error: Error) {
  return action(actions.GET_ALL_SUBSCRIPTION_LIBRARIES_ERROR, error);
}

export function getAllTemplateProjects() {
  return action(actions.GET_ALL_TEMPLATE_PROJECTS);
}

export function getAllTemplateProjectsSuccess(projects: OctopusProjectResponse[]) {
  return action(actions.GET_ALL_TEMPLATE_PROJECTS_SUCCESS, projects);
}

export function getAllTemplateProjectsError(error: Error) {
  return action(actions.GET_ALL_TEMPLATE_PROJECTS_ERROR, error);
}

export function getARMSteps() {
  return action(actions.GET_ARM_STEPS);
}

export function getARMStepsSuccess(steps: OctopusARMStepResponse[]) {
  return action(actions.GET_ARM_STEPS_SUCCESS, steps);
}

export function getARMStepsError(error: Error) {
  return action(actions.GET_ARM_STEPS_ERROR, error);
}

export function createProject() {
  return action(actions.CREATE_OCTO_PROJECT);
}

export function createProjectSuccess() {
  return action(actions.CREATE_OCTO_PROJECT_SUCCESS);
}

export function createProjectError(error: Error) {
  return action(actions.CREATE_OCTO_PROJECT_ERROR, error);
}

export function cloneProject() {
  return action(actions.CLONE_OCTO_PROJECT);
}

export function cloneProjectSuccess() {
  return action(actions.CLONE_OCTO_PROJECT_SUCCESS);
}

export function cloneProjectError(error: Error) {
  return action(actions.CLONE_OCTO_PROJECT_ERROR, error);
}

export function createOrCloneProjectReset() {
  return action(actions.CREATE_OR_CLONE_OCTO_PROJECT_RESET);
}
