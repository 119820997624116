import { ActionType } from "typesafe-actions";

import {
  SharePointUserState,
  RegionState,
  OctopusARMStepState,
  AzureDevOpsProjectState,
  AzureDevOpsTeamState,
  ProductState,
  OctopusTemplateProjectState,
  UserState,
  ServiceFormState
} from "../../../redux/reducers/types";
import {
  ServiceFormRequest,
  ProductResponse,
  DevOpsProjectResponse,
  OctopusProjectResponse,
  UserResponse,
  OctopusARMStepResponse,
  ServiceFormResponse,
  ServiceFormPeerReviewersRequest
} from "../../../models";
import * as FormRequestActions from "./actions";

export type FormRequestAction = ActionType<typeof FormRequestActions>;

export interface FormRequestPageState {
  additionalResources: OctopusARMStepResponse[];
  architectureDiagramContent: string | null;
  architectureDiagramFileName: string | null;
  architectureDiagramUploading: boolean;
  deployableName: string;
  description: string;
  devLeadEmail: string | null;
  devLeadPhone: string;
  devOpsProject: DevOpsProjectResponse | null;
  devOpsTeamName: string | null;
  finalApprover: UserResponse | null;
  finalApproverLoading: boolean;
  monthlyCostEstimate: string;
  primaryDevEmail: string | null;
  primaryDevPhone: string;
  primaryPeerReviewer: UserResponse | null;
  projectType: OctopusProjectResponse | null;
  product: ProductResponse | null;
  productManagerEmail: string | null;
  productManagerPhone: string;
  qaLeadEmail: string | null;
  qaLeadPhone: string;
  region: string | null;
  secondaryDevEmail: string | null;
  secondaryDevPhone: string;
  secondaryPeerReviewer: UserResponse | null;
  targetProductionDate: Date | null;
  formCreator: string | null;
  serviceForm: ServiceFormResponse | null;
}

export interface FormRequestPageProps {
  azureDevOpsProjects: AzureDevOpsProjectState;
  azureDevOpsTeams: AzureDevOpsTeamState;
  createServiceForm: (form: ServiceFormRequest) => Promise<ServiceFormResponse>;
  getAllDevOpsProjects: () => void;
  getAllDevOpsTeams: () => void;
  getAllProducts: () => void;
  getAllTemplateProjects: () => void;
  getAllUsers: () => void;
  getARMSteps: () => void;
  getSharePointUsers: () => void;
  getRegions: () => void;
  octopusARMSteps: OctopusARMStepState;
  octopusTemplateProjects: OctopusTemplateProjectState;
  products: ProductState;
  serviceForms: ServiceFormState;
  sharePointUsers: SharePointUserState;
  regions: RegionState;
  updateServiceForm: (form: ServiceFormRequest) => Promise<ServiceFormResponse>;
  updateServiceFormPeerReviewers: (
    body: ServiceFormPeerReviewersRequest
  ) => Promise<ServiceFormResponse>;
  users: UserState;
}

export interface FormSectionProps {
  children?: JSX.Element | JSX.Element[];
  className?: string;
  title: string;
}

export interface InputContainerProps {
  children?: JSX.Element | JSX.Element[];
  description: string;
  fieldsClass?: string;
}

export interface InputRowProps {
  children: JSX.Element[];
}

// eslint-disable-next-line no-shadow
export enum FormRequestMode {
  Default = 0,
  Preview = 1,
  Edit = 2
}

export const initialState: FormRequestPageState = {
  additionalResources: [],
  architectureDiagramContent: null,
  architectureDiagramFileName: null,
  architectureDiagramUploading: false,
  deployableName: "",
  description: "",
  devLeadEmail: "",
  devLeadPhone: "",
  devOpsProject: null,
  devOpsTeamName: null,
  finalApprover: null,
  finalApproverLoading: true,
  monthlyCostEstimate: "",
  primaryDevEmail: "",
  primaryDevPhone: "",
  primaryPeerReviewer: null,
  projectType: null,
  product: null,
  productManagerEmail: "",
  productManagerPhone: "",
  qaLeadEmail: "",
  qaLeadPhone: "",
  region: null,
  secondaryDevEmail: "",
  secondaryDevPhone: "",
  secondaryPeerReviewer: null,
  targetProductionDate: null,
  formCreator: null,
  serviceForm: null
};
