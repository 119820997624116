import * as React from "react";

import { Grid } from "@material-ui/core";

import { InputRowProps } from "./types";

export function InputRow(props: InputRowProps): JSX.Element {
  const { children } = props;

  const width = children.length <= 2 ? 6 : 4;

  return (
    <Grid className="input-row" container direction="row" justifyContent="flex-end" spacing={2}>
      {children.map((child, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Grid item key={`input-row-field-${index}`} xs={width}>
            {child}
          </Grid>
        );
      })}
    </Grid>
  );
}
