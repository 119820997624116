import * as React from "react";

import { useMediaQuery } from "@material-ui/core";

import { AppContextValue, AppProviderProps } from "./types";

const defaultValue: AppContextValue = {
  darkMode: false,
  selectedTab: 0,
  setDarkMode: undefined,
  setSelectedTab: undefined
};

export const AppContext = React.createContext<AppContextValue>(defaultValue);

export function AppProvider({ children }: AppProviderProps): JSX.Element {
  let themePreference = useMediaQuery("(prefers-color-scheme: dark)");
  const currentPreference = localStorage.getItem("darkMode");

  if (currentPreference) {
    themePreference = currentPreference === "true";
  }

  const [darkMode, setDarkMode] = React.useState<boolean>(themePreference);
  const [selectedTab, setSelectedTab] = React.useState<number>(defaultValue.selectedTab);

  function handleSetDarkMode(): void {
    const value = !darkMode;

    localStorage.setItem("darkMode", String(value));
    setDarkMode(value);
  }

  return (
    <AppContext.Provider
      value={{
        darkMode,
        selectedTab,
        setDarkMode: handleSetDarkMode,
        setSelectedTab
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
