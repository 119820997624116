import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { Button, Grid, Typography } from "@material-ui/core";
import { CommentValidationSettings } from "./types";

interface IProps {
  sendHandler: (comment: string) => void;
  isSendButtonDisabled: boolean;
  validationSettings: CommentValidationSettings;
}

export default function SendCommentBox(props: IProps): JSX.Element {
  const { sendHandler, isSendButtonDisabled, validationSettings } = props;
  const [commentTextState, setCommentTextState] = React.useState<string>("");

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCommentTextState(event.target.value);
  };

  const handleOnSendClick = (): void => {
    sendHandler(commentTextState);
    setCommentTextState("");
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <TextField
          placeholder="Add a comment"
          id="outlined-multiline-static"
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          value={commentTextState}
          onChange={handleTextFieldChange}
          inputProps={
            validationSettings.maxCommentLength
              ? { maxLength: validationSettings.maxCommentLength }
              : undefined
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOnSendClick}
          disabled={
            isSendButtonDisabled ||
            (validationSettings.allowEmpty ? false : commentTextState.length === 0)
          }
        >
          Send
        </Button>
      </Grid>
    </Grid>
  );
}
