import * as React from "react";

import { DetailSectionTitle } from "./DetailSectionTitle";
import { DetailSectionProps } from "./types";

export function DetailSection(props: DetailSectionProps): JSX.Element {
  const { children, title } = props;

  return (
    <div className="detail-section">
      <DetailSectionTitle uppercase title={title} />
      {children}
    </div>
  );
}

export function DetailInnerSection(props: DetailSectionProps): JSX.Element {
  const { children, title } = props;

  return (
    <div className="detail-inner-section">
      <DetailSectionTitle title={title} />
      {children}
    </div>
  );
}
