import { ProductState, ProductAction } from "./types";
import { GET_ALL_PRODUCTS, GET_ALL_PRODUCTS_SUCCESS, GET_ALL_PRODUCTS_ERROR } from "../constants";

export const initalProductState: ProductState = {
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const productReducer = (
  state: ProductState = initalProductState,
  action: ProductAction
): ProductState => {
  switch (action.type) {
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        pending: true
      };
    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_ALL_PRODUCTS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};
