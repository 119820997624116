import * as React from "react";
import TimeAgo from "react-timeago";

import RemoveIcon from "@material-ui/icons/Remove";
import { Typography, Grid } from "@material-ui/core";

import { ServiceFormStatus } from "../../../constants/serviceFormStatus";
import { ApprovalDetailProps } from "./types";
import { AppContext } from "../../../context/AppContext";
import { defaultTheme, defaultDarkTheme } from "../../../themes";

export function ApprovalDetail(props: ApprovalDetailProps): JSX.Element {
  const { userName, status, updateTime, type } = props;
  const { darkMode } = React.useContext(AppContext);

  function renderStatusBadge(formStatus?: ServiceFormStatus): JSX.Element {
    if (!formStatus) {
      return (
        <span className="status-badge" style={{ backgroundColor: "#34515ea0" }}>
          Waiting for peer review approvals
        </span>
      );
    }

    switch (formStatus) {
      case "Approved":
        return (
          <span
            className="status-badge"
            style={{
              backgroundColor: !darkMode
                ? defaultTheme.palette.success.main
                : defaultDarkTheme.palette.success.dark
            }}
          >
            {formStatus}
          </span>
        );
      case "Denied":
        return (
          <span
            className="status-badge status-denied"
            style={{
              backgroundColor: !darkMode
                ? defaultTheme.palette.error.main
                : defaultDarkTheme.palette.error.dark
            }}
          >
            {formStatus}
          </span>
        );
      case "WaitingForFinalApproval":
      case "WaitingForApproval":
        return (
          <span
            className="status-badge status-waiting"
            style={{
              backgroundColor: !darkMode
                ? defaultTheme.palette.info.main
                : defaultDarkTheme.palette.info.dark
            }}
          >
            Waiting
          </span>
            );
        case "WaitingForBCSMApproval":
        return (
          <span
            className="status-badge status-waiting"
            style={{
            backgroundColor: !darkMode
              ? defaultTheme.palette.info.main
              : defaultDarkTheme.palette.info.dark
            }}
          >
            Waiting for BCSM Approval
                </span>
            );
      default:
        return (
          <span className="status-badge" style={{ backgroundColor: "#34515ea0" }}>
            Waiting for peer review approvals
          </span>
        );
    }
  }

  return (
    <div>
      <Grid container direction="row">
        <Grid item>
          <Typography variant="body2">
            {type === "approval" ? "Approver:" : "Reviewer:"}
            {userName || "User not found"}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{renderStatusBadge(status)}</Typography>
        </Grid>
      </Grid>
      <Typography variant="body2">
        Updated:{" "}
        {updateTime ? <TimeAgo date={updateTime} /> : <RemoveIcon className="size-10-10" />}
      </Typography>
    </div>
  );
}
