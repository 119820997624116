export const GET_ALL_DEPLOYABLES = "GET_ALL_DEPLOYABLES";
export const GET_ALL_DEPLOYABLES_SUCCESS = "GET_ALL_DEPLOYABLES_SUCCESS";
export const GET_ALL_DEPLOYABLES_ERROR = "GET_ALL_DEPLOYABLES_ERROR";

export const UPDATE_DEPLOYABLES = "UPDATE_DEPLOYABLES";
export const UPDATE_DEPLOYABLES_SUCCESS = "UPDATE_DEPLOYABLES_SUCCESS";
export const UPDATE_DEPLOYABLES_ERROR = "UPDATE_DEPLOYABLES_ERROR";

export const EXECUTE_DEPLOYABLES_QUERY = "EXECUTE_DEPLOYABLES_QUERY";
export const EXECUTE_DEPLOYABLES_QUERY_SUCCESS = "EXECUTE_DEPLOYABLES_QUERY_SUCCESS";
export const EXECUTE_DEPLOYABLES_QUERY_ERROR = "EXECUTE_DEPLOYABLES_QUERY_ERROR";