/* eslint-disable no-shadow */
import * as React from "react";
import "./Home.scss";
import { useHistory } from "react-router-dom";
import { Paper, Grid, Typography} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { ZeroData } from "../../ZeroData";
import { ServiceFormCard } from "../Forms/ServiceFormCard";
import { ServiceFormsContainerSkeleton } from "../Forms/ServiceFormsContainerSkeleton";
import { FORMS_DETAILS } from "../../../constants/paths";
import { HomePageFormsProps } from "./types";
import { AuthContext } from "../../../context/AuthContext";
import { isAllowed } from "../../Navigation/NavigationBar";
import { ADMIN } from "../../../constants/userRoles";
import SearchStore from "../../../utilities/SearchStore";

function AllFormsReviewPage(props: HomePageFormsProps): JSX.Element {
    const { loading, pages, serviceForms, users, confirmation } = props;
    const [search, setSearch]: [string, (search: string) => void] = React.useState(SearchStore.get());
    const history = useHistory();
    const [pageSize, setPageSize] = React.useState<number>(pages ?? 5);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const { user, roles } = React.useContext(AuthContext);
  function handleRedirectToShow(serviceFormId: number): void {
    history.push(FORMS_DETAILS.replace(":serviceFormId", String(serviceFormId)));
  }
  function handlePaginationChange(event: React.ChangeEvent<unknown>, page: number): void {
    event.preventDefault();
    setCurrentPage(page);
    }

  const handleChange = (e: { target: { value: string; }; }) => {
      setSearch(e.target.value);
      setCurrentPage(1);
      SearchStore.set(e.target.value);
  };

  function renderContent(): JSX.Element {
    if (loading) return <ServiceFormsContainerSkeleton cardCount={pageSize} />;
    if (serviceForms.length > 0) {
      serviceForms.sort((a, b) => {
        return new Date(b.createdTime).getTime() - new Date(a.createdTime).getTime();
      });

      const filteredServiceForms = serviceForms.filter(sf => sf.projectName?.toLowerCase().includes(search.toLowerCase())
                                  || sf.formCreator?.toLowerCase().includes(search.toLowerCase())
                                  || sf.status?.toLowerCase().includes(search.toLowerCase())
                                  || sf.productName?.toLowerCase().includes(search.toLowerCase()))

      const paginationContainer = (
        filteredServiceForms.length > pageSize ? (
          <Pagination
            className="service-form-card-pagination"
            color="secondary"
            count={Math.ceil(filteredServiceForms.length / pageSize)}
            defaultPage={1}
            onChange={handlePaginationChange}
            showFirstButton
            showLastButton
            variant="outlined"
          />
          ) : null
      );

      return (
        <>
          <Grid
            alignItems="flex-start"
            className="flex-self-stretch service-forms-container"
            container
            direction="column"
            spacing={1}
          >
            {filteredServiceForms.slice(currentPage * pageSize - pageSize, currentPage * pageSize)
                                  .map(serviceForm => {
              return (
                  <Grid key={serviceForm.id} className="flex-self-stretch" item xs={12}>
                      <ServiceFormCard
                          onClick={handleRedirectToShow}
                          serviceForm={serviceForm}
                          users={users}
                          showMenu={user?.name === serviceForm.formCreator || isAllowed([ADMIN], roles)}
                          confirmation={confirmation}
                      />
                  </Grid>
              );
            })}
          </Grid>
          {paginationContainer}
        </>
      );
    }
    return <ZeroData message="No cloud service setup requests" />;
  }

  const searchValue = SearchStore.get();

  function isSearchFormStatus(search: string) {
    switch (search) {
      case "Approved":
        return true;
      case "Completed":
        return true;
      case "WaitingForBCSMApproval":
        return true;
      case "WaitingForApproval":
        return true;
      case "WaitingForFinalApproval":
        return true;
      case "Canceled":
        return true;
      default:
        return false;
    }
  }

  return (
    <Paper variant="outlined" className="service-forms-overview-paper">
          <div>
              <div className="lable-overview">
              <Typography className="sub-title">Overview</Typography>
              </div>
              <div className="status-dropdown">
                  <select onChange={handleChange} className="select-dropdown-status">
                      <option value="">- Select -</option>
                      <option value="Approved" selected={searchValue === "Approved"}>Approved</option>
                      <option value="Completed" selected={searchValue === "Completed"}>Completed</option>
                      <option value="WaitingForBCSMApproval" selected={searchValue === "WaitingForBCSMApproval"}>Waiting For BCSM Approval</option>
                      <option value="WaitingForApproval" selected={searchValue === "WaitingForApproval"}>Waiting For Approval</option>
                      <option value="WaitingForFinalApproval" selected={searchValue === "WaitingForFinalApproval"}>Waiting For Final Approval</option>
                      <option value="Canceled" selected={searchValue === "Canceled"}>Canceled</option>
                  </select>
              </div>
              <div className="input-filter-div">
                  <input className="input-format" type="text" value={isSearchFormStatus(searchValue) ? "" : searchValue} placeholder="Filter by Project Name/Product Name/Form Creator..." onChange={handleChange} /></div>
              </div>
          <div>
        {renderContent()}
      </div>
    </Paper>
  );
}

export const AllFormsReview = AllFormsReviewPage;
