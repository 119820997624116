import React from "react";
import "./Inputs.scss";

import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { OutlinedDatePickerProps } from "./types";

export const OutlinedDatePicker = React.memo((props: OutlinedDatePickerProps) => {
  const { dispatch, format, handle, label, minDate, onChange, value } = props;

  function handleChange(date: Date | null): void {
    if (dispatch && handle) dispatch(handle(date));
    else if (handle) handle(date);
    else if (onChange) onChange(date);
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        className="full-width date-field"
        format={format || "yyyy/MM/dd"}
        label={label}
        margin="dense"
        minDate={minDate || new Date()}
        onChange={handleChange}
        inputVariant="outlined"
        value={value}
        variant="inline"
        FormHelperTextProps={{ className: "" }}
        InputLabelProps={{ className: "" }}
        InputProps={{ className: "" }}
      />
    </MuiPickersUtilsProvider>
  );
});
