/* eslint-disable react/no-array-index-key */
import * as React from "react";

import { Grid, Typography } from "@material-ui/core";

import { DetailColumnProps } from "./types";

function renderValueOrNotProvided(
  value: (string | undefined)[] | string | null | undefined,
  index: number
): JSX.Element {
  if (value === undefined || value === null || value.length === 0)
    return (
      <Typography
        className="detail-value-not-provided"
        color="textSecondary"
        key={`value-${index}`}
        variant="body2"
      >
        Not provided
      </Typography>
    );

  if (value instanceof Array && typeof value !== "string") {
    return (
      <div>
        {value.map((v, i) =>
          value ? (
            <Typography className="detail-value" key={`value-${index}-${i}`} variant="body2">
              {v}
            </Typography>
          ) : (
            <Typography
              className="detail-value-not-provided"
              key={`value-${index}-${i}`}
              variant="body2"
            >
              Not found
            </Typography>
          )
        )}
      </div>
    );
  }

  return (
    <Typography className="detail-value" key={`value-${index}`} variant="body2">
      {value}
    </Typography>
  );
}

export function DetailColumn(props: DetailColumnProps): JSX.Element {
  const { type, values } = props;
  return (
    <Grid className="detail-column space-vertical-4" item xs="auto">
      {values.map((value, index) => {
        return type === "name" ? (
          <Typography
            className="detail-property"
            color="textSecondary"
            key={`name-${index}`}
            variant="body2"
          >
            {value}:
          </Typography>
        ) : (
          renderValueOrNotProvided(value, index)
        );
      })}
    </Grid>
  );
}
