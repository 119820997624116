import * as React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { SnackbarProvider } from "notistack";

import { ThemeProvider } from "@material-ui/core";

import { AppContext } from "./context/AppContext";
import { Routes } from "./Routes";
import { Layout } from "./Layout";
import { defaultTheme, defaultDarkTheme } from "./themes";
import { AppProps } from "./types";
import { initialApplicationState } from "./redux/reducers";

export function AuthenticatedApp(props: AppProps): JSX.Element {
  const { history, store } = props;
  const { darkMode } = React.useContext(AppContext);

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={darkMode ? defaultDarkTheme : defaultTheme}>
          <Layout>
            <SnackbarProvider maxSnack={3}>
              <Routes />
            </SnackbarProvider>
          </Layout>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  );
}
