import "../PreAuthPage.scss";
import * as React from "react";

import { Typography, ThemeProvider, Button } from "@material-ui/core";

import { preAuthTheme } from "../../../themes";

export function UnauthenticatedPage(): JSX.Element {
  function handleLoginClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.preventDefault();
    if (window.authContext && window.authContext.clientApp) {
      window.authContext.clientApp.loginRedirect();
    }
  }

  return (
    <div className="pre-auth-content">
      <ThemeProvider theme={preAuthTheme}>
        <Typography className="pre-auth-app-title-no-margin" color="secondary" noWrap variant="h6">
          Cloud Service Setup App
        </Typography>
        <Button color="primary" onClick={handleLoginClick} variant="contained">
          Log in
        </Button>
      </ThemeProvider>
    </div>
  );
}
