import { FormRequestPageState, FormRequestAction } from "./types";
import {
  REMOVE_ARCHITECTURE_DIAGRAM,
  SET_DEV_LEAD_EMAIL,
  SET_DEV_LEAD_PHONE,
  SET_DEPLOYABLE_NAME,
  SET_DESCRIPTION,
  SET_DEVOPS_PROJECT,
  SET_DEVOPS_TEAM_NAME,
  SET_FINAL_APPROVER,
  SET_MONTHLY_COST_ESTIMATE,
  SET_PRIMARY_DEV_EMAIL,
  SET_PRIMARY_DEV_PHONE,
  SET_PRIMARY_PEER_REVIEWER,
  SET_PRODUCT_MANAGER_EMAIL,
  SET_PRODUCT_MANAGER_PHONE,
  SET_PRODUCT,
  SET_PROJECT_TYPE,
  SET_QA_LEAD_EMAIL,
  SET_QA_LEAD_PHONE,
  SET_REGION,
  SET_SECONDARY_DEV_EMAIL,
  SET_SECONDARY_DEV_PHONE,
  SET_SECONDARY_PEER_REVIEWER,
  SET_TARGET_PRODUCTION_DATE,
  SET_SERVICE_FORM_STATE,
  UPLOAD_ARCHITECTURE_DIAGRAM,
  UPLOAD_ARCHITECTURE_DIAGRAM_FINISHED,
  SET_ADDITIONAL_RESOURCES
} from "./actionTypes";
import { UserResponse } from "../../../models";

export function reducer(
  state: FormRequestPageState,
  action: FormRequestAction
): FormRequestPageState {
  switch (action.type) {
    case REMOVE_ARCHITECTURE_DIAGRAM:
      return {
        ...state,
        architectureDiagramContent: null,
        architectureDiagramFileName: null
      };
    case SET_ADDITIONAL_RESOURCES:
      return {
        ...state,
        additionalResources: action.payload
      };
    case SET_DEV_LEAD_EMAIL:
      return {
        ...state,
        devLeadEmail: action.payload
      };
    case SET_DEV_LEAD_PHONE:
      return {
        ...state,
        devLeadPhone: action.payload
      };
    case SET_DEPLOYABLE_NAME:
      return {
        ...state,
        deployableName: action.payload
      };
    case SET_DESCRIPTION:
      return {
        ...state,
        description: action.payload
      };
    case SET_DEVOPS_PROJECT:
      return {
        ...state,
        devOpsProject: action.payload,
        devOpsTeamName: null
      };
    case SET_DEVOPS_TEAM_NAME:
      return {
        ...state,
        devOpsTeamName: action.payload
      };
    case SET_FINAL_APPROVER: {
      return {
        ...state,
        finalApprover: action.payload,
        finalApproverLoading: false
      };
    }
    case SET_MONTHLY_COST_ESTIMATE:
      return {
        ...state,
        monthlyCostEstimate: action.payload
      };
    case SET_PRIMARY_DEV_EMAIL:
      return {
        ...state,
        primaryDevEmail: action.payload
      };
    case SET_PRIMARY_DEV_PHONE:
      return {
        ...state,
        primaryDevPhone: action.payload
      };
    case SET_PRIMARY_PEER_REVIEWER:
      return {
        ...state,
        primaryPeerReviewer: action.payload
      };
    case SET_PRODUCT:
      return {
        ...state,
        product: action.payload
      };
    case SET_PRODUCT_MANAGER_EMAIL:
      return {
        ...state,
        productManagerEmail: action.payload
      };
    case SET_PRODUCT_MANAGER_PHONE:
      return {
        ...state,
        productManagerPhone: action.payload
      };
    case SET_PROJECT_TYPE:
      return {
        ...state,
        projectType: action.payload
      };
    case SET_QA_LEAD_EMAIL:
      return {
        ...state,
        qaLeadEmail: action.payload
      };
    case SET_QA_LEAD_PHONE:
      return {
        ...state,
        qaLeadPhone: action.payload
      };
    case SET_REGION:
      return {
        ...state,
        region: action.payload
      };
    case SET_SECONDARY_DEV_EMAIL:
      return {
        ...state,
        secondaryDevEmail: action.payload
      };
    case SET_SECONDARY_DEV_PHONE:
      return {
        ...state,
        secondaryDevPhone: action.payload
      };
    case SET_SECONDARY_PEER_REVIEWER:
      return {
        ...state,
        secondaryPeerReviewer: action.payload
      };
    case SET_TARGET_PRODUCTION_DATE:
      return {
        ...state,
        targetProductionDate: action.payload
      };

    case SET_SERVICE_FORM_STATE:
      if (action.payload)
        return {
          ...state,
          targetProductionDate: action.payload?.targetProductionDate
            ? new Date(action.payload?.targetProductionDate)
            : null,
          architectureDiagramContent: action.payload?.architectureDiagramFileContent,
          architectureDiagramFileName: action.payload.architectureDiagramFileName,
          devLeadEmail: action.payload.devLeadEmail,
          devLeadPhone: action.payload.devLeadPhone,
          devOpsTeamName: action.payload.devOpsTeamName,
          monthlyCostEstimate: String(action.payload.monthlyCostEstimate || ""),
          deployableName: action.payload.projectName,
          primaryDevEmail: action.payload.primaryDevEmail,
          primaryDevPhone: action.payload.primaryDevPhone,
          productManagerEmail: action.payload.productManagerEmail,
          productManagerPhone: action.payload.productManagerPhone,
          description: action.payload.projDescription,
          qaLeadEmail: action.payload.qaLeadEmail,
          qaLeadPhone: action.payload.qaLeadPhone,
          region: action.payload.region,
          secondaryDevEmail: action.payload.secondaryDevEmail,
          secondaryDevPhone: action.payload.secondaryDevPhone,
          serviceForm: action.payload
        };

      return state;
    case UPLOAD_ARCHITECTURE_DIAGRAM:
      return {
        ...state,
        architectureDiagramFileName: action.payload,
        architectureDiagramUploading: true
      };
    case UPLOAD_ARCHITECTURE_DIAGRAM_FINISHED:
      return {
        ...state,
        architectureDiagramContent: action.payload,
        architectureDiagramUploading: false
      };
    default:
      return state;
  }
}
