import { action } from "typesafe-actions";
import * as actions from "../constants/operationalTestsActionTypes";
import { OpTestsServiceResponse } from "../../models/operationalTestsResponses";

export function getAllServices() {
  return action(actions.GET_ALL_SERVICES);
}

export function getAllServicesSuccess(services: OpTestsServiceResponse[]) {
  return action(actions.GET_ALL_SERVICES_SUCCESS, services);
}

export function getAllServicesError(error: Error) {
  return action(actions.GET_ALL_SERVICES_ERROR, error);
}

export function createService() {
  return action(actions.CREATE_SERVICE);
}

export function createServiceSuccess(service: OpTestsServiceResponse) {
  return action(actions.CREATE_SERVICE_SUCCESS, service);
}

export function createServiceError(error: Error) {
  return action(actions.CREATE_SERVICE_ERROR, error);
}

export function createSchedule() {
  return action(actions.CREATE_SCHEDULE);
}

export function createScheduleSuccess() {
  return action(actions.CREATE_SCHEDULE_SUCCESS);
}

export function createScheduleError(error: Error) {
  return action(actions.CREATE_SCHEDULE_ERROR, error);
}

export function serviceToCreateReset() {
  return action(actions.CREATE_SERVICE_RESET);
}

export function scheduleToCreateReset() {
  return action(actions.CREATE_SCHEDULE_RESET);
}
