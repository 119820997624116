import * as Redux from "redux";
import { ProductAction } from "../redux/reducers/types";
import * as ProductActions from "../redux/actions/productActions";
import { RequestBuilder } from "../utilities/RequestBuilder";
import { ProductResponse } from "../models/productResponses";

export function getAllProductsDispatch() {
  return async (dispatch: Redux.Dispatch<ProductAction>): Promise<void> => {
    dispatch(ProductActions.getAllProducts());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/gpr/products")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const products = (await response.json()) as ProductResponse[];

      dispatch(ProductActions.getAllProductsSuccess(products));
    } catch (error) {
      dispatch(ProductActions.getAllProductsError(error));
    }
  };
}
