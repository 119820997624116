import * as Redux from "redux";
import * as configurationActions from "../redux/actions/configurationActions";
import { RegionConfigurationResponse } from "../models/configurationResponses";
import { ConfigurationAction } from "../redux/reducers/types";
import { RequestBuilder } from "../utilities/RequestBuilder";

export function getRegionsDispatch() {
  return async (dispatch: Redux.Dispatch<ConfigurationAction>): Promise<void> => {
    dispatch(configurationActions.getRegions());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/configuration/regions")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const users = (await response.json()) as RegionConfigurationResponse[];

      dispatch(configurationActions.getRegionsSuccess(users));
    } catch (error) {
      dispatch(configurationActions.getRegionsError(error));
    }
  };
}
