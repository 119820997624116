/* eslint-disable eqeqeq */
import * as Redux from "redux";
import { ServiceFormResponse } from "../models/serviceFormResponses";
import {
  ServiceFormRequest,
  ServiceFormPeerReviewersRequest,
  ServiceFormStatusRequest,
  ServiceFormPeerReviewStatusRequest,
  ServiceFormBCSMReviewStatusRequest
} from "../models/serviceFormRequests";
import { RequestBuilder } from "../utilities/RequestBuilder";
import { ServiceFormAction } from "../redux/reducers/types";
import * as ServiceFormActions from "../redux/actions/serviceFormActions";
import { ServiceFormCommentRequest } from "../models/serviceFormCommentRequest";
import { ServiceFormCommentResponse } from "../models/serviceFormCommentResponse";

export function createServiceFormDispatch(body: ServiceFormRequest) {
  return async (dispatch: Redux.Dispatch<ServiceFormAction>): Promise<void> => {
    dispatch(ServiceFormActions.createServiceForm());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/serviceform")
        .setMethod("POST")
        .setBody(body)
        .build();

      const response = await request.send();

      if (response.status == 400 && body.architectureDiagramFileName == "null") {
        throw new Error("Please upload architecture diagram file");
      } else if (response.status == 400 && body.architectureDiagramFileName != "null") {
        throw new Error(
          `Could not upload architecture diagram file or file: ${body.architectureDiagramFileName} is already present in storage`
        );
      } else if (response.status !== 201) {
        throw new Error(response.statusText);
      }
      const serviceForm = (await response.json()) as ServiceFormResponse;
      dispatch(ServiceFormActions.createServiceFormSuccess(serviceForm));
    } catch (error) {
      dispatch(ServiceFormActions.createServiceFormError(error));
      throw error;
    }
  };
}

export function deleteServiceFormDispatch(serviceForm: ServiceFormResponse) {
  return async (dispatch: Redux.Dispatch<ServiceFormAction>): Promise<void> => {
    dispatch(ServiceFormActions.deleteServiceForm());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/serviceform")
        .setMethod("DELETE")
        .setBody(serviceForm)
        .build();

      const response = await request.send();
      if (!response.ok) throw new Error(response.statusText);

      const deletedForm = (await response.json()) as ServiceFormResponse;
      dispatch(ServiceFormActions.deleteServiceFormSuccess(deletedForm));
    } catch (error) {
      dispatch(ServiceFormActions.deleteServiceFormError(error));
      throw error;
    }
  };
}

export function updateServiceFormDispatch(serviceForm: ServiceFormRequest) {
  return async (dispatch: Redux.Dispatch<ServiceFormAction>): Promise<void> => {
    dispatch(ServiceFormActions.updateServiceForm());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/serviceform")
        .setMethod("PUT")
        .setBody(serviceForm)
        .build();

      const response = await request.send();
      if (!response.ok) throw new Error(response.statusText);

      const updatedForm = (await response.json()) as ServiceFormResponse;
      dispatch(ServiceFormActions.updateServiceFormSuccess(updatedForm));
    } catch (error) {
      dispatch(ServiceFormActions.updateServiceFormError(error));
      throw error;
    }
  };
}

export function updateServiceFormPeerReviewersDispatch(body: ServiceFormPeerReviewersRequest) {
  return async (dispatch: Redux.Dispatch<ServiceFormAction>): Promise<void> => {
    dispatch(ServiceFormActions.updateServiceFormPeerReviewers());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/serviceform/reviewers")
        .setMethod("PUT")
        .setBody(body)
        .build();

      const response = await request.send();
      if (!response.ok) throw new Error(response.statusText);

      const updatedForm = (await response.json()) as ServiceFormResponse;
      dispatch(ServiceFormActions.updateServiceFormPeerReviewersSuccess(updatedForm));
    } catch (error) {
      dispatch(ServiceFormActions.updateServiceFormPeerReviewersError(error));
      throw error;
    }
  };
}

export function updateServiceFormStatusDispatch(body: ServiceFormStatusRequest) {
  return async (dispatch: Redux.Dispatch<ServiceFormAction>): Promise<void> => {
    dispatch(ServiceFormActions.updateServiceFormStatus());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/serviceform/status")
        .setMethod("PUT")
        .setBody(body)
        .build();

      const response = await request.send();
      if (!response.ok) throw new Error(response.statusText);

      const updatedForm = (await response.json()) as ServiceFormResponse;
      dispatch(ServiceFormActions.updateServiceFormStatusSuccess(updatedForm));
    } catch (error) {
      dispatch(ServiceFormActions.updateServiceFormStatusError(error));
      throw error;
    }
  };
}

export function updateServiceFormReviewStatusDispatch(body: ServiceFormPeerReviewStatusRequest) {
  return async (dispatch: Redux.Dispatch<ServiceFormAction>): Promise<void> => {
    dispatch(ServiceFormActions.updateServiceFormPeerReviewStatus());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/serviceform/review/status")
        .setMethod("PUT")
        .setBody(body)
        .build();

      const response = await request.send();
      if (!response.ok) throw new Error(response.statusText);

      const updatedForm = (await response.json()) as ServiceFormResponse;
      dispatch(ServiceFormActions.updateServiceFormPeerReviewStatusSuccess(updatedForm));
    } catch (error) {
      dispatch(ServiceFormActions.updateServiceFormPeerReviewStatusError(error));
      throw error;
    }
  };
}
export function updateServiceFormBCSMStatusDispatch(body: ServiceFormBCSMReviewStatusRequest) {
  return async (dispatch: Redux.Dispatch<ServiceFormAction>): Promise<void> => {
      dispatch(ServiceFormActions.updateServiceFormBCSMReviewStatus());
    try {
        const request = new RequestBuilder()
            .setRequestUrl("api/serviceform/review/bcsmreviewer")
            .setMethod("PUT")
            .setBody(body)
            .build();

      const response = await request.send();
      if (!response.ok) throw new Error(response.statusText);

      const updatedForm = (await response.json()) as ServiceFormResponse;
        dispatch(ServiceFormActions.updateServiceFormBCSMReviewStatusSuccess(updatedForm));
    } catch (error) {
        dispatch(ServiceFormActions.updateServiceFormBCSMReviewStatusError(error));
      throw error;
    }
  };
}
export function getServiceFormsDispatch() {
  return async (dispatch: Redux.Dispatch<ServiceFormAction>): Promise<void> => {
    dispatch(ServiceFormActions.getServiceForms());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/serviceForm")
        .setMethod("GET")
        .build();

      const response = await request.send();
      if (!response.ok) throw new Error(response.statusText);

      const serviceForms = (await response.json()) as ServiceFormResponse[];
      dispatch(ServiceFormActions.getServiceFormsSuccess(serviceForms));
    } catch (error) {
      dispatch(ServiceFormActions.getServiceFormsError(error));
      throw error;
    }
  };
}

export async function getServiceFormById(formId: number): Promise<ServiceFormResponse | null> {
  const request = new RequestBuilder()
    .setRequestUrl(`api/serviceform/${formId}`)
    .setMethod("GET")
    .build();

  const response = await request.send();
  if (response.status === 404) return null;
  if (!response.ok) {
    // const error = await response.json();
    throw Error(`Could not get Service Form id: ${formId} from backend`);
  }

  const serviceForm = (await response.json()) as ServiceFormResponse;
  return serviceForm;
}

export async function getServiceFormsByUserId(
  userId: number
): Promise<ServiceFormResponse[] | null> {
  const request = new RequestBuilder()
    .setRequestUrl(`api/serviceform/user/${userId}`)
    .setMethod("GET")
    .build();

  const response = await request.send();
  if (response.status === 404) return null;
  if (!response.ok) {
    // const error = await response.json();
    throw Error(`Could not get Service Form by user id: ${userId} from backend`);
  }

  const serviceForm = (await response.json()) as ServiceFormResponse[];
  return serviceForm;
}

export async function getServiceFormsByPeerReviewerId(
  peerReviewerId: number
): Promise<ServiceFormResponse[] | null> {
  const request = new RequestBuilder()
    .setRequestUrl(`api/serviceform/reviewers/${peerReviewerId}`)
    .setMethod("GET")
    .build();

  const response = await request.send();
  if (response.status === 404) return null;
  if (!response.ok) {
    // const error = await response.json();
    throw Error(`Could not get Service Form by Peer Reviewer id: ${peerReviewerId} from backend`);
  }

  const serviceForm = (await response.json()) as ServiceFormResponse[];
  return serviceForm;
}

export async function getServiceFormsByApproverId(
  approverId: number
): Promise<ServiceFormResponse[] | null> {
  const request = new RequestBuilder()
    .setRequestUrl(`api/serviceform/approver/${approverId}`)
    .setMethod("GET")
    .build();

  const response = await request.send();
  if (response.status === 404) return null;
  if (!response.ok) {
    // const error = await response.json();
    throw Error(`Could not get Service Form by Approver id: ${approverId} from backend`);
  }

  const serviceForm = (await response.json()) as ServiceFormResponse[];
  return serviceForm;
}

export async function createCommentAsync(
  body: ServiceFormCommentRequest,
  formId: number
): Promise<ServiceFormCommentResponse> {
  const request = new RequestBuilder()
    .setRequestUrl(`api/serviceform/${formId}/comment`)
    .setMethod("POST")
    .setBody(body)
    .build();

  const response = await request.send();
  if (!response.ok) throw new Error(response.statusText);

  return (await response.json()) as ServiceFormCommentResponse;
}

export async function getFromCommentsAsync(formId: number): Promise<ServiceFormCommentResponse[]> {
  const request = new RequestBuilder()
    .setRequestUrl(`api/serviceform/${formId}/comment`)
    .setMethod("GET")
    .build();

  const response = await request.send();
  if (!response.ok) throw new Error(response.statusText);

  return (await response.json()) as ServiceFormCommentResponse[];
}

export async function deleteFromCommentsAsync(
  commentId: number
): Promise<ServiceFormCommentResponse> {
  const request = new RequestBuilder()
    .setRequestUrl(`api/serviceform/comment/${commentId}`)
    .setMethod("DELETE")
    .build();

  const response = await request.send();
  if (!response.ok) throw new Error(response.statusText);

  return (await response.json()) as ServiceFormCommentResponse;
}
