import "./EscalationList.scss";
import * as React from "react";
import { Grid, AccordionDetails, Accordion, AccordionSummary, IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import ContactsEdit from "./ContactsEdit";
import ContactsView from "./ContactsView";
import { AuthContext } from "../../../context/AuthContext";
import { ADMIN, PEER_REVIEWER } from "../../../constants/userRoles";
import { isAllowed } from "../../Navigation/NavigationBar";
import { SharePointContactsInfoViewModel } from "./types";

interface Props {
  contactInfo: SharePointContactsInfoViewModel;
}

export function ContactsInfo(props: Props): JSX.Element {
  const { contactInfo } = props;
  const title = contactInfo.productId
    ? `${contactInfo.productId} - ${contactInfo.projectName}`
    : contactInfo.projectName;
  const [editMode, setEditMode] = React.useState(false);
  const { roles } = React.useContext(AuthContext);

  const handleClickEditButton = () => {
    setEditMode(true);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{ root: "MuiAccordionSummaryContacts-root" }}
      >
         <Grid container justifyContent="space-between">
          <Grid item>
            <Typography> {title}</Typography>
          </Grid>
          {isAllowed([ADMIN, PEER_REVIEWER], roles) && (
            <Grid item>
              <IconButton
                id="escalationContacts-button-edit"
                aria-label="Edit button"
                onClick={event => {
                  event.stopPropagation();
                  handleClickEditButton();
                }}
                onFocus={event => event.stopPropagation()}
              >
                <EditIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="row" justifyContent="flex-end" spacing={1}>
          {editMode ? (
            <Grid item xs={12}>
              <ContactsEdit onExit={() => setEditMode(false)} contactsInfo={contactInfo} />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <ContactsView contacts={contactInfo.contacts} definition={contactInfo.definition} />
              </Grid>
            </>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
