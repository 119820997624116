import * as React from "react";
import { Route, Switch } from "react-router-dom";

import {
  Home,
  Forms,
  FormRequest,
  FormDetails,
  NotFoundPage,
  Provision,
  Settings,
  EscalationList,
  Deployables
} from "./components/Pages";
import {
  HOME,
  NEW_FORM,
  FORMS,
  SETTINGS,
  PROVISION,
  FORMS_DETAILS,
  FORMS_EDIT,
  PROVISION_FORM,
  ESCALATION_CONTACTS,
  DEPLOYABLES_EDIT
} from "./constants/paths";

export function Routes(): JSX.Element {
  return (
    <Switch>
      <Route exact path={HOME} component={Home} />
      <Route exact path={FORMS} component={Forms} />
      <Route exact path={NEW_FORM} component={FormRequest} />
      <Route exact path={FORMS_DETAILS} component={FormDetails} />
      <Route exact path={FORMS_EDIT} component={FormRequest} />
      <Route exact path={PROVISION} component={Provision} />
      <Route exact path={SETTINGS} component={Settings} />
      <Route exact path={PROVISION_FORM} component={Provision} />
      <Route exact path={ESCALATION_CONTACTS} component={EscalationList} />
      <Route exact path={DEPLOYABLES_EDIT} component={Deployables} />
      <Route component={NotFoundPage} />
    </Switch>
  );
}
