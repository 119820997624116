import {
  ScorecardCategoryState,
  ScorecardAction,
  ScorecardSpecificationState,
  ScorecardProjectState,
  ScorecardItemState,
  ScorecardAssociationState
} from "./types";
import {
  GET_ALL_CATEGORIES,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_ALL_CATEGORIES_ERROR,
  GET_ALL_SPECIFICATIONS,
  GET_ALL_SPECIFICATIONS_SUCCESS,
  GET_ALL_SPECIFICATIONS_ERROR,
  GET_ALL_SCORECARD_PROJECTS,
  GET_ALL_SCORECARD_PROJECTS_SUCCESS,
  GET_ALL_SCORECARD_PROJECTS_ERROR,
  CREATE_ASSOCIATION,
  CREATE_ASSOCIATION_SUCCESS,
  CREATE_ASSOCIATION_ERROR,
  CREATE_ASSOCIATION_RESET
} from "../constants";

export const initialScorecardCategoryState: ScorecardCategoryState = {
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const scorecardCategoryReducer = (
  state: ScorecardCategoryState = initialScorecardCategoryState,
  action: ScorecardAction
): ScorecardCategoryState => {
  switch (action.type) {
    case GET_ALL_CATEGORIES:
      return {
        ...state,
        pending: true
      };
    case GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_ALL_CATEGORIES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};

export const initialScorecardSpecificationState: ScorecardSpecificationState = {
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const scorecardSpecificationReducer = (
  state: ScorecardSpecificationState = initialScorecardSpecificationState,
  action: ScorecardAction
): ScorecardSpecificationState => {
  switch (action.type) {
    case GET_ALL_SPECIFICATIONS:
      return {
        ...state,
        pending: true
      };
    case GET_ALL_SPECIFICATIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_ALL_SPECIFICATIONS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};

export const initialScorecardProjectState: ScorecardProjectState = {
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const scorecardProjectReducer = (
  state: ScorecardProjectState = initialScorecardProjectState,
  action: ScorecardAction
): ScorecardProjectState => {
  switch (action.type) {
    case GET_ALL_SCORECARD_PROJECTS:
      return {
        ...state,
        pending: true
      };
    case GET_ALL_SCORECARD_PROJECTS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_ALL_SCORECARD_PROJECTS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};

export const initialScorecardAssociationState: ScorecardAssociationState = {
  pending: false,
  creating: false,
  completed: false,
  data: [],
  error: undefined
};

export const scorecardAssociationReducer = (
  state: ScorecardAssociationState = initialScorecardAssociationState,
  action: ScorecardAction
): ScorecardAssociationState => {
  switch (action.type) {
    case CREATE_ASSOCIATION:
      return {
        ...state,
        creating: true,
        completed: false
      };
    case CREATE_ASSOCIATION_SUCCESS:
      return {
        ...state,
        creating: false,
        completed: true
      };
    case CREATE_ASSOCIATION_ERROR:
      return {
        ...state,
        creating: false,
        error: action.payload,
        completed: true
      };
    case CREATE_ASSOCIATION_RESET:
      return {
        pending: false,
        completed: false,
        data: [],
        creating: false,
        error: undefined
      };
    default:
      return state;
  }
};
