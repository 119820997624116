/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { action } from "typesafe-actions";
import * as actions from "../constants/productActionTypes";
import { ProductResponse } from "../../models/productResponses";

export function getAllProducts() {
  return action(actions.GET_ALL_PRODUCTS);
}

export function getAllProductsSuccess(products: ProductResponse[]) {
  return action(actions.GET_ALL_PRODUCTS_SUCCESS, products);
}

export function getAllProductsError(error: Error) {
  return action(actions.GET_ALL_PRODUCTS_ERROR, error);
}
