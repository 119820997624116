import * as React from "react";
import "./Inputs.scss";

import { Autocomplete, RenderInputParams, UseAutocompleteSingleProps } from "@material-ui/lab";
import { ClickAwayListener } from "@material-ui/core";

import { OutlinedTextField } from "./OutlinedTextField";
import { OutlinedFreesoloAutocompleteProps } from "./types";

export const OutlinedFreesoloAutocomplete = React.memo(
  (props: OutlinedFreesoloAutocompleteProps & UseAutocompleteSingleProps<string>) => {
    const {
      autoSelect,
      disabled,
      dispatch,
      error,
      handle,
      helperText,
      getOptionLabel,
      label,
      loading,
      onChange,
      onClose,
      onOpen,
      options,
      required,
      value
    } = props;
    const [open, setOpen] = React.useState<boolean>(false);
    const [selectableOptions, setSelectableOptions] = React.useState<string[]>([]);
    const [localValue, setLocalValue] = React.useState<string | null>(
      value === null || value === undefined ? null : value
    );

    React.useEffect(() => {
      if (options.length > 0) {
        if (getOptionLabel) {
          setSelectableOptions(
            options.sort((a, b) => getOptionLabel(a).localeCompare(getOptionLabel(b)))
          );
        }
        setSelectableOptions(options.sort((a, b) => String(a).localeCompare(String(b))));
      }
    }, [options]);

    function handleChange(event: React.ChangeEvent<{}>, newValue: string | null): void {
      event.preventDefault();
      if (newValue !== null) setLocalValue(newValue);
      if (dispatch && handle) dispatch(handle(newValue));
      else if (handle) handle(newValue);
      else if (onChange) onChange(event, newValue,"blur");
    }

    function handleClose(event: React.ChangeEvent<{}>): void {
      event.preventDefault();
      setOpen(false);
      if (onClose) onClose(event,"escape");
    }

    function handleOpen(event: React.ChangeEvent<{}>): void {
      event.preventDefault();
      setOpen(true);
      if (onOpen) onOpen(event);
    }

    function triggerHandle(event: React.MouseEvent<Document, MouseEvent>): void {
      event.preventDefault();
      if (dispatch && handle) dispatch(handle(localValue));
      else if (handle) handle(localValue);
    }

    function renderInput(params: RenderInputParams): JSX.Element {
      return (
        <OutlinedTextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          className=""
          disabled={disabled || false}
          error={error || false}
          helperText={helperText || ""}
          label={label}
          required={required || false}
          InputLabelProps={{ className: "" }}
        />
      );
    }

    return (
      <ClickAwayListener
        mouseEvent={value === localValue ? false : "onClick"}
        onClickAway={triggerHandle}
      >
        <Autocomplete
          autoSelect={autoSelect || false}
          className="full-width data-field"
          disabled={disabled || false}
          freeSolo
          getOptionLabel={getOptionLabel}
          loading={loading || false}
          loadingText="Loading..."
          onChange={handleChange}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          options={selectableOptions}
          renderInput={renderInput}
          value={value}
          onInputChange={(e, v): void => setLocalValue(v)}
        />
      </ClickAwayListener>
    );
  }
);
