import { createStyles, makeStyles, Theme } from "@material-ui/core";

import { ServiceFormState, UserState } from "../../../redux/reducers/types";
import { ServiceFormStatus } from "../../../constants/serviceFormStatus";
import { ServiceFormResponse } from "../../../models/serviceFormResponses";
import { ServiceFormStatusRequest, UserResponse } from "../../../models";
import { ConfirmationType } from "../../Dialogs/types";

export interface ServiceFormCardProps {
  confirmation?: (message: string, serviceFormId: number, type: ConfirmationType) => void;
  loading?: boolean;
  onClick?: (serviceFormId: number) => void;
  serviceForm?: ServiceFormResponse;
  showMenu?: boolean;
  users?: UserResponse[];
}

export interface ServiceFormContainerProps {
  confirmation?: (message: string, serviceFormId: number, type: ConfirmationType) => void;
  createButton?: JSX.Element;
  loading?: boolean;
  onFormClickCallback?: (serviceFormId: number) => void;
  pageSize: number;
  serviceForms: ServiceFormResponse[];
  title: string;
  users?: UserResponse[];
}

export interface ServiceFormContainerSkeletonProps {
  cardCount: number;
}

export interface FormsPageProps {
  deleteServiceForm: (serviceForm: ServiceFormResponse) => Promise<ServiceFormResponse>;
  getServiceForms: () => void;
  serviceForms: ServiceFormState;
  updateServiceFormStatus: (body: ServiceFormStatusRequest) => Promise<ServiceFormResponse>;
  getAllUsers: () => void;
  users: UserState;
}

export interface StatusProps {
  status: ServiceFormStatus;
}

export const useColorStyles = makeStyles((theme: Theme) =>
  createStyles({
    blue: {
      color: theme.palette.info.main
    },
    blueBackground: {
      backgroundColor: theme.palette.info.main
    },
    red: {
      color: theme.palette.error.main
    },
    redBackground: {
      backgroundColor: theme.palette.error.main
    },
    green: {
      color: theme.palette.success.main
    },
    greenBackground: {
      backgroundColor: theme.palette.success.main
    },
    darkGreen: {
      color: theme.palette.success.dark
    },
    darkGreenBackground: {
      backgroundColor: theme.palette.success.dark
    }
  })
);
