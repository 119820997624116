import "./FormDetails.scss";

import * as React from "react";
import * as Redux from "redux";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import TimeAgo from "react-timeago";
import { Paper, Grid, Button, ThemeProvider, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { statusTheme, darkStatusTheme } from "../../../themes";
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from "../../../context/AuthContext";
import {
  ServiceFormResponse,
  DevOpsProjectResponse,
  OctopusProjectResponse,
  OctopusARMStepResponse,
  UserResponse,
  ServiceFormPeerReviewStatusRequest,
  ServiceFormBCSMReviewStatusRequest,
  ServiceFormPeerReviewersRequest,
  ServiceFormStatusRequest,
  BCSMReviewerResponse
} from "../../../models";
import {
  getServiceFormById,
  getAllDevOpsProjectsDispatch,
  getAllTemplateProjectsDispatch,
  getARMStepsDispatch,
  getAllUsersDispatch,
  updateServiceFormPeerReviewersDispatch,
  updateServiceFormReviewStatusDispatch,
  updateServiceFormBCSMStatusDispatch,
  updateServiceFormStatusDispatch,
  createCommentAsync,
  getFromCommentsAsync,
  deleteFromCommentsAsync
} from "../../../services";
import { ServiceFormStatus } from "../../../constants/serviceFormStatus";
import { ApplicationState } from "../../../redux/reducers";
import { Page } from "../Page";
import { DetailSection, DetailInnerSection } from "./DetailSection";
import { DetailColumn } from "./DetailColumn";
import { ApprovalDetail } from "./ApprovalDetail";
import { FormDetailsSkeleton } from "./FormDetailsSkeleton";
import { NotFoundPage } from "../NotFound";
import { FormDetailsProps } from "./types";
import { FORMS } from "../../../constants/paths";
import { PeerReviewers } from "../../Dialogs";
import { BCSM_REVIEWER } from "../../../constants/userRoles";
import { isAllowed } from "../../Navigation";
import { showErrorSnackbar, showSuccessSnackbar } from "../../Snackbars/Snackbars";
import Comments from "../../Comments/Comments";
import { Comment } from "../../Comments/types";
import { ServiceFormCommentResponse } from "../../../models/serviceFormCommentResponse";
import { ServiceFormCommentRequest } from "../../../models/serviceFormCommentRequest";
import { generateRandomGuid } from "../../../utilities/StringOperations";

function FormDetailsPage(props: FormDetailsProps): JSX.Element {
  const {
    azureDevOpsProjects,
    getAllUsers,
    getAzureDevOpsProjects,
    getOctopusARMSteps,
    getOctopusTemplateProjects,
    octopusARMSteps,
    octopusTemplateProjects,
    updateServiceFormReviewStatus,
    updateServiceFormBCSMReviewStatus,
    updateServiceFormPeerReviewers,
    updateServiceFormStatus,
    users,
    serviceFormsState
  } = props;

  const { serviceFormId } : any = useParams();
  const { darkMode, setSelectedTab } = React.useContext(AppContext);
  const { user, roles } = React.useContext(AuthContext);

  const [loadingData, setLoadingData] = React.useState<boolean>(true);
  const [peerReviewersDialogOpen, setPeerReviewersDialogOpen] = React.useState<boolean>(false);
  const [loadingServiceForm, setLoadingServiceForm] = React.useState<boolean>(true);
  const [serviceForm, setServiceForm] = React.useState<ServiceFormResponse | null>(null);
  const [comments, setComments] = React.useState<Comment[]>([]);
  const [isLoadingComments, setIsLoadingComments] = React.useState<boolean>(true);
  const [isSendingComment, setIsSendingComment] = React.useState<boolean>(false);

  const dateOptions: Intl.DateTimeFormatOptions = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [bcsmReviewStatus, setBcsmReviewStatus] = React.useState<ServiceFormStatus>("WaitingForBCSMApproval");
  const [approvedReviewer, setBcsmApprovedReviewer] = React.useState<BCSMReviewerResponse>();
    const [bcsmReviewerID, setBcsmReviewerId] = React.useState<number>(0);

  const mapCommentsWithUsers = (
    data: ServiceFormCommentResponse,
    siteUsers: UserResponse[]
  ): Comment => {
    return {
      id: data.id,
      author: findEntity<UserResponse>(data.authorId, siteUsers)?.name ?? "",
      createdAt: new Date(data.createdAt.toString()),
      message: data.message
    };
    };
  
  function checkIfUserIsCurrentUser(userName: string): boolean {
    return userName === user?.name;
  }

  function fetchComments() {
    getFromCommentsAsync(serviceFormId)
      .then(result => {
        setComments(result.map(c => mapCommentsWithUsers(c, users.data)));
        setIsLoadingComments(false);
      })
      .catch(() => showErrorSnackbar(enqueueSnackbar, closeSnackbar, "Failed to get comments"));
  }

  function sendComment(comment: string) {
    const request: ServiceFormCommentRequest = { message: comment };
    setIsSendingComment(true);
    createCommentAsync(request, serviceFormId)
      .then(result => setComments(cs => [...cs, mapCommentsWithUsers(result, users.data)]))

      .catch(() => showErrorSnackbar(enqueueSnackbar, closeSnackbar, "Failed to create comment"))
      .finally(() => setIsSendingComment(false));
  }

  function deleteComment(id: number) {
    deleteFromCommentsAsync(id)
      .then(result => setComments(cs => cs.filter(c => c.id !== result.id)))
      .catch(() => showErrorSnackbar(enqueueSnackbar, closeSnackbar, "Failed to delete comment"));
  }

  React.useEffect(() => {
    if (setSelectedTab) setSelectedTab(1);

    fetchComments();
    if (!azureDevOpsProjects.pending && !azureDevOpsProjects.completed) getAzureDevOpsProjects();
    if (!octopusARMSteps.pending && !octopusARMSteps.completed) getOctopusARMSteps();
    if (!octopusTemplateProjects.pending && !octopusTemplateProjects.completed)
      getOctopusTemplateProjects();
    if (!users.pending && !users.completed) getAllUsers();

    setLoadingData(
      !(
        azureDevOpsProjects.completed &&
        octopusTemplateProjects.completed &&
        octopusARMSteps.completed &&
        users.completed &&
        !loadingServiceForm
      )
    );

    if (serviceFormId)
      getServiceFormById(Number(serviceFormId))
        .then(sf => setServiceForm(sf))
        .finally(() => setLoadingServiceForm(false));
  }, []);

    React.useEffect(() => {
        if (serviceForm && serviceForm.serviceFormBCSMReviewer != null) {
            const bcsmReviewer = serviceForm.serviceFormBCSMReviewer;
            const reviewStatus = bcsmReviewer.reviewerStatus;
            const bcsmReviewerId = bcsmReviewer?.bcsmReviewerId;
            setBcsmReviewStatus(reviewStatus);
            setBcsmApprovedReviewer(bcsmReviewer);
            setBcsmReviewerId(bcsmReviewerId);
        }
    }, [serviceForm]);

  React.useEffect(() => {
    if (serviceFormsState.error) {
      showErrorSnackbar(enqueueSnackbar, closeSnackbar, String(serviceFormsState.error.message));
    }
  }, [serviceFormsState.error]);

  React.useEffect(() => {
    setLoadingData(
      !(
        azureDevOpsProjects.completed &&
        octopusTemplateProjects.completed &&
        octopusARMSteps.completed &&
        users.completed &&
        !loadingServiceForm
      )
    );
  }, [azureDevOpsProjects, loadingServiceForm, octopusARMSteps, octopusTemplateProjects, users]);

  React.useEffect(() => {
    if (serviceFormsState.updating === false) {
      getServiceFormById(Number(serviceFormId)).then(sf => setServiceForm(sf));
    }
  }, [serviceFormsState.updating]);
  const pastPeerReviewers = serviceForm?.serviceFormPeerReviewers.every(
    pr => pr.reviewerStatus === "Approved"
    );
  const waitingForFinalApproval = pastPeerReviewers && serviceForm?.status === "WaitingForFinalApproval";
  const canApproveAsBCSMReviewer =
    serviceForm?.status === "WaitingForBCSMApproval" &&
      isAllowed([BCSM_REVIEWER], roles) && (serviceForm?.serviceFormBCSMReviewer == null);
  const canApproveAsPeerReviewer =
      serviceForm?.status === "WaitingForApproval" && serviceForm?.serviceFormBCSMReviewer?.reviewerStatus === "Approved" && serviceForm?.serviceFormPeerReviewers.some(
        pr => pr.peerReviewerId === user?.id && pr.reviewerStatus !== "Approved"
  );
  const canApproveAsFinalApprover = waitingForFinalApproval && serviceForm?.approverId === user?.id;
  const isBcsmReviewerDetails = bcsmReviewStatus !== "Approved" && bcsmReviewStatus !== "Denied";
  function getFinalApproverStatus(): ServiceFormStatus | undefined {
    if (serviceForm?.status === "Completed") return "Approved";
    if (pastPeerReviewers) {
      return serviceForm?.status;
    }
    return undefined;
  }
  function updateBCSMReviewerStatusDenied(): void {
  const requestBody: ServiceFormBCSMReviewStatusRequest = {
    serviceFormId: Number(serviceForm?.id),
    reviewerId: Number(user?.id),
    status: "Denied"
  };
  updateServiceFormBCSMReviewStatus(requestBody)
    .then(sf =>
      showSuccessSnackbar(enqueueSnackbar, closeSnackbar, "Cloud Service Request Denied.")
    )
    .catch(error =>
      showErrorSnackbar(enqueueSnackbar, closeSnackbar, "Failed to update reviewer status.")
    );
  }
  function updateBCSMReviewerStatusApproved(): void {
  const requestBody: ServiceFormBCSMReviewStatusRequest = {
    serviceFormId: Number(serviceForm?.id),
    reviewerId: Number(user?.id),
    status: "Approved"
  };
  updateServiceFormBCSMReviewStatus(requestBody)
    .then(sf =>
      showSuccessSnackbar(enqueueSnackbar, closeSnackbar, "Cloud Service Request Approved.")
    )
    .catch(error =>
      showErrorSnackbar(enqueueSnackbar, closeSnackbar, "Failed to update reviewer status.")
    );
  }

  function updatePeerReviewerStatusDenied(): void {
    const requestBody: ServiceFormPeerReviewStatusRequest = {
      serviceFormId: Number(serviceForm?.id),
      reviewerId: Number(user?.id),
      status: "Denied"
    };
    updateServiceFormReviewStatus(requestBody)
      .then(sf =>
        showSuccessSnackbar(enqueueSnackbar, closeSnackbar, "Cloud Service Request Denied.")
      )
      .catch(error =>
        showErrorSnackbar(enqueueSnackbar, closeSnackbar, "Failed to update reviewer status.")
      );
  }

  function updatePeerReviewerStatusApproved(): void {
    const requestBody: ServiceFormPeerReviewStatusRequest = {
      serviceFormId: Number(serviceForm?.id),
      reviewerId: Number(user?.id),
      status: "Approved"
    };
    updateServiceFormReviewStatus(requestBody)
      .then(sf =>
        showSuccessSnackbar(enqueueSnackbar, closeSnackbar, "Cloud Service Request Approved.")
      )
      .catch(error =>
        showErrorSnackbar(enqueueSnackbar, closeSnackbar, "Failed to update reviewer status.")
      );
  }

  function updateReviewerStatusDenied(): void {
    const requestBody: ServiceFormStatusRequest = {
      id: Number(serviceForm?.id),
      userId: Number(user?.id),
      status: "Denied"
    };
    updateServiceFormStatus(requestBody)
      .then(sf =>
        showSuccessSnackbar(enqueueSnackbar, closeSnackbar, "Cloud Service Request Denied.")
      )
      .catch(error =>
        showErrorSnackbar(enqueueSnackbar, closeSnackbar, "Failed to update reviewer status.")
      );
  }

  function updateReviewerStatusApproved(): void {
    const requestBody: ServiceFormStatusRequest = {
      id: Number(serviceForm?.id),
      userId: Number(user?.id),
      status: "Approved"
    };
    updateServiceFormStatus(requestBody)
      .then(sf =>
        showSuccessSnackbar(enqueueSnackbar, closeSnackbar, "Cloud Service Request Approved.")
      )
      .catch(error =>
        showErrorSnackbar(enqueueSnackbar, closeSnackbar, "Failed to update reviewer status.")
      );
  }

  function findEntity<T extends { id: number }>(id: number, data: T[]): T | undefined {
    const filteredEntities = data.filter(p => p.id === id);
    if (filteredEntities.length > 0) return filteredEntities[0];
    return undefined;
  }

  function findResource<T extends { id: string }>(id: string, resources: T[]): T | undefined {
    const filteredResources = resources.filter(p => p.id === id);
    if (filteredResources.length > 0) return filteredResources[0];
    return undefined;
  }

  function openPeerReviewersDialog(): void {
    setPeerReviewersDialogOpen(true);
  }

  function closePeerReviewersDialog(): void {
    setPeerReviewersDialogOpen(false);
  }

  function updatePeerReviewers(selectedReviewer: UserResponse | null): void {
    closePeerReviewersDialog();
    if (selectedReviewer) {
      const reviewer = serviceForm?.serviceFormPeerReviewers.find(
        pr => pr.peerReviewerId !== user?.id
      );
      const newReviewers: number[] = [
        Number(reviewer?.peerReviewerId),
        Number(selectedReviewer?.id)
      ];
      const body: ServiceFormPeerReviewersRequest = {
        id: Number(serviceForm?.id),
        peerReviewerIds: newReviewers
      };
      updateServiceFormPeerReviewers(body)
        .then(sf => showSuccessSnackbar(enqueueSnackbar, closeSnackbar, "Updated Peer Reviewer."))
        .catch(error =>
          showErrorSnackbar(enqueueSnackbar, closeSnackbar, "Failed to update peer reviewer.")
        );
    }
  }

  function ApproveForm() {
    if (canApproveAsFinalApprover) {
      updateReviewerStatusApproved();
    }else if(canApproveAsPeerReviewer){
        updatePeerReviewerStatusApproved();
      }
    else{
        updateBCSMReviewerStatusApproved();
      }
  }

  function DenyForm() {
    if (canApproveAsFinalApprover) {
      updateReviewerStatusDenied();
    } else if(canApproveAsBCSMReviewer){
      updateBCSMReviewerStatusDenied();
      }
    else{
      updatePeerReviewerStatusDenied();
    }
  }

  function GetProperFileName(fileName: string) {
    let fileNameParts = fileName.split(".");
    let extensionPart = fileNameParts[fileNameParts.length - 1];
    if (extensionPart.length > generateRandomGuid().length) {
      return fileName.slice(0, (generateRandomGuid().length * -1));
    }

    return fileName;
  }

   

  if (!loadingServiceForm && !serviceForm) return <NotFoundPage />;
 
  if (serviceForm && !loadingData)
    return (
      <Page back title={`Cloud Service Request - ${serviceForm.projectName}`}>
        <Paper variant="outlined">
          <div className="details-paper-content">
            <Grid container direction="row" justifyContent="space-between" spacing={3}>
              <Grid item xs={7}>
                <DetailSection title="Service information">
                  <Grid container direction="row" spacing={3}>
                    <DetailColumn
                      type="name"
                      values={[
                        "Product",
                        "Deployable Name",
                        "Azure DevOps Project",
                        "Azure DevOps Team",
                        "Monthly Cost Estimate",
                        "Target Production Date",
                        "Description"
                      ]}
                    />
                    <DetailColumn
                      type="value"
                      values={[
                        `${serviceForm.productName} (${serviceForm.productId})`,
                        serviceForm.projectName,
                        findResource<DevOpsProjectResponse>(
                          serviceForm.devOpsProjectId,
                          azureDevOpsProjects.data
                        )?.name,
                        serviceForm.devOpsTeamName,
                        serviceForm.monthlyCostEstimate
                          ? `${serviceForm.monthlyCostEstimate}$`
                          : undefined,
                        serviceForm.targetProductionDate
                          ? new Date(serviceForm.targetProductionDate).toLocaleDateString(
                              "en-US",
                              dateOptions
                            )
                          : undefined,
                        serviceForm.projDescription
                      ]}
                    />
                  </Grid>
                  <a
                    download={GetProperFileName(serviceForm.architectureDiagramFileName)}
                    href={`data:image/*;base64,${serviceForm.architectureDiagramFileContent}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button className="detail-section-button" color="secondary" variant="outlined">
                      Download architecture diagram
                    </Button>
                  </a>
                </DetailSection>
                <DetailSection title="Infrastructure">
                  <Grid container direction="row" spacing={3}>
                    <DetailColumn
                      type="name"
                      values={["Region", "Project Type", "Additional Resources"]}
                    />
                    <DetailColumn
                      type="value"
                      values={[
                        serviceForm.region,
                        findResource<OctopusProjectResponse>(
                          serviceForm.octopusProjectType,
                          octopusTemplateProjects.data
                        )?.name,
                        serviceForm.serviceFormAdditionalResources.map(
                          ar =>
                            findResource<OctopusARMStepResponse>(
                              ar.resourceId,
                              octopusARMSteps.data
                            )?.name
                        )
                      ]}
                    />
                  </Grid>
                </DetailSection>
              </Grid>
              <Grid item xs={5}>
                <DetailSection title="Contacts">
                  <Grid container direction="row" spacing={1}>
                    <DetailColumn
                      type="name"
                      values={[
                        "Product Manager Email",
                        "Product Manager Phone",
                        "Development Lead Email",
                        "Development Lead Phone",
                        "Primary Developer Email",
                        "Primary Developer Phone",
                        "Secondary Developer Email",
                        "Secondary Developer Phone",
                        "QA Lead Email",
                        "QA Lead Phone"
                      ]}
                    />
                    <DetailColumn
                      type="value"
                      values={[
                        serviceForm.productManagerEmail,
                        serviceForm.productManagerPhone,
                        serviceForm.devLeadEmail,
                        serviceForm.devLeadPhone,
                        serviceForm.primaryDevEmail,
                        serviceForm.primaryDevPhone,
                        serviceForm.secondaryDevEmail,
                        serviceForm.secondaryDevPhone,
                        serviceForm.qaLeadEmail,
                        serviceForm.qaLeadPhone
                      ]}
                    />
                  </Grid>
                </DetailSection>
                <DetailSection title="Approval">
                  <DetailInnerSection title="Request">
                    <Typography variant="body2">Created By: {serviceForm.formCreator}</Typography>
                    <Typography variant="body2">
                      Updated By:{" "}
                      {findEntity<UserResponse>(serviceForm.userId, users.data)?.name ||
                        "User not found"}
                    </Typography>
                    <Typography variant="body2">
                      Created: <TimeAgo date={`${serviceForm.createdTime}Z`} />
                    </Typography>
                  </DetailInnerSection>
                   <DetailInnerSection title="BCSM  Reviews">
                    <div className="space-vertical-16">
                      <ApprovalDetail
                        status={bcsmReviewStatus}
                        userName={isBcsmReviewerDetails ? "BCSM Approver" : findEntity<UserResponse>(bcsmReviewerID, users.data)?.name || ""}
                        updateTime={approvedReviewer ? `${approvedReviewer.updateTime}Z` : ''}
                        type="review"
                      />
                    </div>
                  </DetailInnerSection>
                  <DetailInnerSection title="Peer Reviews">
                    <div className="space-vertical-16">
                      <ApprovalDetail
                        status={serviceForm.serviceFormPeerReviewers[0].reviewerStatus}
                        userName={
                          findEntity<UserResponse>(
                            serviceForm.serviceFormPeerReviewers[0].peerReviewerId,
                            users.data
                          )?.name
                        }
                        updateTime={`${serviceForm.serviceFormPeerReviewers[0].updateTime}Z`}
                        type="review"
                      />
                      <ApprovalDetail
                        status={serviceForm.serviceFormPeerReviewers[1].reviewerStatus}
                        userName={
                          findEntity<UserResponse>(
                            serviceForm.serviceFormPeerReviewers[1].peerReviewerId,
                            users.data
                          )?.name
                        }
                        updateTime={`${serviceForm.serviceFormPeerReviewers[1].updateTime}Z`}
                        type="review"
                      />
                    </div>
                  </DetailInnerSection>
                  <DetailInnerSection title="Final Approval">
                    <ApprovalDetail
                      status={getFinalApproverStatus()}
                      userName={findEntity<UserResponse>(serviceForm.approverId, users.data)?.name}
                      updateTime={
                        getFinalApproverStatus() === "Approved" ? `${serviceForm.updateTime}Z` : undefined
                      }
                      type="approval"
                    />
                  </DetailInnerSection>
                </DetailSection>
              </Grid>
              <Grid item xs={12}>
                {(canApproveAsBCSMReviewer || canApproveAsPeerReviewer || canApproveAsFinalApprover) && (
                  <div className="space-horizontal-4 align-right">
                    <ThemeProvider theme={!darkMode ? statusTheme : darkStatusTheme}>
                      <Button color="secondary" variant="outlined" onClick={DenyForm}>
                        {serviceFormsState.updating ? "Updating..." : "Deny"}
                      </Button>
                      {canApproveAsPeerReviewer && (
                        <Button
                          color="default"
                          variant="outlined"
                          onClick={openPeerReviewersDialog}
                        >
                          {serviceFormsState.updating ? "Updating..." : "Reassign"}
                        </Button>
                      )}
                      <Button color="primary" variant="outlined" onClick={ApproveForm}>
                        {serviceFormsState.updating ? "Updating..." : "Approve"}
                      </Button>
                    </ThemeProvider>
                  </div>
                )}
                <PeerReviewers
                  onClose={closePeerReviewersDialog}
                  onOK={updatePeerReviewers}
                  open={peerReviewersDialogOpen}
                  users={users}
                />
              </Grid>
              <Grid item xs={12}>
                <DetailSection title="Comments">
                  <Comments
                    validationSettings={{ allowEmpty: false, maxCommentLength: 4000 }}
                    comments={comments}
                    isLoading={isLoadingComments}
                    isSending={isSendingComment}
                    sendHandler={sendComment}
                    getCommentControlPermissions={checkIfUserIsCurrentUser}
                    deleteCommentHandler={deleteComment}
                  />
                </DetailSection>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Page>
    );

  return <FormDetailsSkeleton />;
}

function mapStateToProps(state: ApplicationState): unknown {
  return {
    azureDevOpsProjects: state.azureDevOpsProjects,
    octopusARMSteps: state.octopusARMSteps,
    octopusTemplateProjects: state.octopusTemplateProjects,
    users: state.users,
    serviceFormsState: state.serviceForms
  };
}

function mapDispatchToProps(dispatch: Redux.Dispatch<Redux.AnyAction>): unknown {
  return Redux.bindActionCreators(
    {
      getAzureDevOpsProjects: getAllDevOpsProjectsDispatch,
      getOctopusARMSteps: getARMStepsDispatch,
      getOctopusTemplateProjects: getAllTemplateProjectsDispatch,
      getAllUsers: getAllUsersDispatch,
      updateServiceFormReviewStatus: updateServiceFormReviewStatusDispatch,
      updateServiceFormBCSMReviewStatus: updateServiceFormBCSMStatusDispatch,
      updateServiceFormPeerReviewers: updateServiceFormPeerReviewersDispatch,
      updateServiceFormStatus: updateServiceFormStatusDispatch
    },
    dispatch
  );
}

export const FormDetails = connect(mapStateToProps, mapDispatchToProps)(FormDetailsPage);
