import * as React from "react";
import * as Redux from "redux";
import { connect, ConnectedProps } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { ApplicationState } from "../../../redux/reducers";
import { OutlinedAutocomplete } from "../../Inputs";
import * as Constants from "./constants";
import * as Utils from "../../../utilities/SharePointUtils";
import { SharePointContactViewModel } from "./types";

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & EditContactsTableProps;

interface EditContactsTableProps {
  initialContactsValues: { [name: string]: SharePointContactViewModel };
  setChangesHandler: React.Dispatch<
    React.SetStateAction<{ [x: string]: SharePointContactViewModel }>
  >;
}

const ContactsEditTable = (props: Props): JSX.Element => {
  const { initialContactsValues, setChangesHandler, users } = props;

  const elementRows = Object.keys(initialContactsValues).map(key => ({
    name: (
      <OutlinedAutocomplete<string>
        className="escalationContacts-table"
        getOptionLabel={(p: string): string => `${p}`}
        handle={(newValue: string | null) => {
          setChangesHandler(old => ({ ...old, [key]: { ...old[key], name: newValue ?? "" } }));
        }}
        label=""
        value={
          initialContactsValues[key].name.length === 0 ? null : initialContactsValues[key].name
        }
        options={users.data.map(user => user.name)}
      />
    ),
    phone: (
      <TextField
        classes={{ root: "escalationContacts-table" }}
        defaultValue={initialContactsValues[key].phone}
        placeholder="Enter phone number"
        fullWidth
        onChange={e => {
          setChangesHandler(old => ({
            ...old,
            [key]: { ...old[key], phone: e.target.value }
          }));
        }}
      />
    ),
    role: (
      <Typography variant="inherit" classes={{ root: "escalationContacts-table" }}>
        {Utils.getRoleNameByKey(key)}
      </Typography>
    )
  }));

  return (
    <MUIDataTable
      title=""
      columns={Constants.ContactsTableColumn}
      data={elementRows}
      options={Constants.ContactsTableOptions}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    users: state.sharePointUsers
  };
};

const mapDispatchToProps = (dispatch: Redux.Dispatch<Redux.AnyAction>) => {
  return Redux.bindActionCreators({}, dispatch);
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ContactsEditTable);
