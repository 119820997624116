import * as React from "react";

import { ButtonGroup, Button, Grid, Typography, Divider } from "@material-ui/core";

import * as actions from "./actions";
import {
  OutlinedTextField,
  OutlinedAutocomplete,
  OutlinedMultipleAutocomplete
} from "../../Inputs";
import { ProductResponse } from "../../../models";
import { SeqProps } from "./types";
import { seqKeyEnvironmentList } from "../../../constants/seqKeyEnvironments";
import { FieldValidator } from "../../../utilities/FieldValidator";

export function Seq(props: SeqProps): JSX.Element {
  const {
    isSeqRequired,
    dispatch,
    products,
    product,
    seqKeyEnvironments,
    serviceForm,
    errors,
    displayError,
    isCloneEnabled
  } = props;

  if (isCloneEnabled)
    return (
      <div>
        <Typography color="textSecondary" variant="body2" style={{ marginBottom: 24 }}>
          SEQ key setup for cloned services is not supported.
        </Typography>
      </div>
    );
  return (
      <div>
        <ButtonGroup color="primary">
          <Button
            disableElevation
            onClick={(): void => {
              if (dispatch) dispatch(actions.setIsSeqRequired(false));
            }}
            variant={!isSeqRequired ? "contained" : "outlined"}
          >
            Not Required
          </Button>
          <Button
            disableElevation
            onClick={(): void => {
              if (dispatch) dispatch(actions.setIsSeqRequired(true));
            }}
            variant={isSeqRequired ? "contained" : "outlined"}
          >
            Required
          </Button>
        </ButtonGroup>
        <div className="mt-4">
          <Grid container direction="row" spacing={10}>
            <Grid item xs={12}>
              <Typography
                className="text-uppercase font-weight-bold"
                color="textSecondary"
                variant="body2"
              >
                SEQ API Key
              </Typography>
              <Divider className="mt-2 mb-2" />
              <FieldValidator
                errors={errors}
                displayError={displayError}
                disabled={!isSeqRequired || serviceForm !== null}
              >
                <OutlinedAutocomplete<ProductResponse>
                  dispatch={dispatch}
                  disabled={serviceForm !== null || !isSeqRequired}
                  getOptionLabel={(p: ProductResponse): string =>
                    `${p.productId} - ${p.productName}`
                  }
                  handle={actions.setProduct}
                  label="Product"
                  helperText="Select product from Global Product Registry site (GPRID - Product Name)"
                  loading={products.pending}
                  options={products.data}
                  required={isSeqRequired}
                  value={product}
                />
              </FieldValidator>
              <FieldValidator errors={errors} displayError={displayError} disabled={!isSeqRequired}>
                <OutlinedMultipleAutocomplete<string>
                  dispatch={dispatch}
                  handle={actions.setSeqKeyEnvironments}
                  helperText="SEQ API Key will be created for each selected environment."
                  label="Environments"
                  getOptionLabel={(p: string): string => p.toUpperCase()}
                  multiple
                  options={seqKeyEnvironmentList}
                  required
                  value={seqKeyEnvironments}
                  disabled={!isSeqRequired}
                />
              </FieldValidator>
            </Grid>
          </Grid>
        </div>
      </div>
    );
}
