import {
  OperationaTestsServiceState,
  OperationalTestsAction,
  OperationaTestsServiceToCreateState,
  OperationaTestsScheduleToCreateState
} from "./types";
import {
  GET_ALL_SERVICES,
  GET_ALL_SERVICES_SUCCESS,
  GET_ALL_SERVICES_ERROR,
  CREATE_SERVICE,
  CREATE_SERVICE_SUCCESS,
  CREATE_SERVICE_ERROR,
  CREATE_SCHEDULE,
  CREATE_SCHEDULE_SUCCESS,
  CREATE_SCHEDULE_ERROR,
  CREATE_SERVICE_RESET,
  CREATE_SCHEDULE_RESET
} from "../constants";

export const initialOperationalTestsServicesState: OperationaTestsServiceState = {
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const operationalTestsServicesReducer = (
  state: OperationaTestsServiceState = initialOperationalTestsServicesState,
  action: OperationalTestsAction
): OperationaTestsServiceState => {
  switch (action.type) {
    case GET_ALL_SERVICES:
      return {
        ...state,
        pending: true
      };
    case GET_ALL_SERVICES_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_ALL_SERVICES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};

export const initialOperationalTestsServiceToCreateState: OperationaTestsServiceToCreateState = {
  creating: false,
  pending: false,
  completed: false,
  data: null,
  error: undefined
};

export const operationalTestsServiceToCreateReducer = (
  state: OperationaTestsServiceToCreateState = initialOperationalTestsServiceToCreateState,
  action: OperationalTestsAction
): OperationaTestsServiceToCreateState => {
  switch (action.type) {
    case CREATE_SERVICE:
      return {
        ...state,
        creating: true,
        completed: false
      };
    case CREATE_SERVICE_SUCCESS:
      return {
        ...state,
        creating: false,
        data: action.payload,
        completed: true
      };
    case CREATE_SERVICE_ERROR:
      return {
        ...state,
        creating: false,
        error: action.payload,
        completed: true
      };
    case CREATE_SERVICE_RESET:
      return {
        pending: false,
        completed: false,
        data: null,
        creating: false,
        error: undefined
      };
    default:
      return state;
  }
};

export const initialOperationalTestsScheduleToCreateState: OperationaTestsScheduleToCreateState = {
  creating: false,
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const operationalTestsScheduleToCreateReducer = (
  state: OperationaTestsScheduleToCreateState = initialOperationalTestsScheduleToCreateState,
  action: OperationalTestsAction
): OperationaTestsScheduleToCreateState => {
  switch (action.type) {
    case CREATE_SCHEDULE:
      return {
        ...state,
        creating: true,
        completed: false
      };
    case CREATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        creating: false,
        completed: true
      };
    case CREATE_SCHEDULE_ERROR:
      return {
        ...state,
        creating: false,
        error: action.payload,
        completed: true
      };
    case CREATE_SCHEDULE_RESET:
      return {
        pending: false,
        completed: false,
        data: [],
        creating: false,
        error: undefined
      };
    default:
      return state;
  }
};
