import * as React from "react";
import * as Redux from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { ThemeProvider } from "styled-components";
import { DeployablesProps } from "./types";
import { ApplicationState } from "../../../redux/reducers";
import { DeployablesTable } from "./DeployablesTable";
import { AppContext } from "../../../context/AppContext";
import { ADMIN, FORM_CREATOR } from "../../../constants/userRoles";
import { AuthContext } from "../../../context/AuthContext";
import { isAllowed } from "../../Navigation";
import { HOME } from "../../../constants/paths";
import { getAllDeployablesDispatch, updateDeployablesDispatch } from "../../../services";
import { textFieldsTheme, darkTextFieldsTheme } from "../../../themes";
import { DeployablesQuery } from "./DeployablesQuery";
import { LoadingPage } from "../Loading";
import { executeDeployablesQueryDispatch } from "../../../services/DeployablesService";
import { getDeployablesQueryCount } from "./service";
import { Page } from "../Page";

import "./Deployables.scss";

function DeployablesPage(props: DeployablesProps) {
  const {
    deployables,
    getAllDeployables,
    updateDeployables,
    executeDeployablesQuery
  } = props;

  const { darkMode, setSelectedTab } = React.useContext(AppContext);
  const { roles } = React.useContext(AuthContext);
  const history = useHistory();
  const [data, setData] = React.useState(Array.from(deployables.data));
  const [loadingData, setLoadingData] = React.useState(true);
  const [updateColumnWidths, setUpdateColumnWidths] = React.useState(false);
  const [startingTablePage, setStartingTablePage] = React.useState(5);
  const [columnWidths, setColumnWidths] = React.useState({
    actions: 65,
    gprId: 75,
    consumerGPRID: 100,
    deployable: 130,
    productManager: 130,
    developmentLead: 130,
    projectType: 130,
    resourceGroup: 130,
    resourceGroupNameBase: 145,
    advancementUnit: 135,
    environment: 100,
    subscriptionName: 125
  });

  React.useEffect(() => {
    if (setSelectedTab) setSelectedTab(4);

    if (!deployables.completed && !deployables.pending) getAllDeployables();

    setLoadingData(!deployables.completed);
  }, [])

  React.useEffect(() => {
    if (roles) {
      if (!isAllowed([ADMIN, FORM_CREATOR], roles)) {
        history.push(HOME);
      }
    }
  }, [roles])

  React.useEffect(() => {
    setLoadingData(!deployables.completed);
    setData(Array.from(deployables.data));
  }, [deployables])

  return (
    <Page title="Deployables" className="deployablesPage">
      <ThemeProvider theme={darkMode ? darkTextFieldsTheme : textFieldsTheme}>
        <Paper variant="outlined" className="deployablesPaperContainer">
          <Paper variant="outlined" className="deployablesPaper">
            {loadingData ? <LoadingPage message="Loading..." /> :
              <div className="deployablesDiv">
                <DeployablesTable
                  data={data}
                  setData={setData}
                  updateDeployablesData={updateDeployables}
                  columnWidths={columnWidths}
                  setColumnWidths={setColumnWidths}
                  updateColumnWidths={updateColumnWidths}
                  setUpdateColumnWidths={setUpdateColumnWidths}
                  darkMode={darkMode}
                  startingTablePage={startingTablePage}
                  setStartingTablePage={setStartingTablePage}
                />
              </div>}
          </Paper>
          <div className="deployablesPaperSeparator" />
          {loadingData ? null :
            <Paper variant="outlined" className="deployablesPaper">
              <DeployablesQuery
                executeDeployablesQuery={executeDeployablesQuery}
                getDeployablesQueryCount={getDeployablesQueryCount}
                setUpdateColumnWidths={setUpdateColumnWidths}
                darkMode={darkMode!}
              />
            </Paper>}
        </Paper>
      </ThemeProvider>
    </Page>
  );
}

function mapStateToProps(state: ApplicationState): unknown {
  return {
    deployables: state.deployables,
  };
}

function mapDispatchToProps(dispatch: Redux.Dispatch<Redux.AnyAction>): unknown {
  return Redux.bindActionCreators(
    {
      getAllDeployables: getAllDeployablesDispatch,
      updateDeployables: updateDeployablesDispatch,
      executeDeployablesQuery: executeDeployablesQueryDispatch,
    },
    dispatch
  );
}

export const Deployables = connect(mapStateToProps, mapDispatchToProps)(DeployablesPage);

