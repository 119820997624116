import * as Redux from "redux";
import { AzureDevOpsAction } from "../redux/reducers/types";
import * as DevOpsActions from "../redux/actions/azureDevOpsActions";
import { RequestBuilder } from "../utilities/RequestBuilder";
import {
  DevOpsTeamResponse,
  DevOpsProjectResponse,
  DevOpsEnvironmentResponse,
  DevOpsReleaseDefinitionResponse
} from "../models/azureDevOpsResponses";
import {
  CreateOrGetADOGitRepoRequest,
  CreateOrGetADOTeamRequest,
  CreateADOBuildDefinitionRequest,
  CreateADOReleaseDefinitionRequest,
  SetAzureDevOpsDefaultTeamPermissionsRequest,
  SetAzureDevOpsBuildPipelinePermissionsRequest,

  SetAzureDevOpsReleasePipelinePermissionsRequest
} from "../models/azureDevOpsRequests";

export function getAllDevOpsTeamsDispatch() {
  return async (dispatch: Redux.Dispatch<AzureDevOpsAction>): Promise<void> => {
    dispatch(DevOpsActions.getAllTeams());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/devops/teams")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const teams = (await response.json()) as DevOpsTeamResponse[];

      dispatch(DevOpsActions.getAllTeamsSuccess(teams));
    } catch (error) {
      dispatch(DevOpsActions.getAllTeamsError(error));
    }
  };
}

export function getAllDevOpsProjectsDispatch() {
  return async (dispatch: Redux.Dispatch<AzureDevOpsAction>): Promise<void> => {
    dispatch(DevOpsActions.getAllProjects());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/devops/projects")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const projects = (await response.json()) as DevOpsProjectResponse[];

      dispatch(DevOpsActions.getAllProjectsSuccess(projects));
    } catch (error) {
      dispatch(DevOpsActions.getAllProjectsError(error));
    }
  };
}

export function getDevOpsReleaseEnvironmentsDispatch(releaseId: number) {
  return async (dispatch: Redux.Dispatch<AzureDevOpsAction>): Promise<void> => {
    dispatch(DevOpsActions.getReleaseEnvironments(releaseId));
    try {
      const request = new RequestBuilder()
        .setRequestUrl(`api/devops/releaseEnvironments/${  releaseId}`)
        .setMethod("GET")
        .build();

      const response = await request.send();
      const releaseEnvironments = (await response.json()) as DevOpsEnvironmentResponse[];

      dispatch(DevOpsActions.getReleaseEnvironmentsSuccess(releaseEnvironments));
    } catch (error) {
      dispatch(DevOpsActions.getReleaseEnvironmentsError(error));
    }
  };
}

export function createOrGetAzureDevOpsGitRepoDispatch(body: CreateOrGetADOGitRepoRequest) {
  return async (dispatch: Redux.Dispatch<AzureDevOpsAction>): Promise<void> => {
    dispatch(DevOpsActions.createOrGetAzureDevOpsGitRepo());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/devops/gitrepository")
        .setMethod("POST")
        .setBody(body)
        .build();

      const response = await request.send();
      if (!response.ok) {
        const error = await response.json();
        throw Error(JSON.stringify(error.Message));
      }

      dispatch(DevOpsActions.createOrGetAzureDevOpsGitRepoSuccess());
    } catch (error) {
      dispatch(DevOpsActions.createOrGetAzureDevOpsGitRepoError(error));
    }
  };
}

export function createOrGetAzureDevOpsTeamDispatch(body: CreateOrGetADOTeamRequest) {
  return async (dispatch: Redux.Dispatch<AzureDevOpsAction>): Promise<void> => {
    dispatch(DevOpsActions.createOrGetAzureDevOpsTeam());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/devops/team")
        .setMethod("POST")
        .setBody(body)
        .build();

      const response = await request.send();
      if (!response.ok) {
        const error = await response.json();
        throw Error(JSON.stringify(error.Message));
      }

      dispatch(DevOpsActions.createOrGetAzureDevOpsTeamSuccess());
    } catch (error) {
      dispatch(DevOpsActions.createOrGetAzureDevOpsTeamError(error));
    }
  };
}

export function createAzureDevOpsBuildDefinitionDispatch(body: CreateADOBuildDefinitionRequest) {
  return async (dispatch: Redux.Dispatch<AzureDevOpsAction>): Promise<void> => {
    dispatch(DevOpsActions.createAzureDevOpsBuildDefinition());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/devops/builddefinition")
        .setMethod("POST")
        .setBody(body)
        .build();

      const response = await request.send();
      if (!response.ok) {
        const error = await response.json();
        throw Error(JSON.stringify(error.Message));
      }

      dispatch(DevOpsActions.createAzureDevOpsBuildDefinitionSuccess());
    } catch (error) {
      dispatch(DevOpsActions.createAzureDevOpsBuildDefinitionError(error));
    }
  };
}

export function createAzureDevOpsReleaseDefinitionDispatch(
  body: CreateADOReleaseDefinitionRequest
) {
  return async (dispatch: Redux.Dispatch<AzureDevOpsAction>): Promise<void> => {
    dispatch(DevOpsActions.createAzureDevOpsReleaseDefinition());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/devops/releasedefinition")
        .setMethod("POST")
        .setBody(body)
        .build();

      const response = await request.send();
      if (!response.ok) {
        const error = await response.json();
        throw Error(JSON.stringify(error.Message));
      }

      const instance = (await response.json()) as DevOpsReleaseDefinitionResponse;
      dispatch(DevOpsActions.createAzureDevOpsReleaseDefinitionSuccess(instance));
    } catch (error) {
      dispatch(DevOpsActions.createAzureDevOpsReleaseDefinitionError(error));
    }
  };
}

export function setAzureDevOpsDefaultTeamPermissionsDispatch(
  body: SetAzureDevOpsDefaultTeamPermissionsRequest
) {
  return async (dispatch: Redux.Dispatch<AzureDevOpsAction>): Promise<void> => {
    dispatch(DevOpsActions.setAzureDevOpsDefaultTeamPermissions());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/devops/team/permissions")
        .setMethod("POST")
        .setBody(body)
        .build();

      const response = await request.send();
      if (!response.ok) {
        const error = await response.json();
        throw Error(JSON.stringify(error.Message));
      }

      dispatch(DevOpsActions.setAzureDevOpsDefaultTeamPermissionsSuccess());
    } catch (error) {
      dispatch(DevOpsActions.setAzureDevOpsDefaultTeamPermissionsError(error));
    }
  };
}

export function setAzureDevOpsBuildPipelinePermissionsDispatch(
  body: SetAzureDevOpsBuildPipelinePermissionsRequest
) {
  return async (dispatch: Redux.Dispatch<AzureDevOpsAction>): Promise<void> => {
    dispatch(DevOpsActions.setAzureDevOpsBuildPipelinePermissions());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/devops/builddefinition/permissions")
        .setMethod("POST")
        .setBody(body)
        .build();

      const response = await request.send();
      if (!response.ok) {
        const error = await response.json();
        throw Error(JSON.stringify(error.Message));
      }

      dispatch(DevOpsActions.setAzureDevOpsBuildPipelinePermissionsSuccess());
    } catch (error) {
      dispatch(DevOpsActions.setAzureDevOpsBuildPipelinePermissionsError(error));
    }
  };
}

export function setAzureDevOpsReleasePipelinePermissionsDispatch(
  body: SetAzureDevOpsReleasePipelinePermissionsRequest
) {
  return async (dispatch: Redux.Dispatch<AzureDevOpsAction>): Promise<void> => {
    dispatch(DevOpsActions.setAzureDevOpsReleasePipelinePermissions());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/devops/releasedefinition/permissions")
        .setMethod("POST")
        .setBody(body)
        .build();

      const response = await request.send();
      if (!response.ok) {
        const error = await response.json();
        throw Error(JSON.stringify(error.Message));
      }

      dispatch(DevOpsActions.setAzureDevOpsReleasePipelinePermissionsSuccess());
    } catch (error) {
      dispatch(DevOpsActions.setAzureDevOpsReleasePipelinePermissionsError(error));
    }
  };
}

export function devOpsGitRepoStateResetDispatch() {
  return async (dispatch: Redux.Dispatch<AzureDevOpsAction>): Promise<void> => {
    dispatch(DevOpsActions.gitRepoToCreateReset());
  };
}

export function devOpsTeamToCreateStateResetDispatch() {
  return async (dispatch: Redux.Dispatch<AzureDevOpsAction>): Promise<void> => {
    dispatch(DevOpsActions.teamToCreateReset());
  };
}

export function devOpsTeamPermissionsToAddStateResetDispatch() {
  return async (dispatch: Redux.Dispatch<AzureDevOpsAction>): Promise<void> => {
    dispatch(DevOpsActions.teamPermissionsToAddReset());
  };
}

export function devOpsBuildPipelineToCreateStateResetDispatch() {
  return async (dispatch: Redux.Dispatch<AzureDevOpsAction>): Promise<void> => {
    dispatch(DevOpsActions.buildPipelineToCreateReset());
  };
}

export function devOpsBuildPipelinePermissionsToAddStateResetDispatch() {
  return async (dispatch: Redux.Dispatch<AzureDevOpsAction>): Promise<void> => {
    dispatch(DevOpsActions.buildPipelinePermissionsToAddReset());
  };
}

export function devOpsReleasePipelineToCreateStateResetDispatch() {
  return async (dispatch: Redux.Dispatch<AzureDevOpsAction>): Promise<void> => {
    dispatch(DevOpsActions.releasePipelineToCreateReset());
  };
}

export function devOpsReleasePipelinePermissionsToAddStateResetDispatch() {
  return async (dispatch: Redux.Dispatch<AzureDevOpsAction>): Promise<void> => {
    dispatch(DevOpsActions.releasePipelinePermissionsToAddReset());
  };
}
