import thunk from "redux-thunk";
import { Store, createStore, applyMiddleware } from "redux";
import { History } from "history";

import { ApplicationState, createRootReducer, initialApplicationState } from "./reducers";

export default function configureStore(
  history: History,
  initialState: ApplicationState = initialApplicationState
): Store<ApplicationState> {
  return createStore(createRootReducer(history), initialState, applyMiddleware(thunk));
}
