import * as Redux from "redux";
import { Deployable, DeployablesResponse } from "../models/deployablesResponses";
import { RequestBuilder } from "../utilities/RequestBuilder";
import * as DeployablesActions from "../redux/actions/deployablesActions";
import { DeployablesAction } from "../redux/reducers/types";
import { DeployablesQueryRequest, UpdateDeployablesRequest } from "../models/deployablesRequests";

export function getAllDeployablesDispatch() {
  return async (dispatch: Redux.Dispatch<DeployablesAction>): Promise<void> => {
    dispatch(DeployablesActions.getAllDeployables());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/deployables")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const deployables = (await response.json()) as Deployable[];
      dispatch(DeployablesActions.getAllDeployablesSuccess(deployables));
    } catch (error) {
      dispatch(DeployablesActions.getAllDeployablesError(error));
    }
  };
}

export function updateDeployablesDispatch(body: UpdateDeployablesRequest) {
  return async (dispatch: Redux.Dispatch<DeployablesAction>): Promise<string> => {
    dispatch(DeployablesActions.getAllDeployables());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/updatedeployables")
        .setMethod("POST")
        .setBody(body)
        .build();

      const response = await request.send();
      const updateDeployablesResponse = (await response.json()) as DeployablesResponse;
      dispatch(DeployablesActions.getAllDeployablesSuccess(updateDeployablesResponse.deployables));
      return updateDeployablesResponse.message;
    } catch (error) {
      dispatch(DeployablesActions.getAllDeployablesError(error));
      return error.message;
    }
  };
}

export function executeDeployablesQueryDispatch(body: DeployablesQueryRequest) {
  return async (dispatch: Redux.Dispatch<DeployablesAction>): Promise<string> => {
    dispatch(DeployablesActions.executeDeployablesQuery());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/deployablesquery")
        .setMethod("POST")
        .setBody(body)
        .build();

      const response = await request.send();
      const deployablesQueryResponse = (await response.json()) as DeployablesResponse;
      dispatch(DeployablesActions.executeDeployablesQuerySuccess(deployablesQueryResponse.deployables));
      return deployablesQueryResponse.message;
    } catch (error) {
      dispatch(DeployablesActions.executeDeployablesQueryError(error));
      return error.message;
    }
  };
}