import { Account } from "msal";

import { CreateUserRequestBody } from "../models/userResponses";
import { RequestBuilder } from "../utilities/RequestBuilder";
import { getUserByName, createUser } from "./UserService";

async function authorizeAndCreateUser(name: string, email: string): Promise<boolean> {
  try {
    const existingUser = await getUserByName(name);
    if (existingUser) return true;

    const authorizationRequest = new RequestBuilder()
      .setRequestUrl(`api/authorization/authByGroup/${email}`)
      .setMethod("GET")
      .build();

    const response = await authorizationRequest.send();
    if (!response.ok) return false;

    const roles = await response.json();
    const body: CreateUserRequestBody = { name, email, roles };
    const createdUser = await createUser(body);

    if (createdUser) return true;
    return false;
  } catch {
    return false;
  }
}

export async function authorize(account: Account): Promise<boolean> {
  return authorizeAndCreateUser(account.name, account.userName);
}
