/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { action } from "typesafe-actions";
import { ServiceFormResponse } from "../../models/serviceFormResponses";
import * as actions from "../constants/serviceFormActionTypes";

export function deleteServiceForm() {
  return action(actions.DELETE_SERVICE_FORM);
}
export function deleteServiceFormSuccess(serviceForm: ServiceFormResponse) {
  return action(actions.DELETE_SERVICE_FORM_SUCCESS, serviceForm);
}
export function deleteServiceFormError(error: Error) {
  return action(actions.DELETE_SERVICE_FORM_ERROR, error);
}

export function getServiceForms() {
  return action(actions.GET_SERVICE_FORMS);
}
export function getServiceFormsSuccess(serviceForms: ServiceFormResponse[]) {
  return action(actions.GET_SERVICE_FORMS_SUCCESS, serviceForms);
}
export function getServiceFormsError(error: Error) {
  return action(actions.GET_SERVICE_FORMS_ERROR, error);
}

export function updateServiceForm() {
  return action(actions.UPDATE_SERVICE_FORM);
}
export function updateServiceFormSuccess(serviceForm: ServiceFormResponse) {
  return action(actions.UPDATE_SERVICE_FORM_SUCCESS, serviceForm);
}
export function updateServiceFormError(error: Error) {
  return action(actions.UPDATE_SERVICE_FORM_ERROR, error);
}

export function createServiceForm() {
  return action(actions.CREATE_SERVICE_FORM);
}
export function createServiceFormSuccess(serviceForm: ServiceFormResponse) {
  return action(actions.CREATE_SERVICE_FORM_SUCCESS, serviceForm);
}
export function createServiceFormError(error: Error) {
  return action(actions.CREATE_SERVICE_FORM_ERROR, error);
}

export function updateServiceFormPeerReviewers() {
  return action(actions.UPDATE_PEER_REVIEWERS);
}
export function updateServiceFormPeerReviewersSuccess(serviceForm: ServiceFormResponse) {
  return action(actions.UPDATE_PEER_REVIEWERS_SUCCESS, serviceForm);
}
export function updateServiceFormPeerReviewersError(error: Error) {
  return action(actions.UPDATE_PEER_REVIEWERS_ERROR, error);
}

export function updateServiceFormStatus() {
  return action(actions.UPDATE_STATUS);
}
export function updateServiceFormStatusSuccess(serviceForm: ServiceFormResponse) {
  return action(actions.UPDATE_STATUS_SUCCESS, serviceForm);
}
export function updateServiceFormStatusError(error: Error) {
  return action(actions.UPDATE_STATUS_ERROR, error);
}

export function updateServiceFormPeerReviewStatus() {
  return action(actions.UPDATE_PEER_REVIEW_STATUS);
}
export function updateServiceFormBCSMReviewStatus() {
  return action(actions.UPDATE_BCSM_REVIEW_STATUS);
}
export function updateServiceFormPeerReviewStatusSuccess(serviceForm: ServiceFormResponse) {
  return action(actions.UPDATE_PEER_REVIEW_STATUS_SUCCESS, serviceForm);
}
export function updateServiceFormBCSMReviewStatusSuccess(serviceForm: ServiceFormResponse) {
  return action(actions.UPDATE_BCSM_REVIEW_STATUS_SUCCESS, serviceForm);
}
export function updateServiceFormBCSMReviewStatusError(error: Error) {
    return action(actions.UPDATE_BCSM_REVIEW_STATUS_ERROR, error);
}
export function updateServiceFormPeerReviewStatusError(error: Error) {
  return action(actions.UPDATE_PEER_REVIEW_STATUS_ERROR, error);
}
