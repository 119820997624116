export const HOME = "/";
export const FORMS = "/Requests";
export const FORMS_DETAILS = "/Requests/:serviceFormId";
export const FORMS_EDIT = "/Requests/Edit/:serviceFormId";
export const NEW_FORM = "/Requests/New";
export const PROVISION = "/Provision";
export const PROVISION_FORM = "/Provision/:serviceFormId";
export const SETTINGS = "/Settings";
export const ESCALATION_CONTACTS = "/Contacts";
export const DEPLOYABLES_EDIT = "/Deployables";
