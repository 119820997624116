import * as Redux from "redux";
import { OperationalTestsAction } from "../redux/reducers/types";
import * as OperationalTestsActions from "../redux/actions/operationalTestsActions";
import { RequestBuilder } from "../utilities/RequestBuilder";
import { OpTestsServiceResponse } from "../models/operationalTestsResponses";
import {
  CreateOperationalTestsServiceRequest,
  CreateOperationalTestsScheduleRequest
} from "../models/operationalTestsRequests";

export function getAllOperationalTestsServicesDispatch() {
  return async (dispatch: Redux.Dispatch<OperationalTestsAction>): Promise<void> => {
    dispatch(OperationalTestsActions.getAllServices());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/opts/services")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const projects = (await response.json()) as OpTestsServiceResponse[];

      dispatch(OperationalTestsActions.getAllServicesSuccess(projects));
    } catch (error) {
      dispatch(OperationalTestsActions.getAllServicesError(error));
    }
  };
}

export function createOperationalTestsServiceDispatch(body: CreateOperationalTestsServiceRequest) {
  return async (dispatch: Redux.Dispatch<OperationalTestsAction>): Promise<void> => {
    dispatch(OperationalTestsActions.createService());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/opts/services")
        .setMethod("POST")
        .setBody(body)
        .build();

      const response = await request.send();
      if (!response.ok) {
        const error = await response.json();
        throw Error(JSON.stringify(error.Message));
      }

      const instance = (await response.json()) as OpTestsServiceResponse;
      dispatch(OperationalTestsActions.createServiceSuccess(instance));
    } catch (error) {
      dispatch(OperationalTestsActions.createServiceError(error));
    }
  };
}

export function createOperationalTestsScheduleDispatch(
  body: CreateOperationalTestsScheduleRequest
) {
  return async (dispatch: Redux.Dispatch<OperationalTestsAction>): Promise<void> => {
    dispatch(OperationalTestsActions.createSchedule());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/opts/schedules")
        .setMethod("POST")
        .setBody(body)
        .build();

      const response = await request.send();
      if (!response.ok) {
        const error = await response.json();
        throw Error(JSON.stringify(error.Message));
      }

      dispatch(OperationalTestsActions.createScheduleSuccess());
    } catch (error) {
      dispatch(OperationalTestsActions.createScheduleError(error));
    }
  };
}

export function operationaTestsServiceToCreateStateResetDispatch() {
  return async (dispatch: Redux.Dispatch<OperationalTestsAction>): Promise<void> => {
    dispatch(OperationalTestsActions.serviceToCreateReset());
  };
}

export function operationaTestsScheduleToCreateStateResetDispatch() {
  return async (dispatch: Redux.Dispatch<OperationalTestsAction>): Promise<void> => {
    dispatch(OperationalTestsActions.scheduleToCreateReset());
  };
}
