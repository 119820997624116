import * as React from "react";

import { Grid, Typography, Divider } from "@material-ui/core";

import { FormSectionProps } from "./types";

export function FormSection(props: FormSectionProps): JSX.Element {
  const { children, className, title } = props;

  return (
    <Grid className={className} container direction="column">
      <Typography className="form-section-title" color="textSecondary" variant="body2">
        {title}
      </Typography>
      <Divider className="divider" />
      <div className="space-vertical-16">{children}</div>
    </Grid>
  );
}
