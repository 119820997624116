/* eslint-disable import/no-cycle */
import { ActionType } from "typesafe-actions";
import {
  AzureDevOpsTeamState,
  OctopusARMStepState,
  AzureDevOpsProjectState,
  OctopusTemplateProjectState,
  OctopusLibraryState,
  OctopusLifecycleState,
  OctopusProjectGroupState,
  OctopusProjectState,
  ScorecardCategoryState,
  ScorecardSpecificationState,
  ProductState,
  ScorecardProjectState,
  OperationaTestsServiceState,
  BUDDIRegionState,
  RegionState,
  BUDDIUrlState,
  BUDDIRegionalUrlState,
  OctopusProjectToCreateState,
  AzureDevOpsTeamToCreateState,
  AzureDevOpsBuildPipelineToCreateState,
  AzureDevOpsReleasePipelineToCreateState,
  ScorecardAssociationState,
  OperationaTestsServiceToCreateState,
  OperationaTestsScheduleToCreateState,
  AzureDevOpsGitRepoState,
  AzureDevOpsTeamPermissionsToAddState,
  AzureDevOpsBuildPipelinePermissionsToAddState,
  AzureDevOpsReleasePipelinePermissionsToAddState,
  SeqKeysToCreateState,
  DevOpsEscalationContactsState
} from "../../../redux/reducers/types";
import {
  OctopusProjectResponse,
  OctopusLibraryVariableSetResponse,
  OctopusProjectGroupResponse,
  OctopusARMStepResponse,
  DevOpsProjectResponse,
  OctopusLifecycleResponse,
  ProductResponse,
  ScorecardSpecificationResponse,
  ScorecardProjectResponse,
  ScorecardItemResponse,
  DevOpsEnvironmentResponse,
  OpTestsServiceResponse,
  RegionConfigurationResponse,
  ServiceFormResponse,
  ServiceFormStatusRequest,
  UserResponse,
  EscalationContactsResponse
} from "../../../models";
import * as ProvisionActions from "./actions";
import { BUDDIUrl, BUDDIRegionalUrlResponse } from "../../../models/BUDDIResponses";
import { ProvisioningOperation } from "./ProvisionProgress";
import { ScorecardAssociationRequest } from "../../../models/scorecardRequests";
import {
  CreateOctopusProjectRequest,
  CloneOctopusProjectRequest
} from "../../../models/octopusRequests";
import {
  CreateOrGetADOGitRepoRequest,
  CreateOrGetADOTeamRequest,
  CreateADOBuildDefinitionRequest,
  CreateADOReleaseDefinitionRequest,
  SetAzureDevOpsDefaultTeamPermissionsRequest,
  SetAzureDevOpsBuildPipelinePermissionsRequest,
  SetAzureDevOpsReleasePipelinePermissionsRequest
} from "../../../models/azureDevOpsRequests";
import {
  CreateOperationalTestsServiceRequest,
  CreateOperationalTestsScheduleRequest
} from "../../../models/operationalTestsRequests";
import { SeqKeysToCreateRequest } from "../../../models/seqRequests";
import { EscalationContactsRequest } from "../../../models/sharePointRequest";

export type ProvisionAction = ActionType<typeof ProvisionActions>;

export interface ProvisionState {
  additionalResources: OctopusARMStepResponse[];
  deployableName: string;
  isOctopusRequired: boolean;
  devOpsProject: DevOpsProjectResponse | null;
  devOpsTeamName: string | null;
  gitRepoName: string;
  isBuildPipelineRequired: boolean;
  isCloneEnabled: boolean;
  isGitRepoRequired: boolean;
  isReleasePipelineRequired: boolean;
  isEscalationContactRequired: boolean;
  escalationContacts: EscalationContactsResponse | null;
  lifecycle: OctopusLifecycleResponse | null;
  octopusProject: OctopusProjectResponse | null;
  projectGroup: OctopusProjectGroupResponse | null;
  projectType: OctopusProjectResponse | null;
  region: RegionConfigurationResponse | null;
  subscription: OctopusLibraryVariableSetResponse | null;
  isScorecardRequired: boolean;
  product: ProductResponse | null;
  scorecardProject: ScorecardProjectResponse | null;
  productManagerEmail: string;
  devLeadEmail: string;
  releaseType: ScorecardSpecificationResponse | null;
  productType: ScorecardSpecificationResponse | null;
  selectedItems: ScorecardItemResponse[];
  isOperationalTestsRequired: boolean;
  operationalTestsService: OpTestsServiceResponse | null;
  operationalTestsCrontabExpression: string;
  operationalTestsTimeout: string;
  operationalTestsNotificationList: string;
  isGeoReplicated: boolean;
  operationalTestsAreHighSeverity: boolean;
  operationalTestsAreDisabled: boolean;
  isSeqRequired: boolean;
  seqKeyEnvironments: string[];
  isBuddiRequired: boolean;
  buddiUrl: BUDDIUrl | null;
  buddiRegionalUrlsRequired: boolean;
  configuredBuddiRegionalUrls: BUDDIRegionalUrlResponse[];
  serviceForm: ServiceFormResponse | null;
  operations: ProvisioningOperation[];
  buildFolderPath: string;
}

export interface ProvisionProps {
  azureDevOpsProjects: AzureDevOpsProjectState;
  azureDevOpsTeams: AzureDevOpsTeamState;
  getAllDevOpsProjects: () => void;
  getAllDevOpsTeams: () => void;
  getAllLifecycles: () => void;
  getAllOctopusProjects: () => void;
  getAllProjectGroups: () => void;
  getAllSubscriptionLibraries: () => void;
  getAllTemplateProjects: () => void;
  getAllOperationalTestsServices: () => void;
  getARMSteps: () => void;
  octopusARMSteps: OctopusARMStepState;
  octopusLibraries: OctopusLibraryState;
  octopusLifecycles: OctopusLifecycleState;
  octopusProjects: OctopusProjectState;
  octopusProjectGroups: OctopusProjectGroupState;
  octopusTemplateProjects: OctopusTemplateProjectState;
  getAllScorecardCategories: () => void;
  scorecardCategories: ScorecardCategoryState;
  getAllScorecardSpecifications: () => void;
  scorecardSpecifications: ScorecardSpecificationState;
  getAllProducts: () => void;
  products: ProductState;
  getAllScorecardProjects: () => void;
  scorecardProjects: ScorecardProjectState;
  operationalTestsServices: OperationaTestsServiceState;
  getAllBuddiRegions: () => void;
  buddiRegions: BUDDIRegionState;
  getRegions: () => void;
  regions: RegionState;
  getAllBuddiUrls: () => void;
  buddiUrls: BUDDIUrlState;
  getAllBuddiRegionalUrls: () => void;
  buddiRegionalUrls: BUDDIRegionalUrlState;
  octopusProjectToCreateState: OctopusProjectToCreateState;
  createOctoProject: (request: CreateOctopusProjectRequest) => void;
  cloneOctoProject: (request: CloneOctopusProjectRequest) => void;
  devOpsGitRepoState: AzureDevOpsGitRepoState;
  createOrGetADOGitRepo: (request: CreateOrGetADOGitRepoRequest) => void;
  devOpsTeamToCreateState: AzureDevOpsTeamToCreateState;
  createOrGetAzureDevOpsTeam: (request: CreateOrGetADOTeamRequest) => void;
  devOpsTeamPermissionsToAddState: AzureDevOpsTeamPermissionsToAddState;
  setAzureDevOpsTeamPermissions: (request: SetAzureDevOpsDefaultTeamPermissionsRequest) => void;
  devOpsBuildPipelineToCreateState: AzureDevOpsBuildPipelineToCreateState;
  createAzureDevOpsBuildDefinition: (request: CreateADOBuildDefinitionRequest) => void;
  devOpsBuildPipelinePermissionsToAddState: AzureDevOpsBuildPipelinePermissionsToAddState;
  setAzureDevOpsBuildPipelinePermissions: (request: SetAzureDevOpsBuildPipelinePermissionsRequest) => void;
  devOpsReleasePipelineToCreateState: AzureDevOpsReleasePipelineToCreateState;
  createAzureDevOpsReleasePipelineDefinition: (request: CreateADOReleaseDefinitionRequest) => void;
  devOpsReleasePipelinePermissionsToAddState: AzureDevOpsReleasePipelinePermissionsToAddState;
  setAzureDevOpsReleasePipelineDefinitionPermissions: (request: SetAzureDevOpsReleasePipelinePermissionsRequest) => void;
  scorecardAssociationState: ScorecardAssociationState;
  createScorecardAssociation: (request: ScorecardAssociationRequest) => void;
  operationaTestsServiceToCreateState: OperationaTestsServiceToCreateState;
  createOperationalTestsService: (request: CreateOperationalTestsServiceRequest) => void;
  operationaTestsScheduleToCreateState: OperationaTestsScheduleToCreateState;
  createOperationalTestsSchedule: (request: CreateOperationalTestsScheduleRequest) => void;
  seqKeysToCreateState: SeqKeysToCreateState;
  createSeqKey: (request: SeqKeysToCreateRequest) => void;
  octopusProjectToCreateStateReset: () => void;
  devOpsGitRepoStateReset: () => void;
  devOpsTeamToCreateStateReset: () => void;
  devOpsTeamPermissionsToAddStateReset: () => void;
  devOpsBuildPipelineToCreateStateReset: () => void;
  devOpsBuildPipelinePermissionsToAddStateReset: () => void;
  devOpsReleasePipelineToCreateStateReset: () => void;
  devOpsReleasePipelinePermissionsToAddStateReset: () => void;
  scorecardAssociationStateReset: () => void;
  operationaTestsServiceToCreateStateReset: () => void;
  operationaTestsScheduleToCreateStateReset: () => void;
  seqKeysToCreateStateReset: () => void;
  updateServiceFormStatus: (body: ServiceFormStatusRequest) => void;
  devOpsEscalationContactsState: DevOpsEscalationContactsState;
  createEscalationContacts :(request: EscalationContactsRequest) => void;
}

export interface ProvisionStep {
  name: string;
  optional?: true;
}

export interface ServiceBaseProps {
  additionalResources: OctopusARMStepResponse[];
  azureDevOpsProjects: AzureDevOpsProjectState;
  azureDevOpsTeams: AzureDevOpsTeamState;
  deployableName: string;
  isOctopusRequired: boolean;
  devOpsProject: DevOpsProjectResponse | null;
  devOpsTeamName: string | null;
  dispatch?: React.Dispatch<ProvisionAction>;
  gitRepoName: string;
  isBuildPipelineRequired: boolean;
  isCloneEnabled: boolean;
  isGitRepoRequired: boolean;
  isReleasePipelineRequired: boolean;
  isEscalationContactRequired:boolean;
  lifecycle: OctopusLifecycleResponse | null;
  octopusARMSteps: OctopusARMStepState;
  octopusLibraries: OctopusLibraryState;
  octopusLifecycles: OctopusLifecycleState;
  octopusProject: OctopusProjectResponse | null;
  octopusProjects: OctopusProjectState;
  octopusProjectGroups: OctopusProjectGroupState;
  octopusTemplateProjects: OctopusTemplateProjectState;
  projectGroup: OctopusProjectGroupResponse | null;
  projectType: OctopusProjectResponse | null;
  regions: RegionState;
  region: RegionConfigurationResponse | null;
  subscription: OctopusLibraryVariableSetResponse | null;
  products: ProductState;
  product: ProductResponse | null;
  serviceForm: ServiceFormResponse | null;
  buildFolderPath: string;
  errors: string[];
  displayError: boolean;
  escalationContacts: EscalationContactsResponse | null
}

export interface ScorecardProps {
  azureDevOpsProjects: AzureDevOpsProjectState;
  deployableName: string;
  devOpsProject: DevOpsProjectResponse | null;
  isScorecardRequired: boolean;
  dispatch?: React.Dispatch<ProvisionAction>;
  scorecardCategories: ScorecardCategoryState;
  scorecardSpecifications: ScorecardSpecificationState;
  releaseType: ScorecardSpecificationResponse | null;
  productType: ScorecardSpecificationResponse | null;
  products: ProductState;
  product: ProductResponse | null;
  productManagerEmail: string;
  devLeadEmail: string;
  scorecardProjects: ScorecardProjectState;
  scorecardProject: ScorecardProjectResponse | null;
  selectedItems: ScorecardItemResponse[];
  serviceForm: ServiceFormResponse | null;
  isCloneEnabled: boolean;
  errors: string[];
  displayError: boolean;
}

export interface OperationalTestsProps {
  dispatch?: React.Dispatch<ProvisionAction>;
  isOperationalTestsRequired: boolean;
  operationalTestsServices: OperationaTestsServiceState;
  operationalTestsService: OpTestsServiceResponse | null;
  operationalTestsCrontabExpression: string;
  operationalTestsTimeout: string;
  operationalTestsNotificationList: string;
  region: RegionConfigurationResponse | null;
  isGeoReplicated: boolean;
  operationalTestsAreHighSeverity: boolean;
  operationalTestsAreDisabled: boolean;
  azureDevOpsProjects: AzureDevOpsProjectState;
  devOpsProject: DevOpsProjectResponse | null;
  deployableName: string;
  serviceForm: ServiceFormResponse | null;
  isCloneEnabled: boolean;
  errors: string[];
  displayError: boolean;
}

export interface BuddiProps {
  dispatch?: React.Dispatch<ProvisionAction>;
  isBuddiRequired: boolean;
  region: RegionConfigurationResponse | null;
  isCloneEnabled: boolean;
  buddiRegions: BUDDIRegionState;
  deployableName: string;
  buddiUrl: BUDDIUrl | null;
  buddiRegionalUrlsRequired: boolean;
  configuredBuddiRegionalUrls: BUDDIRegionalUrlResponse[];
  buddiUrls: BUDDIUrlState;
  buddiRegionalUrls: BUDDIRegionalUrlState;
  serviceForm: ServiceFormResponse | null;
  errors: string[];
  displayError: boolean;
}

export interface SeqProps {
  isSeqRequired: boolean;
  dispatch?: React.Dispatch<ProvisionAction>;
  products: ProductState;
  product: ProductResponse | null;
  seqKeyEnvironments: string[];
  serviceForm: ServiceFormResponse | null;
  errors: string[];
  displayError: boolean;
  isCloneEnabled: boolean;
}

export interface FinalizeProps {
  additionalResources: OctopusARMStepResponse[];
  deployableName: string;
  isOctopusRequired: boolean;
  devOpsProject: DevOpsProjectResponse | null;
  devOpsTeamName: string | null;
  gitRepoName: string;
  isBuildPipelineRequired: boolean;
  isCloneEnabled: boolean;
  isGitRepoRequired: boolean;
  isReleasePipelineRequired: boolean;
  isEscalationContactRequired : boolean;
  lifecycle: OctopusLifecycleResponse | null;
  octopusProject: OctopusProjectResponse | null;
  projectGroup: OctopusProjectGroupResponse | null;
  projectType: OctopusProjectResponse | null;
  region: RegionConfigurationResponse | null;
  subscription: OctopusLibraryVariableSetResponse | null;
  isScorecardRequired: boolean;
  releaseType: ScorecardSpecificationResponse | null;
  productType: ScorecardSpecificationResponse | null;
  product: ProductResponse | null;
  productManagerEmail: string;
  devLeadEmail: string;
  scorecardProject: ScorecardProjectResponse | null;
  selectedItems: ScorecardItemResponse[];
  isOperationalTestsRequired: boolean;
  operationalTestsService: OpTestsServiceResponse | null;
  operationalTestsCrontabExpression: string;
  operationalTestsTimeout: string;
  operationalTestsNotificationList: string;
  isGeoReplicated: boolean;
  operationalTestsAreHighSeverity: boolean;
  operationalTestsAreDisabled: boolean;
  isSeqRequired: boolean;
  seqKeyEnvironments: string[];
  isBuddiRequired: boolean;
  buddiRegionalUrlsRequired: boolean;
  buddiUrl: BUDDIUrl | null;
  buddiRegions: BUDDIRegionState;
  configuredBuddiRegionalUrls: BUDDIRegionalUrlResponse[];
  dispatch?: React.Dispatch<ProvisionAction>;
  serviceBaseErrors: string[];
  scorecardErrors: string[];
  OPTestErrors: string[];
  seqErrors: string[];
}

export interface ProvisionProgressProps {
  deployableName: string;
  operations: ProvisioningOperation[];
  octopusProjectToCreateState: OctopusProjectToCreateState;
  devOpsGitRepoState: AzureDevOpsGitRepoState;
  devOpsTeamToCreateState: AzureDevOpsTeamToCreateState;
  devOpsTeamPermissionsToAddState: AzureDevOpsTeamPermissionsToAddState;
  devOpsBuildPipelineToCreateState: AzureDevOpsBuildPipelineToCreateState;
  devOpsBuildPipelinePermissionsToAddState: AzureDevOpsBuildPipelinePermissionsToAddState;
  devOpsReleasePipelineToCreateState: AzureDevOpsReleasePipelineToCreateState;
  devOpsReleasePipelinePermissionsToAddState: AzureDevOpsReleasePipelinePermissionsToAddState;
  scorecardAssociationState: ScorecardAssociationState;
  operationaTestsServiceToCreateState: OperationaTestsServiceToCreateState;
  operationaTestsScheduleToCreateState: OperationaTestsScheduleToCreateState;
  seqKeysToCreateState: SeqKeysToCreateState;
  dispatch?: React.Dispatch<ProvisionAction>;
  updateServiceFormStatus: (body: ServiceFormStatusRequest) => void;
  user: UserResponse | undefined;
  serviceFormId: string | undefined;
  devOpsEscalationContactsState: DevOpsEscalationContactsState;
}

export const initialState: ProvisionState = {
  additionalResources: [],
  deployableName: "",
  isOctopusRequired: true,
  devOpsProject: null,
  devOpsTeamName: null,
  gitRepoName: "",
  isBuildPipelineRequired: true,
  isCloneEnabled: false,
  isGitRepoRequired: true,
  isReleasePipelineRequired: true,
  isEscalationContactRequired: true,
  lifecycle: null,
  octopusProject: null,
  projectGroup: null,
  projectType: null,
  region: null,
  subscription: null,
  isScorecardRequired: false,
  product: null,
  productManagerEmail: "",
  devLeadEmail: "",
  releaseType: null,
  productType: null,
  scorecardProject: null,
  selectedItems: [],
  isOperationalTestsRequired: false,
  operationalTestsService: null,
  operationalTestsCrontabExpression: "0 * * * *",
  operationalTestsTimeout: "10",
  operationalTestsNotificationList: "",
  isGeoReplicated: false,
  operationalTestsAreHighSeverity: false,
  operationalTestsAreDisabled: false,
  isSeqRequired: false,
  seqKeyEnvironments: [],
  isBuddiRequired: false,
  buddiUrl: { id: 0, name: "", url: "", regionalurls: [] },
  buddiRegionalUrlsRequired: false,
  configuredBuddiRegionalUrls: [],
  serviceForm: null,
  operations: [],
  buildFolderPath: "",
  escalationContacts: null
};
