import * as React from "react";
import MUIDataTable from "mui-datatables";
import * as Constants from "./constants";

interface Props {
  definition: string;
}

export function Definition(props: Props): JSX.Element {
  const { definition } = props;

  return (
    <MUIDataTable
      title=""
      columns={Constants.DefinitionTableColumn}
      data={[{ definition }]}
      options={Constants.DefinitionTableOptions}
    />
  );
}
