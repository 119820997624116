export const GET_ALL_LIFECYCLES = "GET_ALL_LIFECYCLES";
export const GET_ALL_LIFECYCLES_SUCCESS = "GET_ALL_LIFECYCLES_SUCCESS";
export const GET_ALL_LIFECYCLES_ERROR = "GET_ALL_LIFECYCLES_ERROR";

export const GET_ALL_OCTO_PROJECTS = "GET_ALL_OCTO_PROJECTS";
export const GET_ALL_OCTO_PROJECTS_SUCCESS = "GET_ALL_OCTO_PROJECTS_SUCCESS";
export const GET_ALL_OCTO_PROJECTS_ERROR = "GET_ALL_OCTO_PROJECTS_ERROR";

export const GET_ALL_PROJECT_GROUPS = "GET_ALL_PROJECT_GROUPS";
export const GET_ALL_PROJECT_GROUPS_SUCCESS = "GET_ALL_PROJECT_GROUPS_SUCCESS";
export const GET_ALL_PROJECT_GROUPS_ERROR = "GET_ALL_PROJECT_GROUPS_ERROR";

export const GET_ALL_SUBSCRIPTION_LIBRARIES = "GET_ALL_SUBSCRIPTION_LIBRARIES";
export const GET_ALL_SUBSCRIPTION_LIBRARIES_SUCCESS = "GET_ALL_SUBSCRIPTION_LIBRARIES_SUCCESS";
export const GET_ALL_SUBSCRIPTION_LIBRARIES_ERROR = "GET_ALL_SUBSCRIPTION_LIBRARIES_ERROR";

export const GET_ALL_TEMPLATE_PROJECTS = "GET_ALL_TEMPLATE_PROJECTS";
export const GET_ALL_TEMPLATE_PROJECTS_SUCCESS = "GET_ALL_TEMPLATE_PROJECTS_SUCCESS";
export const GET_ALL_TEMPLATE_PROJECTS_ERROR = "GET_ALL_TEMPLATE_PROJECTS_ERROR";

export const GET_ARM_STEPS = "GET_ARM_STEPS";
export const GET_ARM_STEPS_SUCCESS = "GET_ARM_STEPS_SUCCESS";
export const GET_ARM_STEPS_ERROR = "GET_ARM_STEPS_ERROR";

export const CREATE_OCTO_PROJECT = "CREATE_OCTO_PROJECT";
export const CREATE_OCTO_PROJECT_SUCCESS = "CREATE_OCTO_PROJECT_SUCCESS";
export const CREATE_OCTO_PROJECT_ERROR = "CREATE_OCTO_PROJECT_ERROR";

export const CLONE_OCTO_PROJECT = "CLONE_OCTO_PROJECT";
export const CLONE_OCTO_PROJECT_SUCCESS = "CLONE_OCTO_PROJECT_SUCCESS";
export const CLONE_OCTO_PROJECT_ERROR = "CLONE_OCTO_PROJECT_ERROR";
export const CREATE_OR_CLONE_OCTO_PROJECT_RESET = "CREATE_OR_CLONE_OCTO_PROJECT_RESET";
