import * as React from "react";

import { Grid, Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { Page } from "../Page";
import { DetailSection, DetailInnerSection } from "./DetailSection";

export function FormDetailsSkeleton(): JSX.Element {
  return (
    <Page loading>
      <Paper variant="outlined">
        <div className="details-paper-content">
          <Grid container direction="row" justifyContent="space-between" spacing={3}>
            <Grid item xs={4}>
              <DetailSection title="Service information">
                <Grid container direction="row" spacing={3}>
                  <Grid item className="margin-top-8">
                    <Skeleton animation="wave" variant="text" width={150} />
                    <Skeleton animation="wave" variant="text" width={150} />
                    <Skeleton animation="wave" variant="text" width={150} />
                    <Skeleton animation="wave" variant="text" width={150} />
                    <Skeleton animation="wave" variant="text" width={150} />
                    <Skeleton animation="wave" variant="text" width={150} />
                    <Skeleton animation="wave" variant="text" width={150} />
                  </Grid>
                  <Grid item className="margin-top-8">
                    <Skeleton animation="wave" variant="text" width={200} />
                    <Skeleton animation="wave" variant="text" width={200} />
                    <Skeleton animation="wave" variant="text" width={200} />
                    <Skeleton animation="wave" variant="text" width={200} />
                    <Skeleton animation="wave" variant="text" width={200} />
                    <Skeleton animation="wave" variant="text" width={200} />
                    <Skeleton animation="wave" variant="text" width={200} />
                  </Grid>
                </Grid>
                <Skeleton
                  animation="wave"
                  className="detail-section-button"
                  height={36}
                  variant="rect"
                  width={290}
                />
              </DetailSection>
              <DetailSection title="Infrastructure">
                <Grid container direction="row" spacing={3}>
                  <Grid item className="margin-top-8">
                    <Skeleton animation="wave" variant="text" width={150} />
                    <Skeleton animation="wave" variant="text" width={150} />
                    <Skeleton animation="wave" variant="text" width={150} />
                  </Grid>
                  <Grid item className="margin-top-8">
                    <Skeleton animation="wave" variant="text" width={200} />
                    <Skeleton animation="wave" variant="text" width={200} />
                    <Skeleton animation="wave" variant="text" width={200} />
                  </Grid>
                </Grid>
              </DetailSection>
            </Grid>
            <Grid item xs={4}>
              <DetailSection title="Contacts">
                <Grid container direction="row" spacing={3}>
                  <Grid item className="margin-top-8">
                    <Skeleton animation="wave" variant="text" width={150} />
                    <Skeleton animation="wave" variant="text" width={150} />
                    <Skeleton animation="wave" variant="text" width={150} />
                    <Skeleton animation="wave" variant="text" width={150} />
                    <Skeleton animation="wave" variant="text" width={150} />
                    <Skeleton animation="wave" variant="text" width={150} />
                    <Skeleton animation="wave" variant="text" width={150} />
                    <Skeleton animation="wave" variant="text" width={150} />
                    <Skeleton animation="wave" variant="text" width={150} />
                    <Skeleton animation="wave" variant="text" width={150} />
                  </Grid>
                  <Grid item className="margin-top-8">
                    <Skeleton animation="wave" variant="text" width={200} />
                    <Skeleton animation="wave" variant="text" width={200} />
                    <Skeleton animation="wave" variant="text" width={200} />
                    <Skeleton animation="wave" variant="text" width={200} />
                    <Skeleton animation="wave" variant="text" width={200} />
                    <Skeleton animation="wave" variant="text" width={200} />
                    <Skeleton animation="wave" variant="text" width={200} />
                    <Skeleton animation="wave" variant="text" width={200} />
                    <Skeleton animation="wave" variant="text" width={200} />
                    <Skeleton animation="wave" variant="text" width={200} />
                  </Grid>
                </Grid>
              </DetailSection>
            </Grid>
            <Grid item xs={3}>
              <DetailSection title="Approval">
                <DetailInnerSection title="Request">
                  <Skeleton
                    animation="wave"
                    className="margin-top-8"
                    height={44}
                    variant="rect"
                    width={300}
                  />
                </DetailInnerSection>
                <DetailInnerSection title="BCSM Approval">
                  <Skeleton
                    animation="wave"
                    className="margin-top-8"
                    height={44}
                    variant="rect"
                    width={300}
                  />
                </DetailInnerSection>
                <DetailInnerSection title="Peer Reviews">
                  <div className="space-vertical-16">
                    <Skeleton
                      animation="wave"
                      className="margin-top-8"
                      height={44}
                      variant="rect"
                      width={300}
                    />
                    <Skeleton
                      animation="wave"
                      className="margin-top-8"
                      height={44}
                      variant="rect"
                      width={300}
                    />
                  </div>
                </DetailInnerSection>
                <DetailInnerSection title="Final Approval">
                  <Skeleton
                    animation="wave"
                    className="margin-top-8"
                    height={44}
                    variant="rect"
                    width={300}
                  />
                </DetailInnerSection>
              </DetailSection>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Page>
  );
}
