import { action } from "typesafe-actions";
import * as actions from "../constants/seqActionTypes";
import { SeqKeyResponse } from "../../models/seqResponses";

export function createSeqKeys() {
  return action(actions.CREATE_SEQ_KEYS);
}

export function createSeqKeysPartialSuccess(key: SeqKeyResponse) {
  return action(actions.CREATE_SEQ_KEYS_PARTIAL_SUCCESS, key);
}

export function createSeqKeysSuccess() {
  return action(actions.CREATE_SEQ_KEYS_SUCCESS);
}

export function createSeqKeysError(error: Error) {
  return action(actions.CREATE_SEQ_KEYS_ERROR, error);
}

export function seqKeysToCreateReset() {
  return action(actions.CREATE_SEQ_KEYS_RESET);
}
