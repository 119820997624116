import { ServiceFormState, ServiceFormAction } from "./types";
import {
    DELETE_SERVICE_FORM,
    DELETE_SERVICE_FORM_ERROR,
    DELETE_SERVICE_FORM_SUCCESS,
    GET_SERVICE_FORMS,
    GET_SERVICE_FORMS_ERROR,
    GET_SERVICE_FORMS_SUCCESS,
    UPDATE_SERVICE_FORM,
    UPDATE_SERVICE_FORM_ERROR,
    UPDATE_SERVICE_FORM_SUCCESS,
    CREATE_SERVICE_FORM,
    CREATE_SERVICE_FORM_ERROR,
    CREATE_SERVICE_FORM_SUCCESS,
    UPDATE_PEER_REVIEWERS,
    UPDATE_PEER_REVIEWERS_SUCCESS,
    UPDATE_PEER_REVIEWERS_ERROR,
    UPDATE_STATUS,
    UPDATE_STATUS_SUCCESS,
    UPDATE_STATUS_ERROR,
    UPDATE_PEER_REVIEW_STATUS,
    UPDATE_PEER_REVIEW_STATUS_SUCCESS,
    UPDATE_PEER_REVIEW_STATUS_ERROR,
    UPDATE_BCSM_REVIEW_STATUS,
    UPDATE_BCSM_REVIEW_STATUS_ERROR,
    UPDATE_BCSM_REVIEW_STATUS_SUCCESS
} from "../constants/serviceFormActionTypes";

export const initialServiceFormState: ServiceFormState = {
    pending: false,
    creating: false,
    deleting: false,
    updating: false,
    completed: false,
    data: [],
    error: undefined
};

export const serviceFormReducer = (
    state: ServiceFormState = initialServiceFormState,
    action: ServiceFormAction
): ServiceFormState => {
    switch (action.type) {
        case DELETE_SERVICE_FORM:
            return {
                ...state,
                deleting: true
            };
        case DELETE_SERVICE_FORM_SUCCESS:
            const formsAfterDeletion = state.data.slice();
            const deletedFormIndex = formsAfterDeletion
                .map(form => {
                    return form.id;
                })
                .indexOf(action.payload.id);
            formsAfterDeletion.splice(deletedFormIndex, 1);

            return {
                ...state,
                deleting: false,
                data: formsAfterDeletion
            };
        case DELETE_SERVICE_FORM_ERROR:
            return {
                ...state,
                deleting: false,
                error: action.payload
            };
        case GET_SERVICE_FORMS:
            return {
                ...state,
                pending: true
            };
        case GET_SERVICE_FORMS_SUCCESS:
            return {
                ...state,
                pending: false,
                data: action.payload,
                completed: true
            };
        case GET_SERVICE_FORMS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
                completed: true
            };
        case UPDATE_PEER_REVIEWERS:
        case UPDATE_STATUS:
        case UPDATE_PEER_REVIEW_STATUS:
        case UPDATE_BCSM_REVIEW_STATUS:
        case UPDATE_SERVICE_FORM:
            return {
                ...state,
                updating: true
            };
        case UPDATE_PEER_REVIEWERS_SUCCESS:
        case UPDATE_STATUS_SUCCESS:
        case UPDATE_PEER_REVIEW_STATUS_SUCCESS:
        case UPDATE_BCSM_REVIEW_STATUS_SUCCESS:
        case UPDATE_SERVICE_FORM_SUCCESS:
            const formsAfterUpdate = state.data.map(form =>
                form.id === action.payload.id ? action.payload : form
            );

            return {
                ...state,
                updating: false,
                data: formsAfterUpdate
            };
        case UPDATE_PEER_REVIEWERS_ERROR:
        case UPDATE_STATUS_ERROR:
        case UPDATE_PEER_REVIEW_STATUS_ERROR:
        case UPDATE_BCSM_REVIEW_STATUS_ERROR:
        case UPDATE_SERVICE_FORM_ERROR:
            return {
                ...state,
                updating: false,
                error: action.payload
            };
        case CREATE_SERVICE_FORM:
            return {
                ...state,
                creating: true
            };
        case CREATE_SERVICE_FORM_SUCCESS:
            const formsAfterCreation = state.data.slice();
            formsAfterCreation.push(action.payload);

            return {
                ...state,
                creating: false,
                data: formsAfterCreation
            };
        case CREATE_SERVICE_FORM_ERROR:
            return {
                ...state,
                creating: false,
                error: action.payload
            };
        default:
            return state;
    }
};
