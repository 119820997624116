import * as React from "react";
import "./Forms.scss";

import { Paper, Typography, Divider, Grid } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

import { ZeroData } from "../../ZeroData";
import { ServiceFormCard } from "./ServiceFormCard";
import { ServiceFormsContainerSkeleton } from "./ServiceFormsContainerSkeleton";
import { ServiceFormContainerProps } from "./types";

export function ServiceFormsContainer(props: ServiceFormContainerProps): JSX.Element {
  const {
    confirmation,
    createButton,
    loading,
    onFormClickCallback,
    pageSize,
    serviceForms,
    title,
    users
  } = props;
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  function handlePaginationChange(event: React.ChangeEvent<unknown>, page: number): void {
    event.preventDefault();
    setCurrentPage(page);
  }

  function renderContent(): JSX.Element {
    if (loading) return <ServiceFormsContainerSkeleton cardCount={5} />;
    if (serviceForms.length > 0) {
      return (
        <Grid
          alignItems="flex-start"
          className="flex-self-stretch service-forms-container"
          container
          direction="column"
          spacing={1}
        >
          {serviceForms
            .slice(currentPage * pageSize - pageSize, currentPage * pageSize)
            .map(serviceForm => {
              return (
                <Grid key={serviceForm.id} className="flex-self-stretch" item xs={12}>
                  <ServiceFormCard
                    confirmation={confirmation}
                    onClick={onFormClickCallback}
                    serviceForm={serviceForm}
                    showMenu
                    users={users}
                  />
                </Grid>
              );
            })}
        </Grid>
      );
    }
    return <ZeroData message="No cloud service setup requests" />;
  }

  return (
    <Paper className="service-forms-paper" variant="outlined">
      <Typography className="service-forms-paper-title" color="textSecondary" variant="body2">
        {title}
      </Typography>
      <Divider className="divider" />
      {createButton || <div className="service-form-card-button" />}
      {renderContent()}
      {serviceForms.length > pageSize ? (
        <Pagination
          className="service-form-card-pagination"
          color="secondary"
          count={Math.ceil(serviceForms.length / pageSize)}
          defaultPage={1}
          onChange={handlePaginationChange}
          showFirstButton
          showLastButton
          variant="outlined"
        />
      ) : null}
    </Paper>
  );
}
