import {
  AzureDevOpsTeamState,
  AzureDevOpsAction,
  AzureDevOpsProjectState,
  AzureDevOpsReleaseEnvironmentsState,
  AzureDevOpsGitRepoState,
  AzureDevOpsReleasePipelineToCreateState,
  AzureDevOpsBuildPipelineToCreateState,
  AzureDevOpsTeamToCreateState,
  AzureDevOpsTeamPermissionsToAddState,
  AzureDevOpsBuildPipelinePermissionsToAddState,
  AzureDevOpsReleasePipelinePermissionsToAddState
} from "./types";
import {
  GET_ALL_TEAMS,
  GET_ALL_TEAMS_SUCCESS,
  GET_ALL_TEAMS_ERROR,
  GET_ALL_ADO_PROJECTS,
  GET_ALL_ADO_PROJECTS_SUCCESS,
  GET_ALL_ADO_PROJECTS_ERROR,
  GET_RELEASE_ENVIRONMENTS,
  GET_RELEASE_ENVIRONMENTS_SUCCESS,
  GET_RELEASE_ENVIRONMENTS_ERROR,
  CREATE_OR_GET_ADO_GIT_REPO,
  CREATE_OR_GET_ADO_GIT_REPO_SUCCESS,
  CREATE_OR_GET_ADO_GIT_REPO_ERROR,
  CREATE_ADO_RELEASE_DEFINITION,
  CREATE_ADO_RELEASE_DEFINITION_SUCCESS,
  CREATE_ADO_RELEASE_DEFINITION_ERROR,
  CREATE_ADO_BUILD_DEFINITION,
  CREATE_ADO_BUILD_DEFINITION_SUCCESS,
  CREATE_ADO_BUILD_DEFINITION_ERROR,
  CREATE_OR_GET_ADO_TEAM,
  CREATE_OR_GET_ADO_TEAM_SUCCESS,
  CREATE_OR_GET_ADO_TEAM_ERROR,
  SET_ADO_TEAM_PERMISSIONS_ERROR,
  SET_ADO_TEAM_PERMISSIONS_SUCCESS,
  SET_ADO_TEAM_PERMISSIONS,
  SET_ADO_BUILD_PIPELINE_PERMISSIONS,
  SET_ADO_BUILD_PIPELINE_PERMISSIONS_SUCCESS,
  SET_ADO_BUILD_PIPELINE_PERMISSIONS_ERROR,
  SET_ADO_RELEASE_PIPELINE_PERMISSIONS,
  SET_ADO_RELEASE_PIPELINE_PERMISSIONS_SUCCESS,
  SET_ADO_RELEASE_PIPELINE_PERMISSIONS_ERROR,
  CREATE_OR_GET_ADO_GIT_REPO_RESET,
  CREATE_OR_GET_ADO_TEAM_RESET,
  CREATE_ADO_BUILD_DEFINITION_RESET,
  SET_ADO_BUILD_PIPELINE_PERMISSIONS_RESET,
  CREATE_ADO_RELEASE_DEFINITION_RESET,
  SET_ADO_RELEASE_PIPELINE_PERMISSIONS_RESET
} from "../constants";

export const initialAzureDevOpsTeamState: AzureDevOpsTeamState = {
  pending: false,
  completed: false,
  creating: false,
  data: [],
  error: undefined
};

export const initialAzureDevOpsProjectState: AzureDevOpsProjectState = {
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const initialAzureDevOpsEnvironmentsState: AzureDevOpsReleaseEnvironmentsState = {
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const initialAzureDevOpsTeamToCreateState: AzureDevOpsTeamToCreateState = {
  creating: false,
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const initialAzureDevOpsTeamPermissionsToAddState: AzureDevOpsTeamPermissionsToAddState = {
  creating: false,
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const initialAzureDevOpsBuildPipelineToCreateState: AzureDevOpsBuildPipelineToCreateState = {
  creating: false,
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const initialAzureDevOpsBuildPipelinePermissionsToAddState: AzureDevOpsBuildPipelinePermissionsToAddState = {
  creating: false,
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const initialAzureDevOpsReleasePipelineToCreateState: AzureDevOpsReleasePipelineToCreateState = {
  creating: false,
  pending: false,
  completed: false,
  data: null,
  error: undefined
};

export const initialAzureDevOpsReleasePipelinePermissionsToAddState: AzureDevOpsReleasePipelinePermissionsToAddState = {
  creating: false,
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const initialAzureDevOpsGitRepoState: AzureDevOpsGitRepoState = {
  pending: false,
  completed: false,
  creating: false,
  data: [],
  error: undefined
};

export const azureDevOpsTeamReducer = (
  state: AzureDevOpsTeamState = initialAzureDevOpsTeamState,
  action: AzureDevOpsAction
): AzureDevOpsProjectState => {
  switch (action.type) {
    case GET_ALL_TEAMS:
      return {
        ...state,
        pending: true
      };
    case GET_ALL_TEAMS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_ALL_TEAMS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};

export const azureDevOpsProjectReducer = (
  state: AzureDevOpsProjectState = initialAzureDevOpsProjectState,
  action: AzureDevOpsAction
): AzureDevOpsProjectState => {
  switch (action.type) {
    case GET_ALL_ADO_PROJECTS:
      return {
        ...state,
        pending: true
      };
    case GET_ALL_ADO_PROJECTS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_ALL_ADO_PROJECTS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};

export const azureDevOpsReleaseEnvironmentsReducer = (
  state: AzureDevOpsReleaseEnvironmentsState = initialAzureDevOpsEnvironmentsState,
  action: AzureDevOpsAction
): AzureDevOpsReleaseEnvironmentsState => {
  switch (action.type) {
    case GET_RELEASE_ENVIRONMENTS:
      return {
        ...state,
        pending: true
      };
    case GET_RELEASE_ENVIRONMENTS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_RELEASE_ENVIRONMENTS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};

export const azureDevOpsGitRepoReducer = (
  state: AzureDevOpsGitRepoState = initialAzureDevOpsGitRepoState,
  action: AzureDevOpsAction
): AzureDevOpsGitRepoState => {
  switch (action.type) {
    case CREATE_OR_GET_ADO_GIT_REPO:
      return {
        ...state,
        creating: true,
        completed: false
      };
    case CREATE_OR_GET_ADO_GIT_REPO_SUCCESS:
      return {
        ...state,
        creating: false,
        completed: true
      };
    case CREATE_OR_GET_ADO_GIT_REPO_ERROR:
      return {
        ...state,
        creating: false,
        error: action.payload,
        completed: true
      };
    case CREATE_OR_GET_ADO_GIT_REPO_RESET:
      return {
        pending: false,
        completed: false,
        creating: false,
        data: [],
        error: undefined
      };
    default:
      return state;
  }
};

export const createAzureDevOpsTeamReducer = (
  state: AzureDevOpsTeamToCreateState = initialAzureDevOpsTeamToCreateState,
  action: AzureDevOpsAction
): AzureDevOpsTeamToCreateState => {
  switch (action.type) {
    case CREATE_OR_GET_ADO_TEAM:
      return {
        ...state,
        creating: true,
        completed: false
      };
    case CREATE_OR_GET_ADO_TEAM_SUCCESS:
      return {
        ...state,
        creating: false,
        completed: true
      };
    case CREATE_OR_GET_ADO_TEAM_ERROR:
      return {
        ...state,
        creating: false,
        error: action.payload,
        completed: true
      };
    case CREATE_OR_GET_ADO_TEAM_RESET:
      return {
        pending: false,
        completed: false,
        creating: false,
        data: [],
        error: undefined
      };
    default:
      return state;
  }
};

export const setAzureDevOpsTeamPermissionsReducer = (
  state: AzureDevOpsTeamPermissionsToAddState = initialAzureDevOpsTeamPermissionsToAddState,
  action: AzureDevOpsAction
): AzureDevOpsTeamPermissionsToAddState => {
  switch (action.type) {
    case SET_ADO_TEAM_PERMISSIONS:
      return {
        ...state,
        creating: true,
        completed: false
      };
    case SET_ADO_TEAM_PERMISSIONS_SUCCESS:
      return {
        ...state,
        creating: false,
        completed: true
      };
    case SET_ADO_TEAM_PERMISSIONS_ERROR:
      return {
        ...state,
        creating: false,
        error: action.payload,
        completed: true
      };
    case CREATE_OR_GET_ADO_TEAM_RESET:
      return {
        pending: false,
        completed: false,
        creating: false,
        data: [],
        error: undefined
      };
    default:
      return state;
  }
};

export const azureDevOpsBuildPipelineReducer = (
  state: AzureDevOpsBuildPipelineToCreateState = initialAzureDevOpsBuildPipelineToCreateState,
  action: AzureDevOpsAction
): AzureDevOpsBuildPipelineToCreateState => {
  switch (action.type) {
    case CREATE_ADO_BUILD_DEFINITION:
      return {
        ...state,
        creating: true,
        completed: false
      };
    case CREATE_ADO_BUILD_DEFINITION_SUCCESS:
      return {
        ...state,
        creating: false,
        completed: true
      };
    case CREATE_ADO_BUILD_DEFINITION_ERROR:
      return {
        ...state,
        creating: false,
        error: action.payload,
        completed: true
      };
    case CREATE_ADO_BUILD_DEFINITION_RESET:
      return {
        pending: false,
        completed: false,
        creating: false,
        data: [],
        error: undefined
      };
    default:
      return state;
  }
};

export const setAzureDevOpsBuildPipelinePermissionsReducer = (
  state: AzureDevOpsBuildPipelinePermissionsToAddState = initialAzureDevOpsBuildPipelinePermissionsToAddState,
  action: AzureDevOpsAction
): AzureDevOpsBuildPipelinePermissionsToAddState => {
  switch (action.type) {
    case SET_ADO_BUILD_PIPELINE_PERMISSIONS:
      return {
        ...state,
        creating: true,
        completed: false
      };
    case SET_ADO_BUILD_PIPELINE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        creating: false,
        completed: true
      };
    case SET_ADO_BUILD_PIPELINE_PERMISSIONS_ERROR:
      return {
        ...state,
        creating: false,
        error: action.payload,
        completed: true
      };
    case SET_ADO_BUILD_PIPELINE_PERMISSIONS_RESET:
      return {
        pending: false,
        completed: false,
        creating: false,
        data: [],
        error: undefined
      };
    default:
      return state;
  }
};

export const azureDevOpsReleasePipelineReducer = (
  state: AzureDevOpsReleasePipelineToCreateState = initialAzureDevOpsReleasePipelineToCreateState,
  action: AzureDevOpsAction
): AzureDevOpsReleasePipelineToCreateState => {
  switch (action.type) {
    case CREATE_ADO_RELEASE_DEFINITION:
      return {
        ...state,
        creating: true,
        completed: false
      };
    case CREATE_ADO_RELEASE_DEFINITION_SUCCESS:
      return {
        ...state,
        creating: false,
        data: action.payload,
        completed: true
      };
    case CREATE_ADO_RELEASE_DEFINITION_ERROR:
      return {
        ...state,
        creating: false,
        error: action.payload,
        completed: true
      };
    case CREATE_ADO_RELEASE_DEFINITION_RESET:
      return {
        pending: false,
        completed: false,
        creating: false,
        data: null,
        error: undefined
      };
    default:
      return state;
  }
};

export const setAzureDevOpsReleasePipelinePermissionsReducer = (
  state: AzureDevOpsReleasePipelinePermissionsToAddState = initialAzureDevOpsReleasePipelinePermissionsToAddState,
  action: AzureDevOpsAction
): AzureDevOpsReleasePipelinePermissionsToAddState => {
  switch (action.type) {
    case SET_ADO_RELEASE_PIPELINE_PERMISSIONS:
      return {
        ...state,
        creating: true,
        completed: false
      };
    case SET_ADO_RELEASE_PIPELINE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        creating: false,
        completed: true
      };
    case SET_ADO_RELEASE_PIPELINE_PERMISSIONS_ERROR:
      return {
        ...state,
        creating: false,
        error: action.payload,
        completed: true
      };
    case SET_ADO_RELEASE_PIPELINE_PERMISSIONS_RESET:
      return {
        pending: false,
        completed: false,
        creating: false,
        data: [],
        error: undefined
      };
    default:
      return state;
  }
};
