import * as React from "react";

import { CssBaseline } from "@material-ui/core";

import { NavigationBar } from "./components/Navigation/NavigationBar";
import { LayoutProps } from "./types";

export function Layout({ children }: LayoutProps): JSX.Element {
  return (
    <div className="flex">
      <CssBaseline />
      <NavigationBar />
      <main className="flex content">{children}</main>
    </div>
  );
}
