import * as React from "react";

import { Grid, Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Page } from "../Page";
import { FormSection } from "./FormSection";
import { InputContainer } from "./InputContainer";
import { InputRow } from "./InputRow";

export function FormRequestSkeleton(): JSX.Element {
  return (
    <Page loading>
      <Paper className="form-paper space-vertical-64" variant="outlined">
        <FormSection title="Service information">
          <InputContainer description="Required. Provide the base information of new service">
            <Skeleton animation="wave" variant="text" width={150} />
            <Skeleton animation="wave" variant="text" width={150} />
          </InputContainer>
          <InputContainer description="Required. Provide Azure DevOps related information">
            <Skeleton animation="wave" variant="text" width={150} />
            <Skeleton animation="wave" variant="text" width={150} />
          </InputContainer>
          <InputContainer
            description="Required. Upload new service architecture diagram 
                including all its dependencies"
          >
            <Skeleton animation="wave" variant="text" width={150} />
          </InputContainer>
          <InputContainer description="Optional. Provide some additional information about the service">
            <InputRow>
              <Skeleton animation="wave" variant="text" width={150} />
              <Skeleton animation="wave" variant="text" width={150} />
            </InputRow>
            <Skeleton animation="wave" variant="text" width={150} />
          </InputContainer>
        </FormSection>
        <FormSection title="Required infrastructure">
          <InputContainer description="Required. Select service base, required additional resources and region">
            <Skeleton animation="wave" variant="text" width={150} />
            <Skeleton animation="wave" variant="text" width={150} />
            <Skeleton animation="wave" variant="text" width={150} />
          </InputContainer>
        </FormSection>
        <FormSection title="Contacts">
          <InputContainer
            description="Contact details of the service. 
                Provided contacts will be stored in the escalation list"
          >
            <Skeleton animation="wave" variant="text" width={150} />
            <Skeleton animation="wave" variant="text" width={150} />
            <div className="field-group">
              <Skeleton animation="wave" variant="text" width={150} />
              <Skeleton animation="wave" variant="text" width={150} />
            </div>
            <div className="field-group">
              <Skeleton animation="wave" variant="text" width={150} />
              <Skeleton animation="wave" variant="text" width={150} />
            </div>
            <div className="field-group">
              <Skeleton animation="wave" variant="text" width={150} />
              <Skeleton animation="wave" variant="text" width={150} />
            </div>
            <div className="field-group">
              <Skeleton animation="wave" variant="text" width={150} />
              <Skeleton animation="wave" variant="text" width={150} />
            </div>
          </InputContainer>
        </FormSection>
        <FormSection title="Approval process">
          <InputContainer
            description="Required. Select two peer reviewers that will be included
                in the approval process"
          >
            <Skeleton animation="wave" variant="text" width={150} />
            <Skeleton animation="wave" variant="text" width={150} />
          </InputContainer>
          <InputContainer
            description="Final approver. It is defined in system configuration 
                and assigned automatically"
          >
            <Skeleton animation="wave" variant="text" width={150} />
          </InputContainer>
        </FormSection>
      </Paper>
    </Page>
  );
}
