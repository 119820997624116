import * as React from "react";
import "./ZeroData.scss";

import MoodBadRoundedIcon from "@material-ui/icons/MoodBadRounded";
import { Typography } from "@material-ui/core";

import { ZeroDataProps } from "./types";

export function ZeroData(props: ZeroDataProps): JSX.Element {
  const { message } = props;

  return (
    <div className="zero-data">
      <MoodBadRoundedIcon className="zero-data-icon" color="disabled" />
      <Typography className="zero-data-message" color="textSecondary" variant="body2">
        {message || "No data to show"}
      </Typography>
    </div>
  );
}
