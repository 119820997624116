import * as React from "react";
import { useLocation } from "react-router-dom";

import { Typography } from "@material-ui/core";

export function NotFoundPage(): JSX.Element {
  const location = useLocation();

  return (
    <div>
      <h1>Uh oh...</h1>
      <Typography variant="body1">
        Requested resource: {location.pathname} was not found or has been removed.
      </Typography>
    </div>
  );
}
