import * as React from "react";
import * as Redux from "redux";
import { connect } from "react-redux";

import "./Home.scss";
import { Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { HomePageProps } from "./types";
import { ServiceFormResponse, ServiceFormStatusRequest } from "../../../models";
import { ApplicationState } from "../../../redux/reducers";
import { getServiceFormsDispatch, updateServiceFormStatusDispatch,updateServiceFormBCSMStatusDispatch, deleteServiceFormDispatch } from "../../../services/ServiceFormService";
import { ConfirmationType } from "../../Dialogs/types";
import { showSuccessSnackbar, showErrorSnackbar } from "../../Snackbars/Snackbars";
import { QuickStart } from "./QuickStart";
import { AllFormsReview } from "./AllFormsReview";
import { AuthContext } from "../../../context/AuthContext";
import { getAllUsersWithRolesDispatch } from "../../../services";
import { AppContext } from "../../../context/AppContext";
import { Page } from "../Page";
import { Confirmation } from "../../Dialogs";

function HomePage(props: HomePageProps): JSX.Element {
    const { getServiceForms, serviceForms, getAllUsers, users, updateServiceFormStatus, deleteServiceForm } = props;
    const [serviceFormsLoading, setServiceFormsLoading] = React.useState<boolean>(true);
    const [serviceFormsData, setServiceFormsData] = React.useState<ServiceFormResponse[]>([]);
    const { user, roles } = React.useContext(AuthContext);
    const { setSelectedTab } = React.useContext(AppContext);

    const [confirmationActive, setConfirmationActive] = React.useState<boolean>(false);
    const [confirmationMessage, setConfirmationMessage] = React.useState<string>("");
    const [confirmationCallback, setConfirmationCallback] = React.useState<(() => void) | undefined>(
        undefined
    );
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (setSelectedTab) setSelectedTab(0);
    if (!serviceForms.pending && !serviceForms.completed) getServiceForms();
    if (!users.pending && !users.completed) getAllUsers();
  }, []);

    function handleServiceFormCompletion(serviceFormId: number): void {
        if (user) {
            const request: ServiceFormStatusRequest = {
                id: serviceFormId,
                status: "Completed",
                userId: user.id
            };
            updateServiceFormStatus(request)
                .then((sf: any) =>
                    showSuccessSnackbar(enqueueSnackbar, closeSnackbar, "Completed Cloud Service Request.")
                )
                .catch((error: any) =>
                    showErrorSnackbar(
                        enqueueSnackbar,
                        closeSnackbar,
                        "Failed to complete Cloud Service Request."
                    )
                );
        }
    }

    function handleServiceFormActivation(serviceFormId: number): void {
        if (user) {
            const request: ServiceFormStatusRequest = {
                id: serviceFormId,
                status: "WaitingForBCSMApproval",
                userId: user.id
            };
            updateServiceFormStatus(request)
                .then(sf =>
                    showSuccessSnackbar(enqueueSnackbar, closeSnackbar, "Activated Cloud Service Request.")
                )
                .catch(error =>
                    showErrorSnackbar(
                        enqueueSnackbar,
                        closeSnackbar,
                        "Failed to activate Cloud Service Request."
                    )
                );
        }
    }

    function handleServiceFormCancelation(serviceFormId: number): void {
        if (user) {
            const request: ServiceFormStatusRequest = {
                id: serviceFormId,
                status: "Canceled",
                userId: user.id
            };
            updateServiceFormStatus(request)
                .then(sf =>
                    showSuccessSnackbar(enqueueSnackbar, closeSnackbar, "Canceled Cloud Service Request.")
                )
                .catch(error =>
                    showErrorSnackbar(
                        enqueueSnackbar,
                        closeSnackbar,
                        "Failed to cancel Cloud Service Request."
                    )
                );
        }
    }

    function handleServiceFormDeletion(serviceFormId: number): void {
        if (user) {
            const filteredServiceForms = serviceForms.data.filter(sf => sf.id === serviceFormId);
            if (filteredServiceForms.length > 0) {
                deleteServiceForm(filteredServiceForms[0])
                    .then(sf =>
                        showSuccessSnackbar(enqueueSnackbar, closeSnackbar, "Deleted Cloud Service Request.")
                    )
                    .catch(error =>
                        showErrorSnackbar(
                            enqueueSnackbar,
                            closeSnackbar,
                            "Failed to delete Cloud Service Request."
                        )
                    );
            }
        }
    }

    function closeConfirmationDialog(): void {
        setConfirmationActive(false);
    }

    function triggerConfirmationDialog(
        message: string,
        serviceFormId: number,
        type: ConfirmationType
    ): void {
        switch (type) {
            case "complete":
                setConfirmationCallback(() => (): void => handleServiceFormCompletion(serviceFormId));
                break;
            case "activate":
                setConfirmationCallback(() => (): void => handleServiceFormActivation(serviceFormId));
                break;
            case "cancel":
                setConfirmationCallback(() => (): void => handleServiceFormCancelation(serviceFormId));
                break;
            case "delete":
                setConfirmationCallback(() => (): void => handleServiceFormDeletion(serviceFormId));
                break;
            default:
                break;
        }
        setConfirmationMessage(message);
        setConfirmationActive(true);
    }

  React.useEffect(() => {
    if (serviceForms.completed) {
      setServiceFormsData(serviceForms.data);
      setServiceFormsLoading(false);
    }
  }, [serviceForms.data]);
  return (
    <Page>
      <Grid container direction="row" spacing={1}>
        <QuickStart roles={roles} />
          <AllFormsReview
            loading={serviceFormsLoading}
            pages={10}
            serviceForms={serviceFormsData}
            users={users.data}
            servicesForm={serviceForms}
            confirmation={triggerConfirmationDialog}
          />
      </Grid>
          <Confirmation
              inProgress={serviceForms.deleting || serviceForms.updating}
              onClose={closeConfirmationDialog}
              onProceed={(): void => {
                  if (confirmationCallback) confirmationCallback();
              }}
              open={confirmationActive}
              message={confirmationMessage}
          />
    </Page>
  );
}

function mapStateToProps(state: ApplicationState): unknown {
  return {
    serviceForms: state.serviceForms,
    users: state.users
  };
}

function mapDispatchToProps(dispatch: Redux.Dispatch<Redux.AnyAction>): unknown {
  return Redux.bindActionCreators(
    {
      getServiceForms: getServiceFormsDispatch,
          getAllUsers: getAllUsersWithRolesDispatch,
          updateServiceFormStatus: updateServiceFormStatusDispatch,
          updateServiceFormBCSMReviewStatus:updateServiceFormBCSMStatusDispatch,
          deleteServiceForm: deleteServiceFormDispatch
    },
    dispatch
  );
}

export const Home = connect(mapStateToProps, mapDispatchToProps)(HomePage);
