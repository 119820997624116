/* eslint-disable max-classes-per-file */
import { requestAccessToken } from "../services/AuthenticationService";

export class Request {
  public requestUrl: string;

  public method: string;

  public headers: Headers;

  public body: string | null;

  private mode?: "cors" | "navigate" | "no-cors" | "same-origin" | undefined;

  constructor(requestBuilder: RequestBuilder) {
    this.requestUrl = requestBuilder.getRequestUrl;
    this.method = requestBuilder.getMethod;
    this.headers = requestBuilder.getHeaders;
    this.body = requestBuilder.getBody || null;
    this.mode = requestBuilder.getMode;
  }

  public async send(): Promise<Response> {
    if (!this.requestUrl) throw new Error("Request URL was not set");

    const accessToken = await requestAccessToken();
    if (!accessToken) throw new Error("Could not get MSAL access token.");

    this.headers.append("Authorization", `Bearer ${accessToken}`);

    return fetch(this.requestUrl, {
      method: this.method,
      headers: this.headers,
      body: this.body,
      mode: this.mode
    });
  }
}
export class RequestBuilder {
  private requestUrl: string;

  private method: string;

  private headers: Headers;

  private body: string;

  private mode?: "cors" | "navigate" | "no-cors" | "same-origin" | undefined;

  constructor() {
    this.requestUrl = "";
    this.method = "GET";
    this.headers = new Headers({ "Content-Type": "application/json" });
    this.body = "";
    this.mode = undefined;

    return this;
  }

  public get getRequestUrl(): string {
    return this.requestUrl;
  }

  public get getMethod(): string {
    return this.method;
  }

  public get getHeaders(): Headers {
    return this.headers;
  }

  public get getBody(): string {
    return this.body;
  }

  public get getMode(): "cors" | "navigate" | "no-cors" | "same-origin" | undefined {
    return this.mode;
  }

  public setRequestUrl = (requestUrl: string): RequestBuilder => {
    this.requestUrl = requestUrl;
    return this;
  };

  public setMethod = (method: string): RequestBuilder => {
    this.method = method;
    return this;
  };

  public appendHeader = (key: string, value: string): RequestBuilder => {
    this.headers.append(key, value);
    return this;
  };

  public setBody = (body: unknown): RequestBuilder => {
    this.body = JSON.stringify(body);
    return this;
  };

  public setMode = (mode: "cors" | "navigate" | "no-cors" | "same-origin"): RequestBuilder => {
    this.mode = mode;
    return this;
  };

  public build = (): Request => {
    return new Request(this);
  };
}
