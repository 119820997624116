import { SharePointUserState, SharePointAction, SharePointContactState, DevOpsEscalationContactsState } from "./types";
import {
  GET_SHAREPOINT_USERS,
  GET_SHAREPOINT_USERS_SUCCESS,
  GET_SHAREPOINT_USERS_ERROR,
  GET_SHAREPOINT_CONTACTS,
  GET_SHAREPOINT_CONTACTS_SUCCESS,
  GET_SHAREPOINT_CONTACTS_ERROR,
  UPDATE_SHAREPOINT_CONTACT,
  UPDATE_SHAREPOINT_CONTACT_SUCCESS,
  UPDATE_SHAREPOINT_CONTACT_ERROR,
  SILENT_UPDATE_SHAREPOINT_CONTACT,
  SILENT_UPDATE_SHAREPOINT_CONTACT_SUCCESS,
  SILENT_UPDATE_SHAREPOINT_CONTACT_ERROR,
  CREATE_ESCALATIONCONTACTS,
  CREATE_ESCALATIONCONTACTS_SUCCESS,
  CREATE_ESCALATIONCONTACTS_ERROR
} from "../constants";
import { SharePointContact } from "../../models";

export const initialSharePointUserState: SharePointUserState = {
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const initialSharePointContactState: SharePointContactState = {
  pending: false,
  completed: false,
  updating: false,
  data: [],
  error: undefined
};

export const initialEscalationContactsState: DevOpsEscalationContactsState = {
  creating:false,
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const sharePointUserReducer = (
  state: SharePointUserState = initialSharePointUserState,
  action: SharePointAction
): SharePointUserState => {
  switch (action.type) {
    case GET_SHAREPOINT_USERS:
      return {
        ...state,
        pending: true
      };
    case GET_SHAREPOINT_USERS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_SHAREPOINT_USERS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};

export const sharePointContactsReducer = (
  state: SharePointContactState = initialSharePointContactState,
  action: SharePointAction
): SharePointContactState => {
  switch (action.type) {
    case GET_SHAREPOINT_CONTACTS:
      return {
        ...state,
        pending: true
      };
    case GET_SHAREPOINT_CONTACTS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_SHAREPOINT_CONTACTS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    case UPDATE_SHAREPOINT_CONTACT:
      return {
        ...state,
        updating: true
      };
    case UPDATE_SHAREPOINT_CONTACT_SUCCESS:
      return {
        ...state,
        updating: false,
        data: state.data.map(contact =>
          contact.id === action.payload.id ? action.payload : contact
        )
      };
    case UPDATE_SHAREPOINT_CONTACT_ERROR:
      return {
        ...state,
        updating: false,
        error: action.payload
      };
    case SILENT_UPDATE_SHAREPOINT_CONTACT:
      const newContactsValue: { [name: string]: SharePointContact } = Object.entries(
        action.payload.contact.contacts
      )
        .map(([key, value]): { [name: string]: SharePointContact } => ({ [key]: value }))
        .reduce((total, current) => ({ ...total, ...current }));
      return {
        ...state,
        updating: true,
        data: state.data.map(contact =>
          contact.id === action.payload.id
            ? {
                ...contact,
                contacts: newContactsValue,
                definition: action.payload.contact.definition
              }
            : contact
        )
      };
    case SILENT_UPDATE_SHAREPOINT_CONTACT_SUCCESS:
      return {
        ...state,
        updating: false
      };
    case SILENT_UPDATE_SHAREPOINT_CONTACT_ERROR:
      return {
        ...state,
        updating: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const EscalationContactsReducer = (
  state: DevOpsEscalationContactsState = initialEscalationContactsState, 
  action: SharePointAction): DevOpsEscalationContactsState => {
    switch (action.type){
      case CREATE_ESCALATIONCONTACTS:
        return{
          ...state,
          creating:true,
          completed:false
        };
      case CREATE_ESCALATIONCONTACTS_SUCCESS:
        return{
          ...state,
          creating:false,
          completed: true
        };
        case CREATE_ESCALATIONCONTACTS_ERROR:
          return {
            ...state,
            creating: false,
            error: action.payload,
            completed: true
          };
        default:
            return state;
    }
  }
