export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_ERROR";

export const GET_ALL_USERS_WITH_ROLES = "GET_ALL_USERS_WITH_ROLES";
export const GET_ALL_USERS_WITH_ROLES_SUCCESS = "GET_ALL_USERS_WITH_ROLES_SUCCESS";
export const GET_ALL_USERS_WITH_ROLES_ERROR = "GET_ALL_USERS_WITH_ROLES_ERROR";

export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";
export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS";
export const UPDATE_USER_ROLE_ERROR = "UPDATE_USER_ROLE_ERROR";

export const SET_PRIMARY_USER = "SET_PRIMARY_USER";
export const SET_PRIMARY_USER_SUCCESS = "SET_PRIMARY_USER_SUCCESS";
export const SET_PRIMARY_USER_ERROR = "SET_PRIMARY_USER_ERROR";
