import * as React from "react";
import { useLocation } from "react-router-dom";

import { Typography, List, ListItem } from "@material-ui/core";

import { UnauthorizedPageProps } from "./types";

export function UnauthorizedPage(props: UnauthorizedPageProps): JSX.Element {
  const { requiredRoles } = props;
  const location = useLocation();

  return (
    <div>
      <h1>Uh oh...</h1>
      <Typography variant="body1">
        Looks like you do not have enough access for requested resource: {location.pathname}.
      </Typography>
      <div className="margin-top-8">
        <Typography variant="body1">Allowed user roles:</Typography>
        <List dense disablePadding>
          {requiredRoles.map(role => (
            <ListItem dense>
              <Typography variant="body1">{role}</Typography>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
}
