import * as React from "react";
import { debounce } from "lodash";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { OutlinedAutocomplete } from "../../Inputs";
import { SharePointUserResponse } from "../../../models";

interface Props {
  users: SharePointUserResponse[];
  usersLoading: boolean;
  setSearch: (value: string) => void;
  setSearchContactName: (value: string | null) => void;
}

export function Filter(props: Props): JSX.Element {
  const { users, usersLoading, setSearch, setSearchContactName } = props;
  React.useEffect(() => {
    return function cleanup() {
      handleChange.cancel();
    };
  });
  const handleChange = debounce((value: string) => {
    setSearch(value);
  }, 300);

  return (
      <Grid container direction="row" justifyContent="space-between" spacing={1}>
      <Grid item xs={3}>
        <TextField
          classes={{ root: "search-textfield MuiFormControl-marginDense" }}
          placeholder="Search.."
          variant="outlined"
          onChange={e => handleChange(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <OutlinedAutocomplete<SharePointUserResponse>
          getOptionLabel={(p: SharePointUserResponse): string => `${p.name}`}
          handle={(newValue: SharePointUserResponse | null) => {
            setSearchContactName(newValue ? newValue.name : null);
          }}
          label="Contains contact..."
          loading={usersLoading}
          options={users}
        />
      </Grid>
    </Grid>
  );
}
