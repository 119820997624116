import { RegionState, ConfigurationAction } from "./types";
import { GET_REGIONS, GET_REGIONS_SUCCESS, GET_REGIONS_ERROR } from "../constants";

export const initialRegionsState: RegionState = {
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const configurationRegionsReducer = (
  state: RegionState = initialRegionsState,
  action: ConfigurationAction
): RegionState => {
  switch (action.type) {
    case GET_REGIONS:
      return {
        ...state,
        pending: true
      };
    case GET_REGIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_REGIONS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};
