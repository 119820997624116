import {
  OctopusTemplateProjectState,
  OctopusAction,
  OctopusARMStepState,
  OctopusLifecycleState,
  OctopusProjectState,
  OctopusProjectGroupState,
  OctopusLibraryState,
  OctopusProjectToCreateState
} from "./types";
import {
  GET_ALL_TEMPLATE_PROJECTS,
  GET_ALL_TEMPLATE_PROJECTS_SUCCESS,
  GET_ALL_TEMPLATE_PROJECTS_ERROR,
  GET_ARM_STEPS,
  GET_ARM_STEPS_SUCCESS,
  GET_ARM_STEPS_ERROR,
  GET_ALL_SUBSCRIPTION_LIBRARIES,
  GET_ALL_SUBSCRIPTION_LIBRARIES_SUCCESS,
  GET_ALL_SUBSCRIPTION_LIBRARIES_ERROR,
  GET_ALL_LIFECYCLES,
  GET_ALL_LIFECYCLES_SUCCESS,
  GET_ALL_LIFECYCLES_ERROR,
  GET_ALL_OCTO_PROJECTS,
  GET_ALL_OCTO_PROJECTS_SUCCESS,
  GET_ALL_OCTO_PROJECTS_ERROR,
  GET_ALL_PROJECT_GROUPS,
  GET_ALL_PROJECT_GROUPS_SUCCESS,
  GET_ALL_PROJECT_GROUPS_ERROR,
  CREATE_OCTO_PROJECT,
  CREATE_OCTO_PROJECT_SUCCESS,
  CREATE_OCTO_PROJECT_ERROR,
  CLONE_OCTO_PROJECT,
  CLONE_OCTO_PROJECT_SUCCESS,
  CLONE_OCTO_PROJECT_ERROR,
  CREATE_OR_CLONE_OCTO_PROJECT_RESET
} from "../constants";

export const initialOctopusTemplateProjectsState: OctopusTemplateProjectState = {
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const initialOctopusARMStepsState: OctopusARMStepState = {
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const initialOctopusLibraryState: OctopusLibraryState = {
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const initialOctopusLifecycleState: OctopusLifecycleState = {
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const initialOctopusProjectState: OctopusProjectState = {
  pending: false,
  deleting: false,
  completed: false,
  data: [],
  error: undefined
};

export const initialOctopusProjectGroupState: OctopusProjectGroupState = {
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const initialOctopusProjectToCreateState: OctopusProjectToCreateState = {
  pending: false,
  completed: false,
  data: [],
  creating: false,
  error: undefined
};

export const octopusTemplateProjectReducer = (
  state: OctopusTemplateProjectState = initialOctopusTemplateProjectsState,
  action: OctopusAction
): OctopusTemplateProjectState => {
  switch (action.type) {
    case GET_ALL_TEMPLATE_PROJECTS:
      return {
        ...state,
        pending: true
      };
    case GET_ALL_TEMPLATE_PROJECTS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_ALL_TEMPLATE_PROJECTS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};

export const octopusARMStepReducer = (
  state: OctopusARMStepState = initialOctopusARMStepsState,
  action: OctopusAction
): OctopusARMStepState => {
  switch (action.type) {
    case GET_ARM_STEPS:
      return {
        ...state,
        pending: true
      };
    case GET_ARM_STEPS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_ARM_STEPS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};

export const octopusLibraryReducer = (
  state: OctopusLibraryState = initialOctopusLibraryState,
  action: OctopusAction
): OctopusLibraryState => {
  switch (action.type) {
    case GET_ALL_SUBSCRIPTION_LIBRARIES:
      return {
        ...state,
        pending: true
      };
    case GET_ALL_SUBSCRIPTION_LIBRARIES_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_ALL_SUBSCRIPTION_LIBRARIES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};

export const octopusLifecycleReducer = (
  state: OctopusLifecycleState = initialOctopusLifecycleState,
  action: OctopusAction
): OctopusLifecycleState => {
  switch (action.type) {
    case GET_ALL_LIFECYCLES:
      return {
        ...state,
        pending: true
      };
    case GET_ALL_LIFECYCLES_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_ALL_LIFECYCLES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};

export const octopusProjectReducer = (
  state: OctopusProjectState = initialOctopusProjectState,
  action: OctopusAction
): OctopusProjectState => {
  switch (action.type) {
    case GET_ALL_OCTO_PROJECTS:
      return {
        ...state,
        pending: true
      };
    case GET_ALL_OCTO_PROJECTS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_ALL_OCTO_PROJECTS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};

export const octopusProjectGroupReducer = (
  state: OctopusProjectGroupState = initialOctopusProjectGroupState,
  action: OctopusAction
): OctopusProjectGroupState => {
  switch (action.type) {
    case GET_ALL_PROJECT_GROUPS:
      return {
        ...state,
        pending: true
      };
    case GET_ALL_PROJECT_GROUPS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_ALL_PROJECT_GROUPS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};

export const octopusProjectCreationReducer = (
  state: OctopusProjectToCreateState = initialOctopusProjectToCreateState,
  action: OctopusAction
): OctopusProjectToCreateState => {
  switch (action.type) {
    case CREATE_OCTO_PROJECT:
      return {
        ...state,
        creating: true,
        completed: false
      };
    case CREATE_OCTO_PROJECT_SUCCESS:
      return {
        ...state,
        creating: false,
        completed: true
      };
    case CREATE_OCTO_PROJECT_ERROR:
      return {
        ...state,
        creating: false,
        error: action.payload,
        completed: true
      };
    case CLONE_OCTO_PROJECT:
      return {
        ...state,
        creating: true,
        completed: false
      };
    case CLONE_OCTO_PROJECT_SUCCESS:
      return {
        ...state,
        creating: false,
        completed: true
      };
    case CLONE_OCTO_PROJECT_ERROR:
      return {
        ...state,
        creating: false,
        error: action.payload,
        completed: true
      };
    case CREATE_OR_CLONE_OCTO_PROJECT_RESET:
      return {
        pending: false,
        completed: false,
        data: [],
        creating: false,
        error: undefined
      };
    default:
      return state;
  }
};
