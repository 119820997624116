import * as React from "react";

import { Grid, Typography, FormHelperText } from "@material-ui/core";

import { InputContainerProps } from "./types";

export function InputContainer(props: InputContainerProps): JSX.Element {
  const { children, description, fieldsClass } = props;

  return (
    <Grid container direction="row" justifyContent="space-between">
      <Grid className="padding-top-8" item xs={4}>
        <Typography color="textSecondary" variant="body2">
          {description}
        </Typography>
      </Grid>
      <Grid item xs={2} />
      <Grid className={fieldsClass} item xs={6}>
        {children}
      </Grid>
    </Grid>
  );
}
