/* eslint-disable @typescript-eslint/no-use-before-define */
import "./Settings.scss";

import * as React from "react";
import { useSnackbar } from "notistack";

import {
  Paper,
  ThemeProvider,
  Typography,
  Button
} from "@material-ui/core";

import { textFieldsTheme, darkTextFieldsTheme } from "../../../themes";
import { AuthContext } from "../../../context/AuthContext";
import { AppContext } from "../../../context/AppContext";
import { UserResponse } from "../../../models";
import { FinalApproverProps } from "./types";
import { OutlinedAutocomplete } from "../../Inputs";
import { FORM_APPROVER } from "../../../constants/userRoles";

export function FinalApprover(props: FinalApproverProps): JSX.Element {
  const { roles, users, setPrimaryUserByRoleName, primaryApprover } = props;
  const { darkMode } = React.useContext(AppContext);
  const { user } = React.useContext(AuthContext);

  const [usersList, setUsersList] = React.useState<UserResponse[]>([]);
  const [updating, setUpdating] = React.useState<boolean>(true);
  const [primaryApproverUser, setprimaryApproverUser] = React.useState<UserResponse | null>(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (users.completed && roles.completed) {
      setUsersList(users.data);
      setUpdating(false);
    }
  }, [users.completed, roles.completed]);

  React.useEffect(() => {
    if (!users.updating) {
      setUpdating(false);
    }
  }, [users.updating]);

  React.useEffect(() => {
    if (primaryApprover) {
      setprimaryApproverUser(primaryApprover);
    }
  }, [primaryApprover]);

  function saveChanges(): void {
    if (primaryApproverUser) {
      setPrimaryUserByRoleName(primaryApproverUser.id, "FormApprover");
      setUpdating(true);
    }
  }

  return (
    <div>
      <ThemeProvider theme={darkMode ? darkTextFieldsTheme : textFieldsTheme}>
        <Paper className="p-3" variant="outlined">
          <Typography variant="button">Final Approver</Typography>
          <div id="management-approval-primary_approver-section" className="mt-2 mb-2">
            <OutlinedAutocomplete
              label="Primary approver"
              getOptionLabel={(u: UserResponse): string => u.name}
              handle={setprimaryApproverUser}
              helperText="By changing primary approver, all not yet approved and new cloud service setup requests will have new approver reassigned."
              value={primaryApproverUser}
              options={users.data.filter(u =>
                u.userRole ? u.userRole?.some(r => r.name === FORM_APPROVER) : false
              )}
              loading={users.pending}
            />
          </div>
          <div>
            <Button variant="outlined" onClick={saveChanges}>
              {!updating ? "Save changes" : "Updating..."}{" "}
            </Button>
          </div>
        </Paper>
      </ThemeProvider>
    </div>
  );
}
