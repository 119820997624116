export interface Comment {
  id: number;
  author: string;
  createdAt: Date;
  message: string;
}

export interface CommentValidationSettings {
  allowEmpty: boolean;
  maxCommentLength?: number;
}

export const defaultCommentValidationSettings: CommentValidationSettings = {
  allowEmpty: false
};
