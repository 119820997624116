import * as React from "react";

import {
  Grid,
  Typography,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FinalizeProps } from "./types";
import { setIsEscalationContactsRequired } from "./actions";
import { AzureDevOpsYmlPipelineProject } from "../../../constants/deployablesColumns";

const useStyles = makeStyles({
  hideLastBorder: {
    "&:last-child td, &:last-child th": {
      border: 0
    }
  }
});

export function Finalize(props: FinalizeProps): JSX.Element {
  const {
    additionalResources,
    deployableName,
    isOctopusRequired,
    devOpsProject,
    devOpsTeamName,
    gitRepoName,
    isBuildPipelineRequired,
    isCloneEnabled,
    isGitRepoRequired,
    isReleasePipelineRequired,
    isEscalationContactRequired,
    lifecycle,
    octopusProject,
    projectGroup,
    projectType,
    region,
    subscription,
    isScorecardRequired,
    releaseType,
    productType,
    product,
    productManagerEmail,
    devLeadEmail,
    scorecardProject,
    selectedItems,
    isOperationalTestsRequired,
    operationalTestsService,
    operationalTestsCrontabExpression,
    operationalTestsTimeout,
    operationalTestsNotificationList,
    isGeoReplicated,
    operationalTestsAreHighSeverity,
    isSeqRequired,
    seqKeyEnvironments,
    isBuddiRequired,
    buddiRegionalUrlsRequired,
    buddiUrl,
    buddiRegions,
    configuredBuddiRegionalUrls,
    serviceBaseErrors,
    scorecardErrors,
    OPTestErrors,
    seqErrors
  } = props;

  const classes = useStyles();

  function createData(title: string, value: any) {
    return { title, value };
  }

  const octopusRows = [
    isCloneEnabled ? createData("Octopus project for cloning:", octopusProject?.name) : null,
    createData("Project group:", projectGroup?.name),
    isCloneEnabled ? null : createData("Project type:", projectType?.name),
    createData("Release lifecycle:", lifecycle?.name),
    createData("Region:", region?.fullName),
    isCloneEnabled ? null : createData("Subscription:", subscription?.title),
    isCloneEnabled
      ? null
      : createData(
          "Additional resources:",
          additionalResources
            ? additionalResources.map((resource, index) => {
                return (
                  <Typography variant="inherit">
                    {`${index + 1}. ${resource.name}`}
                    <br />
                  </Typography>
                );
              })
            : null
        )
  ];

  const adoRows = [
    createData("Project:", devOpsProject?.name),
    createData("Team:", devOpsTeamName),
    isBuildPipelineRequired ? createData("Build pipeline:", deployableName) : null,
      isReleasePipelineRequired && devOpsProject?.name !== AzureDevOpsYmlPipelineProject.iModelTechnologies
      ? createData("Release pipeline:", `${deployableName  } ({short code})`)
      : null,
    isGitRepoRequired ? createData("Git repository:", gitRepoName) : null,
    isEscalationContactRequired ? createData("Escalation Contact:", "Required") : null
  ];

  const scorecardRows = [
    createData("Scorecard Project:", scorecardProject?.projectName),
    createData("Product Manager:", productManagerEmail),
    createData("Lead Developer:", devLeadEmail),
    createData("Release Type:", releaseType?.value),
    createData("Product Type:", productType?.value),
    createData(
      "Validators:",
      selectedItems
        ? selectedItems.map(item => {
            return (
              <Typography variant="inherit">
                {item.name}
                <br />
              </Typography>
            );
          })
        : null
    )
  ];

  const otRows = [
    createData("Service:", operationalTestsService?.name),
    createData("Schedule Crontab Expression:", operationalTestsCrontabExpression),
    createData("Timeout (in minutes):", operationalTestsTimeout),
    createData(
      "Notification recipients:",
      operationalTestsNotificationList
        .replace(" ", "")
        .split(",")
        .map(recipient => {
          return (
            <Typography variant="inherit">
              {recipient}
              <br />
            </Typography>
          );
        })
    ),
    createData("High severity tests:", operationalTestsAreHighSeverity ? "Yes" : "No")
  ];

  const seqRows = [
    createData("Product:", product ? `${product.productId} - ${product.productName}` : null),
    createData(
      "Environments:",
      seqKeyEnvironments?.map(env => {
        return (
          <Typography variant="inherit">
            {env.toUpperCase()}
            <br />
          </Typography>
        );
      })
    )
  ];

  const buddiRows = [
    createData("Name:", buddiUrl?.name),
    createData("URL:", buddiUrl?.url),
    buddiRegionalUrlsRequired
      ? createData(
          "Regional URLs:",
          configuredBuddiRegionalUrls?.map(url => {
            return (
              <Typography variant="inherit">
                {`${buddiRegions.data.find(x => x.id === url.regionid)?.name} - ${url.url}`}
                <br />
              </Typography>
            );
          })
        )
      : null
  ];
  return (
    <div>
      <div className="mt-4">
        <Grid container direction="row" spacing={5}>
          <Grid item xs={12}>
            <Typography
              className="text-uppercase font-weight-bold"
              color="textSecondary"
              variant="body2"
            >
              Summary
            </Typography>
            <Divider className="mt-2 mb-2" />
            <Grid container direction="row" spacing={2}>
              {isOctopusRequired ? (
                <Grid item xs={12}>
                  <ExpansionPanel square defaultExpanded>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel-octopus"
                      id="panel-octopus"
                    >
                      {deployableName ? (
                        <Typography
                          className="text-uppercase font-weight-bold"
                          color="textSecondary"
                          variant="body2"
                        >
                          Octopus Project - {deployableName}
                        </Typography>
                      ) : (
                        <Typography
                          className="text-uppercase font-weight-bold"
                          color="textSecondary"
                          variant="body2"
                        >
                          Octopus Project
                        </Typography>
                      )}
                      {serviceBaseErrors.length > 0 && (
                        <Typography
                          className="text-uppercase font-weight-bold"
                          color="error"
                          variant="body2"
                        >
                          - HAS FIELD ERRORS!
                        </Typography>
                      )}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Table>
                        <TableBody>
                          {octopusRows.map(row =>
                            row !== null ? (
                              <TableRow key={row.title} className={classes.hideLastBorder}>
                                <TableCell
                                  style={{ verticalAlign: "top", width: "40%" }}
                                  className="font-weight-bold"
                                  scope="row"
                                >
                                  {row.title}
                                </TableCell>
                                <TableCell style={{ width: "60%" }}>{row.value}</TableCell>
                              </TableRow>
                            ) : null
                          )}
                        </TableBody>
                      </Table>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              ) : null}
              {isCloneEnabled ? null : (
                <Grid item xs={12}>
                  <ExpansionPanel square defaultExpanded>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel-ado"
                      id="panel-ado"
                    >
                      <Typography
                        className="text-uppercase font-weight-bold"
                        color="textSecondary"
                        variant="body2"
                      >
                        Azure DevOps
                      </Typography>
                      {serviceBaseErrors.length > 0 && (
                        <Typography
                          className="text-uppercase font-weight-bold"
                          color="error"
                          variant="body2"
                        >
                          - HAS FIELD ERRORS!
                        </Typography>
                      )}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Table>
                        <TableBody>
                          {adoRows.map(row =>
                            row !== null ? (
                              <TableRow key={row.title} className={classes.hideLastBorder}>
                                <TableCell
                                  style={{ verticalAlign: "top", width: "40%" }}
                                  className="font-weight-bold"
                                  scope="row"
                                >
                                  {row.title}
                                </TableCell>
                                <TableCell style={{ width: "60%" }}>{row.value}</TableCell>
                              </TableRow>
                            ) : null
                          )}
                        </TableBody>
                      </Table>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              )}
              {isScorecardRequired ? (
                <Grid item xs={12}>
                  <ExpansionPanel square defaultExpanded>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel-scorecard"
                      id="panel-scorecard"
                    >
                      <Typography
                        className="text-uppercase font-weight-bold"
                        color="textSecondary"
                        variant="body2"
                      >
                        Scorecard Association
                      </Typography>
                      {scorecardErrors.length > 0 && (
                        <Typography
                          className="text-uppercase font-weight-bold"
                          color="error"
                          variant="body2"
                        >
                          - HAS FIELD ERRORS!
                        </Typography>
                      )}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Table>
                        <TableBody>
                          {scorecardRows.map(row =>
                            row !== null ? (
                              <TableRow key={row.title} className={classes.hideLastBorder}>
                                <TableCell
                                  style={{ verticalAlign: "top", width: "40%" }}
                                  className="font-weight-bold"
                                  scope="row"
                                >
                                  {row.title}
                                </TableCell>
                                <TableCell style={{ width: "60%" }}>{row.value}</TableCell>
                              </TableRow>
                            ) : null
                          )}
                        </TableBody>
                      </Table>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              ) : null}
              {isOperationalTestsRequired ? (
                <Grid item xs={12}>
                  <ExpansionPanel square defaultExpanded>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel-opts"
                      id="panel-opts"
                    >
                      <Typography
                        className="text-uppercase font-weight-bold"
                        color="textSecondary"
                        variant="body2"
                      >
                        Operational Tests
                      </Typography>
                      {OPTestErrors.length > 0 && (
                        <Typography
                          className="text-uppercase font-weight-bold"
                          color="error"
                          variant="body2"
                        >
                          - HAS FIELD ERRORS!
                        </Typography>
                      )}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Table>
                        <TableBody>
                          {otRows.map(row =>
                            row !== null ? (
                              <TableRow key={row.title} className={classes.hideLastBorder}>
                                <TableCell
                                  style={{ verticalAlign: "top", width: "40%" }}
                                  className="font-weight-bold"
                                  scope="row"
                                >
                                  {row.title}
                                </TableCell>
                                <TableCell style={{ width: "60%" }}>{row.value}</TableCell>
                              </TableRow>
                            ) : null
                          )}
                        </TableBody>
                      </Table>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              ) : null}
              {isSeqRequired ? (
                <Grid item xs={12}>
                  <ExpansionPanel square defaultExpanded>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel-seq"
                      id="panel-seq"
                    >
                      <Typography
                        className="text-uppercase font-weight-bold"
                        color="textSecondary"
                        variant="body2"
                      >
                        SEQ Keys
                      </Typography>
                      {seqErrors.length > 0 && (
                        <Typography
                          className="text-uppercase font-weight-bold"
                          color="error"
                          variant="body2"
                        >
                          - HAS FIELD ERRORS!
                        </Typography>
                      )}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Table>
                        <TableBody>
                          {seqRows.map(row =>
                            row !== null ? (
                              <TableRow key={row.title} className={classes.hideLastBorder}>
                                <TableCell
                                  style={{ verticalAlign: "top", width: "40%" }}
                                  className="font-weight-bold"
                                  scope="row"
                                >
                                  {row.title}
                                </TableCell>
                                <TableCell style={{ width: "60%" }}>{row.value}</TableCell>
                              </TableRow>
                            ) : null
                          )}
                        </TableBody>
                      </Table>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              ) : null}
              {isBuddiRequired ? (
                <Grid item xs={12}>
                  <ExpansionPanel square defaultExpanded>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel-buddi"
                      id="panel-buddi"
                    >
                      <Typography
                        className="text-uppercase font-weight-bold"
                        color="textSecondary"
                        variant="body2"
                      >
                        BUDDI
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Table>
                        <TableBody>
                          {buddiRows.map(row =>
                            row !== null ? (
                              <TableRow key={row.title} className={classes.hideLastBorder}>
                                <TableCell
                                  style={{ verticalAlign: "top", width: "40%" }}
                                  className="font-weight-bold"
                                  scope="row"
                                >
                                  {row.title}
                                </TableCell>
                                <TableCell style={{ width: "60%" }}>{row.value}</TableCell>
                              </TableRow>
                            ) : null
                          )}
                        </TableBody>
                      </Table>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
