/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { action } from "typesafe-actions";
import { RoleResponse } from "../../models/roleResponses";
import * as actions from "../constants/roleActionTypes";

export function getAllRoles() {
  return action(actions.GET_ALL_ROLES);
}

export function getAllRolesSuccess(roles: RoleResponse[]) {
  return action(actions.GET_ALL_ROLES_SUCCESS, roles);
}

export function getAllRolesError(error: Error) {
  return action(actions.GET_ALL_ROLES_ERROR, error);
}

export function getCurrentUserRoles() {
  return action(actions.GET_CURRENT_USER_ROLES);
}

export function getCurrentUserRolesSuccess(roles: RoleResponse[]) {
  return action(actions.GET_CURRENT_USER_ROLES_SUCCESS, roles);
}

export function getCurrentUserRolesError(error: Error) {
  return action(actions.GET_CURRENT_USER_ROLES_ERROR, error);
}
