// eslint-disable-next-line no-shadow
export enum ProvisioningOperationKeys {
  OctopusProject = "OctopusProject",
  DevOpsTeam = "DevOpsTeam",
  DevOpsTeamPermissions = "DevOpsTeamPermissions",
  DevOpsBuildPipeline = "DevOpsBuildPipeline",
  DevOpsBuildPipelinePermissions = "DevOpsBuildPipelinePermissions",
  DevOpsReleasePipeline = "DevOpsReleasePipeline",
  DevOpsReleasePipelinePermissions = "DevOpsReleasePipelinePermissions",
  DevOpsGitRepo = "DevOpsGitRepo",
  ScorecardAssociation = "ScorecardAssociation",
  OperationalTestService = "OperationalTestService",
  OperationalTestSchedule = "OperationalTestSchedule",
  SeqKey = "SeqKey",
  EscalationContacts = "EscalationContacts"
}
