import * as React from "react";
import * as Redux from "redux";
import { connect, ConnectedProps } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { useSnackbar } from "notistack";
import { Grid, Button } from "@material-ui/core";
import { ApplicationState } from "../../../redux/reducers";
import ContactsEditTable from "./ContactsEditTable";
import { SharePointContactRequest } from "../../../models/sharePointRequests";
import { showErrorSnackbar, showSuccessSnackbar } from "../../Snackbars/Snackbars";
import { silentUpdateSharePointContactsDispatch } from "../../../services/SharePointService";
import DefinitionEdit from "./DefinitionEdit";
import { Confirmation } from "../../Dialogs/Confirmation";
import { SharePointUserResponse, SharePointContacts } from "../../../models";
import {
  SharePointContactsInfoViewModel,
  SharePointContactViewModel,
  getSharePointUserByName
} from "./types";

function createRequest(
  contacts: { [name: string]: SharePointContactViewModel },
  definition: string,
  users: SharePointUserResponse[]
): SharePointContactRequest {
  const responseContacts: SharePointContacts = {
    primaryContactUS: {
      phone: contacts.primaryContactUS.phone,
      userId: getSharePointUserByName(users, contacts.primaryContactUS.name)?.id
    },
    primaryContactEU: {
      phone: contacts.primaryContactEU.phone,
      userId: getSharePointUserByName(users, contacts.primaryContactEU.name)?.id
      },
    primaryContactAsia: {
        phone: contacts.primaryContactAsia.phone,
        userId: getSharePointUserByName(users, contacts.primaryContactAsia.name)?.id
    },
    primaryDev: {
      phone: contacts.primaryDev.phone,
      userId: getSharePointUserByName(users, contacts.primaryDev.name)?.id
    },
    secondaryDev: {
      phone: contacts.secondaryDev.phone,
      userId: getSharePointUserByName(users, contacts.secondaryDev.name)?.id
    },
    devManager: {
      phone: contacts.devManager.phone,
      userId: getSharePointUserByName(users, contacts.devManager.name)?.id
    },
    productManager: {
      phone: contacts.productManager.phone,
      userId: getSharePointUserByName(users, contacts.productManager.name)?.id
    },
    supportManager: {
      phone: contacts.supportManager.phone,
      userId: getSharePointUserByName(users, contacts.supportManager.name)?.id
    },
    secondarySupport: {
      phone: contacts.secondarySupport.phone,
      userId: getSharePointUserByName(users, contacts.secondarySupport.name)?.id
    }
  };

  return { definition, contacts: responseContacts };
}

interface ContactsEditProps {
  contactsInfo: SharePointContactsInfoViewModel;
  onExit: () => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ContactsEditProps;

const ContactsEdit = (props: Props): JSX.Element => {
  const { onExit, contactsInfo, updateContacts, sharePointUsers } = props;
  const [contactsInformation, setContactsInformation] = React.useState({
    ...contactsInfo.contacts
  });
  const [definition, setDefinition] = React.useState(contactsInfo.definition);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [confirmationActive, setConfirmationActive] = React.useState(false);
  const [confirmationMessage, setConfirmationMessage] = React.useState("");
  const [confirmationCallback, setConfirmationCallback] =
    React.useState<(() => void) | undefined>(undefined);

  function triggerConfirmationDialog(message: string, callBack: () => void): void {
    setConfirmationCallback(() => (): void => callBack());
    setConfirmationMessage(message);
    setConfirmationActive(true);
  }

  function closeConfirmationDialog(): void {
    setConfirmationActive(false);
  }
  function onSave() {
    updateContacts(
      createRequest(contactsInformation, definition, sharePointUsers.data),
      contactsInfo.id
    )
      .then(() => {
        showSuccessSnackbar(
          enqueueSnackbar,
          closeSnackbar,
          `${contactsInfo.projectName} contacts information updated `
        );
      })
      .catch(error => {
        showErrorSnackbar(
          enqueueSnackbar,
          closeSnackbar,
          `Failed to update ${contactsInfo.projectName} contacts information `
        );
      });
    onExit();
  }

  function onCancel() {
    onExit();
  }

  return (
    <Grid container direction="row" justifyContent="flex-end" spacing={1}>
      <Grid item xs={12}>
        <ContactsEditTable
          initialContactsValues={contactsInformation}
          setChangesHandler={setContactsInformation}
        />
      </Grid>
      <Grid item xs={12}>
        <DefinitionEdit definition={definition} setChangesHandler={setDefinition} />
      </Grid>
      <Grid item>
        <Button color="default" variant="text" onClick={onCancel}>
          Cancel
        </Button>
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="text"
          onClick={() => triggerConfirmationDialog("Are you sure you want to save?", onSave)}
        >
          Save
        </Button>
      </Grid>
      <Grid item>
        <Confirmation
          inProgress={false}
          onClose={closeConfirmationDialog}
          onProceed={(): void => {
            if (confirmationCallback) confirmationCallback();
          }}
          open={confirmationActive}
          message={confirmationMessage}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    sharePointUsers: state.sharePointUsers
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<Promise<void>, ApplicationState, Redux.AnyAction>
) => {
  return {
    updateContacts: (request: SharePointContactRequest, id: number) =>
      dispatch(silentUpdateSharePointContactsDispatch(request, id))
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ContactsEdit);
