import * as Redux from "redux";
import { BUDDIAction } from "../redux/reducers/types";
import * as BUDDIActions from "../redux/actions/BUDDIActions";
import { RequestBuilder } from "../utilities/RequestBuilder";
import {
  BUDDIRegionResponse,
  BUDDIUrlResponse,
  BUDDIRegionalUrlResponse
} from "../models/BUDDIResponses";

export function getBUDDIRegionsDispatch() {
  return async (dispatch: Redux.Dispatch<BUDDIAction>): Promise<void> => {
    dispatch(BUDDIActions.getRegions());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/buddi/regions")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const instances = (await response.json()) as BUDDIRegionResponse[];

      dispatch(BUDDIActions.getRegionsSuccess(instances));
    } catch (error) {
      dispatch(BUDDIActions.getRegionsError(error));
    }
  };
}

export function getBUDDIUrlsDispatch() {
  return async (dispatch: Redux.Dispatch<BUDDIAction>): Promise<void> => {
    dispatch(BUDDIActions.getUrls());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/buddi/url")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const instances = (await response.json()) as BUDDIUrlResponse[];

      dispatch(BUDDIActions.getUrlsSuccess(instances));
    } catch (error) {
      dispatch(BUDDIActions.getUrlsError(error));
    }
  };
}

export function getBUDDIRegionalUrlsDispatch() {
  return async (dispatch: Redux.Dispatch<BUDDIAction>): Promise<void> => {
    dispatch(BUDDIActions.getRegionalUrls());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/buddi/url/regional")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const instances = (await response.json()) as BUDDIRegionalUrlResponse[];

      dispatch(BUDDIActions.getRegionalUrlsSuccess(instances));
    } catch (error) {
      dispatch(BUDDIActions.getRegionalUrlsError(error));
    }
  };
}
