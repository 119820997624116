import * as React from "react";

import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

export function ContactsContainerSkeleton(): JSX.Element {
  return (
      <Grid container direction="column" alignItems="center" justifyContent="center">
      <Grid item xs={3}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
}
