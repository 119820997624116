import * as Redux from "redux";
import * as RoleActions from "../redux/actions/roleActions";
import { RoleAction } from "../redux/reducers/types";
import { RoleResponse } from "../models/roleResponses";
import { RequestBuilder } from "../utilities/RequestBuilder";

export function getAllRolesDispatch() {
  return async (dispatch: Redux.Dispatch<RoleAction>): Promise<void> => {
    dispatch(RoleActions.getAllRoles());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/role")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const roles = (await response.json()) as RoleResponse[];
      dispatch(RoleActions.getAllRolesSuccess(roles));
    } catch (error) {
      dispatch(RoleActions.getAllRolesError(error));
    }
  };
}

export function getCurrentUserRolesDispatch() {
  return async (dispatch: Redux.Dispatch<RoleAction>): Promise<void> => {
    dispatch(RoleActions.getCurrentUserRoles());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/role/current")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const roles = (await response.json()) as RoleResponse[];
      dispatch(RoleActions.getCurrentUserRolesSuccess(roles));
    } catch (error) {
      dispatch(RoleActions.getCurrentUserRolesError(error));
    }
  };
}

export async function getUserRoles(userId: number): Promise<RoleResponse[] | null> {
  const request = new RequestBuilder()
    .setRequestUrl(`api/role/${userId}`)
    .setMethod("GET")
    .build();

  const response = await request.send();
  if (!response.ok) {
    // const error = await response.json();
    throw Error("Could not get user roles for user.");
  }
  return response.json();
}
