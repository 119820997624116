/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import  { useDropzone } from "react-dropzone"; 
import "./Inputs.scss";
import { Typography, IconButton, FormHelperText } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { DragAndDropFieldProps } from "./types";

export function DragAndDropField(props: DragAndDropFieldProps): JSX.Element {
    const {
        disabled,
        dropCallback,
        inProgress,
        message,
        multiple,
        noKeyboard,
        removeCallback,
        diagramFile,
        accept

    } = props;

    const MAX_FILE_SIZE = 100000000;

    function handleRemove(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        acceptedFiles: File[]
    ): void {
        acceptedFiles.splice(0, acceptedFiles.length);
        if (removeCallback) removeCallback();
        event.stopPropagation();
    }

    function renderDefault(): JSX.Element {
        return (
            <div style={{ whiteSpace: "pre-wrap", textAlign: "center", padding: "20px" }}>

                <CloudUploadIcon fontSize="large" />
                <br />

                <Typography color="textSecondary" variant="body2">
                    <div style={{ whiteSpace: "pre-line", textAlign: "center" }}>
                        {diagramFile ? diagramFile.concat("") : message}
                    </div>

                </Typography>
            </div>

        );
    }

    function renderOnDrag(): JSX.Element {
        return (
            <div>
                <Typography color="primary" variant="body2">
                    Drop the file here
                </Typography>
            </div>
            
        );
    }

    function renderOnRead(): JSX.Element {
        return (
            <Typography color="textSecondary" variant="body2">
                Uploading...
            </Typography>
        );
    }

    function renderOnComplete(acceptedFiles: File[]): JSX.Element {
        return (
            <div className="flex space-horizontal-4">
                <Typography color="textSecondary" variant="body2">
                    {acceptedFiles.length > 0 ? acceptedFiles[0].name : ""}
                </Typography>
                <IconButton onClick={(event): void => handleRemove(event, acceptedFiles)} size="small">
                    <ClearIcon />
                </IconButton>
            </div>
        );
    }

    const { getRootProps, getInputProps, acceptedFiles, isDragActive, fileRejections } = useDropzone({
        onDrop: dropCallback,
        multiple: false,
        accept: accept || {
            'image/png': ['.png'],
            'image/jpg': ['.jpg'],
            'image/jpeg': ['.jpeg'],
        },
        disabled: disabled || false,
        noKeyboard: noKeyboard || true,
        maxSize: MAX_FILE_SIZE

    });

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li className = "dropzone-li" >
            <ul>
                {file.size > MAX_FILE_SIZE ? <li className="dropzone-li" >File is larger than 100mb</li> : <></>}
                {errors.map(e => (
                    e.code === 'file-invalid-type' ? <li className="dropzone-li">Specified diagram "{file.name}" could not be uploaded. Allowed types: png, jpg, jpeg</li> : <></>   // eslint-disable-line
                ))}

            </ul>
            
        </li>
    ));

    return (
        <div>
            <section>
                <div className={`dropzone MuiPaper-rounded drag-and-drop-section full-width data-field 
                ${isDragActive || inProgress ? "drag-and-drop-section-on-drag" : ""} 
                ${acceptedFiles.length > 0 && !inProgress ? "drag-and-drop-section-on-success" : ""}
                ${fileRejections.length > 0 ? "border-red-500" : ""}
                `}  {...getRootProps()}>
                    <input  {...getInputProps()} />
                    {isDragActive && renderOnDrag()}
                    {inProgress && renderOnRead()}
                    {acceptedFiles.length > 0 &&
                        !isDragActive &&
                        !inProgress &&
                        renderOnComplete(acceptedFiles)}
                    {acceptedFiles.length === 0 && !isDragActive && !inProgress && renderDefault()}   
                </div>
            </section>            
            <FormHelperText>Maximum upload image size is 100mb & allowed image types are png, jpg, jpeg</FormHelperText>
            <ul>{fileRejectionItems}</ul>  
                 
          </div>

    )
}

    
   
    

   
