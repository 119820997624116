import React from "react";
import "./Inputs.scss";
import { ClickAwayListener } from "@material-ui/core";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { PhoneInputProps } from "./types";

export const PhoneField = React.memo((props: PhoneInputProps) => {
    const {
        dispatch,
        fullWidth,
        handle,
        helperText,
        onChange,
        onlyNumbers,
        value,
        placeholder,
        required,
        error,
        ...other
    } = props;

    const [localValue, setLocalValue] = React.useState<string>(String(value));
    const [valid, setValid] = React.useState<boolean>(true);
    const [localError, setLocalError] = React.useState<boolean>(false);

    React.useEffect(() => {
        setLocalError(error);
    }, [error]);

    const handleChange = (newValue: any) => {
        setValid(matchIsValidTel(localValue));
        if (matchIsValidTel(localValue)) {
            setLocalError(false);
        }
        setLocalValue(newValue);
    };

    function triggerHandle(event: React.MouseEvent<Document, MouseEvent>): void {
        event.preventDefault();
        setValid(matchIsValidTel(localValue));
        if (matchIsValidTel(localValue)) {
            setLocalError(false);
        }
        if (dispatch && handle) dispatch(handle(localValue));
        else if (handle) handle(localValue);
    }


    return (
        <div>
            <ClickAwayListener
                onClickAway={triggerHandle}
                mouseEvent={localValue.length ? "onClick" : false}
            >
                <MuiTelInput
                    {...other}
                    forceCallingCode
                    defaultCountry="US"
                    value={localValue}
                    onChange={handleChange}
                    error={!valid || localError}
                    helperText={!valid ? "Phone number should be in correct format" : null}
                    className="MuiTelInput-TextField"
                    placeholder={placeholder}
                    required={required}
                    size="small"
                    sx={{
                        "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                                borderColor: localError ? "#b71c1c" : "#7149C6",
                            }
                        },
                        "& .MuiInputBase-root .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline .Mui-error": {
                            borderColor: localError || !valid ? "#b71c1c" : ""
                        },
                        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
                            opacity: 1,
                            color: localError || !valid ? "#b71c1c" : "grey"
                        },
                        "& .MuiInputBase-root .MuiFormHelperText-root": {
                            color: "#b71c1c"
                        }
                    }}
                />
            </ClickAwayListener>
        </div>
    )
});