import { RoleState, RoleAction } from "./types";
import { GET_ALL_ROLES, GET_ALL_ROLES_SUCCESS, GET_ALL_ROLES_ERROR } from "../constants";

export const initialRoleState: RoleState = {
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const roleReducer = (state: RoleState = initialRoleState, action: RoleAction): RoleState => {
  switch (action.type) {
    case GET_ALL_ROLES:
      return {
        ...state,
        pending: true
      };
    case GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_ALL_ROLES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};
