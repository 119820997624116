import * as React from "react";
import { useSnackbar } from "notistack";
import { IconButton, Button, FormControl, InputLabel, Select, MenuItem, Box } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { getDeployablesTableColumnsItems, getDeployablesTableRelationsItems } from "./helpers";
import { DeployablesQueryProps, DeployablesQuerySetRow, DeployablesQueryWhereRow } from "./types";
import { DeployablesQueryRequest } from "../../../models/deployablesRequests";
import { showErrorSnackbar, showInformationSnackbar, showSuccessSnackbar } from "../../Snackbars/Snackbars";

import "./Deployables.scss";

export function DeployablesQuery(props: DeployablesQueryProps) {
  const {
    executeDeployablesQuery,
    getDeployablesQueryCount,
    setUpdateColumnWidths,
    darkMode
  } = props;

  const columnItems = getDeployablesTableColumnsItems();
  const relationsItems = getDeployablesTableRelationsItems();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [whereColumnSelections, setWhereColumnSelections] = React.useState<string[]>(
    [""]
  );
  const [whereRelationSelections, setWhereRelationSelections] = React.useState<string[]>(
    [""]
  );
  const [setColumnSelections, setSetColumnSelections] = React.useState<string[]>(
    [""]
  );

  const [whereRows, setWhereRows] = React.useState<DeployablesQueryWhereRow[]>([]);
  const [setRows, setSetRows] = React.useState<DeployablesQuerySetRow[]>([]);

  React.useEffect(() => {
    setWhereRows([{ column: "", relation: "", value: "" }]);
    setSetRows([{ column: "", value: "" }])
  }, [])

  const addWhereTableRow = () => {
    setWhereRows([...whereRows, { column: "", relation: "", value: "" }]);
    setWhereColumnSelections([...whereColumnSelections, ""]);
    setWhereRelationSelections([...whereRelationSelections, ""]);
  }

  const addSetTableRow = () => {
    setSetRows([...setRows, { column: "", value: "" }]);
    setSetColumnSelections([...setColumnSelections, ""]);
  }

  const removeWhereTableRow = (index: number) => {
    setWhereRows((old) => old.filter((row, rowIndex) => rowIndex !== index));
    setWhereColumnSelections((old) => old.filter((row, rowIndex) => rowIndex !== index));
    setWhereRelationSelections((old) => old.filter((row, rowIndex) => rowIndex !== index));
  }

  const removeSetTableRow = (index: number) => {
    setSetRows((old) => old.filter((row, rowIndex) => rowIndex !== index));
    setSetColumnSelections((old) => old.filter((row, rowIndex) => rowIndex !== index))
  }

  const onWhereInputChange = (rowIndex: number, e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
    setWhereRows((old) => old.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...old[rowIndex],
          value: newValue
        };
      }
      return row;
    }));
  }

  const onSetInputChange = (rowIndex: number, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newValue = e.target.value;
    setSetRows((old) => old.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...old[rowIndex],
          value: newValue
        };
      }
      return row;
    }));
  }

  const onWhereColumnSelect = (
    event: React.ChangeEvent<{ value: unknown }>,
    rowIndex: number
  ) => {
    setWhereColumnSelections((old) => old.map((row, index) => {
      if (index === rowIndex) {
        return event.target.value as string;
      }
      return row;
    }));

    setWhereRows((old) => old.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...old[rowIndex],
          column: event.target.value as string
        };
      }
      return row;
    }));
  };

  const onWhereRelationSelect = (
    event: React.ChangeEvent<{ value: unknown }>,
    rowIndex: number
  ) => {
    setWhereRelationSelections((old) => old.map((row, index) => {
      if (index === rowIndex) {
        return event.target.value as string;
      }
      return row;
    }));

    setWhereRows((old) => old.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...old[rowIndex],
          relation: event.target.value as string
        };
      }
      return row;
    }));
  };

  const onSetColumnSelect = (
    event: React.ChangeEvent<{ value: unknown }>,
    rowIndex: number
  ) => {
    setSetColumnSelections((old) => old.map((row, index) => {
      if (index === rowIndex) {
        return event.target.value as string;
      }
      return row;
    }));

    setSetRows((old) => old.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...old[rowIndex],
          column: event.target.value as string
        };
      }
      return row;
    }));
  };

  const validateQueryWhereFields = () => {
    let whereErrors = false;

    whereRows.forEach(row => {
      if (row.column === "" || row.relation === "" || row.value === "")
        whereErrors = true;
    });

    if (whereErrors) {
      showErrorSnackbar(enqueueSnackbar, closeSnackbar, "Not all query \"Where\" fields have values");
      return false;
    }

    return true;
  }

  const validateQuerySetFields = () => {
    let setErrors = false;

    setRows.forEach(row => {
      if (row.column === "")
        setErrors = true;
    });

    if (setErrors) {
      showErrorSnackbar(enqueueSnackbar, closeSnackbar, "Not all query \"Set\" fields have values");
      return false;
    }

    return true;
  }

  const executeQuery = async () => {
    setUpdateColumnWidths(true);

    let whereFieldsValidation = validateQueryWhereFields();
    let setFieldsValidation = validateQuerySetFields();

    if (whereFieldsValidation && setFieldsValidation) {
      let request: DeployablesQueryRequest = { where: [], set: [] };
      request.where = whereRows;
      request.set = setRows;

      let message = await executeDeployablesQuery(request);

      showSuccessSnackbar(enqueueSnackbar, closeSnackbar, message);

      setWhereRows([]);
      setSetRows([]);
    }
  }

  const getQueryAffectedRowCount = async () => {
    if (validateQueryWhereFields()) {
      let request: DeployablesQueryRequest = { where: [], set: [] };
      request.where = whereRows;
      request.set = setRows;

      let message = await getDeployablesQueryCount(request);

      showInformationSnackbar(enqueueSnackbar, closeSnackbar, message);
    }
  }

  const [isQueryTooltipOpen, setIsQueryTooltipOpen] = React.useState(false);
  const [isQueryCountTooltipOpen, setIsQueryCountTooltipOpen] = React.useState(false);

  return (
    <div className="deployablesQueryDiv">
      <div className="deployablesQueryWhereDiv">
        <b>DEPLOYABLES QUERY</b>
        <ClickAwayListener onClickAway={() => setIsQueryTooltipOpen(false)}>
          <Tooltip
            onClose={() => setIsQueryTooltipOpen(false)}
            open={isQueryTooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement="top"
            title={<div style={{ fontSize: `13px` }}>Use queries to bulk update deployables <br /> <b>WHERE</b> will be used to filter rows that will be updated
              <br /> <b>SET</b> will be used to update the selected columns to their new values</div>}>
            <IconButton className="deployablesMinorButton icon-button" onClick={() => setIsQueryTooltipOpen(!isQueryTooltipOpen)}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </ClickAwayListener>
        <br /><br />
        <b>Where: </b>
        <table>
          <tbody>
            {whereRows.map((row, index) => (
              <tr key={`row ${index.toString()}`}>
                <td>{index !== 0 ?
                  <IconButton className="deployablesMinorButton icon-button redColor" onClick={() => removeWhereTableRow(index)}>
                    <RemoveIcon />
                        </IconButton> : <pre>    </pre>} </td>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <td>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="where-column-name-select-label">Column Name</InputLabel>
                      <Select
                        className={`queryColumnDropdown${darkMode ? " darkMode" : ""}`}
                        labelId="where-column-name-select-label"
                        id="where-column-name-select"
                        value={whereColumnSelections[index]}
                        label="Column Name"
                        onChange={(event) => onWhereColumnSelect(event, index)}
                      >
                        {columnItems.map((row, index) => (
                          <MenuItem value={row.id}>{row.text}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </td>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <td>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="where-relation-select-label">Relation</InputLabel>
                      <Select
                        className={`queryRelationDropdown${darkMode ? " darkMode" : ""}`}
                        labelId="where-relation-select-label"
                        id="where-relation-select"
                        value={whereRelationSelections[index]}
                        label="Relation"
                        onChange={(event) => onWhereRelationSelect(event, index)}
                      >
                        {relationsItems.map((row, index) => (
                          <MenuItem value={row.id}>{row.text}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </td>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <td>            
                  <input className={`queryInput${darkMode ? " darkMode" : ""}`} onChange={(e) => onWhereInputChange(index, e)} placeholder="Value" value={whereRows[index].value} />
                </td>
              </tr>))}
            <tr>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <td />
              <td>
                {whereRows.length !== 11 ?
                  <IconButton className="deployablesMinorButton icon-button greenColor" onClick={() => addWhereTableRow()}>
                    <AddIcon />
                  </IconButton> : null}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="deployablesQueryMajorButtonDiv">
          <Button variant="outlined" onClick={() => getQueryAffectedRowCount()}>Get affected row count</Button>
          <ClickAwayListener onClickAway={() => setIsQueryCountTooltipOpen(false)}>
            <Tooltip
              onClose={() => setIsQueryCountTooltipOpen(false)}
              open={isQueryCountTooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement="top"
              title={<div style={{ fontSize: `13px`, top: `200px` }}>Check how many rows will be updated after
                executing the query with provided <b>WHERE</b> values</div>}>
              <IconButton className="deployablesMinorButton icon-button" onClick={() => setIsQueryCountTooltipOpen(!isQueryCountTooltipOpen)}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </ClickAwayListener>
        </div>
      </div>
      <div className="deployablesQuerySetDiv">
        <b>Set: </b>
        <table>
          <tbody>
            {setRows.map((row, index) => (
              <tr key={`row ${index.toString()}`}>
                <td>{index !== 0 ?
                  <IconButton className="deployablesMinorButton icon-button redColor" onClick={() => removeSetTableRow(index)}>
                    <RemoveIcon />
                  </IconButton>
                  : <pre>    </pre>} </td>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <td>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="set-column-name-select-label">Column Name</InputLabel>
                      <Select
                        className={`queryColumnDropdown${darkMode ? " darkMode" : ""}`}
                        labelId="set-column-name-select-label"
                        id="set-column-name-select"
                        value={setColumnSelections[index]}
                        label="Column Name"
                        onChange={(event) => onSetColumnSelect(event, index)}
                      >
                        {columnItems.map((row, index) => (
                          <MenuItem value={row.id}>{row.text}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </td>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <td>
                  <input className={`queryInput${darkMode ? " darkMode" : ""}`} onChange={(e) => onSetInputChange(index, e)} placeholder="Value" value={setRows[index].value} />
                </td>
              </tr>))}
            <tr>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <td />
              <td>
                {setRows.length !== 11 ?
                  <IconButton className="deployablesMinorButton icon-button greenColor" onClick={() => addSetTableRow()}>
                    <AddIcon />
                  </IconButton> : null}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="deployablesQueryMajorButtonDiv"><Button variant="outlined" onClick={() => executeQuery()}>Execute query</Button></div>
      </div>
    </div>
  );
}