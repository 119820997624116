import { AuthConfigurationResponse } from "../models/configurationResponses";

export async function getAuthConfiguration(): Promise<AuthConfigurationResponse> {
  const response = await fetch("api/configuration/auth", { method: "GET" });

  if (!response.ok) {
    throw Error("Failed to retrieve MSAL configuration from backend.");
  }

  const configuration = (await response.json()) as AuthConfigurationResponse;
  return configuration;
}
