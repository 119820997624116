import * as React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CommentHeader from "./CommentHeader";
import CommentText from "./CommentText";
import { Comment } from "./types";

interface IProps {
  comments: Comment[];
  getCommentControlPermissions: (userName: string) => boolean;
  deleteCommentHandler: (id: number) => void;
}

export default function CommentList(props: IProps): JSX.Element {
  const { comments, getCommentControlPermissions, deleteCommentHandler } = props;

  return (
    <>
      <List className="comment-list">
        {comments.map(comment => (
          <ListItem key={comment.id} divider alignItems="flex-start">
            <ListItemText
              primary={
                <CommentHeader
                  author={comment.author}
                  date={comment.createdAt}
                  getCommentControlPermissions={getCommentControlPermissions}
                  deleteCommentHandler={() => deleteCommentHandler(comment.id)}
                />
              }
              secondary={<CommentText text={comment.message} />}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
}
