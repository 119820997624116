/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { action } from "typesafe-actions";

import {
  DevOpsProjectResponse,
  ProductResponse,
  OctopusProjectResponse,
  UserResponse,
  OctopusARMStepResponse,
  ServiceFormResponse
} from "../../../models";
import * as actionType from "./actionTypes";

export function removeArchitectureDiagram() {
  return action(actionType.REMOVE_ARCHITECTURE_DIAGRAM);
}

export function setAdditionalResources(value: OctopusARMStepResponse[]) {
  return action(actionType.SET_ADDITIONAL_RESOURCES, value);
}

export function setDevLeadEmail(value: string | null) {
  return action(actionType.SET_DEV_LEAD_EMAIL, value);
}

export function setDevLeadPhone(value: string) {
  return action(actionType.SET_DEV_LEAD_PHONE, value);
}

export function setDeployableName(value: string) {
  return action(actionType.SET_DEPLOYABLE_NAME, value);
}

export function setDescription(value: string) {
  return action(actionType.SET_DESCRIPTION, value);
}

export function setDevOpsProject(value: DevOpsProjectResponse | null) {
  return action(actionType.SET_DEVOPS_PROJECT, value);
}

export function setDevOpsTeamName(value: string | null) {
  return action(actionType.SET_DEVOPS_TEAM_NAME, value);
}

export function setFinalApprover(value: UserResponse | null) {
  return action(actionType.SET_FINAL_APPROVER, value);
}

export function setMonthlyCostEstimate(value: string) {
  return action(actionType.SET_MONTHLY_COST_ESTIMATE, value);
}

export function setPrimaryDevEmail(value: string | null) {
  return action(actionType.SET_PRIMARY_DEV_EMAIL, value);
}

export function setPrimaryDevPhone(value: string) {
  return action(actionType.SET_PRIMARY_DEV_PHONE, value);
}

export function setPrimaryPeerReviewer(value: UserResponse | null) {
  return action(actionType.SET_PRIMARY_PEER_REVIEWER, value);
}

export function setProduct(value: ProductResponse | null) {
  return action(actionType.SET_PRODUCT, value);
}

export function setProductManagerEmail(value: string | null) {
  return action(actionType.SET_PRODUCT_MANAGER_EMAIL, value);
}

export function setProductManagerPhone(value: string) {
  return action(actionType.SET_PRODUCT_MANAGER_PHONE, value);
}

export function setProjectType(value: OctopusProjectResponse | null) {
  return action(actionType.SET_PROJECT_TYPE, value);
}

export function setQaLeadEmail(value: string | null) {
  return action(actionType.SET_QA_LEAD_EMAIL, value);
}

export function setQaLeadPhone(value: string) {
  return action(actionType.SET_QA_LEAD_PHONE, value);
}

export function setRegion(value: string | null) {
  return action(actionType.SET_REGION, value);
}

export function setSecondaryDevEmail(value: string | null) {
  return action(actionType.SET_SECONDARY_DEV_EMAIL, value);
}

export function setSecondaryDevPhone(value: string) {
  return action(actionType.SET_SECONDARY_DEV_PHONE, value);
}

export function setSecondaryPeerReviewer(value: UserResponse | null) {
  return action(actionType.SET_SECONDARY_PEER_REVIEWER, value);
}

export function setTargetProductionDate(value: Date | null) {
  return action(actionType.SET_TARGET_PRODUCTION_DATE, value);
}

export function setServiceFormState(value: ServiceFormResponse | null) {
  return action(actionType.SET_SERVICE_FORM_STATE, value);
}

export function uploadArchitectureDiagram(fileName: string) {
  return action(actionType.UPLOAD_ARCHITECTURE_DIAGRAM, fileName);
}

export function uploadArchitectureDiagramFinished(content: string) {
  return action(actionType.UPLOAD_ARCHITECTURE_DIAGRAM_FINISHED, content);
}
