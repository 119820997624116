import * as Redux from "redux";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { SharePointAction } from "../redux/reducers/types";
import * as SharePointActions from "../redux/actions/sharePointActions";
import { RequestBuilder } from "../utilities/RequestBuilder";
import {
  SharePointUserResponse,
  SharePointContactResponse,
  SharePointContact
} from "../models/sharePointResponses";
import { SharePointContactRequest } from "../models/sharePointRequests";
import { EscalationContactsRequest } from "../models/sharePointRequest";

import { ApplicationState } from "../redux/reducers";

export function getSharePointUsersDispatch() {
  return async (dispatch: Redux.Dispatch<SharePointAction>): Promise<void> => {
    dispatch(SharePointActions.getSharePointUsers());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/sharepoint/users")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const users = (await response.json()) as SharePointUserResponse[];

      dispatch(SharePointActions.getSharePointUsersSuccess(users));
    } catch (error) {
      dispatch(SharePointActions.getSharePointUsersError(error));
    }
  };
}

export function createEscalationContactsDispatch(body: EscalationContactsRequest) {
  return async (dispatch: Redux.Dispatch<SharePointAction>): Promise<void> => {
    dispatch(SharePointActions.createEscalationContacts());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/sharepoint/escalationcontacts")
        .setMethod("POST")
        .setBody(body)
        .build();
      const response = await request.send();

      if (response.status === 404) {
        throw Error("Deployble name not found.");
      }

      if (!response.ok) {
        throw Error("Failed to create escalation contacts in SharePoint site.");
      }

      dispatch(SharePointActions.createEscalationContactsSuccess());
    } catch (error) {
      dispatch(SharePointActions.createEscalationContactsError(error));
    }
  };
}

export function getSharePointContactsDispatch() {
  return async (dispatch: Redux.Dispatch<SharePointAction>): Promise<void> => {
    dispatch(SharePointActions.getSharePointContacts());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/sharepoint/escalationcontacts")
        .setMethod("GET")
        .build();

      const response = await request.send();

      if (!response.ok)
        throw new Error("Failed to get escalation contacts information from SharePoint site.");

      const contacts = (await response.json()) as SharePointContactResponse[];

      dispatch(SharePointActions.getSharePointContactsSuccess(contacts));
    } catch (error) {
      dispatch(SharePointActions.getSharePointContactsError(error));
    }
  };
}

export function updateSharePointContactsDispatch(body: SharePointContactRequest, id: number) {
  return async (dispatch: Redux.Dispatch<SharePointAction>): Promise<void> => {
    dispatch(SharePointActions.updateSharePointContact());
    try {
      const request = new RequestBuilder()
        .setRequestUrl(`api/sharepoint/escalationcontacts/${id}`)
        .setMethod("PUT")
        .setBody(body)
        .build();

      const response = await request.send();

      if (!response.ok) throw new Error("Failed to update escalation contacts in SharePoint site.");

      const updatedContact = (await response.json()) as SharePointContactResponse;
      dispatch(SharePointActions.updateSharePointContactSuccess(updatedContact));
    } catch (error) {
      dispatch(SharePointActions.updateSharePointContactError(error));
      throw error;
    }
  };
}

export function silentUpdateSharePointContactsDispatch(body: SharePointContactRequest, id: number) {
  return async (dispatch: Redux.Dispatch<SharePointAction>): Promise<void> => {
    dispatch(SharePointActions.silentUpdateSharePointContact(body, id));
    try {
      const request = new RequestBuilder()
        .setRequestUrl(`api/sharepoint/escalationcontacts/${id}`)
        .setMethod("PUT")
        .setBody(body)
        .build();

      const response = await request.send();

      if (!response.ok) throw new Error("Failed to update escalation contacts in SharePoint site.");

      // const updatedContact = (await response.json()) as SharePointContactResponse;

      dispatch(SharePointActions.silentUpdateSharePointSuccess());
    } catch (error) {
      dispatch(SharePointActions.silentUpdateSharePointError(error));
      throw error;
    }
  };
}
