import { combineReducers, Reducer } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import { userReducer, initialUserState } from "./userReducer";
import { roleReducer, initialRoleState } from "./roleReducer";
import { productReducer, initalProductState } from "./productReducer";
import {
  azureDevOpsTeamReducer,
  azureDevOpsProjectReducer,
  initialAzureDevOpsProjectState,
  initialAzureDevOpsTeamState,
  initialAzureDevOpsEnvironmentsState,
  azureDevOpsReleaseEnvironmentsReducer,
  createAzureDevOpsTeamReducer,
  azureDevOpsBuildPipelineReducer,
  azureDevOpsReleasePipelineReducer,
  initialAzureDevOpsTeamToCreateState,
  initialAzureDevOpsBuildPipelineToCreateState,
  initialAzureDevOpsReleasePipelineToCreateState,
  azureDevOpsGitRepoReducer,
  initialAzureDevOpsGitRepoState,
  setAzureDevOpsTeamPermissionsReducer,
  initialAzureDevOpsTeamPermissionsToAddState,
  initialAzureDevOpsBuildPipelinePermissionsToAddState,
  initialAzureDevOpsReleasePipelinePermissionsToAddState,
  setAzureDevOpsBuildPipelinePermissionsReducer,
  setAzureDevOpsReleasePipelinePermissionsReducer
} from "./azureDevOpsReducer";
import {
  initialScorecardAssociationState,
  scorecardAssociationReducer,
  scorecardCategoryReducer,
  initialScorecardCategoryState,
  initialScorecardSpecificationState,
  scorecardSpecificationReducer,
  scorecardProjectReducer,
  initialScorecardProjectState
} from "./scorecardReducer";
import {
  initialOperationalTestsServiceToCreateState,
  operationalTestsServiceToCreateReducer,
  initialOperationalTestsScheduleToCreateState,
  operationalTestsScheduleToCreateReducer,
  initialOperationalTestsServicesState,
  operationalTestsServicesReducer
} from "./operationalTestsReducer";
import {
  sharePointUserReducer,
  sharePointContactsReducer,
  initialSharePointUserState,
  initialSharePointContactState,
  EscalationContactsReducer,
  initialEscalationContactsState
} from "./sharePointReducer";
import {
  BUDDIRegionsReducer,
  initialBUDDIRegionState,
  initialBUDDIUrlState,
  initialBUDDIRegionalUrlState,
  BUDDIUrlReducer,
  BUDDIRegionalUrlReducer
} from "./BUDDIReducer";
import { configurationRegionsReducer, initialRegionsState } from "./configurationReducer";
import { serviceFormReducer, initialServiceFormState } from "./serviceFormReducer";
import { deployablesReducer, initialDeployablesState } from "./deployablesReducer";
import {
  octopusTemplateProjectReducer,
  octopusARMStepReducer,
  initialOctopusARMStepsState,
  initialOctopusTemplateProjectsState,
  initialOctopusLibraryState,
  initialOctopusLifecycleState,
  initialOctopusProjectGroupState,
  initialOctopusProjectState,
  octopusLibraryReducer,
  octopusLifecycleReducer,
  octopusProjectReducer,
  octopusProjectGroupReducer,
  initialOctopusProjectToCreateState,
  octopusProjectCreationReducer
} from "./octopusReducer";

import {
  OctopusARMStepState,
  AzureDevOpsProjectState,
  OctopusTemplateProjectState,
  ProductState,
  RoleState,
  SharePointUserState,
  AzureDevOpsTeamState,
  UserState,
  ServiceFormState,
  OctopusLifecycleState,
  OctopusProjectState,
  OctopusProjectGroupState,
  OctopusLibraryState,
  BUDDIRegionState,
  RegionState,
  ScorecardCategoryState,
  ScorecardSpecificationState,
  ScorecardProjectState,
  OperationaTestsServiceState,
  AzureDevOpsReleaseEnvironmentsState,
  BUDDIRegionalUrlState,
  BUDDIUrlState,
  OctopusProjectToCreateState,
  AzureDevOpsTeamToCreateState,
  AzureDevOpsBuildPipelineToCreateState,
  AzureDevOpsReleasePipelineToCreateState,
  ScorecardAssociationState,
  OperationaTestsServiceToCreateState,
  OperationaTestsScheduleToCreateState,
  AzureDevOpsGitRepoState,
  AzureDevOpsTeamPermissionsToAddState,
  AzureDevOpsBuildPipelinePermissionsToAddState,
  AzureDevOpsReleasePipelinePermissionsToAddState,
  SeqKeysToCreateState,
  SharePointContactState,
  DevOpsEscalationContactsState,
  DeployablesState
} from "./types";

import { initialSeqKeysToCreateState, createSeqKeyReducer } from "./seqReducer";

const initialRouterState: RouterState = {
  action: "PUSH",
  location: {
    hash: "",
    pathname: "",
    search: "",
    state: "",
    key: "",
    query: {}
  }
};

export interface ApplicationState {
  azureDevOpsProjects: AzureDevOpsProjectState;
  azureDevOpsTeams: AzureDevOpsTeamState;
  azureDevOpsReleaseEnvironments: AzureDevOpsReleaseEnvironmentsState;
  deployables: DeployablesState;
  octopusARMSteps: OctopusARMStepState;
  octopusLibraries: OctopusLibraryState;
  octopusLifecycles: OctopusLifecycleState;
  octopusProjects: OctopusProjectState;
  octopusProjectGroups: OctopusProjectGroupState;
  octopusTemplateProjects: OctopusTemplateProjectState;
  operationalTestsServices: OperationaTestsServiceState;
  products: ProductState;
  roles: RoleState;
  router: RouterState;
  sharePointUsers: SharePointUserState;
  serviceForms: ServiceFormState;
  users: UserState;
  buddiRegions: BUDDIRegionState;
  regions: RegionState;
  scorecardCategories: ScorecardCategoryState;
  scorecardSpecifications: ScorecardSpecificationState;
  scorecardProjects: ScorecardProjectState;
  buddiUrls: BUDDIUrlState;
  buddiRegionalUrls: BUDDIRegionalUrlState;
  octopusProjectToCreateState: OctopusProjectToCreateState;
  devOpsGitRepoState: AzureDevOpsGitRepoState;
  devOpsTeamToCreateState: AzureDevOpsTeamToCreateState;
  devOpsTeamPermissionsToAddState: AzureDevOpsTeamPermissionsToAddState;
  devOpsBuildPipelineToCreateState: AzureDevOpsBuildPipelineToCreateState;
  devOpsBuildPipelinePermissionsToAddState: AzureDevOpsBuildPipelinePermissionsToAddState;
  devOpsReleasePipelineToCreateState: AzureDevOpsReleasePipelineToCreateState;
  devOpsReleasePipelinePermissionsToAddState: AzureDevOpsReleasePipelinePermissionsToAddState;
  scorecardAssociationState: ScorecardAssociationState;
  operationaTestsServiceToCreateState: OperationaTestsServiceToCreateState;
  operationaTestsScheduleToCreateState: OperationaTestsScheduleToCreateState;
  seqKeysToCreateState: SeqKeysToCreateState;
  sharePointContacts: SharePointContactState;
  devOpsEscalationContactsState : DevOpsEscalationContactsState;
}

export const initialApplicationState: ApplicationState = {
  azureDevOpsProjects: initialAzureDevOpsProjectState,
  azureDevOpsTeams: initialAzureDevOpsTeamState,
  azureDevOpsReleaseEnvironments: initialAzureDevOpsEnvironmentsState,
  deployables: initialDeployablesState,
  octopusARMSteps: initialOctopusARMStepsState,
  octopusLibraries: initialOctopusLibraryState,
  octopusLifecycles: initialOctopusLifecycleState,
  octopusProjects: initialOctopusProjectState,
  octopusProjectGroups: initialOctopusProjectGroupState,
  octopusTemplateProjects: initialOctopusTemplateProjectsState,
  operationalTestsServices: initialOperationalTestsServicesState,
  products: initalProductState,
  roles: initialRoleState,
  router: initialRouterState,
  sharePointUsers: initialSharePointUserState,
  serviceForms: initialServiceFormState,
  users: initialUserState,
  buddiRegions: initialBUDDIRegionState,
  regions: initialRegionsState,
  scorecardCategories: initialScorecardCategoryState,
  scorecardSpecifications: initialScorecardSpecificationState,
  scorecardProjects: initialScorecardProjectState,
  buddiUrls: initialBUDDIUrlState,
  buddiRegionalUrls: initialBUDDIRegionalUrlState,
  octopusProjectToCreateState: initialOctopusProjectToCreateState,
  devOpsGitRepoState: initialAzureDevOpsGitRepoState,
  devOpsTeamToCreateState: initialAzureDevOpsTeamToCreateState,
  devOpsTeamPermissionsToAddState: initialAzureDevOpsTeamPermissionsToAddState,
  devOpsBuildPipelineToCreateState: initialAzureDevOpsBuildPipelineToCreateState,
  devOpsBuildPipelinePermissionsToAddState: initialAzureDevOpsBuildPipelinePermissionsToAddState,
  devOpsReleasePipelineToCreateState: initialAzureDevOpsReleasePipelineToCreateState,
  devOpsReleasePipelinePermissionsToAddState:
    initialAzureDevOpsReleasePipelinePermissionsToAddState,
  scorecardAssociationState: initialScorecardAssociationState,
  operationaTestsServiceToCreateState: initialOperationalTestsServiceToCreateState,
  operationaTestsScheduleToCreateState: initialOperationalTestsScheduleToCreateState,
  seqKeysToCreateState: initialSeqKeysToCreateState,
  sharePointContacts: initialSharePointContactState,
  devOpsEscalationContactsState: initialEscalationContactsState
};

export function createRootReducer(history: History): Reducer {
  return combineReducers({
    azureDevOpsProjects: azureDevOpsProjectReducer,
    azureDevOpsTeams: azureDevOpsTeamReducer,
    azureDevOpsReleaseEnvironments: azureDevOpsReleaseEnvironmentsReducer,
    deployables: deployablesReducer,
    octopusARMSteps: octopusARMStepReducer,
    octopusLibraries: octopusLibraryReducer,
    octopusLifecycles: octopusLifecycleReducer,
    octopusProjects: octopusProjectReducer,
    octopusProjectGroups: octopusProjectGroupReducer,
    octopusTemplateProjects: octopusTemplateProjectReducer,
    operationalTestsServices: operationalTestsServicesReducer,
    products: productReducer,
    roles: roleReducer,
    router: connectRouter(history),
    sharePointUsers: sharePointUserReducer,
    serviceForms: serviceFormReducer,
    users: userReducer,
    buddiRegions: BUDDIRegionsReducer,
    regions: configurationRegionsReducer,
    scorecardCategories: scorecardCategoryReducer,
    scorecardSpecifications: scorecardSpecificationReducer,
    scorecardProjects: scorecardProjectReducer,
    buddiUrls: BUDDIUrlReducer,
    buddiRegionalUrls: BUDDIRegionalUrlReducer,
    octopusProjectToCreateState: octopusProjectCreationReducer,
    devOpsGitRepoState: azureDevOpsGitRepoReducer,
    devOpsTeamToCreateState: createAzureDevOpsTeamReducer,
    devOpsTeamPermissionsToAddState: setAzureDevOpsTeamPermissionsReducer,
    devOpsBuildPipelineToCreateState: azureDevOpsBuildPipelineReducer,
    devOpsBuildPipelinePermissionsToAddState: setAzureDevOpsBuildPipelinePermissionsReducer,
    devOpsReleasePipelineToCreateState: azureDevOpsReleasePipelineReducer,
    devOpsReleasePipelinePermissionsToAddState: setAzureDevOpsReleasePipelinePermissionsReducer,
    scorecardAssociationState: scorecardAssociationReducer,
    operationaTestsServiceToCreateState: operationalTestsServiceToCreateReducer,
    operationaTestsScheduleToCreateState: operationalTestsScheduleToCreateReducer,
    seqKeysToCreateState: createSeqKeyReducer,
    sharePointContacts: sharePointContactsReducer,
    devOpsEscalationContactsState: EscalationContactsReducer
  });
}
