import * as React from "react";

import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";

import { ConfirmationProps } from "./types";

export function Confirmation(props: ConfirmationProps): JSX.Element {
  const { inProgress, onClose, onProceed, open, message } = props;

  const [proceedStarted, setProceedStarted] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!inProgress && proceedStarted) {
      setProceedStarted(false);
      onClose();
    }
  }, [proceedStarted, inProgress]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={(): void => onClose()} variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={inProgress}
          onClick={(): void => {
            setProceedStarted(true);
            if (onProceed) onProceed();
          }}
          variant="outlined"
        >
          {inProgress ? "Proceeding..." : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
