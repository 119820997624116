import "./FormDetails.scss";

import * as React from "react";

import { Divider, Typography } from "@material-ui/core";

import { DetailSectionTitleProps } from "./types";

export function DetailSectionTitle(props: DetailSectionTitleProps): JSX.Element {
  const { uppercase, title } = props;

  return (
    <div>
      <Typography
        className={`${uppercase ? "detail-section-title-u" : "detail-section-title"}`}
        color="textSecondary"
        variant="body2"
      >
        {title}
      </Typography>
      {uppercase ? <Divider className="divider details-divider" /> : <div className="divider" />}
    </div>
  );
}
