export const GET_ALL_TEAMS = "GET_ALL_TEAMS";
export const GET_ALL_TEAMS_SUCCESS = "GET_ALL_TEAMS_SUCCESS";
export const GET_ALL_TEAMS_ERROR = "GET_ALL_TEAMS_ERROR";

export const GET_ALL_ADO_PROJECTS = "GET_ALL_ADO_PROJECTS";
export const GET_ALL_ADO_PROJECTS_SUCCESS = "GET_ALL_ADO_PROJECTS_SUCCESS";
export const GET_ALL_ADO_PROJECTS_ERROR = "GET_ALL_ADO_PROJECTS_ERROR";

export const GET_RELEASE_ENVIRONMENTS = "GET_RELEASE_ENVIRONMENTS";
export const GET_RELEASE_ENVIRONMENTS_SUCCESS = "GET_RELEASE_ENVIRONMENTS_SUCCESS";
export const GET_RELEASE_ENVIRONMENTS_ERROR = "GET_RELEASE_ENVIRONMENTS_ERROR";

export const CREATE_OR_GET_ADO_TEAM = "CREATE_OR_GET_ADO_TEAM";
export const CREATE_OR_GET_ADO_TEAM_SUCCESS = "CREATE_OR_GET_ADO_TEAM_SUCCESS";
export const CREATE_OR_GET_ADO_TEAM_ERROR = "CREATE_OR_GET_ADO_TEAM_ERROR";

export const CREATE_OR_GET_ADO_GIT_REPO = "CREATE_OR_GET_ADO_GIT_REPO";
export const CREATE_OR_GET_ADO_GIT_REPO_SUCCESS = "CREATE_OR_GET_ADO_GIT_REPO_SUCCESS";
export const CREATE_OR_GET_ADO_GIT_REPO_ERROR = "CREATE_OR_GET_ADO_GIT_REPO_ERROR";

export const CREATE_ADO_BUILD_DEFINITION = "CREATE_ADO_BUILD_DEFINITION";
export const CREATE_ADO_BUILD_DEFINITION_SUCCESS = "CREATE_ADO_BUILD_DEFINITION_SUCCESS";
export const CREATE_ADO_BUILD_DEFINITION_ERROR = "CREATE_ADO_BUILD_DEFINITION_ERROR";

export const CREATE_ADO_RELEASE_DEFINITION = "CREATE_ADO_RELEASE_DEFINITION";
export const CREATE_ADO_RELEASE_DEFINITION_SUCCESS = "CREATE_ADO_RELEASE_DEFINITION_SUCCESS";
export const CREATE_ADO_RELEASE_DEFINITION_ERROR = "CREATE_ADO_RELEASE_DEFINITION_ERROR";

export const SET_ADO_TEAM_PERMISSIONS = "SET_ADO_TEAM_PERMISSIONS";
export const SET_ADO_TEAM_PERMISSIONS_SUCCESS = "SET_ADO_TEAM_PERMISSIONS_SUCCESS";
export const SET_ADO_TEAM_PERMISSIONS_ERROR = "SET_ADO_TEAM_PERMISSIONS_ERROR";

export const SET_ADO_BUILD_PIPELINE_PERMISSIONS = "SET_ADO_BUILD_PIPELINE_PERMISSIONS";
export const SET_ADO_BUILD_PIPELINE_PERMISSIONS_SUCCESS =
  "SET_ADO_BUILD_PIPELINE_PERMISSIONS_SUCCESS";
export const SET_ADO_BUILD_PIPELINE_PERMISSIONS_ERROR = "SET_ADO_BUILD_PIPELINE_PERMISSIONS_ERROR";

export const SET_ADO_RELEASE_PIPELINE_PERMISSIONS = "SET_ADO_RELEASE_PIPELINE_PERMISSIONS";
export const SET_ADO_RELEASE_PIPELINE_PERMISSIONS_SUCCESS =
  "SET_ADO_RELEASE_PIPELINE_PERMISSIONS_SUCCESS";
export const SET_ADO_RELEASE_PIPELINE_PERMISSIONS_ERROR =
  "SET_ADO_RELEASE_PIPELINE_PERMISSIONS_ERROR";

export const CREATE_OR_GET_ADO_TEAM_RESET = "CREATE_OR_GET_ADO_TEAM_RESET";
export const CREATE_OR_GET_ADO_GIT_REPO_RESET = "CREATE_OR_GET_ADO_GIT_REPO_RESET";
export const CREATE_ADO_BUILD_DEFINITION_RESET = "CREATE_ADO_BUILD_DEFINITION_RESET";
export const CREATE_ADO_RELEASE_DEFINITION_RESET = "CREATE_ADO_RELEASE_DEFINITION_RESET";
export const SET_ADO_TEAM_PERMISSIONS_RESET = "SET_ADO_TEAM_PERMISSIONS_RESET";
export const SET_ADO_BUILD_PIPELINE_PERMISSIONS_RESET = "SET_ADO_BUILD_PIPELINE_PERMISSIONS_RESET";
export const SET_ADO_RELEASE_PIPELINE_PERMISSIONS_RESET =
  "SET_ADO_RELEASE_PIPELINE_PERMISSIONS_RESET";
