import { BUDDIRegionState, BUDDIAction, BUDDIUrlState, BUDDIRegionalUrlState } from "./types";
import {
  GET_BUDDI_REGIONS,
  GET_BUDDI_REGIONS_SUCCESS,
  GET_BUDDI_REGIONS_ERROR,
  GET_BUDDI_URLS,
  GET_BUDDI_URLS_ERROR,
  GET_BUDDI_URLS_SUCCESS,
  GET_BUDDI_REGIONAL_URLS,
  GET_BUDDI_REGIONAL_URLS_SUCCESS,
  GET_BUDDI_REGIONAL_URLS_ERROR
} from "../constants";

export const initialBUDDIRegionState: BUDDIRegionState = {
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const BUDDIRegionsReducer = (
  state: BUDDIRegionState = initialBUDDIRegionState,
  action: BUDDIAction
): BUDDIRegionState => {
  switch (action.type) {
    case GET_BUDDI_REGIONS:
      return {
        ...state,
        pending: true
      };
    case GET_BUDDI_REGIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_BUDDI_REGIONS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};

export const initialBUDDIUrlState: BUDDIUrlState = {
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const BUDDIUrlReducer = (
  state: BUDDIUrlState = initialBUDDIUrlState,
  action: BUDDIAction
): BUDDIUrlState => {
  switch (action.type) {
    case GET_BUDDI_URLS:
      return {
        ...state,
        pending: true
      };
    case GET_BUDDI_URLS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_BUDDI_URLS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};

export const initialBUDDIRegionalUrlState: BUDDIRegionalUrlState = {
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const BUDDIRegionalUrlReducer = (
  state: BUDDIRegionalUrlState = initialBUDDIRegionalUrlState,
  action: BUDDIAction
): BUDDIRegionalUrlState => {
  switch (action.type) {
    case GET_BUDDI_REGIONAL_URLS:
      return {
        ...state,
        pending: true
      };
    case GET_BUDDI_REGIONAL_URLS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_BUDDI_REGIONAL_URLS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
};
