export const GET_SHAREPOINT_USERS = "GET_SHAREPOINT_USERS";
export const GET_SHAREPOINT_USERS_SUCCESS = "GET_SHAREPOINT_USERS_SUCCESS";
export const GET_SHAREPOINT_USERS_ERROR = "GET_SHAREPOINT_USERS_ERROR";

export const GET_SHAREPOINT_CONTACTS = "GET_SHAREPOINT_CONTACTS";
export const GET_SHAREPOINT_CONTACTS_SUCCESS = "GET_SHAREPOINT_CONTACTS_SUCCESS";
export const GET_SHAREPOINT_CONTACTS_ERROR = "GET_SHAREPOINT_CONTACTS_ERROR";

export const UPDATE_SHAREPOINT_CONTACT = "UPDATE_SHAREPOINT_CONTACT";
export const UPDATE_SHAREPOINT_CONTACT_SUCCESS = "UPDATE_SHAREPOINT_CONTACT_SUCCESS";
export const UPDATE_SHAREPOINT_CONTACT_ERROR = "UPDATE_SHAREPOINT_CONTACT_ERROR";

export const SILENT_UPDATE_SHAREPOINT_CONTACT = "SILENT_UPDATE_SHAREPOINT_CONTACT";
export const SILENT_UPDATE_SHAREPOINT_CONTACT_SUCCESS = "SILENT_UPDATE_SHAREPOINT_CONTACT_SUCCESS";
export const SILENT_UPDATE_SHAREPOINT_CONTACT_ERROR = "SILENT_UPDATE_SHAREPOINT_CONTACT_ERROR";
export const CREATE_ESCALATIONCONTACTS = "CREATE_ESCALATIONCONTACTS";
export const CREATE_ESCALATIONCONTACTS_SUCCESS = "CREATE_ESCALATIONCONTACTS_SUCCESS";
export const CREATE_ESCALATIONCONTACTS_ERROR = "CREATE_ESCALATIONCONTACTS_ERROR";
