export const SET_ADDITIONAL_RESOURCES = "SET_ADDITIONAL_RESOURCES";
export const SET_DEPLOYABLE_NAME = "SET_DEPLOYABLE_NAME";
export const SET_IS_OCTOPUS_REQUIRED = "SET_IS_OCTOPUS_REQUIRED";
export const SET_DEVOPS_PROJECT = "SET_DEVOPS_PROJECT";
export const SET_DEVOPS_TEAM_NAME = "SET_DEVOPS_TEAM_NAME";
export const SET_GIT_REPO_NAME = "SET_GIT_REPO_NAME";
export const SET_IS_BUILD_PIPELINE_REQUIRED = "SET_IS_BUILD_PIPELINE_REQUIRED";
export const SET_IS_CLONE_ENABLED = "SET_IS_CLONE_ENABLED";
export const SET_IS_GIT_REPO_REQUIRED = "SET_IS_GIT_REPO_REQUIRED";
export const SET_IS_RELEASE_PIPELINE_REQUIRED = "SET_IS_RELEASE_PIPELINE_REQUIRED";
export const SET_IS_ESCALATION_CONTACTS_REQUIRED = "SET_IS_ESCALATION_CONTACTS_REQUIRED";
export const SET_LIFECYCLE = "SET_LIFECYCLE";
export const SET_OCTOPUS_PROJECT = "SET_OCTOPUS_PROJECT";
export const SET_PROJECT_GROUP = "SET_PROJECT_GROUP";
export const SET_PROJECT_TYPE = "SET_PROJECT_TYPE";
export const SET_REGION = "SET_REGION";
export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";
export const SET_IS_SCORECARD_REQUIRED = "SET_IS_SCORECARD_REQUIRED";
export const SET_SCORECARD_CATEGORY = "SET_SCORECARD_CATEGORY";
export const SET_GPR_PRODUCT = "SET_GPR_PRODUCT";
export const SET_SCORECARD_PRODUCT = "SET_SCORECARD_PRODUCT";
export const SET_SCORECARD_PROJECT = "SET_SCORECARD_PROJECT";
export const SET_PRODUCT_TYPE = "SET_PRODUCT_TYPE";
export const SET_RELEASE_TYPE = "SET_RELEASE_TYPE";
export const SET_PRODUCT_MANAGER_EMAIL = "SET_PRODUCT_MANAGER_EMAIL";
export const SET_DEV_LEAD_EMAIL = "SET_DEV_LEAD_EMAIL";
export const SELECT_VALIDATOR = "SELECT_VALIDATOR";
export const SET_IS_OPERATIONAL_TESTS_REQUIRED = "SET_IS_OPERATIONAL_TESTS_REQUIRED";
export const SET_OPERATIONAL_TESTS_SERVICE = "SET_OPERATIONAL_TESTS_SERVICE";
export const SET_OPERATIONAL_TESTS_TARGET_ENVIRONMENT = "SET_OPERATIONAL_TESTS_TARGET_ENVIRONMENT";
export const SET_OPERATIONAL_TESTS_DEPENDENT_ENVIRONMENT = "SET_OPERATIONAL_TESTS_DEPENDENT_ENVIRONMENT";
export const SET_OPERATIONAL_TESTS_CRONTAB_EXPRESSION = "SET_OPERATIONAL_TESTS_CRONTAB_EXPRESSION";
export const SET_OPERATIONAL_TESTS_TIMEOUT = "SET_OPERATIONAL_TESTS_TIMEOUT";
export const SET_OPERATIONAL_TESTS_NOTIFICATIONS_LIST = "SET_OPERATIONAL_TESTS_NOTIFICATIONS_LIST";
export const SET_IS_GEO_REPLICATED = "SET_IS_GEO_REPLICATED";
export const SET_OT_DISABLED = "SET_OT_DISABLED";
export const SET_OT_HIGH_SEVERITY = "SET_OT_HIGH_SEVERITY";
export const SET_IS_SEQ_REQUIRED = "SET_IS_SEQ_REQUIRED";
export const SET_SEQ_KEY_ENVIRONMENTS = "SET_SEQ_KEY_ENVIRONMENTS";
export const SET_IS_BUDDI_REQUIRED = "SET_IS_BUDDI_REQUIRED";
export const SET_BUDDI_URL = "SET_BUDDI_URL";
export const SET_BUDDI_REGIONAL_URL_REQUIRED = "SET_BUDDI_REGIONAL_URL_REQUIRED";
export const SET_BUDDI_REGIONAL_URL = "SET_BUDDI_REGIONAL_URL";
export const SET_SERVICE_FORM = "SET_SERVICE_FORM";
export const SET_OPERATIONS = "SET_OPERATIONS";
export const SET_BUILD_FOLDER_PATH = "SET_BUILD_FOLDER_PATH";
