const SearchStore = (() => {
    let search = "";

    const setSearch = (newSearch: string) => {
        search = newSearch;
    }

    const getSearch = () => {
        return search;
    }

    return {
        set: setSearch,
        get: getSearch,
    }
})();

export default SearchStore;