import * as React from "react";

import { Grid, Paper, Typography, Divider } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export function ProvisionStepSkeleton(): JSX.Element {
  return (
    <div>
      <Skeleton
        animation="wave"
        className="detail-section-button"
        height={36}
        variant="rect"
        width={435}
      />
      <div className="mt-4">
        <Grid container direction="row" spacing={10}>
          <Grid item xs={4}>
            <Typography
              className="text-uppercase font-weight-bold"
              color="textSecondary"
              variant="body2"
            >
              <Skeleton width="50%" />
            </Typography>
            <Divider className="mt-2 mb-2" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
          </Grid>
          <Grid item xs={4}>
            <Typography
              className="text-uppercase font-weight-bold"
              color="textSecondary"
              variant="body2"
            >
              <Skeleton width="50%" />
            </Typography>
            <Divider className="mt-2 mb-2" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
          </Grid>
          <Grid item xs={4}>
            <Typography
              className="text-uppercase font-weight-bold"
              color="textSecondary"
              variant="body2"
            >
              <Skeleton width="50%" />
            </Typography>
            <Divider className="mt-2 mb-2" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
            <Skeleton animation="wave" height={52} variant="text" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
