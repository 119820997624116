import { action } from "typesafe-actions";
import { Deployable } from "../../models/deployablesResponses";
import * as actionType from "../constants/deployablesActionTypes";

export function getAllDeployables() {
  return action(actionType.GET_ALL_DEPLOYABLES);
}

export function getAllDeployablesSuccess(value: Deployable[]) {
  return action(actionType.GET_ALL_DEPLOYABLES_SUCCESS, value);
}

export function getAllDeployablesError(error: Error) {
  return action(actionType.GET_ALL_DEPLOYABLES_ERROR, error);
}

export function updateDeployables() {
  return action(actionType.UPDATE_DEPLOYABLES);
}

export function updateDeployablesSuccess(value: Deployable[]) {
  return action(actionType.UPDATE_DEPLOYABLES_SUCCESS, value);
}

export function updateDeployablesError(error: Error) {
  return action(actionType.UPDATE_DEPLOYABLES_ERROR, error);
}

export function executeDeployablesQuery() {
  return action(actionType.EXECUTE_DEPLOYABLES_QUERY);
}

export function executeDeployablesQuerySuccess(value: Deployable[]) {
  return action(actionType.EXECUTE_DEPLOYABLES_QUERY_SUCCESS, value);
}

export function executeDeployablesQueryError(error: Error) {
  return action(actionType.EXECUTE_DEPLOYABLES_QUERY_ERROR, error);
}