export const REMOVE_ARCHITECTURE_DIAGRAM = "REMOVE_ARCHITECTURE_DIAGRAM";
export const SET_ADDITIONAL_RESOURCES = "SET_ADDITIONAL_RESOURCES";
export const SET_DEV_LEAD_EMAIL = "SET_DEV_LEAD_EMAIL";
export const SET_DEV_LEAD_PHONE = "SET_DEV_LEAD_PHONE";
export const SET_DEPLOYABLE_NAME = "SET_DEPLOYABLE_NAME";
export const SET_DESCRIPTION = "SET_DESCRIPTION";
export const SET_DEVOPS_PROJECT = "SET_DEVOPS_PROJECT";
export const SET_DEVOPS_TEAM_NAME = "SET_DEVOPS_TEAM_NAME";
export const SET_FINAL_APPROVER = "SET_FINAL_APPROVER";
export const SET_MONTHLY_COST_ESTIMATE = "SET_MONTHLY_COST_ESTIMATE";
export const SET_PRIMARY_DEV_EMAIL = "SET_PRIMARY_DEV_EMAIL";
export const SET_PRIMARY_DEV_PHONE = "SET_PRIMARY_DEV_PHONE";
export const SET_PRIMARY_PEER_REVIEWER = "SET_PRIMARY_PEER_REVIEWER";
export const SET_PRODUCT = "SET_PRODUCT";
export const SET_PRODUCT_MANAGER_EMAIL = "SET_PRODUCT_MANAGER_EMAIL";
export const SET_PRODUCT_MANAGER_PHONE = "SET_PRODUCT_MANAGER_PHONE";
export const SET_PROJECT_TYPE = "SET_PROJECT_TYPE";
export const SET_QA_LEAD_EMAIL = "SET_QA_LEAD_EMAIL";
export const SET_QA_LEAD_PHONE = "SET_QA_LEAD_PHONE";
export const SET_REGION = "SET_REGION";
export const SET_SECONDARY_DEV_EMAIL = "SET_SECONDARY_DEV_EMAIL";
export const SET_SECONDARY_DEV_PHONE = "SET_SECONDARY_DEV_PHONE";
export const SET_SECONDARY_PEER_REVIEWER = "SET_SECONDARY_PEER_REVIEWER";
export const SET_SERVICE_FORM_STATE = "SET_SERVICE_FORM_STATE";
export const SET_TARGET_PRODUCTION_DATE = "SET_TARGET_PRODUCTION_DATE";
export const UPLOAD_ARCHITECTURE_DIAGRAM = "UPLOAD_ARCHITECTURE_DIAGRAM";
export const UPLOAD_ARCHITECTURE_DIAGRAM_FINISHED = "UPLOAD_ARCHITECTURE_DIAGRAM_FINISHED";
