import * as Redux from "redux";
import { ScorecardAction } from "../redux/reducers/types";
import * as ScorecardActions from "../redux/actions/scorecardActions";
import { RequestBuilder } from "../utilities";
import {
  ScorecardCategoryResponse,
  ScorecardSpecificationResponse,
  ScorecardProductResponse,
  ScorecardItemResponse,
  ScorecardProjectResponse
} from "../models/scorecardResponses";
import { ScorecardAssociationRequest } from "../models/scorecardRequests";

export function getAllScorecardCategoriesDispatch() {
  return async (dispatch: Redux.Dispatch<ScorecardAction>): Promise<void> => {
    dispatch(ScorecardActions.getAllCategories());
    try {
      const request = new RequestBuilder()
        .setRequestUrl(
          '/api/scorecard/categories?filter={"include":{"relation":"items", "scope":{"where":{"and":[{"isDeleted":false}, {"isLatest":true}]}, "include":{"relation":"specGroups", "scope":{"include":{"relation":"specifications"}}}}}}'
        )
        .setMethod("GET")
        .build();

      const response = await request.send();
      const categories = (await response.json()) as ScorecardCategoryResponse[];

      dispatch(ScorecardActions.getAllCategoriesSuccess(categories));
    } catch (error) {
      dispatch(ScorecardActions.getAllCategoriesError(error));
    }
  };
}

export function getAllScorecardSpecificationsDispatch() {
  return async (dispatch: Redux.Dispatch<ScorecardAction>): Promise<void> => {
    dispatch(ScorecardActions.getAllSpecifications());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("/api/scorecard/specifications")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const specifications = (await response.json()) as ScorecardSpecificationResponse[];

      dispatch(ScorecardActions.getAllSpecificationsSuccess(specifications));
    } catch (error) {
      dispatch(ScorecardActions.getAllSpecificationsError(error));
    }
  };
}

export function getAllScorecardProductsDispatch() {
  return async (dispatch: Redux.Dispatch<ScorecardAction>): Promise<void> => {
    dispatch(ScorecardActions.getAllProducts());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("/api/scorecard/products")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const products = (await response.json()) as ScorecardProductResponse[];

      dispatch(ScorecardActions.getAllProductsSuccess(products));
    } catch (error) {
      dispatch(ScorecardActions.getAllProductsError(error));
    }
  };
}

export function getAllScorecardProjectsDispatch() {
  return async (dispatch: Redux.Dispatch<ScorecardAction>): Promise<void> => {
    dispatch(ScorecardActions.getAllProjects());
    try {
      const request = new RequestBuilder()
        .setRequestUrl('/api/scorecard/projects?filter={"where":{"projectName":{"neq":null}}}')
        .setMethod("GET")
        .build();

      const response = await request.send();
      const projects = (await response.json()) as ScorecardProjectResponse[];

      dispatch(ScorecardActions.getAllProjectsSuccess(projects));
    } catch (error) {
      dispatch(ScorecardActions.getAllProjectsError(error));
    }
  };
}

export function createScorecardAssociationDispatch(requestBody: ScorecardAssociationRequest) {
  return async (dispatch: Redux.Dispatch<ScorecardAction>): Promise<void> => {
    dispatch(ScorecardActions.createScorecardAssociation());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("/api/scorecard/associations")
        .setMethod("POST")
        .setBody(requestBody)
        .build();

      const response = await request.send();
      if (!response.ok) {
        const error = await response.json();
        throw Error(JSON.stringify(error.Message));
      }

      dispatch(ScorecardActions.createScorecardAssociationSuccess());
    } catch (error) {
      dispatch(ScorecardActions.createScorecardAssociationError(error));
    }
  };
}

export function scorecardAssociationStateResetDispatch() {
  return async (dispatch: Redux.Dispatch<ScorecardAction>): Promise<void> => {
    dispatch(ScorecardActions.scorecardAssociationToCreateReset());
  };
}
