import React from "react";
import styled from "styled-components";
import { useTable, useFilters, usePagination, useResizeColumns, useBlockLayout } from "react-table";
import { matchSorter } from "match-sorter";
import { useSnackbar } from "notistack";
import { IconButton, Button } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { DeployablesColumnsText } from "../../../constants/deployablesColumns.ts";
import { showSuccessSnackbar } from "../../Snackbars/Snackbars.tsx";

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

let changedRows = [];
let addedRows = [];
let deletedRows = [];

const filters = {
  gprId: '',
  consumerGPRID: '',
  deployable: '',
  productManager: '',
  developmentLead: '',
  projectType: '',
  resourceGroup: '',
  resourceGroupNameBase: '',
  advancementUnit: '',
  environment: '',
  subscriptionName: ''
}

const addChangedRow = (data, columnId, rowIndex, value) => {
  let row = data[rowIndex];

  let alreadyExists = false;
  if (row.rowId < 0) {
    addedRows.forEach(addedRow => {
      if (addedRow.rowId === row.rowId) {
        addedRow[columnId] = value;
        alreadyExists = true;
      }
    })
  }

  changedRows.forEach((changedRow) => {
    if (changedRow.rowId === row.rowId) {
      changedRow[columnId] = value;
      alreadyExists = true;
    }
  });

  if (!alreadyExists) {
    row[columnId] = value;
    changedRows.push(row);
  }
}

const addNewRow = (data, setData) => {
  let newRow = {
    gprId: "New Line",
    consumerGPRID: "",
    deployable: "",
    productManager: "",
    developmentLead: "",
    projectType: "",
    resourceGroup: "",
    resourceGroupNameBase: "",
    advancementUnit: "",
    environment: "",
    subscriptionName: "",
    rowId: ((data.length + 1) * -1)
  }

  addedRows.push(newRow);
  setData([newRow].concat(data));
}

const duplicateRow = (data, setData, rowIndex) => {
  let row = data[rowIndex];

  let newRow = {
    gprId: row.gprId,
    consumerGPRID: row.consumerGPRID,
    deployable: row.deployable,
    productManager: row.productManager,
    developmentLead: row.developmentLead,
    projectType: row.projectType,
    resourceGroup: row.resourceGroup,
    resourceGroupNameBase: row.resourceGroupNameBase,
    advancementUnit: row.advancementUnit,
    environment: row.environment,
    subscriptionName: row.subscriptionName,
    rowId: ((data.length + 1) * -1)
  }

  addedRows.push(newRow);
  setData([newRow].concat(data));
}

const deleteRow = (data, setData, rowIndex, updateDeployablesTablePage, tooltipOpen, setTooltipOpen) => {
  if (!tooltipOpen) {
    setTooltipOpen(true);
  }
  else {
    let row = data[rowIndex];

    let changedIndex = changedRows.indexOf(row);
    if (changedIndex !== -1) changedRows.splice(changedIndex, 1);

    let addedIndex = -1;
    addedRows.forEach((addedRow) => {
      if (addedRow.rowId === row.rowId) addedIndex = addedRows.indexOf(addedRow);
    })
    if (addedIndex !== -1) addedRows.splice(addedIndex, 1);
    else deletedRows.push(row);

    updateDeployablesTablePage();
    setData((old) => old.filter(item => item.rowId !== row.rowId));
    setTooltipOpen(false);
  }
}

function EditableCell({
  value: initialValue,
  row: { index },
  column: { id },
  setData,
  data,
  updateDeployablesTablePage,
  darkMode
}) {
  const [value, setValue] = React.useState(data[index][id]);

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const onChange = (e) => {
    addChangedRow(data, id, index, e.target.value);
    setValue(data[index][id]);
  };

  React.useEffect(() => {
    setValue(data[index][id]);
  }, [data[index][id]]);

  return id === "actions" ?
    <div>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="bottom-start"
          title="Are you sure you want to delete this row?">
          <IconButton className="deployablesMinorButton icon-button" onClick={() => deleteRow(data, setData, index, updateDeployablesTablePage, open, setOpen)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
      <IconButton className="deployablesMinorButton icon-button" title="Duplicate row" onClick={() => duplicateRow(data, setData, index)}>
        <FilterNoneIcon />
      </IconButton>
    </div>
    : <div className="deployablesTableInputDiv">
      <input className={`deployablesTableInput${darkMode ? " darkMode" : ""}`} value={value} onChange={onChange} />
    </div>;
}

function DefaultColumnFilter({
  column: { id, filterValue, preFilteredRows, setFilter },
  darkMode
}) {
  const count = preFilteredRows.length
  const inputClassname = `filterInput${darkMode ? " darkMode" : ""}`;

  if (filterValue !== filters[id] && filters[id] !== "")
    setFilter(filters[id] || undefined);

  return (
    <div className="filterInputDiv">
      <input
        className={inputClassname}
        value={filters[id] || ''}
        onChange={e => {
          filters[id] = e.target.value;
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search ${count} rows`}
      />
    </div>
  )
}

function NullColumnFilter() {
  return ("")
}

const defaultColumn = {
  Cell: EditableCell,
  Filter: DefaultColumnFilter
};

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

fuzzyTextFilterFn.autoRemove = val => !val

export function DeployablesTable({ data, setData, updateDeployablesData, columnWidths, setColumnWidths, updateColumnWidths, setUpdateColumnWidths, darkMode, startingTablePage, setStartingTablePage }) {

  const [deleting, setDeleting] = React.useState(false);

  React.useEffect(() => {
    if (updateColumnWidths) {
      setColumnWidths(old => {
        old.actions = state.columnResizing.columnWidths.actions ?? old.actions;
        old.gprId = state.columnResizing.columnWidths.gprId ?? old.gprId;
        old.consumerGPRID = state.columnResizing.columnWidths.consumerGPRID ?? old.consumerGPRID;
        old.deployable = state.columnResizing.columnWidths.deployable ?? old.deployable;
        old.productManager = state.columnResizing.columnWidths.productManager ?? old.productManager;
        old.developmentLead = state.columnResizing.columnWidths.developmentLead ?? old.developmentLead;
        old.projectType = state.columnResizing.columnWidths.projectType ?? old.projectType;
        old.resourceGroup = state.columnResizing.columnWidths.resourceGroup ?? old.resourceGroup;
        old.resourceGroupNameBase = state.columnResizing.columnWidths.resourceGroupNameBase ?? old.resourceGroupNameBase;
        old.advancementUnit = state.columnResizing.columnWidths.advancementUnit ?? old.advancementUnit;
        old.environment = state.columnResizing.columnWidths.environment ?? old.environment;
        old.subscriptionName = state.columnResizing.columnWidths.subscriptionName ?? old.subscriptionName;
        return old;
      });

      setUpdateColumnWidths(false);
    }
  }, [updateColumnWidths]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const columns = React.useMemo(() =>
    [
      {
        Header: "Actions",
        accessor: "actions",
        Filter: NullColumnFilter,
        width: columnWidths.actions,
        minWidth: columnWidths.actions,
        maxWidth: columnWidths.actions
      },
      {
        Header: DeployablesColumnsText.GprId,
        accessor: "gprId",
        width: columnWidths.gprId * window.innerHeight / 754
      },
      {
        Header: DeployablesColumnsText.ConsumerGprId,
        accessor: "consumerGPRID",
        width: columnWidths.consumerGPRID * window.innerHeight / 754
      },
      {
        Header: DeployablesColumnsText.Deployable,
        accessor: "deployable",
        width: columnWidths.deployable * window.innerHeight / 754
      },
      {
        Header: DeployablesColumnsText.ProductManager,
        accessor: "productManager",
        width: columnWidths.productManager * window.innerHeight / 754
      },
      {
        Header: DeployablesColumnsText.DevelopmentLead,
        accessor: "developmentLead",
        width: columnWidths.developmentLead * window.innerHeight / 754
      },
      {
        Header: DeployablesColumnsText.ProjectType,
        accessor: "projectType",
        width: columnWidths.projectType * window.innerHeight / 754
      },
      {
        Header: DeployablesColumnsText.ResourceGroup,
        accessor: "resourceGroup",
        width: columnWidths.resourceGroup * window.innerHeight / 754
      },
      {
        Header: DeployablesColumnsText.ResourceGroupNameBase,
        accessor: "resourceGroupNameBase",
        width: columnWidths.resourceGroupNameBase * window.innerHeight / 754
      },
      {
        Header: DeployablesColumnsText.AdvancementUnit,
        accessor: "advancementUnit",
        width: columnWidths.advancementUnit * window.innerHeight / 754
      },
      {
        Header: DeployablesColumnsText.Environment,
        accessor: "environment",
        width: columnWidths.environment * window.innerHeight / 754
      },
      {
        Header: DeployablesColumnsText.SubscriptionName,
        accessor: "subscriptionName",
        width: columnWidths.subscriptionName * window.innerHeight / 754
      },
    ], []);

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
    }),
    []
  )

  const updateDeployablesTablePage = () => {
    if (gotoPage) {
      setStartingTablePage(state.pageIndex);
      setDeleting(true);
    }
  }

  const updateDeployables = async () => {
    let request = { added: [], changed: [], deleted: [] };
    request.added = addedRows;
    request.changed = changedRows;
    request.deleted = deletedRows;

    let message = await updateDeployablesData(request);

    setColumnWidths(old => {
      old.actions = state.columnResizing.columnWidths.actions ?? old.actions;
      old.gprId = state.columnResizing.columnWidths.gprId ?? old.gprId;
      old.consumerGPRID = state.columnResizing.columnWidths.consumerGPRID ?? old.consumerGPRID;
      old.deployable = state.columnResizing.columnWidths.deployable ?? old.deployable;
      old.productManager = state.columnResizing.columnWidths.productManager ?? old.productManager;
      old.developmentLead = state.columnResizing.columnWidths.developmentLead ?? old.developmentLead;
      old.projectType = state.columnResizing.columnWidths.projectType ?? old.projectType;
      old.resourceGroup = state.columnResizing.columnWidths.resourceGroup ?? old.resourceGroup;
      old.resourceGroupNameBase = state.columnResizing.columnWidths.resourceGroupNameBase ?? old.resourceGroupNameBase;
      old.advancementUnit = state.columnResizing.columnWidths.advancementUnit ?? old.advancementUnit;
      old.environment = state.columnResizing.columnWidths.environment ?? old.environment;
      old.subscriptionName = state.columnResizing.columnWidths.subscriptionName ?? old.subscriptionName;
      return old;
    });

    showSuccessSnackbar(enqueueSnackbar, closeSnackbar, message);

    addedRows = [];
    changedRows = [];
    deletedRows = [];
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      autoResetPage: true,
      setData,
      darkMode,
      updateDeployablesTablePage
    },
    useFilters,
    usePagination,
    useBlockLayout,
    useResizeColumns
  );

  React.useEffect(() => {
    if (deleting && startingTablePage !== state.pageIndex) {
      gotoPage(startingTablePage);
      setDeleting(false);
    }
  })

  return (
    <Styles>
      <div className="deployablesTableMajorButtonDiv"><Button className="deployablesTableButton" variant="outlined" onClick={() => updateDeployables()}>Update table</Button></div>
      <div className="deployablesTableNewRowButtonDiv"><Button className="deployablesTableButton" variant="outlined" onClick={() => addNewRow(data, setData)}>Add new row</Button></div>
      <div className="deployablesTableDiv">
        <table className="deployablesTable" {...getTableProps()} style={{ fontSize: `${10 * window.innerHeight / 754}px` }}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${column.id} ${column.isResizing ? 'isResizing' : ''
                        }`}
                    />
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button className={`deployablesPaginationButton${darkMode ? " darkMode" : ""}`} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"|<"}
        </button>{" "}
        <button className={`deployablesPaginationButton${darkMode ? " darkMode" : ""}`} onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button className={`deployablesPaginationButton${darkMode ? " darkMode" : ""}`} onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button className={`deployablesPaginationButton${darkMode ? " darkMode" : ""}`} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">|"}
        </button>{" "}
        <span className="paginationInputSpan">
          Page{" "}
          <strong>
            {state.pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
          Go to page:{" "}
          <input
            className={`paginationInput${darkMode ? " darkMode" : ""}`}
            type="number"
            defaultValue={state.pageIndex + 1}
            min={1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </span>{" "}
        <select
          className={`paginationDropdown${darkMode ? " darkMode" : ""}`}
          value={state.pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </Styles>
  );
}