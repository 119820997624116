import * as Msal from "msal";
import { CacheOptions, AuthOptions } from "msal/lib-commonjs/Configuration";
import { getAuthConfiguration } from "./ConfigurationService";
import { AuthContextResponse } from "../types";

function authCallback(error: Error): void {
  if (error) throw Error("Authentication failed.");
}

export async function requestAuthContext(): Promise<AuthContextResponse> {
  if (!window.authContext) {
    const authConfig = await getAuthConfiguration();

    const authOptions: AuthOptions = {
      clientId: authConfig.ClientId,
      authority: authConfig.Instance + authConfig.TenantId,
      redirectUri: authConfig.RedirectUri
    };

    const cacheOptions: CacheOptions = {
      cacheLocation: "localStorage"
    };

    const clientApp = new Msal.UserAgentApplication({
      auth: authOptions,
      cache: cacheOptions
    });

    clientApp.handleRedirectCallback(authCallback);

    const authContext: AuthContextResponse = {
      accessToken: {
        expiresOn: undefined,
        value: undefined
      },
      clientApp,
      defaultScopes: [authConfig.DefaultScope]
    };

    window.authContext = authContext;
  }

  return window.authContext;
}

export async function requestAccessToken(scopeList: string[] = []): Promise<string | undefined> {
  const { accessToken } = window.authContext;

  if (accessToken.value && accessToken.expiresOn) {
    const expiresOnDate = new Date(accessToken.expiresOn);
    const currentTime = new Date();

    if (expiresOnDate.getMilliseconds() - currentTime.getMilliseconds() >= 60000)
      return accessToken.value;
  }

  const authContext = await requestAuthContext();

  let scopes = scopeList;
  if (scopes.length === 0) scopes = authContext.defaultScopes;

  const accessTokenRequest: Msal.AuthenticationParameters = { scopes };
  try {
    const response = await authContext.clientApp.acquireTokenSilent(accessTokenRequest);
    window.authContext.accessToken = {
      expiresOn: response.expiresOn,
      value: response.accessToken
    };

    return window.authContext.accessToken.value;
  } catch (error) {
    return undefined;
  }
}
