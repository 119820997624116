import * as React from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider
} from "@material-ui/core";

import { UserRolesProps } from "./types";

export function UserRoles(props: UserRolesProps): JSX.Element {
  const { onClose, open, userRoles } = props;
  const lastRoleId =
    userRoles?.length !== undefined && userRoles?.length > 0
      ? userRoles[userRoles?.length - 1].id
      : 0;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>My Roles</DialogTitle>
      <DialogContent>
        {userRoles?.length !== undefined && userRoles?.length > 0 ? (
          <List>
            {userRoles?.map(role => (
              <React.Fragment key={role.id}>
                <ListItem>
                  <ListItemText primary={role.fullName} secondary={role.description} />
                </ListItem>
                {role.id !== lastRoleId && <Divider />}
              </React.Fragment>
            ))}
          </List>
        ) : (
          <Typography variant="inherit">Currently you have no roles assigned.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={(): void => onClose()} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
