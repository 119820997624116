import "./Comments.scss";

import * as React from "react";
import SendCommentBox from "./SendCommentBox";
import CommentList from "./CommentList";
import CommentListSkeleton from "./CommentListSkeleton";
import { Comment, CommentValidationSettings, defaultCommentValidationSettings } from "./types";

interface IProps {
  sendHandler: (comment: string) => void;
  getCommentControlPermissions: (userName: string) => boolean;
  deleteCommentHandler: (id: number) => void;
  comments: Comment[];
  isLoading: boolean;
  isSending: boolean;
  validationSettings?: CommentValidationSettings;
}

Comments.defaultProps = {
  validationSettings: defaultCommentValidationSettings
};

export default function Comments(props: IProps): JSX.Element {
  const {
    sendHandler,
    getCommentControlPermissions,
    deleteCommentHandler,
    comments,
    isLoading,
    isSending,
    validationSettings
  } = props;
  const [sortedComments, setSortedComments] = React.useState<Comment[]>([]);
  React.useEffect(() => {
    setSortedComments(
      [...comments].sort((left, right) => left.createdAt.valueOf() - right.createdAt.valueOf())
    );
  }, [comments]);

  return (
    <>
      {isLoading ? (
        <CommentListSkeleton />
      ) : (
        <CommentList
          comments={sortedComments}
          getCommentControlPermissions={getCommentControlPermissions}
          deleteCommentHandler={deleteCommentHandler}
        />
      )}
      <SendCommentBox
        sendHandler={sendHandler}
        isSendButtonDisabled={isLoading || isSending}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        validationSettings={validationSettings!}
      />
    </>
  );
}
