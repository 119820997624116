import * as React from "react";
import { Grid } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { ContactsInfo } from "./ContactsInfo";
import { ZeroData } from "../../ZeroData";
import { ContactsContainerSkeleton } from "./ContactsContainerSkeleton";
import { SharePointContactsInfoViewModel } from "./types";

interface Props {
  contactInfo: SharePointContactsInfoViewModel[];
  pageSize: number;
  loading: boolean;
}

export function ContactsContainer(props: Props): JSX.Element {
  const { contactInfo, pageSize, loading } = props;
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  React.useEffect(() => {
    setCurrentPage(1);
  }, [contactInfo]);

  function handlePaginationChange(event: React.ChangeEvent<unknown>, page: number): void {
    event.preventDefault();
    setCurrentPage(page);
  }

  function renderContent(): JSX.Element {
    if (loading) return <ContactsContainerSkeleton />;
    if (contactInfo.length > 0) {
      return (
        <Grid container direction="row" spacing={1}>
          {contactInfo
            .slice(currentPage * pageSize - pageSize, currentPage * pageSize)
            .map(info => {
              return (
                <Grid key={info.id} item className="contact-row" xs={12}>
                  <ContactsInfo contactInfo={info} />
                </Grid>
              );
            })}
        </Grid>
      );
    }
    return <ZeroData message="No data has been found" />;
  }

  return (
    <Grid container direction="row" spacing={1}>
      {renderContent()}
      {contactInfo.length > 0 ? (
        <Grid item className="contact-row" xs={12}>
          <Pagination
            page={currentPage}
            className="service-form-card-pagination"
            color="secondary"
            count={Math.ceil(contactInfo.length / pageSize)}
            defaultPage={1}
            onChange={handlePaginationChange}
            showFirstButton
            showLastButton
            variant="outlined"
          />
        </Grid>
      ) : null}
    </Grid>
  );
}
