import { SharePointContactResponse, SharePointUserResponse } from "../../../models";

export interface ContactRow {
  role: string;
  name: string;
  phone: string;
}

export interface SharePointContactViewModel {
  name: string;
  phone: string;
}
export interface SharePointContactsInfoViewModel {
  id: number;
  projectName: string;
  productId?: number;
  definition: string;
  contacts: { [name: string]: SharePointContactViewModel };
}

export function mapSharePointContactsInfoViewModel(
  spResponse: SharePointContactResponse,
  usersResponses: SharePointUserResponse[]
): SharePointContactsInfoViewModel {
  return {
    ...spResponse,
    contacts: Object.keys(spResponse.contacts)
      .map(key => ({
        [key]: {
          phone: spResponse.contacts[key]?.phone,
          name:
            getSharePointUserById(usersResponses, spResponse.contacts[key]?.userId ?? -1)?.name ?? ""
        }
      }))
      .reduce((total, current) => ({ ...total, ...current }))
  };
}

export function getSharePointUserById(
  users: SharePointUserResponse[],
  id: number
): SharePointUserResponse | null {
  const selectedUserArray = users.filter(u => u.id === id);

  return selectedUserArray.length === 0 ? null : selectedUserArray[0];
}

export function getSharePointUserByName(
  users: SharePointUserResponse[],
  name: string
): SharePointUserResponse | null {
  const selectedUserArray = users.filter(u => u.name === name);

  return selectedUserArray.length === 0 ? null : selectedUserArray[0];
}
