import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";

export const ContactsTableColumn: MUIDataTableColumn[] = [
  {
    label: "Role",
    name: "role",
    options: {
      setCellProps: () => ({
        style: { minWidth: "150px", maxWidth: "150px" },
        className: "escalationContacts-table-cell"
      }),
      sort: false,
      filter: false
    }
  },
  {
    label: "Name",
    name: "name",
    options: {
      setCellProps: () => ({
        style: { minWidth: "250px", maxWidth: "250px" },
        className: "escalationContacts-table-cell"
      }),
      sort: false,
      filter: false
    }
  },
  {
    label: "Phone number",
    name: "phone",
    options: {
      setCellProps: () => ({
        style: { minWidth: "200px", maxWidth: "200px" },
        className: "escalationContacts-table-cell"
      }),
      sort: false,
      filter: true
    }
  }
];

export const ContactsTableOptions: MUIDataTableOptions = {
  pagination: false,
  selectableRows: "none",
  download: false,
  print: false,
  filter: false,
  elevation: 0,
  viewColumns: false,
  search: false
};

export const DefinitionTableColumn: MUIDataTableColumn[] = [
  {
    label: "Definition",
    name: "definition",
    options: {
      setCellProps: () => ({
        className: "escalationContacts-definition-cell"
      }),
      sort: false,
      filter: false
    }
  }
];

export const DefinitionTableOptions: MUIDataTableOptions = {
  pagination: false,
  selectableRows: "none",
  download: false,
  print: false,
  filter: false,
  elevation: 0,
  viewColumns: false,
  search: false
};
