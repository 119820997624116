import * as React from "react";
import TimeAgo from "react-timeago";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Grid, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";

interface IProps {
  author: string;
  date: Date;
  getCommentControlPermissions: (userName: string) => boolean;
  deleteCommentHandler: () => void;
}

export default function CommentHeader(props: IProps): JSX.Element {
  const {
    author,
    date,
    getCommentControlPermissions: getCommentControl,
    deleteCommentHandler
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleMenuClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function handleServiceFormDelete(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
    event.stopPropagation();
    setAnchorEl(null);
    deleteCommentHandler();
  }

  function handleMenuClose(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();
    setAnchorEl(null);
  }

  return (
    <Grid container direction="row">
      <Grid item xs>
        <Typography variant="body1">{author}</Typography>
      </Grid>
      <Grid item xs style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <TimeAgo date={date.toString()} minPeriod={30} />
      </Grid>
      <Grid
        item
        xs
        style={{
          display: "flex",
          maxWidth: "30px",
          justifyContent: "flex-end",
          alignItems: "center"
        }}
      >
        {getCommentControl(author) && (
          <>
            <IconButton aria-label="more" onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleServiceFormDelete}>Delete</MenuItem>
            </Menu>
          </>
        )}
      </Grid>
    </Grid>
  );
}
