/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { action } from "typesafe-actions";
import { SharePointContactRequest } from "../../models/sharePointRequests";
import {
  SharePointUserResponse,
  SharePointContactResponse,
  EscalationContactsResponse
} from "../../models/sharePointResponses";
import * as actions from "../constants/sharePointActionTypes";

export function getSharePointUsers() {
  return action(actions.GET_SHAREPOINT_USERS);
}

export function getSharePointUsersSuccess(users: SharePointUserResponse[]) {
  return action(actions.GET_SHAREPOINT_USERS_SUCCESS, users);
}

export function getSharePointUsersError(error: Error) {
  return action(actions.GET_SHAREPOINT_USERS_ERROR, error);
}

export function getSharePointContacts() {
  return action(actions.GET_SHAREPOINT_CONTACTS);
}

export function getSharePointContactsSuccess(contacts: SharePointContactResponse[]) {
  return action(actions.GET_SHAREPOINT_CONTACTS_SUCCESS, contacts);
}

export function getSharePointContactsError(error: Error) {
  return action(actions.GET_SHAREPOINT_CONTACTS_ERROR, error);
}

export function updateSharePointContact() {
  return action(actions.UPDATE_SHAREPOINT_CONTACT);
}

export function updateSharePointContactSuccess(contact: SharePointContactResponse) {
  return action(actions.UPDATE_SHAREPOINT_CONTACT_SUCCESS, contact);
}

export function updateSharePointContactError(error: Error) {
  return action(actions.UPDATE_SHAREPOINT_CONTACT_ERROR, error);
}

export function silentUpdateSharePointContact(contact: SharePointContactRequest, id: number) {
  return action(actions.SILENT_UPDATE_SHAREPOINT_CONTACT, { contact, id });
}

export function silentUpdateSharePointSuccess() {
  return action(actions.SILENT_UPDATE_SHAREPOINT_CONTACT_SUCCESS);
}

export function silentUpdateSharePointError(error: Error) {
  return action(actions.SILENT_UPDATE_SHAREPOINT_CONTACT_ERROR, error);
}

export function createEscalationContacts(){
  return action(actions.CREATE_ESCALATIONCONTACTS)
}

export function createEscalationContactsSuccess(){ 
  return action(actions.CREATE_ESCALATIONCONTACTS_SUCCESS)
}

export function createEscalationContactsError(error: Error){
  return action(actions.CREATE_ESCALATIONCONTACTS_ERROR, error)
}



