import * as React from "react";
import { Typography } from "@material-ui/core";

interface IProps {
  text: string;
}

export default function CommentText(props: IProps): JSX.Element {
  const { text } = props;
  return (
    <Typography variant="body2" color="textPrimary" style={{ whiteSpace: 'pre-line', wordWrap: "break-word" }}>
      {text}
    </Typography>
  );
}
