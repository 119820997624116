import * as React from "react";

import { ClickSuppressorProps } from "./types";

export function preventOnClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
  event.stopPropagation();
}

export function preventOnKeyPress(event: React.KeyboardEvent<HTMLDivElement>): void {
  event.stopPropagation();
}

export function ClickSuppressor(props: ClickSuppressorProps): JSX.Element {
  const { children } = props;

  return (
    <div onClick={preventOnClick} onKeyPress={preventOnKeyPress} role="button" tabIndex={0}>
      {children}
    </div>
  );
}
