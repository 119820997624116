/* eslint-disable spaced-comment */
import * as Redux from "redux";
import * as UserActions from "../redux/actions/userActions";
import { UserAction } from "../redux/reducers/types";
import {
  CreateUserRequestBody,
  UserResponse,
  DeleteUserRequestBody,
  UpdateUserRoleRequestBody
} from "../models/userResponses";
import { RequestBuilder } from "../utilities/RequestBuilder";

export async function createUser(body: CreateUserRequestBody): Promise<UserResponse | null> {
  const request = new RequestBuilder()
    .setRequestUrl("api/user")
    .setMethod("POST")
    .setBody(body)
    .build();

  try {
    const response = await request.send();
    if (!response.ok) throw new Error(response.statusText);

    const createdUser = (await response.json()) as UserResponse;
    return createdUser;
  } catch {
    return null;
  }
}

export function deleteUserDispatch(body: DeleteUserRequestBody) {
  return async (dispatch: Redux.Dispatch<UserAction>): Promise<void> => {
    dispatch(UserActions.deleteUser());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/user")
        .setMethod("DELETE")
        .setBody(body)
        .build();

      const response = await request.send();
      if (!response.ok) throw new Error(response.statusText);

      const user = (await response.json()) as UserResponse;
      dispatch(UserActions.deleteUserSuccess(user));
    } catch (error) {
      dispatch(UserActions.deleteUserError(error));
    }
  };
}

export function updateUserRoleDispatch(body: UpdateUserRoleRequestBody) {
  return async (dispatch: Redux.Dispatch<UserAction>): Promise<void> => {
    dispatch(UserActions.updateUserRole());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/user/role")
        .setMethod("PUT")
        .setBody(body)
        .build();
      const response = await request.send();
      if (!response.ok) throw new Error(response.statusText);

      const user = (await response.json()) as UserResponse;
      dispatch(UserActions.updateUserRoleSuccess(user));
    } catch (error) {
      dispatch(UserActions.updateUserRoleError(error));
    }
  };
}

export function setPrimaryUserByRoleNameDispatch(userId: number, roleName: string) {
  return async (dispatch: Redux.Dispatch<UserAction>): Promise<void> => {
    dispatch(UserActions.setPrimaryUserByRoleName());
    try {
      const request = new RequestBuilder()
        .setRequestUrl(`api/user/${userId}/role/${roleName}/primary`)
        .setMethod("POST")
        .build();

      const response = await request.send();
      if (!response.ok) {
        //const error = await response.json();
        throw Error(`Could not set primary user for role ${roleName}.`);
      }

      const user = (await response.json()) as UserResponse;
      dispatch(UserActions.setPrimaryUserByRoleNameSuccess(user));
    } catch (error) {
      dispatch(UserActions.setPrimaryUserByRoleNameError(error));
    }
  };
}

export function getAllUsersDispatch() {
  return async (dispatch: Redux.Dispatch<UserAction>): Promise<void> => {
    dispatch(UserActions.getAllUsers());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/user")
        .setMethod("GET")
        .build();
      const response = await request.send();
      const users = (await response.json()) as UserResponse[];
      dispatch(UserActions.getAllUsersSuccess(users));
    } catch (error) {
      dispatch(UserActions.getAllUsersError(error));
    }
  };
}

export function getAllUsersWithRolesDispatch() {
  return async (dispatch: Redux.Dispatch<UserAction>): Promise<void> => {
    dispatch(UserActions.getAllUsersWithRoles());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/user/role")
        .setMethod("GET")
        .build();
      const response = await request.send();
      const users = (await response.json()) as UserResponse[];
      dispatch(UserActions.getAllUsersWithRolesSuccess(users));
    } catch (error) {
      dispatch(UserActions.getAllUsersWithRolesError(error));
    }
  };
}

export async function getUserById(userId: number): Promise<UserResponse | null> {
  const request = new RequestBuilder()
    .setRequestUrl(`api/user/${userId}`)
    .setMethod("GET")
    .build();

  const response = await request.send();
  if (response.status === 404) return null;
  if (!response.ok) {
    //const error = await response.json();
    throw Error(`Could not get user by id: ${userId} from backend.`);
  }

  const user = (await response.json()) as UserResponse;
  return user;
}

export async function getUserByName(name: string): Promise<UserResponse | null> {
  const request = new RequestBuilder()
    .setRequestUrl(`api/user/name/${name}`)
    .setMethod("GET")
    .build();

  const response = await request.send();
  if (response.status === 404) return null;
  if (!response.ok) {
    //const error = await response.json();
    throw Error(`Could not get user by name: ${name} from backend.`);
  }

  const user = (await response.json()) as UserResponse;
  return user;
}

export async function getUsersByRoleName(roleName: string): Promise<UserResponse | null> {
  const request = new RequestBuilder()
    .setRequestUrl(`api/user/role/${roleName}`)
    .setMethod("GET")
    .build();

  const response = await request.send();
  if (!response.ok) {
    //const error = await response.json();
    throw Error(`Could not get users with role ${roleName} from backend.`);
  }
  return response.json();
}

export async function getPrimaryUserByRoleName(roleName: string): Promise<UserResponse | null> {
  const request = new RequestBuilder()
    .setRequestUrl(`api/user/role/${roleName}/primary`)
    .setMethod("GET")
    .build();

  const response = await request.send();
  if (response.status === 404) return null;
  if (!response.ok) {
    //const error = await response.json();
    throw Error(`Could not get primary user for role ${roleName}.`);
  }
  return response.json();
}
