/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from "react";
import * as Redux from "redux";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core";
import { Page } from "../Page";
import { ApplicationState } from "../../../redux/reducers";
import { getSharePointUsersDispatch, getSharePointContactsDispatch } from "../../../services";
import { ContactsContainer } from "./ContactsContainer";
import { AppContext } from "../../../context/AppContext";
import { Filter } from "./Filter";
import { SharePointContactState, SharePointUserState } from "../../../redux/reducers/types";
import { SharePointContactsInfoViewModel, mapSharePointContactsInfoViewModel } from "./types";

export interface Props {
  sharePointUsers: SharePointUserState;
  sharePointContacts: SharePointContactState;
  getAllSharePointUsers: () => Promise<void>;
  getAllSharePointContacts: () => Promise<void>;
}

function EscalationListPage(props: Props): JSX.Element {
  const { sharePointUsers, getAllSharePointUsers, sharePointContacts, getAllSharePointContacts } =
    props;
  const { setSelectedTab } = React.useContext(AppContext);
  const [loadingContacts, setLoadingContacts] = React.useState<boolean>(true);
  const [search, setSearch] = React.useState<string>("");
  const [searchContactName, setSearchContactName] = React.useState<string | null>(null);
  const [allContactsVM, setContactsViewModel] = React.useState<SharePointContactsInfoViewModel[]>(
    []
  );
  const [filteredContacts, setFilteredContacts] = React.useState<SharePointContactsInfoViewModel[]>(
    []
  );

  React.useEffect(() => {
    if (setSelectedTab) setSelectedTab(2);
    if (!sharePointUsers.pending && !sharePointUsers.completed) getAllSharePointUsers();
    if (!sharePointContacts.pending && !sharePointContacts.completed) getAllSharePointContacts();
  }, []);

  React.useEffect(() => {
    if (sharePointUsers.completed && sharePointContacts.completed) {
      if (!sharePointContacts.error && !sharePointContacts.error) {
        setContactsViewModel(
          sharePointContacts.data
            .map(c => mapSharePointContactsInfoViewModel(c, sharePointUsers.data))
            .sort((a, b) => (b.productId ?? 0) - (a.productId ?? 0))
        );
      } else {
        setLoadingContacts(false);
      }
    }
  }, [sharePointContacts.completed, sharePointContacts.updating, sharePointUsers.completed]);

  React.useEffect(() => {
    if (allContactsVM.length > 0) {
      if (search.length > 1 || searchContactName)
        setFilteredContacts([
          ...allContactsVM
            .filter(
              sf =>
                sf.projectName.toLowerCase().includes(search.toLowerCase()) ||
                sf.productId?.toString().includes(search.toLowerCase()) ||
                `${sf.productId} - ${sf.projectName}`
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                containsName(sf, search)
            )
            .filter(sf => (searchContactName ? containsName(sf, searchContactName ?? "") : true))
        ]);
      else setFilteredContacts([...allContactsVM]);
      setLoadingContacts(false);
    }
  }, [allContactsVM, search, searchContactName]);

  function containsName(
    contactsInfo: SharePointContactsInfoViewModel,
    targetName: string
  ): boolean {
    let contains: boolean[] = Object.keys(contactsInfo.contacts).map(key => {
      return contactsInfo.contacts[key].name
        ? contactsInfo.contacts[key].name.toLowerCase().includes(targetName.toLowerCase())
        : false;
    });
    return contains.reduce((total, value) => total || value);
  }

  return (
    <Page title="Escalation contacts">
      <Paper className="form-paper space-vertical-64" variant="outlined">
        <Filter
          users={sharePointUsers.data}
          usersLoading={sharePointUsers.pending}
          setSearch={setSearch}
          setSearchContactName={setSearchContactName}
        />
        <ContactsContainer contactInfo={filteredContacts} pageSize={10} loading={loadingContacts} />
      </Paper>
    </Page>
  );
}

function mapStateToProps(state: ApplicationState): unknown {
  return {
    sharePointContacts: state.sharePointContacts,
    sharePointUsers: state.sharePointUsers
  };
}

function mapDispatchToProps(dispatch: Redux.Dispatch<Redux.AnyAction>): unknown {
  return Redux.bindActionCreators(
    {
      getAllSharePointUsers: getSharePointUsersDispatch,
      getAllSharePointContacts: getSharePointContactsDispatch
    },
    dispatch
  );
}

export const EscalationList = connect(mapStateToProps, mapDispatchToProps)(EscalationListPage);
