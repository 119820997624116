/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { action } from "typesafe-actions";
import {
  ScorecardCategoryResponse,
  ScorecardSpecificationResponse,
  ScorecardProductResponse,
  ScorecardProjectResponse
} from "../../models/scorecardResponses";
import * as actions from "../constants/scorecardActionTypes";

export function getAllCategories() {
  return action(actions.GET_ALL_CATEGORIES);
}

export function getAllCategoriesSuccess(categories: ScorecardCategoryResponse[]) {
  return action(actions.GET_ALL_CATEGORIES_SUCCESS, categories);
}

export function getAllCategoriesError(error: Error) {
  return action(actions.GET_ALL_CATEGORIES_ERROR, error);
}

export function getAllSpecifications() {
  return action(actions.GET_ALL_SPECIFICATIONS);
}

export function getAllSpecificationsSuccess(specifications: ScorecardSpecificationResponse[]) {
  return action(actions.GET_ALL_SPECIFICATIONS_SUCCESS, specifications);
}

export function getAllSpecificationsError(error: Error) {
  return action(actions.GET_ALL_SPECIFICATIONS_ERROR, error);
}

export function getAllProducts() {
  return action(actions.GET_ALL_SCORECARD_PRODUCTS);
}

export function getAllProductsSuccess(products: ScorecardProductResponse[]) {
  return action(actions.GET_ALL_SCORECARD_PRODUCTS_SUCCESS, products);
}

export function getAllProductsError(error: Error) {
  return action(actions.GET_ALL_SCORECARD_PRODUCTS_ERROR, error);
}

export function getAllProjects() {
  return action(actions.GET_ALL_SCORECARD_PROJECTS);
}

export function getAllProjectsSuccess(projects: ScorecardProjectResponse[]) {
  return action(actions.GET_ALL_SCORECARD_PROJECTS_SUCCESS, projects);
}

export function getAllProjectsError(error: Error) {
  return action(actions.GET_ALL_SCORECARD_PROJECTS_ERROR, error);
}

export function createScorecardAssociation() {
  return action(actions.CREATE_ASSOCIATION);
}

export function createScorecardAssociationSuccess() {
  return action(actions.CREATE_ASSOCIATION_SUCCESS);
}

export function createScorecardAssociationError(error: Error) {
  return action(actions.CREATE_ASSOCIATION_ERROR, error);
}

export function scorecardAssociationToCreateReset() {
  return action(actions.CREATE_ASSOCIATION_RESET);
}
