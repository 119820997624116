/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { action } from "typesafe-actions";
import { UserResponse } from "../../models/userResponses";
import * as actions from "../constants/userActionTypes";

export function deleteUser() {
  return action(actions.DELETE_USER);
}
export function deleteUserSuccess(user: UserResponse) {
  return action(actions.DELETE_USER_SUCCESS, user);
}
export function deleteUserError(error: Error) {
  return action(actions.DELETE_USER_ERROR, error);
}

export function getAllUsers() {
  return action(actions.GET_ALL_USERS);
}
export function getAllUsersSuccess(users: UserResponse[]) {
  return action(actions.GET_ALL_USERS_SUCCESS, users);
}
export function getAllUsersError(error: Error) {
  return action(actions.GET_ALL_USERS_ERROR, error);
}

export function getAllUsersWithRoles() {
  return action(actions.GET_ALL_USERS_WITH_ROLES);
}
export function getAllUsersWithRolesSuccess(users: UserResponse[]) {
  return action(actions.GET_ALL_USERS_WITH_ROLES_SUCCESS, users);
}
export function getAllUsersWithRolesError(error: Error) {
  return action(actions.GET_ALL_USERS_WITH_ROLES_ERROR, error);
}

export function updateUserRole() {
  return action(actions.UPDATE_USER_ROLE);
}
export function updateUserRoleSuccess(user: UserResponse) {
  return action(actions.UPDATE_USER_ROLE_SUCCESS, user);
}
export function updateUserRoleError(error: Error) {
  return action(actions.UPDATE_USER_ROLE_ERROR, error);
}

export function setPrimaryUserByRoleName() {
  return action(actions.SET_PRIMARY_USER);
}
export function setPrimaryUserByRoleNameSuccess(user: UserResponse) {
  return action(actions.SET_PRIMARY_USER_SUCCESS, user);
}
export function setPrimaryUserByRoleNameError(error: Error) {
  return action(actions.SET_PRIMARY_USER_ERROR, error);
}
