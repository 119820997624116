import * as React from "react";

import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";

import { UserResponse } from "../../models";

import { OutlinedAutocomplete } from "../Inputs";
import { PEER_REVIEWER } from "../../constants/userRoles";
import { PeerReviewersProps } from "./types";

export function PeerReviewers(props: PeerReviewersProps): JSX.Element {
  const { onClose, onOK, open, users } = props;

  const [selectedReviewer, setSelectedReviewer] = React.useState<UserResponse | null>(null);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Reassign cloud service setup request</DialogTitle>
      <DialogContent>
        <OutlinedAutocomplete<UserResponse>
          getOptionLabel={(u: UserResponse): string => u.name}
          handle={setSelectedReviewer}
          label="Peer Reviewer"
          options={users.data.filter(u =>
            u.userRole ? u.userRole?.some(r => r.name === PEER_REVIEWER) : false
          )}
          required
          value={selectedReviewer}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={(): void => onOK(selectedReviewer)} variant="outlined">
          OK
        </Button>
        <Button onClick={(): void => onClose()} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
