export const DELETE_SERVICE_FORM = "DELETE_SERVICE_FORM";
export const DELETE_SERVICE_FORM_SUCCESS = "DELETE_SERVICE_FORM_SUCCESS";
export const DELETE_SERVICE_FORM_ERROR = "DELETE_SERVICE_FORM_ERROR";

export const GET_SERVICE_FORMS = "GET_SERVICE_FORMS";
export const GET_SERVICE_FORMS_SUCCESS = "GET_SERVICE_FORMS_SUCCESS";
export const GET_SERVICE_FORMS_ERROR = "GET_SERVICE_FORMS_ERROR";

export const UPDATE_SERVICE_FORM = "UPDATE_SERVICE_FORM";
export const UPDATE_SERVICE_FORM_SUCCESS = "UPDATE_SERVICE_FORM_SUCCESS";
export const UPDATE_SERVICE_FORM_ERROR = "UPDATE_SERVICE_FORM_ERROR";

export const CREATE_SERVICE_FORM = "CREATE_SERVICE_FORM";
export const CREATE_SERVICE_FORM_SUCCESS = "CREATE_SERVICE_FORM_SUCCESS";
export const CREATE_SERVICE_FORM_ERROR = "CREATE_SERVICE_FORM_ERROR";

export const UPDATE_PEER_REVIEWERS = "UPDATE_PEER_REVIEWERS";
export const UPDATE_PEER_REVIEWERS_SUCCESS = "UPDATE_PEER_REVIEWERS_SUCCESS";
export const UPDATE_PEER_REVIEWERS_ERROR = "UPDATE_PEER_REVIEWERS_ERROR";

export const UPDATE_STATUS = "UPDATE_STATUS";
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";
export const UPDATE_STATUS_ERROR = "UPDATE_STATUS_ERROR";

export const UPDATE_PEER_REVIEW_STATUS = "UPDATE_PEER_REVIEW_STATUS";
export const UPDATE_BCSM_REVIEW_STATUS = "UPDATE_BCSM_REVIEW_STATUS";
export const UPDATE_PEER_REVIEW_STATUS_SUCCESS = "UPDATE_PEER_REVIEW_STATUS_SUCCESS";
export const UPDATE_BCSM_REVIEW_STATUS_SUCCESS = "UPDATE_BCSM_REVIEW_STATUS_SUCCESS";
export const UPDATE_BCSM_REVIEW_STATUS_ERROR = "UPDATE_BCSM_REVIEW_STATUS_ERROR";
export const UPDATE_PEER_REVIEW_STATUS_ERROR = "UPDATE_PEER_REVIEW_STATUS_ERROR";
