/* eslint-disable react/no-array-index-key */
import "./Provision.scss";

import * as React from "react";
import * as Redux from "redux";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import {
  Button,
  Paper,
  Stepper,
  Step,
  ThemeProvider,
  Typography,
  StepButton
} from "@material-ui/core";

import { AppContext } from "../../../context/AppContext";
import { textFieldsTheme, darkTextFieldsTheme } from "../../../themes";
import { Page } from "../Page";
import { ServiceBase } from "./ServiceBase";
import { Scorecards } from "./Scorecards";
import { OperationalTests } from "./OperationalTests";
import { Seq } from "./Seq";
import { Buddi } from "./Buddi";
import { ApplicationState } from "../../../redux/reducers";
import { AzureDevOpsYmlPipelineProject } from "../../../constants/deployablesColumns";
import {
  getAllDevOpsProjectsDispatch,
  getAllDevOpsTeamsDispatch,
  getAllTemplateProjectsDispatch,
  getARMStepsDispatch,
  getAllScorecardCategoriesDispatch,
  getAllProductsDispatch,
  getAllScorecardSpecificationsDispatch,
  getAllScorecardProductsDispatch,
  getAllOperationalTestsServicesDispatch,
  getBUDDIRegionsDispatch,
  getRegionsDispatch,
  getAllScorecardProjectsDispatch,
  getBUDDIUrlsDispatch,
  getBUDDIRegionalUrlsDispatch,
  getServiceFormById,
  createScorecardAssociationDispatch,
  updateServiceFormStatusDispatch,
  createEscalationContactsDispatch
} from "../../../services";
import {
  getAllSubscriptionLibrariesDispatch,
  getAllOctopusProjectsDispatch,
  getAllLifecyclesDispatch,
  getAllProjectGroupsDispatch,
  createOctoProjectDispatch,
  cloneOctoProjectDispatch,
  octopusProjectToCreateStateResetDispatch
} from "../../../services/OctopusService";
import { reducer } from "./reducer";
import { initialState, ProvisionProps } from "./types";
import { Finalize } from "./Finalize";
import { ProvisioningOperation, ProvisionProgress } from "./ProvisionProgress";
import * as actions from "./actions";
import { ProvisionStepSkeleton } from "./ProvisionStepSkeleton";
import { ScorecardAssociationRequest } from "../../../models/scorecardRequests";
import {
  CreateOctopusProjectRequest,
  CloneOctopusProjectRequest
} from "../../../models/octopusRequests";
import {
  CreateOrGetADOGitRepoRequest,
  CreateADOBuildDefinitionRequest,
  CreateADOReleaseDefinitionRequest,
  CreateOrGetADOTeamRequest,
  SetAzureDevOpsReleasePipelinePermissionsRequest,
  SetAzureDevOpsDefaultTeamPermissionsRequest,
  SetAzureDevOpsBuildPipelinePermissionsRequest
} from "../../../models/azureDevOpsRequests";
import {
  CreateOperationalTestsScheduleRequest,
  CreateOperationalTestsServiceRequest
} from "../../../models/operationalTestsRequests";
import {
  createOrGetAzureDevOpsGitRepoDispatch,
  createOrGetAzureDevOpsTeamDispatch,
  createAzureDevOpsBuildDefinitionDispatch,
  createAzureDevOpsReleaseDefinitionDispatch,
  setAzureDevOpsDefaultTeamPermissionsDispatch,
  setAzureDevOpsBuildPipelinePermissionsDispatch,
  setAzureDevOpsReleasePipelinePermissionsDispatch,
  devOpsGitRepoStateResetDispatch,
  devOpsTeamToCreateStateResetDispatch,
  devOpsTeamPermissionsToAddStateResetDispatch,
  devOpsBuildPipelineToCreateStateResetDispatch,
  devOpsBuildPipelinePermissionsToAddStateResetDispatch,
  devOpsReleasePipelineToCreateStateResetDispatch,
  devOpsReleasePipelinePermissionsToAddStateResetDispatch
} from "../../../services/AzureDevOpsService";
import { setAzureDevOpsReleasePipelinePermissions } from "../../../redux/actions/azureDevOpsActions";
import {
  createOperationalTestsScheduleDispatch,
  createOperationalTestsServiceDispatch,
  operationaTestsServiceToCreateStateResetDispatch,
  operationaTestsScheduleToCreateStateResetDispatch
} from "../../../services/OperationalTestsService";

import { ProvisioningOperationKeys } from "../../../constants/operationKeys";
import { ADMIN, FORM_CREATOR } from "../../../constants/userRoles";
import { AuthContext } from "../../../context/AuthContext";
import { isAllowed } from "../../Navigation";
import { HOME } from "../../../constants/paths";
import {
  createSeqKeyDispatch,
  seqKeysToCreateStateResetDispatch
} from "../../../services/SeqService";
import { SeqKeysToCreateRequest } from "../../../models/seqRequests";
import { scorecardAssociationStateResetDispatch } from "../../../services/ScorecardService";
import { OctopusARMStepResponse } from "../../../models";
import { EscalationContactsRequest } from "../../../models/sharePointRequest";

function ProvisionPage(props: ProvisionProps): JSX.Element {
  const {
    azureDevOpsProjects,
    azureDevOpsTeams,
    getAllDevOpsProjects,
    getAllDevOpsTeams,
    getAllLifecycles,
    getAllOctopusProjects,
    getAllProjectGroups,
    getAllSubscriptionLibraries,
    getAllTemplateProjects,
    getAllOperationalTestsServices,
    getARMSteps,
    octopusARMSteps,
    octopusLibraries,
    octopusLifecycles,
    octopusProjects,
    octopusProjectGroups,
    octopusTemplateProjects,
    getAllScorecardCategories,
    scorecardCategories,
    getAllScorecardSpecifications,
    scorecardSpecifications,
    getAllProducts,
    products,
    getAllScorecardProjects,
    scorecardProjects,
    operationalTestsServices,
    getAllBuddiRegions,
    buddiRegions,
    getRegions,
    regions,
    getAllBuddiUrls,
    buddiUrls,
    getAllBuddiRegionalUrls,
    buddiRegionalUrls,
    octopusProjectToCreateState,
    createOctoProject,
    cloneOctoProject,
    devOpsGitRepoState,
    createOrGetADOGitRepo,
    devOpsTeamToCreateState,
    createOrGetAzureDevOpsTeam,
    devOpsTeamPermissionsToAddState,
    setAzureDevOpsTeamPermissions,
    devOpsBuildPipelineToCreateState,
    createAzureDevOpsBuildDefinition,
    devOpsBuildPipelinePermissionsToAddState,
    setAzureDevOpsBuildPipelinePermissions,
    devOpsReleasePipelineToCreateState,
    createAzureDevOpsReleasePipelineDefinition,
    devOpsReleasePipelinePermissionsToAddState,
    setAzureDevOpsReleasePipelineDefinitionPermissions,
    scorecardAssociationState,
    createScorecardAssociation,
    operationaTestsServiceToCreateState,
    createOperationalTestsService,
    operationaTestsScheduleToCreateState,
    createOperationalTestsSchedule,
    seqKeysToCreateState,
    createSeqKey: createSeqKeys,
    octopusProjectToCreateStateReset,
    devOpsGitRepoStateReset,
    devOpsTeamToCreateStateReset,
    devOpsTeamPermissionsToAddStateReset,
    devOpsBuildPipelineToCreateStateReset,
    devOpsBuildPipelinePermissionsToAddStateReset,
    devOpsReleasePipelineToCreateStateReset,
    devOpsReleasePipelinePermissionsToAddStateReset,
    scorecardAssociationStateReset,
    operationaTestsServiceToCreateStateReset,
    operationaTestsScheduleToCreateStateReset,
    seqKeysToCreateStateReset,
    updateServiceFormStatus,
    devOpsEscalationContactsState,
    createEscalationContacts
  } = props;

  const { serviceFormId } : any = useParams();
  const { darkMode, setSelectedTab } = React.useContext(AppContext);
  const { user, roles } = React.useContext(AuthContext);
  const history = useHistory();
  const [displayError, setDisplayError] = React.useState<boolean>(false);
  const [serviceBaseErrors] = React.useState<string[]>([]);
  const [BUDDIErrors] = React.useState<string[]>([]);
  const [scorecardErrors] = React.useState<string[]>([]);
  const [seqErrors] = React.useState<string[]>([]);
  const [OPTestErrors] = React.useState<string[]>([]);

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [activeStep, setActiveStep] = React.useState<number>(0);

  const [loadingData, setLoadingData] = React.useState<boolean>(true);
  const [loadingServiceForm, setLoadingServiceForm] = React.useState<boolean>(true);

  const [isReadyToCreate, setIsReadyToCreate] = React.useState<boolean>(false);

  React.useEffect(() => {
    octopusProjectToCreateStateReset();
    devOpsGitRepoStateReset();
    devOpsTeamToCreateStateReset();
    devOpsTeamPermissionsToAddStateReset();
    devOpsBuildPipelineToCreateStateReset();
    devOpsBuildPipelinePermissionsToAddStateReset();
    devOpsReleasePipelineToCreateStateReset();
    devOpsReleasePipelinePermissionsToAddStateReset();
    scorecardAssociationStateReset();
    operationaTestsServiceToCreateStateReset();
    operationaTestsScheduleToCreateStateReset();
    seqKeysToCreateStateReset();
  }, []);

  React.useEffect(() => {
    if (!isAllowed([ADMIN, FORM_CREATOR], roles)) {
      history.push(HOME);
    }

    if (setSelectedTab) setSelectedTab(3);
    switch (activeStep) {
      case 0:
        if (!azureDevOpsProjects.completed && !azureDevOpsProjects.pending) getAllDevOpsProjects();
        if (!azureDevOpsTeams.completed && !azureDevOpsTeams.pending) getAllDevOpsTeams();
        if (!octopusARMSteps.completed && !octopusARMSteps.pending) getARMSteps();
        if (!octopusLibraries.completed && !octopusLibraries.pending) getAllSubscriptionLibraries();
        if (!octopusLifecycles.completed && !octopusLifecycles.pending) getAllLifecycles();
        if (!octopusProjects.completed && !octopusProjects.pending) getAllOctopusProjects();
        if (!octopusProjectGroups.completed && !octopusProjectGroups.pending) getAllProjectGroups();
        if (!octopusTemplateProjects.completed && !octopusTemplateProjects.pending)
          getAllTemplateProjects();
        if (!regions.pending && !regions.completed) getRegions();
        if (!products.pending && !products.completed) getAllProducts();
        if (!scorecardProjects.pending && !scorecardProjects.completed) getAllScorecardProjects();
        break;
      case 1:
        if (!azureDevOpsProjects.completed && !azureDevOpsProjects.pending) getAllDevOpsProjects();
        if (!scorecardCategories.completed && !scorecardCategories.pending)
          getAllScorecardCategories();
        if (!scorecardSpecifications.completed && !scorecardSpecifications.pending)
          getAllScorecardSpecifications();
        if (!scorecardProjects.pending && !scorecardProjects.completed) getAllScorecardProjects();
        break;
      case 2:
        // if (!operationalTestsServices.completed && !operationalTestsServices.pending)
        // getAllOperationalTestsServices();
        break;
      case 3:
        break;
      // case 4:
      // if (!buddiRegions.completed && !buddiRegions.pending) getAllBuddiRegions();
      // if (!buddiUrls.completed && !buddiUrls.pending) getAllBuddiUrls();
      // if (!buddiRegionalUrls.completed && !buddiRegionalUrls.pending) getAllBuddiRegionalUrls();
      // break;
      default:
        break;
    }

    setLoadingData(
      !(
        products.completed &&
        octopusTemplateProjects.completed &&
        regions.completed &&
        octopusARMSteps.completed &&
        azureDevOpsProjects.completed &&
        azureDevOpsTeams.completed &&
        !loadingServiceForm
      )
    );

    if (serviceFormId)
      getServiceFormById(Number(serviceFormId))
        .then(sf => {
          if (dispatch) dispatch(actions.setServiceForm(sf));
        })
        .finally(() => setLoadingServiceForm(false));
  }, [activeStep]);

  React.useEffect(() => {
    setLoadingData(
      !(
        products.completed &&
        octopusTemplateProjects.completed &&
        regions.completed &&
        octopusARMSteps.completed &&
        azureDevOpsProjects.completed &&
        azureDevOpsTeams.completed &&
        !loadingServiceForm
      )
    );

    if (serviceFormId)
      getServiceFormById(Number(serviceFormId))
        .then(sf => {
          if (dispatch) dispatch(actions.setServiceForm(sf));
        })
        .finally(() => setLoadingServiceForm(false));
  }, [
    loadingServiceForm,
    products,
    octopusTemplateProjects,
    regions,
    azureDevOpsProjects,
    azureDevOpsTeams
  ]);

  React.useEffect(() => {
    if (state.serviceForm) {
      let { serviceForm } = state;

      let _product = products.data.find(instance => instance.productId === serviceForm.productId);
      let _projectType = octopusTemplateProjects.data.find(
        instance => instance.id === serviceForm.octopusProjectType
      );
      let _region = regions.data.find(instance => instance.fullName === serviceForm.region);
      let _additionalResources: OctopusARMStepResponse[] = [];

      // eslint-disable-next-line array-callback-return
      serviceForm.serviceFormAdditionalResources.map(instance => {
        let resource = octopusARMSteps.data.find(x => x.id === instance.resourceId);
        if (resource) _additionalResources?.push(resource);
      });

      let _adoProject = azureDevOpsProjects.data.find(x => x.id === serviceForm.devOpsProjectId);

      if (dispatch) {
        dispatch(actions.setDeployableName(serviceForm.projectName));
        dispatch(actions.setProduct(_product || null));
        dispatch(actions.setProjectType(_projectType || null));
        dispatch(actions.setRegion(_region || null));
        dispatch(actions.setAdditionalResources(_additionalResources));

        dispatch(actions.setDevOpsProject(_adoProject || null));
        dispatch(actions.setDevOpsTeamName(serviceForm.devOpsTeamName));

        dispatch(actions.setProductManagerEmail(serviceForm.productManagerEmail));
        dispatch(actions.setDevLeadEmail(serviceForm.devLeadEmail));
      }
    }
  }, [
    state.serviceForm,
    products,
    octopusTemplateProjects,
    regions,
    octopusARMSteps,
    azureDevOpsProjects,
    azureDevOpsTeams,
    scorecardProjects
  ]);

  const steps = [
    { name: "Service Base" },
    { name: "Scorecard", optional: true },
    // { name: "Operational Tests", optional: true },
    { name: "SEQ", optional: true },
    // { name: "BUDDI", optional: true },
    { name: "Finalize" }
  ];
  function ChangeStep(index: number): void {
    setDisplayError(false);
    setActiveStep(index);
  }
  function NextStep(): void {
    if (
      (serviceBaseErrors.length > 0 && activeStep === 0) ||
      (scorecardErrors.length > 0 && activeStep === 1) ||
      (OPTestErrors.length > 0 && activeStep === 2) ||
      (seqErrors.length > 0 && activeStep === 3) /* ||
      (BUDDIErrors.length > 0 && activeStep === 4) */
    ) {
      setDisplayError(true);
    } else {
      ChangeStep(activeStep + 1);
    }
  }

  function PreviousStep(): void {
    ChangeStep(activeStep - 1);
  }

  function CanProceed(): boolean {
    if (
      serviceBaseErrors.length === 0 &&
      scorecardErrors.length === 0 &&
      OPTestErrors.length === 0 &&
      seqErrors.length === 0
    )
      return true;
    return false;
  }

  return (
    <Page title="Provision">
      <ThemeProvider theme={darkMode ? darkTextFieldsTheme : textFieldsTheme}>
        <Paper variant="outlined">
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((step, index) => {
              return (
                <Step key={index}>
                  <StepButton className="text-left" onClick={(): void => ChangeStep(index)}>
                    <Typography color="textSecondary" variant="body2">
                      {step.name}
                    </Typography>
                    {step.optional && (
                      <Typography color="textSecondary" variant="caption">
                        Optional
                      </Typography>
                    )}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
        </Paper>
        {serviceFormId && loadingData ? (
          <ProvisionStepSkeleton />
        ) : (
          <div className="mt-4 provision-step-body">
            {activeStep === 0 && (
              <ServiceBase
                additionalResources={state.additionalResources}
                azureDevOpsProjects={azureDevOpsProjects}
                azureDevOpsTeams={azureDevOpsTeams}
                deployableName={state.deployableName}
                isOctopusRequired={state.isOctopusRequired}
                devOpsProject={state.devOpsProject}
                devOpsTeamName={state.devOpsTeamName}
                dispatch={dispatch}
                gitRepoName={state.gitRepoName}
                isBuildPipelineRequired={state.isBuildPipelineRequired}
                isCloneEnabled={state.isCloneEnabled}
                isGitRepoRequired={state.isGitRepoRequired}
                isReleasePipelineRequired={state.isReleasePipelineRequired}
                isEscalationContactRequired = {state.isEscalationContactRequired}
                escalationContacts = {state.escalationContacts}
                lifecycle={state.lifecycle}
                octopusARMSteps={octopusARMSteps}
                octopusLibraries={octopusLibraries}
                octopusLifecycles={octopusLifecycles}
                octopusProject={state.octopusProject}
                octopusProjects={octopusProjects}
                octopusProjectGroups={octopusProjectGroups}
                octopusTemplateProjects={octopusTemplateProjects}
                projectGroup={state.projectGroup}
                projectType={state.projectType}
                regions={regions}
                region={state.region}
                subscription={state.subscription}
                products={products}
                product={state.product}
                serviceForm={state.serviceForm}
                buildFolderPath={state.buildFolderPath}
                errors={serviceBaseErrors}
                displayError={displayError}
              />
            )}
            {activeStep === 1 && (
              <Scorecards
                azureDevOpsProjects={azureDevOpsProjects}
                deployableName={state.deployableName}
                devOpsProject={state.devOpsProject}
                dispatch={dispatch}
                isScorecardRequired={state.isScorecardRequired}
                scorecardCategories={scorecardCategories}
                products={products}
                product={state.product}
                scorecardSpecifications={scorecardSpecifications}
                productType={state.productType}
                releaseType={state.releaseType}
                scorecardProjects={scorecardProjects}
                scorecardProject={state.scorecardProject}
                selectedItems={state.selectedItems}
                productManagerEmail={state.productManagerEmail}
                devLeadEmail={state.devLeadEmail}
                serviceForm={state.serviceForm}
                isCloneEnabled={state.isCloneEnabled}
                errors={scorecardErrors}
                displayError={displayError}
              />
            )}
            {/* activeStep === 2 && (
              <OperationalTests
                dispatch={dispatch}
                isOperationalTestsRequired={state.isOperationalTestsRequired}
                operationalTestsServices={operationalTestsServices}
                operationalTestsService={state.operationalTestsService}
                region={state.region}
                operationalTestsCrontabExpression={state.operationalTestsCrontabExpression}
                operationalTestsTimeout={state.operationalTestsTimeout}
                operationalTestsNotificationList={state.operationalTestsNotificationList}
                isGeoReplicated={state.isGeoReplicated}
                operationalTestsAreDisabled={state.operationalTestsAreDisabled}
                operationalTestsAreHighSeverity={state.operationalTestsAreHighSeverity}
                deployableName={state.deployableName}
                azureDevOpsProjects={azureDevOpsProjects}
                devOpsProject={state.devOpsProject}
                serviceForm={state.serviceForm}
                isCloneEnabled={state.isCloneEnabled}
                errors={OPTestErrors}
                displayError={displayError}
              />
            ) */}
            {activeStep === 2 && (
              <Seq
                dispatch={dispatch}
                isSeqRequired={state.isSeqRequired}
                products={products}
                product={state.product}
                seqKeyEnvironments={state.seqKeyEnvironments}
                serviceForm={state.serviceForm}
                errors={seqErrors}
                displayError={displayError}
                isCloneEnabled={state.isCloneEnabled}
              />
            )}
            {/* activeStep === 4 && (
              <Buddi
                dispatch={dispatch}
                isBuddiRequired={state.isBuddiRequired}
                region={state.region}
                isCloneEnabled={state.isCloneEnabled}
                buddiRegions={buddiRegions}
                deployableName={state.deployableName}
                buddiUrl={state.buddiUrl}
                buddiRegionalUrlsRequired={state.buddiRegionalUrlsRequired}
                configuredBuddiRegionalUrls={state.configuredBuddiRegionalUrls}
                buddiUrls={buddiUrls}
                buddiRegionalUrls={buddiRegionalUrls}
                serviceForm={state.serviceForm}
                errors={BUDDIErrors}
                displayError={displayError}
              />
            ) */}
            {!isReadyToCreate && activeStep === 3 && (
              <Finalize
                additionalResources={state.additionalResources}
                deployableName={state.deployableName}
                isOctopusRequired={state.isOctopusRequired}
                devOpsProject={state.devOpsProject}
                devOpsTeamName={state.devOpsTeamName}
                gitRepoName={state.gitRepoName}
                isBuildPipelineRequired={state.isBuildPipelineRequired}
                isCloneEnabled={state.isCloneEnabled}
                isGitRepoRequired={state.isGitRepoRequired}
                isReleasePipelineRequired={state.isReleasePipelineRequired}
                isEscalationContactRequired = {state.isEscalationContactRequired}
                lifecycle={state.lifecycle}
                octopusProject={state.octopusProject}
                projectGroup={state.projectGroup}
                projectType={state.projectType}
                region={state.region}
                subscription={state.subscription}
                isScorecardRequired={state.isScorecardRequired}
                releaseType={state.releaseType}
                productType={state.productType}
                product={state.product}
                productManagerEmail={state.productManagerEmail}
                devLeadEmail={state.devLeadEmail}
                scorecardProject={state.scorecardProject}
                selectedItems={state.selectedItems}
                isOperationalTestsRequired={state.isOperationalTestsRequired}
                operationalTestsService={state.operationalTestsService}
                operationalTestsCrontabExpression={state.operationalTestsCrontabExpression}
                operationalTestsTimeout={state.operationalTestsTimeout}
                operationalTestsNotificationList={state.operationalTestsNotificationList}
                isGeoReplicated={state.isGeoReplicated}
                operationalTestsAreDisabled={state.operationalTestsAreDisabled}
                operationalTestsAreHighSeverity={state.operationalTestsAreHighSeverity}
                isSeqRequired={state.isSeqRequired}
                seqKeyEnvironments={state.seqKeyEnvironments}
                isBuddiRequired={state.isBuddiRequired}
                buddiRegionalUrlsRequired={state.buddiRegionalUrlsRequired}
                buddiUrl={state.buddiUrl}
                buddiRegions={buddiRegions}
                configuredBuddiRegionalUrls={state.configuredBuddiRegionalUrls}
                serviceBaseErrors={serviceBaseErrors}
                scorecardErrors={scorecardErrors}
                OPTestErrors={OPTestErrors}
                seqErrors={seqErrors}
              />
            )}
            {isReadyToCreate && activeStep === 3 && (
              <ProvisionProgress
                operations={state.operations}
                deployableName={state.deployableName}
                octopusProjectToCreateState={octopusProjectToCreateState}
                devOpsGitRepoState={devOpsGitRepoState}
                devOpsTeamToCreateState={devOpsTeamToCreateState}
                devOpsTeamPermissionsToAddState={devOpsTeamPermissionsToAddState}
                devOpsBuildPipelineToCreateState={devOpsBuildPipelineToCreateState}
                devOpsBuildPipelinePermissionsToAddState={devOpsBuildPipelinePermissionsToAddState}
                devOpsReleasePipelineToCreateState={devOpsReleasePipelineToCreateState}
                devOpsReleasePipelinePermissionsToAddState={devOpsReleasePipelinePermissionsToAddState}
                scorecardAssociationState={scorecardAssociationState}
                operationaTestsServiceToCreateState={operationaTestsServiceToCreateState}
                operationaTestsScheduleToCreateState={operationaTestsScheduleToCreateState}
                seqKeysToCreateState={seqKeysToCreateState}
                updateServiceFormStatus={updateServiceFormStatus}
                user={user}
                serviceFormId={serviceFormId}
                dispatch={dispatch}
                devOpsEscalationContactsState = {devOpsEscalationContactsState}
              />
            )}
          </div>
        )}
        <div className="d-flex flex-row-reverse m-4">
          {activeStep === steps.length - 1 && !(serviceFormId && loadingData) && !isReadyToCreate && (
            <Button
              className="mx-1"
              color="primary"
              disabled={!CanProceed()}
              onClick={(): void => {
                let _operations: ProvisioningOperation[] = [];
                  // Octopus
                if (state.isOctopusRequired) {
                  if (
                    state.isCloneEnabled &&
                    state.lifecycle &&
                    state.projectGroup &&
                    state.octopusProject
                  ) {
                    // Cloning project
                    const requestBody: CloneOctopusProjectRequest = {
                      projectName: state.deployableName,
                      lifecycleId: state.lifecycle.id,
                      projectGroupId: state.projectGroup.id,
                      templateProjectId: state.octopusProject.id
                    };

                    _operations.push({
                      name: `Creating Octopus project - ${state.deployableName} (Clone from ${state.octopusProject?.name})`,
                      key: ProvisioningOperationKeys.OctopusProject,
                      function: () => cloneOctoProject(requestBody),
                      requestBody,
                      parentKeys: [],
                      state: octopusProjectToCreateState
                    });
                  } else {
                    // Creating new project
                    // eslint-disable-next-line no-lonely-if
                    if (
                      state.projectType &&
                      state.lifecycle &&
                      state.region &&
                      state.projectGroup &&
                      state.product
                    ) {
                      let requestBody: CreateOctopusProjectRequest = {
                        name: state.deployableName,
                        templateProjectId: state.projectType.id,
                        groupId: state.projectGroup.id,
                        lifecycleId: state.lifecycle.id,
                        description: state.serviceForm?.projDescription
                          ? state.serviceForm?.projDescription
                          : "",
                        region: state.region.abbreviation,
                        projectGroupId: state.projectGroup.id,
                        serviceFormAdditionalResources: state.additionalResources,
                        variables: [
                          {
                            name: "Process.GPRID",
                            scope: "",
                            value: state.product.productId.toString(),
                            sensitive: false
                          }
                        ]
                      };

                      if (state.isSeqRequired)
                        requestBody.variables.push({
                          name: "Bentley.Cloud.Framework.Serilog.ApplicationName",
                          scope: "",
                          value: state.deployableName,
                          sensitive: false
                        });

                      _operations.push({
                        name: `Creating Octopus project - ${state.deployableName}`,
                        key: ProvisioningOperationKeys.OctopusProject,
                        function: () => createOctoProject(requestBody),
                        requestBody,
                        parentKeys: state.isSeqRequired ? [ProvisioningOperationKeys.SeqKey] : [],
                        state: octopusProjectToCreateState
                      });
                    }
                  }
                }

                // ADO
                if (!state.isCloneEnabled) {
                  if (state.devOpsTeamName && state.devOpsProject) {
                    const requestBody: CreateOrGetADOTeamRequest = {
                      serviceFormId: serviceFormId ? Number(serviceFormId) : 0,
                      teamName: state.devOpsTeamName,
                      azureDevOpsProjectName: state.devOpsProject.name
                    };

                    _operations.push({
                      name: `Getting or creating Azure DevOps Team - ${state.devOpsTeamName}`,
                      key: ProvisioningOperationKeys.DevOpsTeam,
                      function: () => createOrGetAzureDevOpsTeam(requestBody),
                      requestBody,
                      parentKeys: [],
                      state: devOpsTeamToCreateState
                    });

                    const permissionRequestBody: SetAzureDevOpsDefaultTeamPermissionsRequest = {
                      serviceFormId: serviceFormId ? Number(serviceFormId) : 0,
                      projectId: state.devOpsProject.id,
                      teamName: state.devOpsTeamName,
                      azureDevOpsProjectName: state.devOpsProject.name
                    };

                    _operations.push({
                      name: `Setting up Azure DevOps Team permissions`,
                      key: ProvisioningOperationKeys.DevOpsTeamPermissions,
                      function: () => setAzureDevOpsTeamPermissions(permissionRequestBody),
                      requestBody,
                      parentKeys: [ProvisioningOperationKeys.DevOpsTeam],
                      state: devOpsTeamPermissionsToAddState
                    });
                  }

                  if (
                    state.isBuildPipelineRequired &&
                    state.devOpsProject &&
                    state.devOpsTeamName
                  ) {
                      const requestBody: CreateADOBuildDefinitionRequest = {
                      serviceFormId: serviceFormId ? Number(serviceFormId) : 0,
                      azureDevOpsProjectName: state.devOpsProject.name,
                      buildFolderPath:
                        state.buildFolderPath === "" ? "" : `\\${state.buildFolderPath}`,
                      gitRepoName: state.gitRepoName,
                      pipelineName: `${state.deployableName}`
                    };
                    _operations.push({
                      name: `Creating Azure DevOps build definition - ${state.deployableName}`,
                      key: ProvisioningOperationKeys.DevOpsBuildPipeline,
                      function: () => createAzureDevOpsBuildDefinition(requestBody),
                      requestBody,
                      parentKeys: state.isGitRepoRequired ? [ProvisioningOperationKeys.DevOpsGitRepo] : [],
                      state: devOpsBuildPipelineToCreateState
                    });

                    const permissionRequestBody: SetAzureDevOpsBuildPipelinePermissionsRequest = {
                      serviceFormId: serviceFormId ? Number(serviceFormId) : 0,
                      teamName: state.devOpsTeamName,
                      azureDevOpsProjectName: state.devOpsProject.name,
                      projectId: state.devOpsProject.id,
                      folderPath: state.buildFolderPath === "" ? "" : `\\${state.buildFolderPath}`
                    };

                    _operations.push({
                      name: `Setting up Azure DevOps build definition permissions`,
                      key: ProvisioningOperationKeys.DevOpsBuildPipelinePermissions,
                      function: () => setAzureDevOpsBuildPipelinePermissions(permissionRequestBody),
                      requestBody,
                      parentKeys: [
                        ProvisioningOperationKeys.DevOpsBuildPipeline,
                        ProvisioningOperationKeys.DevOpsTeam
                      ],
                      state: devOpsBuildPipelinePermissionsToAddState
                    });
                  }

                  if (state.isEscalationContactRequired) {
                    const escalationContactReqBody: EscalationContactsRequest = {
                        serviceFormId: serviceFormId ? Number(serviceFormId) : 0,
                        productName: state.deployableName
                    };

                    _operations.push({
                      name: `Escalation Contacts`,
                      key: ProvisioningOperationKeys.EscalationContacts,
                      function: () => createEscalationContacts(escalationContactReqBody),
                      requestBody: escalationContactReqBody,
                      parentKeys: [],
                      state: devOpsEscalationContactsState
                  });
                  }

                  if (
                      state.isReleasePipelineRequired &&
                      state.devOpsProject?.name.includes(AzureDevOpsYmlPipelineProject.iModelTechnologies) === false &&
                    state.devOpsTeamName
                  ) {
                    const requestBody: CreateADOReleaseDefinitionRequest = {
                      serviceFormId: serviceFormId ? Number(serviceFormId) : 0,
                      azureDevOpsProjectName: state.devOpsProject.name,
                      buildFolderPath: state.buildFolderPath,
                      teamName: state.devOpsTeamName,
                      pipelineName: `${state.deployableName} ({short code})`
                    };

                    _operations.push({
                      name: `Creating Azure DevOps release definition - ${state.deployableName} ({short code})`,
                      key: ProvisioningOperationKeys.DevOpsReleasePipeline,
                      function: () => createAzureDevOpsReleasePipelineDefinition(requestBody),
                      requestBody,
                      parentKeys: [ProvisioningOperationKeys.DevOpsTeam],
                      state: devOpsReleasePipelineToCreateState
                    });

                    const permissionRequestBody: SetAzureDevOpsReleasePipelinePermissionsRequest = {
                      serviceFormId: serviceFormId ? Number(serviceFormId) : 0,
                      azureDevOpsProjectName: state.devOpsProject.name,
                      folderPath: state.buildFolderPath,
                      projectId: state.devOpsProject.id,
                      teamName: state.devOpsTeamName
                    };

                    _operations.push({
                      name: `Setting up Azure DevOps release definition permissions`,
                      key: ProvisioningOperationKeys.DevOpsReleasePipelinePermissions,
                      function: () =>
                        setAzureDevOpsReleasePipelineDefinitionPermissions(permissionRequestBody),
                      requestBody,
                      parentKeys: [
                        ProvisioningOperationKeys.DevOpsReleasePipeline,
                        ProvisioningOperationKeys.DevOpsTeam
                      ],
                      state: devOpsReleasePipelinePermissionsToAddState
                    });
                  }

                  if (state.isGitRepoRequired && state.devOpsProject) {
                    const requestBody: CreateOrGetADOGitRepoRequest = {
                      serviceFormId: serviceFormId ? Number(serviceFormId) : 0,
                      azureDevOpsProjectName: state.devOpsProject.name,
                      gitRepoName: state.gitRepoName
                    };

                    _operations.push({
                      name: `Creating Git repository - ${state.gitRepoName}`,
                      key: ProvisioningOperationKeys.DevOpsGitRepo,
                      function: () => createOrGetADOGitRepo(requestBody),
                      requestBody,
                      parentKeys: [],
                      state: devOpsGitRepoState
                    });
                  }
                }
                // Scorecard
                if (state.isScorecardRequired) {
                  if (
                    state.product &&
                    state.productType &&
                    state.scorecardProject &&
                    state.releaseType
                  ) {
                    const requestBody: ScorecardAssociationRequest = {
                      deployableName: state.deployableName,
                      gprid: state.product.productId,
                      leadDeveloper: state.devLeadEmail,
                      productManager: state.productManagerEmail,
                      productTypeId: state.productType.id,
                      projectId: state.scorecardProject.projectId,
                      releaseDefinitionId: 0,
                      releaseTypeId: state.releaseType.id,
                      serviceFormId: serviceFormId ? Number(serviceFormId) : 0,
                      validators: state.selectedItems
                    };

                    _operations.push({
                      name: `Creating Scorecard association - ${state.deployableName} (${state.releaseType?.value}, ${state.productType?.value})`,
                      key: ProvisioningOperationKeys.ScorecardAssociation,
                      function: createScorecardAssociation,
                      requestBody,
                      parentKeys: [ProvisioningOperationKeys.DevOpsReleasePipeline],
                      state: scorecardAssociationState
                    });
                  }
                }

                // OPTS
                if (state.isOperationalTestsRequired) {
                  // if service is not registered add promise for new service
                  if (
                    state.operationalTestsService &&
                    state.operationalTestsService.serviceId === "" &&
                    state.devOpsProject
                  ) {
                    const requestBody: CreateOperationalTestsServiceRequest = {
                      serviceFormId: serviceFormId ? Number(serviceFormId) : 0,
                      projectName: state.devOpsProject.name,
                      releaseDefinitionId: 0,
                      serviceName: state.operationalTestsService.name
                    };

                    _operations.push({
                      name: `Registering ${state.operationalTestsService?.name} in Operational Tests Scheduler`,
                      key: ProvisioningOperationKeys.OperationalTestService,
                      function: createOperationalTestsService,
                      requestBody,
                      parentKeys: [ProvisioningOperationKeys.DevOpsReleasePipeline],
                      state: operationaTestsServiceToCreateState
                    });
                  }

                  if (state.operationalTestsService) {
                    const requestBody: CreateOperationalTestsScheduleRequest = {
                      serviceFormId: serviceFormId ? Number(serviceFormId) : 0,
                      crontabExpression: state.operationalTestsCrontabExpression,
                      definitionEnvironmentId: 0,
                      dependentEnvironmentId: 0,
                      isHighSeverity: state.operationalTestsAreHighSeverity,
                      notificationRecipients: state.operationalTestsNotificationList,
                      serviceId: state.operationalTestsService.serviceId,
                      timeout: Number(state.operationalTestsTimeout)
                    };

                    _operations.push({
                      name: `Creating Operational Tests schedule`,
                      key: ProvisioningOperationKeys.OperationalTestSchedule,
                      function: createOperationalTestsSchedule,
                      requestBody,
                      parentKeys: [ProvisioningOperationKeys.OperationalTestService],
                      state: operationaTestsScheduleToCreateState
                    });
                  }
                }

                // SEQ
                if (state.isSeqRequired && state.product) {
                  let requestBodies: SeqKeysToCreateRequest[] = [];

                  state.seqKeyEnvironments.forEach(env => {
                    if (state.product) {
                      const requestBody: SeqKeysToCreateRequest = {
                        serviceFormId: serviceFormId ? Number(serviceFormId) : 0,
                        gprid: state.product.productId.toString(),
                        environment: env,
                        projectName: state.deployableName,
                        logLevelEnum: 0
                      };
                      requestBodies.push(requestBody);
                    }
                  });

                  if (requestBodies.length > 0)
                    _operations.push({
                      name: `Creating SEQ API Key for ${state.seqKeyEnvironments.join(
                        ", "
                      )} environment(s)`,
                      key: ProvisioningOperationKeys.SeqKey,
                      function: createSeqKeys,
                      requestBody: requestBodies,
                      parentKeys: [],
                      state: seqKeysToCreateState
                    });
                }

                setIsReadyToCreate(true);
                dispatch(actions.setOperations(_operations));
              }}
              variant="outlined"
            >
              Complete
            </Button>
          )}
          {activeStep !== steps.length - 1 && !(serviceFormId && loadingData) && !isReadyToCreate && (
            <Button className="mx-1" onClick={NextStep} variant="outlined">
              Next
            </Button>
          )}
          {activeStep !== 0 && !(serviceFormId && loadingData) && !isReadyToCreate && (
            <Button className="mx-1" onClick={PreviousStep} variant="outlined">
              Back
            </Button>
          )}
        </div>
      </ThemeProvider>
    </Page>
  );
}

function mapStateToProps(state: ApplicationState): unknown {
  return {
    azureDevOpsProjects: state.azureDevOpsProjects,
    azureDevOpsTeams: state.azureDevOpsTeams,
    octopusARMSteps: state.octopusARMSteps,
    octopusLibraries: state.octopusLibraries,
    octopusLifecycles: state.octopusLifecycles,
    octopusProjects: state.octopusProjects,
    octopusProjectGroups: state.octopusProjectGroups,
    octopusTemplateProjects: state.octopusTemplateProjects,
    scorecardCategories: state.scorecardCategories,
    scorecardSpecifications: state.scorecardSpecifications,
    products: state.products,
    scorecardProjects: state.scorecardProjects,
    operationalTestsServices: state.operationalTestsServices,
    buddiRegions: state.buddiRegions,
    regions: state.regions,
    buddiUrls: state.buddiUrls,
    buddiRegionalUrls: state.buddiRegionalUrls,
    serviceFormsState: state.serviceForms,
    octopusProjectToCreateState: state.octopusProjectToCreateState,
    devOpsGitRepoState: state.devOpsGitRepoState,
    devOpsTeamToCreateState: state.devOpsTeamToCreateState,
    devOpsTeamPermissionsToAddState: state.devOpsTeamPermissionsToAddState,
    devOpsBuildPipelineToCreateState: state.devOpsBuildPipelineToCreateState,
    devOpsBuildPipelinePermissionsToAddState: state.devOpsBuildPipelinePermissionsToAddState,
    devOpsReleasePipelineToCreateState: state.devOpsReleasePipelineToCreateState,
    devOpsReleasePipelinePermissionsToAddState: state.devOpsReleasePipelinePermissionsToAddState,
    scorecardAssociationState: state.scorecardAssociationState,
    operationaTestsServiceToCreateState: state.operationaTestsServiceToCreateState,
    operationaTestsScheduleToCreateState: state.operationaTestsScheduleToCreateState,
    seqKeysToCreateState: state.seqKeysToCreateState,
    devOpsEscalationContactsState: state.devOpsEscalationContactsState
  };
}

function mapDispatchToProps(dispatch: Redux.Dispatch<Redux.AnyAction>): unknown {
  return Redux.bindActionCreators(
    {
      getAllDevOpsProjects: getAllDevOpsProjectsDispatch,
      getAllDevOpsTeams: getAllDevOpsTeamsDispatch,
      getAllLifecycles: getAllLifecyclesDispatch,
      getAllOctopusProjects: getAllOctopusProjectsDispatch,
      getAllProjectGroups: getAllProjectGroupsDispatch,
      getAllSubscriptionLibraries: getAllSubscriptionLibrariesDispatch,
      getAllTemplateProjects: getAllTemplateProjectsDispatch,
      getARMSteps: getARMStepsDispatch,
      getAllScorecardCategories: getAllScorecardCategoriesDispatch,
      getAllScorecardSpecifications: getAllScorecardSpecificationsDispatch,
      getAllProducts: getAllProductsDispatch,
      getAllScorecardProducts: getAllScorecardProductsDispatch,
      getAllScorecardProjects: getAllScorecardProjectsDispatch,
      getAllOperationalTestsServices: getAllOperationalTestsServicesDispatch,
      getAllBuddiRegions: getBUDDIRegionsDispatch,
      getRegions: getRegionsDispatch,
      getAllBuddiUrls: getBUDDIUrlsDispatch,
      getAllBuddiRegionalUrls: getBUDDIRegionalUrlsDispatch,
      createOctoProject: createOctoProjectDispatch,
      cloneOctoProject: cloneOctoProjectDispatch,
      createOrGetADOGitRepo: createOrGetAzureDevOpsGitRepoDispatch,
      createOrGetAzureDevOpsTeam: createOrGetAzureDevOpsTeamDispatch,
      setAzureDevOpsTeamPermissions: setAzureDevOpsDefaultTeamPermissionsDispatch,
      createAzureDevOpsBuildDefinition: createAzureDevOpsBuildDefinitionDispatch,
      setAzureDevOpsBuildPipelinePermissions: setAzureDevOpsBuildPipelinePermissionsDispatch,
      createAzureDevOpsReleasePipelineDefinition: createAzureDevOpsReleaseDefinitionDispatch,
      setAzureDevOpsReleasePipelineDefinitionPermissions:
        setAzureDevOpsReleasePipelinePermissionsDispatch,
      createScorecardAssociation: createScorecardAssociationDispatch,
      createOperationalTestsService: createOperationalTestsServiceDispatch,
      createOperationalTestsSchedule: createOperationalTestsScheduleDispatch,
      createSeqKey: createSeqKeyDispatch,
      octopusProjectToCreateStateReset: octopusProjectToCreateStateResetDispatch,
      devOpsGitRepoStateReset: devOpsGitRepoStateResetDispatch,
      devOpsTeamToCreateStateReset: devOpsTeamToCreateStateResetDispatch,
      devOpsTeamPermissionsToAddStateReset: devOpsTeamPermissionsToAddStateResetDispatch,
      devOpsBuildPipelineToCreateStateReset: devOpsBuildPipelineToCreateStateResetDispatch,
      devOpsBuildPipelinePermissionsToAddStateReset:
        devOpsBuildPipelinePermissionsToAddStateResetDispatch,
      devOpsReleasePipelineToCreateStateReset: devOpsReleasePipelineToCreateStateResetDispatch,
      devOpsReleasePipelinePermissionsToAddStateReset:
        devOpsReleasePipelinePermissionsToAddStateResetDispatch,
      scorecardAssociationStateReset: scorecardAssociationStateResetDispatch,
      operationaTestsServiceToCreateStateReset: operationaTestsServiceToCreateStateResetDispatch,
      operationaTestsScheduleToCreateStateReset: operationaTestsScheduleToCreateStateResetDispatch,
      seqKeysToCreateStateReset: seqKeysToCreateStateResetDispatch,
      updateServiceFormStatus: updateServiceFormStatusDispatch,
      createEscalationContacts: createEscalationContactsDispatch
    },
    dispatch
  );
}

export const Provision = connect(mapStateToProps, mapDispatchToProps)(ProvisionPage);
