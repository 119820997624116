/* eslint-disable @typescript-eslint/no-empty-function */
import "./FormRequest.scss";

import * as React from "react";
import * as Redux from "redux";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { InputAdornment, ThemeProvider, Paper, Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { AppContext } from "../../../context/AppContext";
import { textFieldsTheme, darkTextFieldsTheme } from "../../../themes";
import {
  getServiceFormById,
  createServiceFormDispatch,
  getAllDevOpsProjectsDispatch,
  getAllDevOpsTeamsDispatch,
  getAllProductsDispatch,
  getAllTemplateProjectsDispatch,
  getARMStepsDispatch,
  getSharePointUsersDispatch,
  getRegionsDispatch,
  updateServiceFormDispatch,
  getAllUsersWithRolesDispatch,
  getPrimaryUserByRoleName,
  updateServiceFormPeerReviewersDispatch
} from "../../../services";
import { readContentOnLoadEnd } from "../../../utilities";
import {
  ProductResponse,
  DevOpsProjectResponse,
  OctopusProjectResponse,
  UserResponse,
  OctopusARMStepResponse,
  ServiceFormRequest
} from "../../../models";
import { ApplicationState } from "../../../redux/reducers";
import { PEER_REVIEWER, FORM_APPROVER } from "../../../constants/userRoles";
import { AuthContext } from "../../../context/AuthContext";
import {
  OutlinedAutocomplete,
  OutlinedMultipleAutocomplete,
  OutlinedFreesoloAutocomplete,
  OutlinedTextField,
  OutlinedDatePicker,
  DragAndDropField,
  PhoneField
} from "../../Inputs";
import * as action from "./actions";
import { reducer } from "./reducer";
import { Page } from "../Page";
import { InputContainer } from "./InputContainer";
import { InputRow } from "./InputRow";
import { FormSection } from "./FormSection";
import { FieldValidator } from "../../../utilities/FieldValidator";
import { FormRequestPageProps, initialState, FormRequestMode } from "./types";
import { FORMS } from "../../../constants/paths";
import { FormRequestSkeleton } from "./FormRequestSkeleton";
import { showErrorSnackbar, showSuccessSnackbar } from "../../Snackbars/Snackbars";
import { generateRandomGuid } from "../../../utilities/StringOperations";

function findResource<T extends { id: string }>(id: string, resources: T[]): T {
  const filteredResources = resources.filter(p => p.id === id);
  return filteredResources[0];
}

function FormRequestPage(props: FormRequestPageProps): JSX.Element {
  const {
    azureDevOpsProjects,
    azureDevOpsTeams,
    createServiceForm,
    updateServiceForm,
    updateServiceFormPeerReviewers,
    getAllDevOpsProjects,
    getAllDevOpsTeams,
    getAllProducts,
    getAllTemplateProjects,
    getAllUsers,
    getARMSteps,
    getSharePointUsers,
    getRegions,
    octopusARMSteps,
    octopusTemplateProjects,
    products,
    serviceForms,
    sharePointUsers,
    regions,
    users
  } = props;

  const { serviceFormId } : any = useParams();
  const { darkMode, setSelectedTab } = React.useContext(AppContext);
  const { user } = React.useContext(AuthContext);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [displayError, setDisplayError] = React.useState<boolean>(false);
  const [errors] = React.useState<string[]>([]);
  const [mode, setMode] = React.useState<FormRequestMode>(0);
  const [loadingServiceForm, setLoadingServiceForm] = React.useState<boolean>(true);
  const [loadingData, setLoadingData] = React.useState<boolean>(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isPhoneError, setPhoneError] = React.useState<boolean>(false) 

  React.useEffect(() => {
    if (setSelectedTab) setSelectedTab(1);
    if (!azureDevOpsProjects.pending && !azureDevOpsProjects.completed) getAllDevOpsProjects();
    if (!azureDevOpsTeams.pending && !azureDevOpsTeams.completed) getAllDevOpsTeams();
    if (!products.pending && !products.completed) getAllProducts();
    if (!sharePointUsers.pending && !sharePointUsers.completed) getSharePointUsers();
    if (!regions.pending && !regions.completed) getRegions();
    if (!users.pending && !users.completed) getAllUsers();
    if (!octopusARMSteps.pending && !octopusTemplateProjects.pending) getARMSteps();
    if (!octopusTemplateProjects.pending && !octopusTemplateProjects.completed)
      getAllTemplateProjects();

    getPrimaryUserByRoleName(FORM_APPROVER).then(fa => dispatch(action.setFinalApprover(fa)));

    if (serviceFormId)
      getServiceFormById(Number(serviceFormId))
        .then(sf => dispatch(action.setServiceFormState(sf)))
        .finally(() => setLoadingServiceForm(false));
    else setLoadingServiceForm(false);

    setLoadingData(loadingServiceForm);
  }, []);

  // Load Octopus template
  React.useEffect(() => {
    if (!loadingServiceForm && octopusTemplateProjects.completed) {
      const projectType = octopusTemplateProjects.data.filter(
        p => p.id === state.serviceForm?.octopusProjectType
      );
      dispatch(action.setProjectType(projectType[0]));
    }
  }, [loadingServiceForm, octopusTemplateProjects.completed]);

  // Load reviewers
  React.useEffect(() => {
    if (!loadingServiceForm && users.completed) {
      const primaryPeerReviewer = users.data.filter(
        p => p.id === state.serviceForm?.serviceFormPeerReviewers[0].peerReviewerId
      );
      dispatch(action.setPrimaryPeerReviewer(primaryPeerReviewer[0]));
      const secondaryPeerReviewer = users.data.filter(
        p => p.id === state.serviceForm?.serviceFormPeerReviewers[1].peerReviewerId
      );
      dispatch(action.setSecondaryPeerReviewer(secondaryPeerReviewer[0]));
    }
  }, [loadingServiceForm, users.completed]);

  // Load DevOps project and Team
  React.useEffect(() => {
    if (!loadingServiceForm && azureDevOpsProjects.completed) {
      const devOpsProject = azureDevOpsProjects.data.filter(
        p => p.id === state.serviceForm?.devOpsProjectId
      );
      dispatch(action.setDevOpsProject(devOpsProject[0]));
      dispatch(action.setDevOpsTeamName(state.devOpsTeamName));
    }
  }, [loadingServiceForm, azureDevOpsProjects.completed]);

  // Load Product
  React.useEffect(() => {
    if (!loadingServiceForm && products.completed) {
      const product = products.data.filter(p => p.productId === state.serviceForm?.productId);
      dispatch(action.setProduct(product[0]));
    }
  }, [loadingServiceForm, products.completed]);

  // Load Additional Resources
  React.useEffect(() => {
    if (!loadingServiceForm && octopusARMSteps.completed) {
      const aditionalResourcesTemp = state.serviceForm?.serviceFormAdditionalResources;
      if (aditionalResourcesTemp && aditionalResourcesTemp.length > 0) {
        const aditionalResources = aditionalResourcesTemp.map(ar =>
          findResource<OctopusARMStepResponse>(ar.resourceId, octopusARMSteps.data)
        );
        if (aditionalResources && aditionalResources.length > 0)
          dispatch(action.setAdditionalResources(aditionalResources));
      }
    }
  }, [loadingServiceForm, octopusARMSteps.completed]);

  const reader = new FileReader();
  reader.onloadend = (event: ProgressEvent<FileReader>): void => {
    try {
      const content = readContentOnLoadEnd(event);
      if (content) dispatch(action.uploadArchitectureDiagramFinished(content));
    } catch {
      dispatch(action.removeArchitectureDiagram());
    }
  };

  const peerReviewers = React.useMemo(() => (users.data.filter(u =>
    u.userRole ? u.userRole?.some(r => r.name === PEER_REVIEWER) && 
      state.primaryPeerReviewer?.id !== u.id && 
      state.secondaryPeerReviewer?.id !== u.id &&
      u.name !== "cssa-test-developer" // user is only used for testing, not a valid approver
     : false
  )), [state.primaryPeerReviewer, state.secondaryPeerReviewer]);

  function handleFileUpload(files: File[]): void {

            if (files[0]) {
                const blob = files[0].slice(0, files[0].size);
                dispatch(action.uploadArchitectureDiagram(files[0].name + generateRandomGuid()));
                reader.readAsArrayBuffer(blob);
            }
  }
    function isPhoneFieldEmpty(): boolean {
        if (state.primaryDevPhone.length <= 3 || 
            state.productManagerPhone.length <= 3 || 
            state.qaLeadPhone.length <= 3 || 
            state.secondaryDevPhone.length <= 3 ||
            state.devLeadPhone.length <= 3) {
            setPhoneError(true);
            showErrorSnackbar(enqueueSnackbar, closeSnackbar, "Some Phone Fields are empty")
            return true;

        }
        setPhoneError(true);
        return false;
    }

  function createSFBody(): ServiceFormRequest {
    const requestBody: ServiceFormRequest = {
      additionalResources: state.additionalResources,
      architectureDiagramFileContent: String(state.architectureDiagramContent),
      architectureDiagramFileName: String(state.architectureDiagramFileName),
      devLeadEmail: String(state.devLeadEmail),
      devLeadPhone: state.devLeadPhone,
      devOpsProjectId: String(state.devOpsProject?.id),
      devOpsTeamName: String(state.devOpsTeamName),
      isExternal: true,
      isServiceIdentityRequired: false,
      monthlyCostEstimate: Number(state.monthlyCostEstimate),
      octopusProjectType: String(state.projectType?.id),
      userId: user?.id,
      productId: Number(state.product?.productId),
      productName: String(state.product?.productName),
      projectName: state.deployableName,
      primaryDevEmail: String(state.primaryDevEmail),
      primaryDevPhone: state.primaryDevPhone,
      productManagerEmail: String(state.productManagerEmail),
      productManagerPhone: state.productManagerPhone,
      projDescription: state.description,
      qaLeadEmail: String(state.qaLeadEmail),
      qaLeadPhone: state.qaLeadPhone,
      region: String(state.region),
      reviewerIds: [Number(state.primaryPeerReviewer?.id), Number(state.secondaryPeerReviewer?.id)],
      secondaryDevEmail: String(state.secondaryDevEmail),
      secondaryDevPhone: state.secondaryDevPhone,
      targetProductionDate: state.targetProductionDate ? state.targetProductionDate : undefined,
      formCreator: String(state.serviceForm?.formCreator),
      updatedBy: user ? user?.name : ""
    };
    return requestBody;
  }

  function handleServiceFormCreation(): void {
    if (errors.length > 0) {
        setDisplayError(true);
        return;
    }

      if (isPhoneFieldEmpty()) { 
          return;
      }

    const requestBody = createSFBody();

    createServiceForm(requestBody)
      .then(sf =>
        showSuccessSnackbar(enqueueSnackbar, closeSnackbar, "Created Cloud Service Request.")
      )
      .catch(error => showErrorSnackbar(enqueueSnackbar, closeSnackbar, error));
  }

    function handleServiceFormUpdate(): void {
        if (errors.length > 0) {
            setDisplayError(true);
            return;
        }

        if (isPhoneFieldEmpty()) {
            return;
        }

        const requestBody = createSFBody();
        if (serviceFormId) {
            requestBody.id = Number(serviceFormId);
        }

        updateServiceForm(requestBody)
            .then(sf =>
            showSuccessSnackbar(enqueueSnackbar, closeSnackbar, "Updated Cloud Service Request.")
            )
            .catch(error =>
            showErrorSnackbar(enqueueSnackbar, closeSnackbar, "Failed to update Cloud Service Request")
            );
  }

  function handleSFChanges(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    if (serviceFormId) {
      handleServiceFormUpdate();
    } else {
      handleServiceFormCreation();
    }
  }

  if (!loadingServiceForm)
    return (
      <Page back title={`Cloud Service Request  ${state.deployableName}`}>
        <ThemeProvider theme={darkMode ? darkTextFieldsTheme : textFieldsTheme}>
          <Paper className="form-paper space-vertical-64" variant="outlined">
            <FormSection title="Service information">
              <InputContainer description="Required. Provide the base information of new service">
                <FieldValidator displayError={displayError} errors={errors}>
                  <OutlinedAutocomplete<ProductResponse>
                    dispatch={dispatch}
                    getOptionLabel={(p: ProductResponse): string =>
                      `${p.productId} - ${p.productName}`
                    }
                    handle={action.setProduct}
                    label="Product"
                    loading={products.pending}
                    options={products.data}
                    required
                    value={state.product}
                  />
                </FieldValidator>
                <FieldValidator projectName displayError={displayError} errors={errors}>
                  <OutlinedTextField
                    dispatch={dispatch}
                    handle={action.setDeployableName}
                    helperText="The name will be used for Octopus project and Azure DevOps release definition"
                    label="Deployable Name"
                    required
                    value={state.deployableName}
                  />
                </FieldValidator>
              </InputContainer>
              <InputContainer description="Required. Provide Azure DevOps related information">
                <FieldValidator displayError={displayError} errors={errors}>
                  <OutlinedAutocomplete<DevOpsProjectResponse>
                    dispatch={dispatch}
                    getOptionLabel={(p: DevOpsProjectResponse): string => p.name}
                    handle={action.setDevOpsProject}
                    label="Azure DevOps Project"
                    loading={azureDevOpsProjects.pending}
                    options={azureDevOpsProjects.data}
                    required
                    value={state.devOpsProject}
                  />
                </FieldValidator>
                <FieldValidator displayError={displayError} errors={errors}>
                  <OutlinedFreesoloAutocomplete
                    disabled={!state.devOpsProject}
                    dispatch={dispatch}
                    freeSolo
                    handle={action.setDevOpsTeamName}
                    helperText="Select existing team or provide new team name"
                    label="Azure DevOps Team"
                    loading={azureDevOpsTeams.pending}
                    options={azureDevOpsTeams.data
                      .filter(team =>
                        state.devOpsProject ? team.projectId === state.devOpsProject.id : false
                      )
                      .map(team => team.name)}
                    required
                    value={state.devOpsTeamName}
                  />
                </FieldValidator>
              </InputContainer>
              <InputContainer
                description="Required. Upload new service architecture diagram 
                including all its dependencies"
              >
                <DragAndDropField
                  dropCallback={handleFileUpload}
                  diagramFile={state.architectureDiagramFileName}
                  inProgress={state.architectureDiagramUploading}
                  message={`Drag and Drop\n Or \n Browse architecture diagram image *`} 
                  removeCallback={(): void => dispatch(action.removeArchitectureDiagram())}
                />
              </InputContainer>
                  <InputContainer description="Required. Provide some additional information about the service">
                    <InputRow>
                        <FieldValidator displayError={displayError} errors={errors}>
                        <OutlinedTextField
                            dispatch={dispatch}
                            handle={action.setMonthlyCostEstimate}
                            label="Monthly Cost Estimate"
                            onlyNumbers
                            required
                            value={state.monthlyCostEstimate}
                            InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                         />
                        </FieldValidator>
                        <OutlinedDatePicker
                            dispatch={dispatch}
                            handle={action.setTargetProductionDate}
                            label="Target PRD Date"
                            value={state.targetProductionDate}
                        />
                        </InputRow>
                        <FieldValidator displayError={displayError} errors={errors}>
                        <OutlinedTextField
                            dispatch={dispatch}
                            handle={action.setDescription}
                            label="Description"
                            multiline
                            required
                            value={state.description}
                        />
                    </FieldValidator>
              </InputContainer>
            </FormSection>
            <FormSection title="Required infrastructure">
              <InputContainer description="Required. Select service base, required additional resources and region">
                <FieldValidator displayError={displayError} errors={errors}>
                  <OutlinedAutocomplete<OctopusProjectResponse>
                    dispatch={dispatch}
                    getOptionLabel={(p: OctopusProjectResponse): string => p.name}
                    handle={action.setProjectType}
                    helperText="Select base Octopus project type"
                    label="Project Type"
                    loading={octopusTemplateProjects.pending}
                    options={octopusTemplateProjects.data}
                    required
                    value={state.projectType}
                  />
                </FieldValidator>
                <OutlinedMultipleAutocomplete<OctopusARMStepResponse>
                  dispatch={dispatch}
                  getOptionLabel={(p: OctopusARMStepResponse): string => p.name}
                  handle={action.setAdditionalResources}
                  label="Additional resources"
                  loading={octopusARMSteps.pending}
                  multiple
                  options={octopusARMSteps.data}
                  value={state.additionalResources}
                />
                <FieldValidator displayError={displayError} errors={errors}>
                  <OutlinedAutocomplete
                    label="Region"
                    dispatch={dispatch}
                    handle={action.setRegion}
                    options={regions.data.map(u => u.fullName)}
                    required
                    loading={regions.pending}
                    value={state.region}
                  />
                </FieldValidator>
              </InputContainer>
            </FormSection>
            <FormSection title="Contacts">
              <InputContainer
                description="Contact details of the service. 
                Provided contacts will be stored in the escalation list"
              >
                <div className="field-group">
                  <FieldValidator email displayError={displayError} errors={errors}>
                     <OutlinedFreesoloAutocomplete
                      dispatch={dispatch}
                      freeSolo
                      handle={action.setProductManagerEmail}
                      label="Product Manager Email"
                      loading={sharePointUsers.pending}
                      options={sharePointUsers.data.map(u => u.email)}
                      required
                      value={state.productManagerEmail}
                     />
                  </FieldValidator>
                  <FieldValidator phone displayError={displayError} errors={errors}>
                     <PhoneField
                      dispatch={dispatch}
                      handle={action.setProductManagerPhone}
                      placeholder="Product Manager Phone *"
                      value={state.productManagerPhone}
                      required
                      error={isPhoneError}
                      />
                  </FieldValidator> 
                </div>               
                <div className="field-group">
                  <FieldValidator email displayError={displayError} errors={errors}>
                    <OutlinedFreesoloAutocomplete
                      dispatch={dispatch}
                      freeSolo
                      handle={action.setDevLeadEmail}
                      label="Development Lead Email"
                      loading={sharePointUsers.pending}
                      options={sharePointUsers.data.map(u => u.email)}
                      required
                      value={state.devLeadEmail}
                    />
                  </FieldValidator>
                  <FieldValidator phone displayError={displayError} errors={errors}>
                    <PhoneField
                      dispatch={dispatch}
                      handle={action.setDevLeadPhone}
                      placeholder="Development Lead Phone *"
                      value={state.devLeadPhone}
                      required
                      error={isPhoneError}
                    />
                  </FieldValidator>
                </div>
                <div className="field-group">
                  <FieldValidator email displayError={displayError} errors={errors}>
                    <OutlinedFreesoloAutocomplete
                      dispatch={dispatch}
                      freeSolo
                      handle={action.setPrimaryDevEmail}
                      label="Primary Developer Contact Email"
                      loading={sharePointUsers.pending}
                      options={sharePointUsers.data.map(u => u.email)}
                      required
                      value={state.primaryDevEmail}
                    />
                  </FieldValidator>
                  <FieldValidator phone displayError={displayError} errors={errors}>
                    <PhoneField
                      dispatch={dispatch}
                      handle={action.setPrimaryDevPhone}
                      placeholder="Primary Developer Contact Phone *"
                      value={state.primaryDevPhone}
                      required
                      error={isPhoneError}
                     />
                   </FieldValidator>
                </div>
                <div className="field-group">
                  <FieldValidator email displayError={displayError} errors={errors}>
                    <OutlinedFreesoloAutocomplete
                      dispatch={dispatch}
                      freeSolo
                      handle={action.setSecondaryDevEmail}
                      label="Secondary Developer Contact Email"
                      loading={sharePointUsers.pending}
                      options={sharePointUsers.data.map(u => u.email)}
                      required
                      value={state.secondaryDevEmail}
                    />
                  </FieldValidator>
                  <FieldValidator phone displayError={displayError} errors={errors}>
                    <PhoneField
                      dispatch={dispatch}
                      handle={action.setSecondaryDevPhone}
                      placeholder="Secondary Developer Contact Phone *"
                      value={state.secondaryDevPhone}
                      required
                      error={isPhoneError}
                     />
                  </FieldValidator>
                </div>
                <div className="field-group">
                  <FieldValidator email displayError={displayError} errors={errors}>
                    <OutlinedFreesoloAutocomplete
                      dispatch={dispatch}
                      freeSolo
                      handle={action.setQaLeadEmail}
                      label="QA Lead Email"
                      loading={sharePointUsers.pending}
                      options={sharePointUsers.data.map(u => u.email)}
                      value={state.qaLeadEmail}
                      required
                    />
                  </FieldValidator>
                  <FieldValidator phone displayError={displayError} errors={errors}>
                    <PhoneField
                      dispatch={dispatch}
                      handle={action.setQaLeadPhone}
                      placeholder="QA Lead Phone *"
                      value={state.qaLeadPhone}
                      required
                      error={isPhoneError}
                     />
                  </FieldValidator>
                </div>
              </InputContainer>
            </FormSection>
            <FormSection title="Approval process">
              <InputContainer
                description="Required. Select two peer reviewers that will be included
                in the approval process"
              >
                <FieldValidator displayError={displayError} errors={errors}>
                  <OutlinedAutocomplete<UserResponse>
                    dispatch={dispatch}
                    getOptionLabel={(u: UserResponse): string => u.name}
                    handle={action.setPrimaryPeerReviewer}
                    label="Peer Reviewer #1"
                    loading={users.pending}
                    options={peerReviewers}
                    required
                    value={state.primaryPeerReviewer}
                  />
                </FieldValidator>
                <FieldValidator displayError={displayError} errors={errors}>
                  <OutlinedAutocomplete<UserResponse>
                    dispatch={dispatch}
                    getOptionLabel={(u: UserResponse): string => u.name}
                    handle={action.setSecondaryPeerReviewer}
                    label="Peer Reviewer #2"
                    loading={users.pending}
                    options={peerReviewers}
                    required
                    value={state.secondaryPeerReviewer}
                  />
                </FieldValidator>
              </InputContainer>
              <InputContainer
                description="Final approver. It is defined in system configuration 
                and assigned automatically"
              >
                <OutlinedAutocomplete<UserResponse>
                  disabled
                  getOptionLabel={(u: UserResponse): string => u.name}
                  label="Final Approver"
                  options={[]}
                  required
                  value={state.finalApprover}
                />
              </InputContainer>
            </FormSection>
            <div className="align-right">
              <Button color="primary" onClick={handleSFChanges} variant="outlined">
                {serviceForms.creating || serviceForms.updating ? "Creating..." : "Complete"}
              </Button>
            </div>
          </Paper>
        </ThemeProvider>
      </Page>
    );

  return <FormRequestSkeleton />;
}

function mapStateToProps(state: ApplicationState): unknown {
  return {
    azureDevOpsProjects: state.azureDevOpsProjects,
    azureDevOpsTeams: state.azureDevOpsTeams,
    octopusARMSteps: state.octopusARMSteps,
    octopusTemplateProjects: state.octopusTemplateProjects,
    products: state.products,
    serviceForms: state.serviceForms,
    sharePointUsers: state.sharePointUsers,
    regions: state.regions,
    users: state.users
  };
}

function mapDispatchToProps(dispatch: Redux.Dispatch<Redux.AnyAction>): unknown {
  return Redux.bindActionCreators(
    {
      createServiceForm: createServiceFormDispatch,
      getAllDevOpsProjects: getAllDevOpsProjectsDispatch,
      getAllDevOpsTeams: getAllDevOpsTeamsDispatch,
      getAllProducts: getAllProductsDispatch,
      getAllTemplateProjects: getAllTemplateProjectsDispatch,
      getAllUsers: getAllUsersWithRolesDispatch,
      getARMSteps: getARMStepsDispatch,
      getSharePointUsers: getSharePointUsersDispatch,
      getRegions: getRegionsDispatch,
      updateServiceForm: updateServiceFormDispatch,
      updateServiceFormPeerReviewers: updateServiceFormPeerReviewersDispatch
    },
    dispatch
  );
}

export const FormRequest = connect(mapStateToProps, mapDispatchToProps)(FormRequestPage);
