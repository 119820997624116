import * as React from "react";
import "./Forms.scss";

import { Grid } from "@material-ui/core";

import { ServiceFormCard } from "./ServiceFormCard";
import { ServiceFormContainerSkeletonProps } from "./types";

export function ServiceFormsContainerSkeleton(
  props: ServiceFormContainerSkeletonProps
): JSX.Element {
  const { cardCount } = props;

  function fill(elementCount: number): JSX.Element[] {
    const elements: JSX.Element[] = [];

    for (let i = 0; i < elementCount; i += 1) {
      elements.push(
        <Grid key={i} className="flex-self-stretch" item xs={12}>
          <ServiceFormCard loading />
        </Grid>
      );
    }

    return elements;
  }

  return (
    <Grid
      alignItems="flex-start"
      className="flex-self-stretch"
      container
      direction="column"
      spacing={1}
    >
      {fill(cardCount)}
    </Grid>
  );
}
