import { SeqKeysToCreateState, SeqAction } from "./types";
import {
  CREATE_SEQ_KEYS,
  CREATE_SEQ_KEYS_SUCCESS,
  CREATE_SEQ_KEYS_ERROR,
  CREATE_SEQ_KEYS_RESET,
  CREATE_SEQ_KEYS_PARTIAL_SUCCESS
} from "../constants";

export const initialSeqKeysToCreateState: SeqKeysToCreateState = {
  creating: false,
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export const createSeqKeyReducer = (
  state: SeqKeysToCreateState = initialSeqKeysToCreateState,
  action: SeqAction
): SeqKeysToCreateState => {
  switch (action.type) {
    case CREATE_SEQ_KEYS:
      return {
        ...state,
        creating: true,
        completed: false
      };
    case CREATE_SEQ_KEYS_PARTIAL_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload]
      };
    case CREATE_SEQ_KEYS_SUCCESS:
      return {
        ...state,
        creating: false,
        completed: true
      };
    case CREATE_SEQ_KEYS_ERROR:
      return {
        ...state,
        creating: false,
        error: action.payload,
        completed: true
      };
    case CREATE_SEQ_KEYS_RESET:
      return {
        pending: false,
        completed: false,
        data: [],
        creating: false,
        error: undefined
      };
    default:
      return state;
  }
};
