// eslint-disable-next-line no-shadow
export enum DeployablesColumnsIds {
  GprId = "gprid",
  ConsumerGprId = "consumergprid",
  Deployable = "deployable",
  ProductManager = "productmanager",
  DevelopmentLead = "developmentlead",
  ProjectType = "projecttype",
  ResourceGroup = "resourcegroup",
  ResourceGroupNameBase = "resourcegroupnamebase",
  AdvancementUnit = "advancementunit",
  Environment = "environment",
  SubscriptionName = "subscriptionname"
}

export enum DeployablesColumnsText {
  GprId = "GPR ID",
  ConsumerGprId = "Consumer GPR ID",
  Deployable = "Deployable",
  ProductManager = "Product Manager",
  DevelopmentLead = "Development Lead",
  ProjectType = "Project Type",
  ResourceGroup = "Resource Group",
  ResourceGroupNameBase = "Resource Group name base",
  AdvancementUnit = "Advancement Unit",
  Environment = "Environment",
  SubscriptionName = "Subscription Name"
}

export enum AzureDevOpsYmlPipelineProject {
    iModelTechnologies = "iModelTechnologies"
}