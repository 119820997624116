/* eslint-disable react/require-default-props */
import * as React from "react";
import { OctopusARMStepResponse } from "../models";

export const emailRegex =
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
export const projectNameRegex = new RegExp('[<>?"|:{}*\\\\]');
export const phoneNumberRegex = /^$|^[+][\d ]+$/;

export function validateWithRegex(value: string, regex: RegExp): boolean {
  if (value === "") return false;
  return regex.test(value);
}

export function validateEmail(email: string): boolean {
  return validateWithRegex(email, emailRegex);
}

export function validateWithRegexOpp(value: string, regex: RegExp): boolean {
  if (value === "") return false;
  return !regex.test(value);
}

export function validateNotEmpty(value: string): boolean {
  return value !== "";
}

export function validateAdditionalResourcesNoDuplicates(
  selectedArms: OctopusARMStepResponse[],
  projectArmSteps: string[]
): boolean {
  return selectedArms.some(r => projectArmSteps.indexOf(r.name) >= 0);
}

interface FieldValidationOptions {
  allowEmpty?: boolean;
  email?: boolean;
  phone?: boolean;
  projectName?: boolean;
  children: JSX.Element;
  displayError: boolean;
  errors: string[];
  disabled?: boolean;
}

export function FieldValidator(props: FieldValidationOptions): JSX.Element {
  const { allowEmpty, email, phone, projectName, children, displayError, errors, disabled } = props;
  let newProps;
  const indexOfError = errors.indexOf(children.props.label);
  let errorsFound = false;
  if (
    (!allowEmpty &&
      (children.props.value === null ||
        children.props.value === undefined ||
        children.props.value === "" ||
        (children.props.value && children.props.value.length === 0))) ||
    (email && validateWithRegexOpp(children.props.value, emailRegex)) ||
    (phone && validateWithRegexOpp(children.props.value, phoneNumberRegex)) ||
    (projectName && validateWithRegex(children.props.value, projectNameRegex))
  )
    errorsFound = true;
  if (errorsFound && !disabled) {
    if (displayError) newProps = { error: true };
    if (indexOfError === -1) errors.push(children.props.label);
  } else if (indexOfError !== -1 || disabled) errors.splice(indexOfError, 1);

  const child: JSX.Element = React.cloneElement(children, newProps);
  return <div>{child}</div>;
}
