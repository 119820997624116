import * as Redux from "redux";
import { RequestBuilder } from "../utilities/RequestBuilder";
import * as SeqActions from "../redux/actions/seqActions";
import { SeqAction } from "../redux/reducers/types";
import { SeqKeysToCreateRequest } from "../models/seqRequests";

export function createSeqKeyDispatch(bodies: SeqKeysToCreateRequest[]) {
  return async (dispatch: Redux.Dispatch<SeqAction>): Promise<void> => {
    try {
      bodies.forEach(async body => {
        dispatch(SeqActions.createSeqKeys());
        const request = new RequestBuilder()
          .setRequestUrl("api/seq/apikey")
          .setMethod("POST")
          .setBody(body)
          .build();

        const response = await request.send();
        if (!response.ok) {
          const error = await response.json();
          throw Error(JSON.stringify(error.Message));
        }
        const instance = await response.json();
        dispatch(
          SeqActions.createSeqKeysPartialSuccess({
            token: instance.token,
            environment: body.environment
          })
        );
        if (bodies.indexOf(body) === bodies.length - 1) dispatch(SeqActions.createSeqKeysSuccess());
      });
    } catch (error) {
      dispatch(SeqActions.createSeqKeysError(error));
    }
  };
}

export function seqKeysToCreateStateResetDispatch() {
  return async (dispatch: Redux.Dispatch<SeqAction>): Promise<void> => {
    dispatch(SeqActions.seqKeysToCreateReset());
  };
}
