/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-props-no-spreading */

import * as React from "react";

import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import {
  ButtonGroup,
  Button,
  Grid,
  FormControlLabel,
  Switch,
  FormGroup,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";

import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { AzureDevOpsYmlPipelineProject } from "../../../constants/deployablesColumns";
import * as actions from "./actions";
import {
  OutlinedTextField,
  OutlinedAutocomplete,
  OutlinedMultipleAutocomplete,
  OutlinedFreesoloAutocomplete
} from "../../Inputs";
import {
  DevOpsProjectResponse,
  OctopusProjectResponse,
  OctopusARMStepResponse,
  OctopusLibraryVariableSetResponse,
  OctopusProjectGroupResponse,
  OctopusLifecycleResponse,
  RegionConfigurationResponse,
  ProductResponse} from "../../../models";
import { ServiceBaseProps } from "./types";
import { FieldValidator } from "../../../utilities/FieldValidator";

export type DevOpsTeamOptionType = { name: string; inputValue?: string };

export function ServiceBase(props: ServiceBaseProps): JSX.Element {
  const {
    additionalResources,
    azureDevOpsProjects,
    azureDevOpsTeams,
    deployableName,
    isOctopusRequired,
    devOpsProject,
    devOpsTeamName,
    dispatch,
    gitRepoName,
    isBuildPipelineRequired,
    isCloneEnabled,
    isGitRepoRequired,
    isReleasePipelineRequired,
    isEscalationContactRequired,
    lifecycle,
    octopusARMSteps,
    octopusLibraries,
    octopusLifecycles,
    octopusProject,
    octopusProjects,
    octopusProjectGroups,
    octopusTemplateProjects,
    projectGroup,
    projectType,
    regions,
    region,
    subscription,
    products,
    product,
    serviceForm,
    buildFolderPath,
    errors,
    displayError
  } = props;

  function onDragEnd(result: DropResult): void {
    if (result.destination && dispatch) {
      const reordered = Array.from(additionalResources);
      const removed = reordered.splice(result.source.index, 1);
      reordered.splice(result.destination.index, 0, ...removed);
      dispatch(actions.setAdditionalResources(reordered));
    }
  }

  const filter = createFilterOptions<DevOpsTeamOptionType>();

  React.useEffect(() => {
    if (octopusProject && octopusProjectGroups.data) {
      let projectGroup = octopusProjectGroups.data.find(
        pg => pg.id === octopusProject.projectGroupId
      );
      if (dispatch) dispatch(actions.setSetProjectGroup(projectGroup || null));
    }
    if (octopusProject && octopusLifecycles.data) {
      let lifecycle = octopusLifecycles.data.find(pg => pg.id === octopusProject.lifecycleId);
      if (dispatch) dispatch(actions.setLifecycle(lifecycle || null));
    }
  }, [octopusProject]);

  React.useEffect(() => {
    if (octopusLibraries.completed && octopusLibraries.data) {
      let defaultSub = octopusLibraries.data.find(x => x.title === "Connect - EUS");
      if (defaultSub && dispatch) dispatch(actions.setSubscription(defaultSub));
    }
  }, [octopusLibraries.completed]);

  return (
    <div>
      <ButtonGroup color="primary">
        <Button
          disableElevation
          onClick={(): void => {
            if (dispatch) dispatch(actions.setIsCloneEnabled(false));
          }}
          variant={!isCloneEnabled ? "contained" : "outlined"}
        >
          Provision new service
        </Button>
        <Button
          disableElevation
          onClick={(): void => {
            if (dispatch) {
              dispatch(actions.setIsCloneEnabled(true));
              dispatch(actions.setIsOctopusRequired(true));
            }
          }}
          variant={isCloneEnabled ? "contained" : "outlined"}
        >
          Clone an existing service
        </Button>
      </ButtonGroup>
      <div className="mt-4">
        <Grid container direction="row" spacing={10}>
          <Grid item xs={4}>
            <Typography
              className="text-uppercase font-weight-bold"
              color="textSecondary"
              variant="body2"
            >
              Main
            </Typography>
            <Divider className="mt-2 mb-2" />
            <FieldValidator errors={errors} displayError={displayError}>
              <OutlinedAutocomplete<ProductResponse>
                dispatch={dispatch}
                disabled={serviceForm !== null}
                getOptionLabel={(p: ProductResponse): string => `${p.productId} - ${p.productName}`}
                handle={actions.setProduct}
                label="Product"
                helperText="Select product from Global Product Registry site (GPRID - Product Name)"
                loading={products.pending}
                options={products.data}
                required
                value={product}
              />
            </FieldValidator>
            <FieldValidator errors={errors} displayError={displayError}>
              <OutlinedTextField
                dispatch={dispatch}
                disabled={serviceForm !== null}
                handle={actions.setDeployableName}
                label="Deployable Name"
                helperText="Octopus project/deployable name"
                required
                value={deployableName}
              />
            </FieldValidator>
            <br/>
            <Typography
              className="text-uppercase font-weight-bold"
              color="textSecondary"
              variant="body2"
            >
              Octopus
            </Typography>
            <Divider className="mt-2 mb-2" />
            {!isCloneEnabled ?
              (<FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={isOctopusRequired}
                    />
                  }
                  onClick={(): void => {
                    if (dispatch)
                      dispatch(actions.setIsOctopusRequired(!isOctopusRequired));
                  }}
                  label="Is new Octopus project required"
                />
              </FormGroup>) : null}
            {isCloneEnabled ? (
              <FieldValidator errors={errors} displayError={displayError}>
                <OutlinedAutocomplete<OctopusProjectResponse>
                  dispatch={dispatch}
                  getOptionLabel={(p: OctopusProjectResponse): string => p.name}
                  handle={actions.setOctopusProject}
                  helperText="Select existing Octopus project for cloning"
                  label="Octopus Project"
                  loading={octopusProjects.pending}
                  options={octopusProjects.data}
                  required
                  value={octopusProject}
                />
              </FieldValidator>
            ) : (
              <FieldValidator errors={errors} displayError={displayError} disabled={!isOctopusRequired}>
                <OutlinedAutocomplete<OctopusProjectResponse>
                  dispatch={dispatch}
                  disabled={serviceForm !== null || !isOctopusRequired}
                  getOptionLabel={(p: OctopusProjectResponse): string => p.name}
                  handle={actions.setProjectType}
                  helperText="Select base Octopus project type"
                  label="Project Type"
                  loading={octopusTemplateProjects.pending}
                  options={octopusTemplateProjects.data}
                  required
                  value={projectType}
                />
              </FieldValidator>
            )}
            <FieldValidator errors={errors} displayError={displayError} disabled={!isOctopusRequired}>
              <OutlinedAutocomplete<OctopusProjectGroupResponse>
                dispatch={dispatch}
                disabled={!isOctopusRequired}
                getOptionLabel={(p: OctopusProjectGroupResponse): string => p.name}
                handle={actions.setSetProjectGroup}
                label="Project Group"
                helperText="Octopus project group"
                loading={octopusProjectGroups.pending}
                options={octopusProjectGroups.data}
                required
                value={projectGroup}
              />
            </FieldValidator>
            <FieldValidator errors={errors} displayError={displayError} disabled={!isOctopusRequired}>
              <OutlinedAutocomplete<OctopusLifecycleResponse>
                dispatch={dispatch}
                disabled={!isOctopusRequired}
                getOptionLabel={(p: OctopusLifecycleResponse): string => p.name}
                handle={actions.setLifecycle}
                label="Lifecycle"
                loading={octopusLifecycles.pending}
                options={octopusLifecycles.data}
                helperText="Octopus project release lifecycle"
                required
                value={lifecycle}
              />
            </FieldValidator>
            <FieldValidator errors={errors} displayError={displayError} disabled={!isOctopusRequired}>
              <OutlinedAutocomplete<RegionConfigurationResponse>
                getOptionLabel={(p: RegionConfigurationResponse): string => p.fullName}
                dispatch={dispatch}
                disabled={serviceForm !== null || !isOctopusRequired}
                handle={actions.setRegion}
                label="Region"
                helperText="Select region in which your service will be deployed"
                loading={octopusProjects.pending}
                options={regions.data}
                value={region}
              />
            </FieldValidator>

            {!isCloneEnabled ? (
              <FieldValidator errors={errors} displayError={displayError} disabled={!isOctopusRequired}>
                <OutlinedAutocomplete<OctopusLibraryVariableSetResponse>
                  dispatch={dispatch}
                  disabled={!isOctopusRequired}
                  getOptionLabel={(p: OctopusLibraryVariableSetResponse): string => p.title}
                  handle={actions.setSubscription}
                  label="Subscription"
                  helperText="Library variable sets will be added based on selected subscription"
                  loading={octopusLibraries.pending}
                  options={octopusLibraries.data}
                  required
                  value={subscription}
                />
              </FieldValidator>
            ) : null}
            {!isCloneEnabled ? (
              <OutlinedMultipleAutocomplete<OctopusARMStepResponse>
                dispatch={dispatch}
                disabled={serviceForm !== null || !isOctopusRequired}
                getOptionLabel={(p: OctopusARMStepResponse): string => p.name}
                handle={actions.setAdditionalResources}
                helperText="All additional resources are deployed before base resource"
                label="Additional Resources"
                loading={octopusARMSteps.pending}
                multiple
                options={octopusARMSteps.data}
                value={additionalResources}
              />
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <Typography
              className="text-uppercase font-weight-bold"
              color="textSecondary"
              variant="body2"
            >
              Azure DevOps
            </Typography>
            <Divider className="mt-2 mb-2" />
            <FieldValidator
              errors={errors}
              displayError={displayError}
              disabled={isCloneEnabled || (serviceForm ? true : false)}
            >
              <OutlinedAutocomplete<DevOpsProjectResponse>
                disabled={isCloneEnabled || (serviceForm ? true : false)}
                dispatch={dispatch}
                getOptionLabel={(p: DevOpsProjectResponse): string => p.name}
                handle={actions.setDevOpsProject}
                label="Azure DevOps Project"
                helperText='Select Azure DevOps project'
                loading={azureDevOpsProjects.pending}
                              options={azureDevOpsProjects.data}
                required
                value={devOpsProject}
              />
            </FieldValidator>
            <FieldValidator
              errors={errors}
              displayError={displayError}
              disabled={isCloneEnabled || !devOpsProject || (serviceForm ? true : false)}
            >
              <OutlinedFreesoloAutocomplete
                disabled={isCloneEnabled || !devOpsProject || (serviceForm ? true : false)}
                dispatch={dispatch}
                handle={actions.setDevOpsTeamName}
                helperText="Select existing team or provide new team name"
                label="Azure DevOps Team"
                loading={azureDevOpsTeams.pending}
                options={azureDevOpsTeams.data
                  .filter(team => team.projectId === devOpsProject?.id)
                  .map(team => team.name)}
                required
                value={devOpsTeamName}
              />
            </FieldValidator>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={isBuildPipelineRequired}
                    disabled={isCloneEnabled}
                  />
                }
                onClick={(): void => {
                  if (dispatch && !isCloneEnabled)
                    dispatch(actions.setIsBuildPipelineRequired(!isBuildPipelineRequired));
                }}
                label="Is new Azure DevOps build definition required"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={devOpsProject?.name === AzureDevOpsYmlPipelineProject.iModelTechnologies ? false : isReleasePipelineRequired}
                    disabled={isCloneEnabled}
                  />
                }
                onClick={(): void => {
                    if (dispatch && !isCloneEnabled)
                    dispatch(actions.setIsReleasePipelineRequired(!isReleasePipelineRequired));
                }}
                label="Is new Azure DevOps release definition required"
              />
            </FormGroup>
            <FieldValidator
              errors={errors}
              displayError={displayError}
              disabled={isCloneEnabled || (!isBuildPipelineRequired && !isReleasePipelineRequired)}
            >
              <OutlinedTextField
                dispatch={dispatch}
                disabled={
                  isCloneEnabled || (!isBuildPipelineRequired && !isReleasePipelineRequired)
                }
                handle={actions.setBuildFolderPath}
                label="Folder Path"
                helperText="Provide a Build/Release Folder Path (i.e. \WebApp, \MyProject\Test)"
                required
                value={buildFolderPath}
              />
            </FieldValidator>
            <FormGroup>
              <FormControlLabel
                              control={
                  <Switch color="primary" checked={isGitRepoRequired} disabled={isCloneEnabled} />
                }
                onClick={(): void => {
                  if (dispatch && !isCloneEnabled)
                    dispatch(actions.setIsGitRepoRequired(!isGitRepoRequired));
                }}
                label="Is new Git repository required"
              />
              <FieldValidator
                errors={errors}
                displayError={displayError}
                disabled={isCloneEnabled || (!isGitRepoRequired && !isBuildPipelineRequired)}
              >
                <OutlinedTextField
                  disabled={isCloneEnabled || (!isGitRepoRequired && !isBuildPipelineRequired)}
                  dispatch={dispatch}
                  handle={actions.setGitRepoName}
                  label="Git Repository Name"
                  helperText={
                    isGitRepoRequired
                      ? "Provide name of new Git repository"
                      : "Git repository is required for build pipeline creation. Provide name of existing Git repository"
                  }
                  required
                  value={gitRepoName}
                />
              </FieldValidator>
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <Typography
              className="text-uppercase font-weight-bold"
              color="textSecondary"
              variant="body2"
            >
              Deployment order
            </Typography>
            <Divider className="mt-2 mb-2" />
            {additionalResources.length < 2 || isCloneEnabled ? (
              <Typography color="textSecondary" variant="caption">
                Requires at least 2 additional resources selected. Not required when cloning an
                existing service.
              </Typography>
            ) : (
              <div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided): JSX.Element => (
                      <List ref={provided.innerRef} {...provided.droppableProps}>
                        {additionalResources.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(p, s): JSX.Element => (
                              <ListItem
                                className={`${
                                  s.isDragging
                                    ? "draggable-resource-is-dragging"
                                    : "draggable-resource"
                                } ${index !== 0 ? "mt-2" : ""}`}
                                ref={p.innerRef}
                                {...p.draggableProps}
                                {...p.dragHandleProps}
                              >
                                <ListItemText primary={`${index + 1}. ${item.name}`} />
                              </ListItem>
                            )}
                          </Draggable>
                        ))}
                      </List>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
            <br/><br/>
            <Typography
              className="text-uppercase font-weight-bold"
              color="textSecondary"
              variant="body2"
            >
              Additional items
            </Typography>
            <Divider className="mt-2 mb-2" />
          <FormGroup>
                <FormControlLabel
                    control={
                      <Switch color="primary" 
                      checked={isEscalationContactRequired} 
                      disabled={isCloneEnabled} />
                    }
                    onClick={(): void => {
                    if (dispatch && !isCloneEnabled)
                        dispatch(actions.setIsEscalationContactsRequired(!isEscalationContactRequired));
                    }}
                    label="Create escalation contacts in SharePoint Site"
            /></FormGroup>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
