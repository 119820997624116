export const GET_ALL_SERVICES = "GET_ALL_SERVICES";
export const GET_ALL_SERVICES_SUCCESS = "GET_ALL_SERVICES_SUCCESS";
export const GET_ALL_SERVICES_ERROR = "GET_ALL_SERVICES_ERROR";

export const CREATE_SERVICE = "CREATE_SERVICE";
export const CREATE_SERVICE_SUCCESS = "CREATE_SERVICE_SUCCESS";
export const CREATE_SERVICE_ERROR = "CREATE_SERVICE_ERROR";

export const CREATE_SCHEDULE = "CREATE_SCHEDULE";
export const CREATE_SCHEDULE_SUCCESS = "CREATE_SCHEDULE_SUCCESS";
export const CREATE_SCHEDULE_ERROR = "CREATE_SCHEDULE_ERROR";

export const CREATE_SERVICE_RESET = "CREATE_SERVICE_RESET";
export const CREATE_SCHEDULE_RESET = "CREATE_SCHEDULE_RESET";
