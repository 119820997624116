import {
  EXECUTE_DEPLOYABLES_QUERY,
  EXECUTE_DEPLOYABLES_QUERY_ERROR,
  EXECUTE_DEPLOYABLES_QUERY_SUCCESS,
  GET_ALL_DEPLOYABLES,
  GET_ALL_DEPLOYABLES_ERROR,
  GET_ALL_DEPLOYABLES_SUCCESS,
  UPDATE_DEPLOYABLES,
  UPDATE_DEPLOYABLES_ERROR,
  UPDATE_DEPLOYABLES_SUCCESS
} from "../constants/deployablesActionTypes";
import { DeployablesAction, DeployablesState } from "./types";

export const initialDeployablesState: DeployablesState = {
  pending: false,
  completed: false,
  data: [],
  error: undefined
};

export function deployablesReducer(
  state: DeployablesState = initialDeployablesState,
  action: DeployablesAction
): DeployablesState {
  switch (action.type) {
    case GET_ALL_DEPLOYABLES:
      return {
        ...state,
        pending: true,
        completed: false
      };
    case GET_ALL_DEPLOYABLES_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_ALL_DEPLOYABLES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    case UPDATE_DEPLOYABLES:
      return {
        ...state,
        pending: true,
        completed: false
      };
    case UPDATE_DEPLOYABLES_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case UPDATE_DEPLOYABLES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    case EXECUTE_DEPLOYABLES_QUERY:
      return {
        ...state,
        pending: true,
        completed: false
      };
    case EXECUTE_DEPLOYABLES_QUERY_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case EXECUTE_DEPLOYABLES_QUERY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
        completed: true
      };
    default:
      return state;
  }
}
