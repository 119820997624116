import * as React from "react";
import "./NavigationBar.scss";

import {
  Avatar,
  Button,
  Fade,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  Divider
} from "@material-ui/core";
import { ColorLens, VpnKey, PowerSettingsNew } from "@material-ui/icons";

import { UserRoles } from "../Dialogs";

import { UserMenuProps, useStyles } from "./types";

export function getInitials(fullName: string | undefined): string | undefined {
  if (fullName) {
    const names = fullName.split(" ");
    if (names.length === 2) {
      return `${names[0][0].toLocaleUpperCase()}${names[1][0].toLocaleUpperCase()}`;
    }
    if (names.length === 1) {
      return names[0][0].toLocaleUpperCase();
    }
  }

  return undefined;
}

export function UserMenu(props: UserMenuProps): JSX.Element {
  const {
    onButtonClick,
    onLogoutClick,
    onMenuClose,
    onThemeSwitch,
    userRoles,
    menuAnchorRef,
    userEmail,
    userName
  } = props;

  const classes = useStyles();
  const initials = getInitials(userName);

  const [userRolesDialogOpen, setUserRolesDialogOpen] = React.useState(false);

  function closeDialog(): void {
    setUserRolesDialogOpen(false);
  }

  function openDialog(): void {
    setUserRolesDialogOpen(true);
  }

  return (
    <div>
      <Button onClick={onButtonClick}>
        <Avatar alt={userName} className={classes.avatar}>
          {initials}
        </Avatar>
      </Button>
      <Menu
        anchorEl={menuAnchorRef}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        keepMounted={false}
        getContentAnchorEl={null}
        open={Boolean(menuAnchorRef)}
        onClose={onMenuClose}
        TransitionComponent={Fade}
      >
        <div className="navigation-user-menu-content">
          <div className="navigation-user-menu-intro">
            <Typography variant="h6">{userName}</Typography>
            <Typography variant="body2">{userEmail}</Typography>
          </div>
          <div className="margin-bottom-8">
            <Divider />
          </div>
          <MenuItem onClick={openDialog}>
            <ListItemIcon>
              <VpnKey />
            </ListItemIcon>
            <Typography noWrap variant="inherit">
              My roles
            </Typography>
          </MenuItem>
          <MenuItem onClick={onThemeSwitch}>
            <ListItemIcon>
              <ColorLens />
            </ListItemIcon>
            <Typography noWrap variant="inherit">
              Switch theme
            </Typography>
          </MenuItem>
          <MenuItem onClick={onLogoutClick}>
            <ListItemIcon>
              <PowerSettingsNew />
            </ListItemIcon>
            <Typography noWrap variant="inherit">
              Logout
            </Typography>
          </MenuItem>
        </div>
      </Menu>
      <UserRoles onClose={closeDialog} open={userRolesDialogOpen} userRoles={userRoles} />
    </div>
  );
}
