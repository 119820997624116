import { UserState, UserAction } from "./types";
import {
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
  GET_ALL_USERS_WITH_ROLES,
  GET_ALL_USERS_WITH_ROLES_ERROR,
  GET_ALL_USERS_WITH_ROLES_SUCCESS,
  UPDATE_USER_ROLE,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_ERROR,
  SET_PRIMARY_USER,
  SET_PRIMARY_USER_SUCCESS,
  SET_PRIMARY_USER_ERROR
} from "../constants";

export const initialUserState: UserState = {
  pending: false,
  updating: false,
  completed: false,
  deleting: false,
  data: [],
  error: undefined
};

export const userReducer = (state: UserState = initialUserState, action: UserAction): UserState => {
  switch (action.type) {
    case GET_ALL_USERS:
    case GET_ALL_USERS_WITH_ROLES:
    case DELETE_USER:
      return{
        ...state,
        deleting: true
      }
    case UPDATE_USER_ROLE:
    case SET_PRIMARY_USER:
      return {
        ...state,
        updating: true
      };
    case GET_ALL_USERS_SUCCESS:
    case GET_ALL_USERS_WITH_ROLES_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        completed: true
      };
    case GET_ALL_USERS_ERROR:
    case GET_ALL_USERS_WITH_ROLES_ERROR:
    case DELETE_USER_ERROR:
    case UPDATE_USER_ROLE_ERROR:
    case SET_PRIMARY_USER_ERROR:
      return {
        ...state,
        updating: false,
        error: action.payload,
        completed: true
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.filter(user => user.id !== action.payload.id),
        completed: true
      };
    case UPDATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        updating: false,
        data: state.data.map(user => (user.id === action.payload.id ? action.payload : user)),
        completed: true
      };
    case SET_PRIMARY_USER_SUCCESS:
      return {
        ...state,
        updating: false,
        completed: true
      };
    default:
      return state;
  }
};
