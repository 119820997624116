/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { action } from "typesafe-actions";
import {
  BUDDIRegionResponse,
  BUDDIUrlResponse,
  BUDDIRegionalUrlResponse
} from "../../models/BUDDIResponses";
import * as actions from "../constants/BUDDIActionTypes";

export function getRegions() {
  return action(actions.GET_BUDDI_REGIONS);
}

export function getRegionsSuccess(users: BUDDIRegionResponse[]) {
  return action(actions.GET_BUDDI_REGIONS_SUCCESS, users);
}

export function getRegionsError(error: Error) {
  return action(actions.GET_BUDDI_REGIONS_ERROR, error);
}

export function getUrls() {
  return action(actions.GET_BUDDI_URLS);
}

export function getUrlsSuccess(users: BUDDIUrlResponse[]) {
  return action(actions.GET_BUDDI_URLS_SUCCESS, users);
}

export function getUrlsError(error: Error) {
  return action(actions.GET_BUDDI_URLS_ERROR, error);
}

export function getRegionalUrls() {
  return action(actions.GET_BUDDI_REGIONAL_URLS);
}

export function getRegionalUrlsSuccess(users: BUDDIRegionalUrlResponse[]) {
  return action(actions.GET_BUDDI_REGIONAL_URLS_SUCCESS, users);
}

export function getRegionalUrlsError(error: Error) {
  return action(actions.GET_BUDDI_REGIONAL_URLS_ERROR, error);
}
