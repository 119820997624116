import * as React from "react";
import { Link } from "react-router-dom";
import "./NavigationBar.scss";

import { AppBar, Tab, Tabs } from "@material-ui/core";

import { RoleResponse } from "../../models/roleResponses";
import { AppContext } from "../../context/AppContext";
import { AuthContext } from "../../context/AuthContext";
import { HOME, FORMS, SETTINGS, PROVISION, ESCALATION_CONTACTS, DEPLOYABLES_EDIT } from "../../constants/paths";
import { UserMenu } from "./UserMenu";
import { ADMIN } from "../../constants/userRoles";

export function isAllowed(allowed: string[], roles?: RoleResponse[]): boolean {
  if (!roles) return false;
  return roles.some(role => allowed.indexOf(role.name) >= 0);
}

export function NavigationBar(): JSX.Element {
  const { darkMode, selectedTab, setDarkMode, setSelectedTab } = React.useContext(AppContext);
  const { logout, user, roles } = React.useContext(AuthContext);

  const [userMenuAnchor, setUserMenuAnchor] = React.useState<null | HTMLElement>(null);

  function handleLogout(): void {
    if (logout) logout();
  }

  function handleTabSelection(event: React.ChangeEvent<{}>, value: number): void {
    if (setSelectedTab) setSelectedTab(value);
  }

  function handleThemeSwitch(): void {
    if (setDarkMode) setDarkMode(!darkMode);
  }

  function handleUserMenuClose(): void {
    setUserMenuAnchor(null);
  }

  function handleUserMenuOpen(event: React.MouseEvent<HTMLButtonElement>): void {
    setUserMenuAnchor(event.currentTarget);
  }

  return (
    <AppBar>
      <div className="navigation-app-bar-content">
        <Tabs onChange={handleTabSelection} value={selectedTab}>
          <Tab component={Link} label="Home" to={HOME} />
          <Tab component={Link} label="Cloud Service Requests" to={FORMS} />
          <Tab component={Link} label="Escalation Contacts" to={ESCALATION_CONTACTS} />
          {isAllowed([ADMIN], roles) && (
            <Tab
              component={Link}
              disabled={!isAllowed([ADMIN], roles)}
              label="Provision"
              to={PROVISION}
            />
          )}
          {isAllowed([ADMIN], roles) && (
            <Tab
              component={Link}
              disabled={!isAllowed([ADMIN], roles)}
              label="Deployables"
              to={DEPLOYABLES_EDIT}
            />
          )}
          {isAllowed([ADMIN], roles) && (
            <Tab
              component={Link}
              disabled={!isAllowed([ADMIN], roles)}
              label="Settings"
              to={SETTINGS}
            />
          )}
        </Tabs>
        <UserMenu
          onButtonClick={handleUserMenuOpen}
          onLogoutClick={handleLogout}
          onMenuClose={handleUserMenuClose}
          onThemeSwitch={handleThemeSwitch}
          menuAnchorRef={userMenuAnchor}
          userRoles={roles}
          userEmail={user?.email}
          userName={user?.name}
        />
      </div>
    </AppBar>
  );
}
