import * as Redux from "redux";
import { OctopusAction } from "../redux/reducers/types";
import * as OctopusActions from "../redux/actions/octopusActions";
import { RequestBuilder } from "../utilities/RequestBuilder";
import {
  OctopusProjectResponse,
  OctopusARMStepResponse,
  OctopusLibraryVariableSetResponse,
  OctopusLifecycleResponse,
  OctopusProjectGroupResponse
} from "../models/octopusResponses";
import { CreateOctopusProjectRequest, CloneOctopusProjectRequest } from "../models/octopusRequests";

export function getAllSubscriptionLibrariesDispatch() {
  return async (dispatch: Redux.Dispatch<OctopusAction>): Promise<void> => {
    dispatch(OctopusActions.getAllSubscriptionLibraries());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/octopus/library/subscriptions")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const libraries = (await response.json()) as OctopusLibraryVariableSetResponse[];

      dispatch(OctopusActions.getAllSubscriptionLibrariesSuccess(libraries));
    } catch (error) {
      dispatch(OctopusActions.getAllSubscriptionLibrariesError(error));
    }
  };
}

export function getAllLifecyclesDispatch() {
  return async (dispatch: Redux.Dispatch<OctopusAction>): Promise<void> => {
    dispatch(OctopusActions.getAllLifecycles());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/octopus/lifecycle")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const lifecycles = (await response.json()) as OctopusLifecycleResponse[];

      dispatch(OctopusActions.getAllLifecyclesSuccess(lifecycles));
    } catch (error) {
      dispatch(OctopusActions.getAllLifecyclesError(error));
    }
  };
}

export function getAllProjectGroupsDispatch() {
  return async (dispatch: Redux.Dispatch<OctopusAction>): Promise<void> => {
    dispatch(OctopusActions.getAllProjectGroups());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/octopus/projectgroups")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const projectGroups = (await response.json()) as OctopusProjectGroupResponse[];

      dispatch(OctopusActions.getAllProjectGroupsSuccess(projectGroups));
    } catch (error) {
      dispatch(OctopusActions.getAllProjectGroupsError(error));
    }
  };
}

export function getAllOctopusProjectsDispatch() {
  return async (dispatch: Redux.Dispatch<OctopusAction>): Promise<void> => {
    dispatch(OctopusActions.getAllProjects());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/octopus/project")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const projects = (await response.json()) as OctopusProjectResponse[];

      dispatch(OctopusActions.getAllProjectsSuccess(projects));
    } catch (error) {
      dispatch(OctopusActions.getAllProjectsError(error));
    }
  };
}

export function getAllTemplateProjectsDispatch() {
  return async (dispatch: Redux.Dispatch<OctopusAction>): Promise<void> => {
    dispatch(OctopusActions.getAllTemplateProjects());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/octopus/project/template")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const projects = (await response.json()) as OctopusProjectResponse[];

      dispatch(OctopusActions.getAllTemplateProjectsSuccess(projects));
    } catch (error) {
      dispatch(OctopusActions.getAllTemplateProjectsError(error));
    }
  };
}

export function getARMStepsDispatch() {
  return async (dispatch: Redux.Dispatch<OctopusAction>): Promise<void> => {
    dispatch(OctopusActions.getARMSteps());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/octopus/steps/arm")
        .setMethod("GET")
        .build();

      const response = await request.send();
      const steps = (await response.json()) as OctopusARMStepResponse[];

      dispatch(OctopusActions.getARMStepsSuccess(steps));
    } catch (error) {
      dispatch(OctopusActions.getARMStepsError(error));
    }
  };
}

export function createOctoProjectDispatch(body: CreateOctopusProjectRequest) {
  return async (dispatch: Redux.Dispatch<OctopusAction>): Promise<void> => {
    dispatch(OctopusActions.createProject());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/octopus/project")
        .setMethod("POST")
        .setBody(body)
        .build();

      const response = await request.send();
      if (!response.ok) {
        const error = await response.json();
        throw Error(JSON.stringify(error.Message));
      }

      dispatch(OctopusActions.createProjectSuccess());
    } catch (error) {
      dispatch(OctopusActions.createProjectError(error));
    }
  };
}

export function cloneOctoProjectDispatch(body: CloneOctopusProjectRequest) {
  return async (dispatch: Redux.Dispatch<OctopusAction>): Promise<void> => {
    dispatch(OctopusActions.cloneProject());
    try {
      const request = new RequestBuilder()
        .setRequestUrl("api/octopus/project/clone")
        .setMethod("POST")
        .setBody(body)
        .build();

      const response = await request.send();
      if (!response.ok) {
        const error = await response.json();
        throw Error(JSON.stringify(error.Message));
      }

      dispatch(OctopusActions.cloneProjectSuccess());
    } catch (error) {
      dispatch(OctopusActions.cloneProjectError(error));
    }
  };
}

export function octopusProjectToCreateStateResetDispatch() {
  return async (dispatch: Redux.Dispatch<OctopusAction>): Promise<void> => {
    dispatch(OctopusActions.createOrCloneProjectReset());
  };
}
