import "typeface-roboto";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

import * as React from "react";

import { AuthContext } from "./context/AuthContext";
import { LoadingPage } from "./components/Pages/Loading";
import { UnauthenticatedPage } from "./components/Pages/Unauthenticated";
import { AuthenticatedApp } from "./AuthenticatedApp";
import { AppProps } from "./types";

export function App(props: AppProps): JSX.Element {
  const { store, history } = props;
  const { authenticated, inProgress } = React.useContext(AuthContext);

  if (inProgress) return <LoadingPage message="Authenticating..." />;
  return authenticated ? (
    <AuthenticatedApp store={store} history={history} />
  ) : (
    <UnauthenticatedPage />
  );
}
