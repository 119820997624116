import "./Page.scss";

import * as React from "react";
import { useHistory } from "react-router-dom";

import { IconButton, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { AuthContext } from "../../context/AuthContext";
import { isAllowed } from "../Navigation";
import { UnauthorizedPage } from "./Unauthorized";
import { PageProps } from "./types";

export function Page(props: PageProps): JSX.Element {
  const { goBack } = useHistory();
  const { back, children, allowedRoles, loading, title, className } = props;
  const { roles } = React.useContext(AuthContext);

  if (allowedRoles && !isAllowed(allowedRoles, roles)) {
    return <UnauthorizedPage requiredRoles={allowedRoles} />;
  }

  return (
    <div className={`page row justify-content-center ${className ?? ""}`}>
      <div className="d-flex col-10">
        {back && (
          <IconButton className="page-back-button" onClick={() => goBack()}>
            <ArrowBackIcon />
          </IconButton>
        )}
        {title && (
          <Typography className={`page-title ${back ? "ml-3" : ""}`} gutterBottom>
            {title}
          </Typography>
        )}
        {loading && <Skeleton animation="wave" variant="text" width={300} />}
      </div>
      <div className="page-content col-10">{children}</div>
    </div>
  );
}
