import { RequestBuilder } from "../../../utilities/RequestBuilder";
import { DeployablesQueryRequest } from "../../../models/deployablesRequests";

export async function getDeployablesQueryCount(body: DeployablesQueryRequest): Promise<string> {
  try {
    const request = new RequestBuilder()
      .setRequestUrl("api/deployablesquerycheck")
      .setMethod("POST")
      .setBody(body)
      .build();

    const response = await request.send();
    return (await response.text()).replaceAll('"', '');
  } catch (error) {
    return error.message;
  }
}