import "./Settings.scss";

import * as React from "react";
import * as Redux from "redux";
import { connect } from "react-redux";

import { Grid, List, ListItem, ListItemText, Paper, Divider, Typography } from "@material-ui/core";

import { useSnackbar } from "notistack";
import { ApplicationState } from "../../../redux/reducers";
import {
  getAllUsersWithRolesDispatch,
  getAllRolesDispatch,
  updateUserRoleDispatch,
  setPrimaryUserByRoleNameDispatch,
  getPrimaryUserByRoleName,
  deleteUserDispatch
} from "../../../services";
import { DeleteUserRequestBody, UserResponse } from "../../../models";
import { AppContext } from "../../../context/AppContext";
import { Page } from "../Page";
import { Users } from "./Users";
import { SettingsProps } from "./types";
import { FinalApprover } from "./FinalApprover";
import { FORM_APPROVER } from "../../../constants/userRoles";
import { ConfirmationType } from "../../Dialogs/types";
import { showErrorSnackbar, showSuccessSnackbar } from "../../Snackbars/Snackbars";
import { Confirmation } from "../../Dialogs";

function SettingsPage(props: SettingsProps): JSX.Element {
  const {
    getAllRoles,
    getAllUsers,
    roles,
    updateUserRole,
    setFinalApprover,
    users,
    deleteUser,
    confirmation
  } = props;
  const { setSelectedTab } = React.useContext(AppContext);

  const usersRef = React.createRef<HTMLDivElement>();
  const finalApprovalRef = React.createRef<HTMLDivElement>();
  const [primaryApprover, setPrimaryApprover] = React.useState<UserResponse | null>(null);

  const [confirmationActive, setConfirmationActive] = React.useState<boolean>(false);
  const [confirmationMessage, setConfirmationMessage] = React.useState<string>("");
  const [confirmationCallback, setConfirmationCallback] =
    React.useState<(() => void) | undefined>(undefined);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (setSelectedTab) setSelectedTab(5);

    if (!roles.completed && !roles.pending) getAllRoles();
    if (!users.completed && !users.pending) getAllUsers();

    if (!primaryApprover) {
      getPrimaryUserByRoleName(FORM_APPROVER).then(fa => setPrimaryApprover(fa));
    }
  }, []);

  function scrollToRef(ref: React.RefObject<HTMLDivElement>): void {
    if (ref && ref.current) {
      window.scrollTo({ left: 0, top: ref.current.offsetTop - 75, behavior: "smooth" });
    }
  }

  function triggerConfirmationDialog(
    message: string,
    user: UserResponse,
    type: ConfirmationType
  ): void {
    setConfirmationCallback(() => (): void => handleUserDeletion(user));
    setConfirmationMessage(message);
    setConfirmationActive(true);
  }

  function handleUserDeletion(user: UserResponse): void {
    if (user) {
      const request: DeleteUserRequestBody = {
        id: user.id,
        name: user.name,
        email: user.email
      };
      deleteUser(request)
        .then(sf => showSuccessSnackbar(enqueueSnackbar, closeSnackbar, "Deleted user."))
        .then(sf => window.location.reload())
        .catch(error =>
          showErrorSnackbar(enqueueSnackbar, closeSnackbar, "Failed to delete user.")
        );
    }
  }

  function closeConfirmationDialog(): void {
    setConfirmationActive(false);
  }

  return (
    <Page title="Settings">
      <Grid container direction="row" spacing={3}>
        <Grid item xs={2}>
          <div className="settings-menu">
            <Paper className="full-width" variant="outlined">
              <List className="settings-menu-list">
                <ListItem button onClick={(): void => scrollToRef(usersRef)}>
                  <ListItemText primary="Users" />
                </ListItem>
                <Divider />
                <ListItem button onClick={(): void => scrollToRef(finalApprovalRef)}>
                  <ListItemText primary="Final Approval" />
                </ListItem>
              </List>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={10}>
          <div ref={usersRef}>
            <Users
              roles={roles}
              updateUserRole={updateUserRole}
              users={users}
              confirmation={triggerConfirmationDialog}
            />
          </div>
          <Confirmation
            inProgress={users.updating}
            onClose={closeConfirmationDialog}
            onProceed={(): void => {
              if (confirmationCallback) confirmationCallback();
            }}
            open={confirmationActive}
            message={confirmationMessage}
          />
          <div className="spacer" />
          <div ref={finalApprovalRef}>
            <FinalApprover
              roles={roles}
              setPrimaryUserByRoleName={setFinalApprover}
              users={users}
              primaryApprover={primaryApprover}
            />
          </div>
        </Grid>
      </Grid>
    </Page>
  );
}

function mapStateToProps(state: ApplicationState): unknown {
  return {
    users: state.users,
    roles: state.roles
  };
}

function mapDispatchToProps(dispatch: Redux.Dispatch<Redux.AnyAction>): unknown {
  return Redux.bindActionCreators(
    {
      getAllUsers: getAllUsersWithRolesDispatch,
      getAllRoles: getAllRolesDispatch,
      updateUserRole: updateUserRoleDispatch,
      deleteUser: deleteUserDispatch,
      setFinalApprover: setPrimaryUserByRoleNameDispatch
    },
    dispatch
  );
}

export const Settings = connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
