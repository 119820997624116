import { Theme, createTheme, ThemeOptions } from "@material-ui/core";

const defaultThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      light: "#9162e4",
      main: "#5e35b1",
      dark: "#280680",
      contrastText: "#ffffff"
    },
    secondary: {
      light: "#5ddef4",
      main: "#00acc1",
      dark: "#007c91",
      contrastText: "#000000"
    },
    error: {
      main: "#b71c1c"
    },
    info: {
      main: "#0d47a1"
    },
    success: {
      main: "#1b5e20"
    }
  }
};

const defaultDarkThemeOptions: ThemeOptions = {
  palette: {
    type: "dark",
    primary: {
      light: "#b085f5",
      main: "#7e57c2",
      dark: "#4d2c91",
      contrastText: "#ffffff"
    },
    secondary: {
      light: "#88ffff",
      main: "#4dd0e1",
      dark: "#009faf",
      contrastText: "#000000"
    },
    error: {
      main: "#e57373",
      dark: "#ba000da0"
    },
    info: {
      main: "#64b5f6",
      dark: "#0069c0a0"
    },
    success: {
      main: "#81c784",
      dark: "#087f23a0"
    }
  }
};

const textFieldsThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#5e35b1"
    },
    secondary: {
      main: "#00acc1"
    },
    error: {
      main: "#b71c1c"
    }
  },
  overrides: {
    MuiTableCell: {
      root: {
        paddingTop: 2,
        paddingBottom: 2,
        fontSize: "15px"
      }
    }
  }
};

const darkTextFieldsThemeOptions: ThemeOptions = {
  palette: {
    type: "dark",
    primary: {
      main: "#b085f5"
    },
    secondary: {
      main: "#00acc1"
    },
    error: {
      main: "#e57373"
    }
  },
  overrides: {
    MuiTableCell: {
      root: {
        paddingTop: 2,
        paddingBottom: 2,
        fontSize: "15px"
      }
    }
  }
};

const statusThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#2e7d32"
    },
    secondary: {
      main: "#b71c1c"
    },
    warning: {
      main: "#ff8f00"
    }
  }
};

const darkStatusThemeOptions: ThemeOptions = {
  palette: {
    type: "dark",
    primary: {
      main: "#81c784"
    },
    secondary: {
      main: "#e57373"
    },
    warning: {
      main: "#ffd54f"
    }
  }
};

const preAuthThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#673ab7"
    },
    secondary: {
      main: "#4d2c91"
    }
  }
};

export const defaultTheme: Theme = createTheme(defaultThemeOptions);
export const defaultDarkTheme: Theme = createTheme(defaultDarkThemeOptions);
export const textFieldsTheme: Theme = createTheme(textFieldsThemeOptions);
export const darkTextFieldsTheme: Theme = createTheme(darkTextFieldsThemeOptions);
export const statusTheme: Theme = createTheme(statusThemeOptions);
export const darkStatusTheme: Theme = createTheme(darkStatusThemeOptions);
export const preAuthTheme: Theme = createTheme(preAuthThemeOptions);
