import { Theme, makeStyles, createStyles } from "@material-ui/core";
import { RoleResponse } from "../../models";

export interface UserMenuProps {
  onButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onLogoutClick: () => void;
  onMenuClose: () => void;
  onThemeSwitch: () => void;
  menuAnchorRef: HTMLElement | null;
  userRoles?: RoleResponse[];
  userEmail?: string;
  userName?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      color: theme.palette.getContrastText(theme.palette.secondary.dark),
      backgroundColor: theme.palette.secondary.dark
    }
  })
);
